<template>
  <div v-if="isRendered" class="r-page-edit">
    <div class="r-page-edit-header">
      <div class="r-page-edit-header-content">
        <div class="r-page-edit-header-title">👤 הפרופיל שלי</div>
      </div>
    </div>

    <div class="r-page-edit-body">
      <div class="r-page-edit-body-container" style="max-width: 700px">
        <div class="r-page-edit-body-content">
          <RSimpleCard :disabled="isLoading" class="mb-4" title="פרטים אישיים">
            <RFields>
              <RFieldText v-model="userSettings.name" disabled label="שם מלא"/>

              <RFieldText
                  v-model="userSettings.email"
                  disabled
                  label="אימייל"
              />

              <RFieldText
                  v-model="userSettings.phone"
                  label="טלפון"
                  data-test="phone"
                  :only-numbers="true"
              />

              <RButton
                  block
                  lg
                  :loading="isLoading"
                  @click="submitUpdateProfile"
              >
                שמירת שינויים
              </RButton>
            </RFields>
          </RSimpleCard>
          <RSimpleCard
              :disabled="isLoading"
              :title="`הפרופיל שלך ב-${displayCompanyName}`"
              class="mb-4"
          >
            <RFieldText
                v-model="userSettings.profession"
                class="mb-2"
                label="מקצוע"
            />

            <RFieldWysiwyg id="education-field" v-model="userSettings.education" label="השכלה"/>

            <RFieldWysiwyg id="experience-field" v-model="userSettings.experience" label="ניסיון"/>

            <RFieldWysiwyg
                v-model="userSettings.signatureText"
                label="חתימה"
                :items="['bold', 'italic', 'underline']"
            />

            <RButton
                v-if="shouldCreateAutoUserSignature"
                class="mt-0 mb-6"
                sm
                outlined
                color="black"
                @click="createAutoUserSignature"
            >
              צרו לי חתימה אוטומטית
            </RButton>

            <div class="app-field">
              <label class="mb-2 d-block subtitle-2"
              >איזה סוג חתימה תרצו?</label
              >
              <div class="d-flex u-gap-1">
                <RButton
                    icon-before="mdi-image"
                    :outlined="userSettings.signatureType === 'draw'"
                    sm
                    @click="userSettings.signatureType = 'image'"
                >תמונה
                </RButton
                >
                <RButton
                    icon-before="mdi-pencil"
                    :outlined="userSettings.signatureType === 'image'"
                    sm
                    @click="userSettings.signatureType = 'draw'"
                >ציור חתימה
                </RButton
                >
              </div>
            </div>

            <RFieldImage
                v-if="userSettings.signatureType === 'image'"
                v-model="userSettings.signatureImage"
                label="תמונת חתימה"
            />

            <RFieldSignature
                v-if="userSettings.signatureType === 'draw'"
                v-model="userSettings.signatureDraw"
                label="ציור חתימה"
                modal-title="חתימה"
            />

            <br/>

            <RButton id="save-profile-button" block lg :loading="isLoading" @click="submitUpdateProfile">
              שמירת שינויים
            </RButton>
          </RSimpleCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, computed, nextTick, onMounted, onBeforeUnmount, watch} from 'vue'
import {useStore} from 'vuex'
import {useRouter, useRoute} from 'vue-router'
import AuthApi from "@/api/AuthApi"
import {useShepherd} from 'vue-shepherd'
import {useLoading} from 'vue-loading-overlay'
import {useToast} from 'vue-toastification'
import TourService from '@/services/TourService'

const store = useStore()
const router = useRouter()
const route = useRoute()
const loading = useLoading()
const toast = useToast()

const userSettings = ref({
  name: null,
  email: null,
  phone: null,
  education: null,
  experience: null,
  profession: null,
  signatureText: null,
  signatureImage: null,
  signatureType: "image",
  signatureDraw: null,
})
const isRendered = ref(false)
const isLoading = ref(false)
const debugMode = ref(localStorage.getItem('settings/debugMode') === 'true')

// Watch debug mode changes
watch(debugMode, (newValue) => {
  localStorage.setItem('settings/debugMode', newValue)
  window.location.reload()
})

// Computed properties
const user = computed(() => store.getters['auth/user'])
const displayCompanyName = computed(() => store.getters['auth/displayCompanyName'])

const shouldCreateAutoUserSignature = computed(() =>
    !userSettings.value.signatureText ||
    userSettings.value.signatureText.length < 10
)


// Methods
const createAutoUserSignature = () => {
  let nameAndProfession = userSettings.value.name
  if (userSettings.value.profession) {
    nameAndProfession += ", " + userSettings.value.profession
  }

  userSettings.value.signatureText = `<p>
    <strong>בברכה,</strong>
    <br>
    <strong>${nameAndProfession}</strong>
    <br>
    <strong>${displayCompanyName.value}</strong>
    </p>`
  isRendered.value = false
  nextTick(() => {
    isRendered.value = true
  })
}

const setInitialData = async () => {
  let loader = loading.show()

  userSettings.value.name = user.value.name
  userSettings.value.email = user.value.email
  userSettings.value.phone = user.value.phone
  userSettings.value.profession = user.value.profession
  userSettings.value.education = user.value.education
  userSettings.value.experience = user.value.experience
  userSettings.value.signatureText = user.value.signatureText
  userSettings.value.signatureType = user.value.signatureType || "image"
  userSettings.value.signatureImage = user.value.signatureImage
  userSettings.value.signatureDraw = user.value.signatureDraw
      ? user.value.signatureDraw
      : ""

  loader.hide()
}

const submitUpdateProfile = async () => {
  let userData = {
    name: userSettings.value.name,
    email: userSettings.value.email,
    phone: userSettings.value.phone,
    profession: userSettings.value.profession,
    experience: userSettings.value.experience,
    signatureText: userSettings.value.signatureText,
    signatureType: userSettings.value.signatureType,
    signatureImage: userSettings.value.signatureImage,
    signatureDraw: userSettings.value.signatureDraw,
    education: userSettings.value.education,
  }

  isLoading.value = true

  try {
    await AuthApi.updateMe(userData)
    await store.dispatch('auth/fetchMe')
    toast.success("הפרופיל עודכן בהצלחה")
  } catch (e) {
    toast.error(
        Utils.toastMessageFromResponse(
            e,
            "שגיאה: לא הצלחנו לעדכן את הפרופיל"
        )
    )
  } finally {
    isLoading.value = false
  }
}

// Created lifecycle hook equivalent
onMounted(async () => {
  await setInitialData()
  isRendered.value = true
})

// Tour
const tour = useShepherd({
  useModalOverlay: true,
  defaultStepOptions: {
    classes: 'shepherd-theme-default',
    scrollTo: true
  }
});

tour.addStep({
  id: 'step-1',
  text: '<b>מלאו את פרטי ההשכלה שלכם</b> <br> הפרטים האלה יוצגו בדוח שתפיקו בצורה אוטומטית. <br> <small>(אפשר גם להכניס סתם משהו לדוגמא כרגע)</small>',
  attachTo: {element: '#education-field', on: 'bottom'},
  buttons: [
    {
      text: 'הבא',
      action: () => tour.next()
    },
  ]
})

tour.addStep({
  id: 'step-2',
  text: '<b>מלאו את פרטי הניסיון שלכם</b> <br> הפרטים האלה יוצגו בדוח שתפיקו בצורה אוטומטית. <br> <small>(אפשר גם להכניס סתם משהו לדוגמא כרגע)</small>',
  attachTo: {element: '#experience-field', on: 'bottom'},
  buttons: [
    {
      text: 'הבא',
      action: () => tour.next()
    },
  ]
})

tour.addStep({
  id: 'step-3',
  text: '<b>לחצו על כפתור שמירת שינויים</b> <br> תמיד תוכלו לחזור ולעדכן את הפרטים שוב.',
  attachTo: {element: '#save-profile-button', on: 'top'},
  buttons: [
    {
      text: 'סיימתי',
      action: () => {
        TourService.setStepComplete('profile')
        tour.complete()
        router.push('/')
      }
    }
  ]
})

onMounted(() => {
  // Start tour if URL contains ?tour
  if (route.fullPath.includes('?tour')) {
    setTimeout(() => {
      tour.start()
    }, 1000)
  }
})

onBeforeUnmount(() => {
  if (tour.isActive()) {
    tour.hide()
  }
})

</script>
