<template>
  <div class="r-page-table">
    <div class="r-page-table-header">
      <div class="r-page-table-header-content">
        <h3 class="r-page-table-header-title">
          {{ title }}
          <span v-if="totalProjects">({{ totalProjects }})</span>
        </h3>
      </div>

      <div class="r-page-table-header-actions">
        <RSearchCompact
          v-model="searchQuery"
          style="width: 330px"
          placeholder="חיפוש"
          :initial-open="true"
        />
        <RButton
          v-if="$checkRole(['admin', 'owner'])"
          color="purple"
          :to="{ name: 'projects.add' }"
        >
          פרויקט חדש
        </RButton>
      </div>
    </div>

    <div class="r-page-table-body">
      <!-- Mobile View -->
      <div v-if="isMobile && isLoaded && projects" class="rm-list">
        <div v-for="project in filteredProjects" :key="project.id" class="rm-item">
          <router-link
            :to="{
              name: 'projects.edit',
              params: { id: project.id },
            }"
          >
            <div class="rm-item--header">
              <span class="date">
                {{ $Utils.presentHebrewDate(project.created_at) }}
              </span>
              <span class="number">
                {{ project.number }}
                <RIcon v-if="project.visibility === 'private'" class="mr-1" size="14">
                  mdi-lock
                </RIcon>
              </span>
            </div>
            <div class="rm-item--body">
              <div class="rm-item--body--right">
                <p class="address">{{ project.address }}</p>
                <p class="customer mt-3">{{ project.customer_name }}</p>
              </div>
              <div class="rm-item--body--left">
                <p>{{ project.name }}</p>
                <RChip v-if="project.tag" sm class="mt-3">
                  {{ project.tag }}
                </RChip>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <!-- Desktop View -->
      <v-table v-if="!isMobile && isLoaded && projects">
        <thead>
          <tr>
            <th class="projects-col-number">מספר</th>
            <th class="projects-col-name">שם פרויקט</th>
            <th class="projects-col-address">כתובת</th>
            <th class="projects-col-customer">לקוח</th>
            <th class="projects-col-tag">תווית</th>
            <th class="projects-col-date">תאריך יצירה</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="project in filteredProjects" :key="project.id">
            <td>
              <router-link
                :to="{
                  name: 'projects.edit',
                  params: { id: project.id },
                }"
                style="
                  color: var(--color-gray-700) !important;
                  text-decoration: underline;
                  text-underline-position: under;
                "
              >{{ project.number }}</router-link>
              <RIcon v-if="project.visibility === 'private'" class="mr-1" size="12">
                mdi-lock
              </RIcon>
            </td>
            <td>{{ project.name }}</td>
            <td>{{ project.address }}</td>
            <td>{{ project.customer_name }}</td>
            <td>
              <RChip v-if="project.tag">
                {{ project.tag }}
              </RChip>
            </td>
            <td>{{ $Utils.presentHebrewDate(project.created_at) }}</td>
          </tr>
        </tbody>
      </v-table>

      <div v-if="isLoading" class="reports-loader">
        <RIconLoading size="20" />
        <div class="subtitle-1">טוענים פרויקטים ...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "📓 פרויקטים",
    },
  },
  data() {
    return {
      isLoaded: false,
      isLoading: false,
      projects: [],
      searchQuery: "",
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
    totalProjects() {
      return this.projects?.length || 0;
    },
    filteredProjects() {
      if (!this.searchQuery) return this.projects;

      const query = this.searchQuery.toLowerCase();

      return this.projects.filter(project =>
        project.name?.toLowerCase().includes(query) ||
        project.number?.toString().toLowerCase().includes(query) ||
        project.address?.toLowerCase().includes(query) ||
        project.customer_name?.toLowerCase().includes(query) ||
        project.tag?.toLowerCase().includes(query)
      );
    }
  },
  async created() {
    await this.fetchProjects();
    this.isLoaded = true;
  },
  methods: {
    async fetchProjects() {
      this.isLoading = true;
      await this.$store.dispatch("projects/fetchProjects");
      this.projects = this.$store.state.projects.projects;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss">
// Reuse existing styles from ReportsList.vue
.projects-col-number { width: 80px; }
.projects-col-name { width: 180px; }
.projects-col-address { width: 220px; }
.projects-col-customer { width: 200px; }
.projects-col-tag { width: 140px; }
.projects-col-date { width: 130px; }

// Apply responsive adjustments
@media (max-width: 1440px) {
  .projects-col-address { width: 187px; }
  .projects-col-customer { width: 170px; }
  .projects-col-name { width: 153px; }
}

@media (max-width: 1250px) {
  .projects-col-address { width: 168px; }
  .projects-col-customer { width: 153px; }
  .projects-col-name { width: 138px; }
}
</style>
