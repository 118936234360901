<template>
  <div class="p-4">
    <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4">
      <div class="p-6">
        <h2 class="text-xl font-semibold mb-4">ממצאים</h2>

        <!-- Filters Section -->
        <div class="mb-6">
          <!-- Add Collapse Toggle Button for Mobile -->
          <div class="md:hidden mb-4">
            <RButton
              block
              outlined
              lg
              color="black"
              icon-before="mdi-filter"
              @click="isFiltersExpanded = !isFiltersExpanded"
              :append-icon="isFiltersExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'"
            >
              סינון ממצאים

              <span class="text-xs font-medium">
                ({{ Object.values(filters).reduce((acc, curr) => acc + curr.length, 0) }})
              </span>
            </RButton>
          </div>

          <!-- Wrap filters in collapse -->
          <v-expand-transition>
            <div v-show="isFiltersExpanded || !isMobile">
              <div class="grid grid-cols-1 md:grid-cols-6 gap-4">
                <!-- Categories Filter -->
                <div>
                  <v-select
                    v-model="filters.categories"
                    :items="siteCategories"
                    label="קטגוריות"
                    multiple
                    chips
                    closable-chips
                    density="compact"
                    hide-details
                    variant="outlined"
                    class="filter-select"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        closable
                        size="small"
                        @click:close="removeFilter('categories', item.value)"
                      >
                        {{ item.value }}
                      </v-chip>
                    </template>
                  </v-select>
                </div>

                <!-- Statuses Filter -->
                <div>
                  <v-select
                    v-model="filters.statuses"
                    :items="siteStatuses"
                    label="סטטוס"
                    multiple
                    chips
                    hide-details
                    closable-chips
                    density="compact"
                    variant="outlined"
                    class="filter-select"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        closable
                        size="small"
                        :color="getStatusColor(item.value).replace('bg-', '')"
                        class="text-white"
                        @click:close="removeFilter('statuses', item.value)"
                      >
                        {{ item.value }}
                      </v-chip>
                    </template>
                  </v-select>
                </div>

                <!-- Responsibility Filter -->
                <div>
                  <v-select
                    v-model="filters.responsibilities"
                    :items="siteResponsibilities"
                    label="אחריות"
                    multiple
                    chips
                    closable-chips
                    hide-details
                    density="compact"
                    variant="outlined"
                    class="filter-select"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        closable
                        size="small"
                        @click:close="removeFilter('responsibilities', item.value)"
                      >
                        {{ item.value }}
                      </v-chip>
                    </template>
                  </v-select>
                </div>

                <!-- Area Filter -->
                <div>
                  <v-select
                    v-model="filters.areas"
                    :items="uniqueAreas"
                    label="אזור"
                    multiple
                    chips
                    closable-chips
                    density="compact"
                    variant="outlined"
                    class="filter-select"
                    hide-details
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        closable
                        size="small"
                        @click:close="removeFilter('areas', item.value)"
                      >
                        {{ item.value }}
                      </v-chip>
                    </template>
                  </v-select>
                </div>

                <!-- Level Filter -->
                <div>
                  <v-select
                    v-model="filters.levels"
                    :items="uniqueLevels"
                    label="קומה"
                    multiple
                    chips
                    closable-chips
                    density="compact"
                    variant="outlined"
                    class="filter-select"
                    hide-details
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        closable
                        size="small"
                        @click:close="removeFilter('levels', item.value)"
                      >
                        {{ item.value }}
                      </v-chip>
                    </template>
                  </v-select>
                </div>

                <!-- Building Filter -->
                <div>
                  <v-select
                    v-model="filters.buildings"
                    :items="uniqueBuildings"
                    label="בניין"
                    multiple
                    chips
                    closable-chips
                    density="compact"
                    variant="outlined"
                    class="filter-select"
                    hide-details
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        closable
                        size="small"
                        @click:close="removeFilter('buildings', item.value)"
                      >
                        {{ item.value }}
                      </v-chip>
                    </template>
                  </v-select>
                </div>
              </div>

              <!-- Clear All Filters Button -->
              <div v-if="hasActiveFilters" class="flex justify-end mt-2 gap-2">
                <v-btn
                  color="error"
                  variant="text"
                  size="small"
                  @click="clearAllFilters"
                  prepend-icon="mdi-close"
                >
                  נקה הכל
                </v-btn>
                <v-btn
                  color="primary"
                  variant="text"
                  size="small"
                  @click="isReportModalOpen = true"
                  prepend-icon="mdi-file-export"
                >
                  יצא דוח
                </v-btn>
              </div>
            </div>
          </v-expand-transition>
        </div>
        
        <!-- Loading State -->
        <div v-if="loading" class="text-center py-8">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>

        <!-- Error State -->
        <div v-else-if="error" class="text-center py-8 text-red-600">
          {{ error }}
        </div>

        <!-- Findings Table -->
        <v-data-table
          v-else
          :headers="headers"
          :items="filteredFindings"
          :loading="loading"
          class="elevation-3 sm:mx-0 sm:w-full sm:max-w-full -mx-6 w-[calc(100%+1.5rem+1.5rem)] ![max-width:calc(100%+1.5rem+1.5rem)]"
          :items-per-page="30"
          hover 
          @click:row="handleRowClick"
        >
          <!-- Category Column -->
          <template v-slot:item.category="{ item }">
            <span class="text-sm font-medium">{{ item.category }}</span>
          </template>

          <!-- Status Column -->
          <template v-slot:item.status="{ item }">
            <v-chip
              :color="getStatusColor(item.status)"
              text-color="white"
              size="small"
            >
              {{ item.status }}
            </v-chip>
          </template>

          <!-- Description Column -->
          <template v-slot:item.description="{ item }">
            <div class="text-sm max-w-md">{{ item.description }}</div>
          </template>

          <!-- Responsibility Column -->
          <template v-slot:item.responsibility="{ item }">
            <div class="text-sm">{{ item.responsibility || '-' }}</div>
          </template>

          <!-- Location Column -->
          <template v-slot:item.location="{ item }">
            <div class="text-sm">{{ item.location || '-' }}</div>
          </template>

          <!-- Images Column -->
          <template v-slot:item.images="{ item }">
            <div class="flex gap-1" v-if="item.images?.length > 0">
              <v-avatar
                size="32"
                class="cursor-pointer relative"
              >
                <v-img :src="item.images[0].url" cover />
                <div
                  v-if="item.images.length > 1"
                  class="absolute inset-0 bg-black/60 flex items-center justify-center rounded-full"
                >
                  <span class="text-xs text-white">+{{ item.images.length - 1 }}</span>
                </div>
              </v-avatar>
            </div>
          </template>

          <!-- No Data Text -->
          <template v-slot:no-data>
            <div class="text-center py-4 text-gray-500">
              לא נמצאו ממצאים
            </div>
          </template>

          <!-- Created At Column -->
          <template v-slot:item.created_at="{ item }">
            <div class="text-sm">{{ new Date(item.created_at).toLocaleDateString('he-IL') }}</div>
          </template>
        </v-data-table>
      </div>
    </div>

    <FindingModal
      v-model="isEditModalOpen"
      :finding="selectedFinding"
      :action="modalAction"
      @submit="handleSubmit"
    />

    <RModal
      v-model="isReportModalOpen"
      title="הפקת דוח"
      size="lg"
    >
      <form v-if="reportExportingStep === 'collectData'" @submit.prevent="handleExportReport" class="space-y-6">
        <!-- Findings Summary -->
        <div class="bg-gray-100 p-4 rounded-lg mb-4">
          <div class="flex items-center justify-between mb-3">
            <h4 class="text-sm font-medium text-gray-700">
              הדוח יכלול 
              <span class="font-semibold text-purple-600">{{ filteredFindings.length }} ממצאים</span>  
              לפי הסינון הבא:
            </h4>
          </div>

          <div v-if="hasActiveFilters">
            <div class="space-y-1.5">
              <template v-for="(values, key) in activeFilters" :key="key">
                <div class="text-sm flex gap-2">
                  <span class="font-medium min-w-24">{{ getFilterLabel(key) }}:</span>
                  <span class="text-gray-600">{{ values.join(' | ') }}</span>
                </div>
              </template>
            </div>
          </div>
          <div v-else class="text-sm text-gray-600">
            הדוח יכלול את כל הממצאים (ללא סינון)
          </div>
        </div>

        <!-- Recipients Selection -->
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-2">נמענים</label>
          <v-select
            v-model="reportData.selectedRecipients"
            :items="siteRecipients"
            item-title="name"
            item-value="id"
            label="בחר נמענים"
            multiple
            chips
            closable-chips
            density="compact"
            variant="outlined"
            hide-details
            return-object
          >
            <template v-slot:chip="{ props, item }">
              <v-chip
                v-bind="props"
                :text="item.raw.name"
              />
            </template>
            <template v-slot:item="{ props, item }">
              <v-list-item
                v-bind="props"
                :title="item.raw.name"
                :subtitle="`${item.raw.role} - ${item.raw.email}`"
              >
                <template v-slot:prepend>
                  <v-avatar
                    color="grey-lighten-1"
                    size="32"
                    variant="tonal"
                  >
                    <span class="text-uppercase">{{ item.raw.name.charAt(0) }}</span>
                  </v-avatar>
                </template>
              </v-list-item>
            </template>
          </v-select>
        </div>

        <div class="flex gap-2 w-full sm:flex-row flex-col">
          <!-- Right Top Text -->
          <RFieldWysiwyg class="w-full" :items="['bold', 'italic', 'underline']" label="טקסט עליון מימין" v-model="reportData.rightTopText" />

          <!-- Left Top Text -->
          <RFieldWysiwyg class="w-full" :items="['bold', 'italic', 'underline']" label="טקסט עליון שמאל" v-model="reportData.leftTopText" />
        </div>
        

        <div class="app-field">
          <label class="mb-2 r-field-label">
            כותרת הדוח
          </label>
          <textarea
              v-model="reportData.mainTitle"
              rows="2"
              maxlength="150"
              class="w-full px-4 py-2 border rounded-lg text-2xl font-bold text-center resize-none focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent max-h-[120px]"
          />
        </div>

        
        <!-- Top Text -->
        <RFieldWysiwyg label="טקסט עליון" v-model="reportData.topText" />

        <!-- Bottom Text -->
        <RFieldWysiwyg label="טקסט תחתון" v-model="reportData.bottomText" />

        <!-- Submit Button -->
        <div class="flex justify-end">
          <RButton
            color="primary"
            block
            lg
            type="submit"
            icon-before="mdi-file-pdf"
            :loading="reportExportingStep === 'generating' || reportExportingStep === 'processing'"
          >
            הפקת הדוח
          </RButton>
        </div>
      </form>

      <div v-if="reportExportingStep === 'generating' || reportExportingStep === 'processing'" class="py-6">
        <div class="flex flex-col items-center justify-center text-center space-y-6">
          <!-- Loading Icon and Animation -->
          <v-progress-circular
            indeterminate
            color="primary"
            size="34"
          />

          <!-- Title -->
          <div class="flex justify-center items-center mt-3 mb-1 text-xl font-bold">
            מכינים את הדוח
          </div>

          <!-- Subtitle -->
          <div class="flex justify-center items-center mt-1 text-sm text-gray-500">
            אל תסגרו את החלון בזמן שמייצרים את הדוח.
            <br>
            פעולה זו עשויה לקחת כמה דקות.
          </div>
        </div>
      </div>

      <div v-if="reportExportingStep === 'failed'" class="py-6">
        <div class="flex flex-col items-center justify-center text-text">
          <div class="w-12 h-12 rounded-full bg-red-100 flex items-center justify-center">
            <v-icon
              color="error"
              size="large"
            >
              mdi-alert-circle
            </v-icon>
          </div>

          <div class="text-xl font-bold mt-4 mb-1">
            יצירת הדוח נכשלה
          </div>

          <div class="text-sm text-gray-500">
            אנא נסה שנית או פנה למנהל המערכת
          </div>
        </div>
      </div>

      <div v-if="reportExportingStep === 'completed'" class="py-6">
        <div class="flex flex-col items-center justify-center text-center space-y-6">
          <!-- Success Icon -->
          <div class="w-12 h-12 rounded-full bg-green-100 flex items-center justify-center">
            <v-icon
              color="success"
              size="large"
            >
              mdi-check-circle
            </v-icon>
          </div>

          <!-- Title -->
          <div class="flex justify-center items-center mt-3 mb-1 text-xl font-bold">
            הדוח נוצר בהצלחה
          </div>

          <!-- Subtitle -->
          <div class="flex justify-center items-center mt-1 text-sm text-gray-500">
            הדוח מוכן להורדה.
            <br />
            לאחר ההורדה, הדוח יישלח גם למייל של הנמענים שנבחרו.
          </div>

          <!-- Download Button -->
          <RButton
            color="primary"
            lg
            icon-before="mdi-download"
            :href="reportExportedData.full_pdf_url"
            target="_blank"
            class="mt-4"
          >
            הורדת דוח
          </RButton>
        </div>
      </div>
    </RModal>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useSite } from '@/composables/useSite'
import SiteFindingsApi from '@/api/SiteFindingsApi'
import FindingModal from './components/FindingModal.vue'
import RFieldWysiwyg from '@/ui/RFieldWysiwyg.vue'
import SiteReportsApi from '@/api/SiteReportsApi'
import SitesApi from '@/api/SitesApi'
import { useToast } from 'vue-toastification'
import RFieldTextarea from '@/ui/RFieldTextarea.vue'

const route = useRoute()
const router = useRouter()

const {
  site,
  isLoading: siteLoading,
  error: siteError,
  siteCategories,
  siteResponsibilities,
  siteStatuses,
  getStatusColor,
  getSiteBuildings,
  getSiteLevels,
  getSiteAreas
} = useSite()

// Replace dummy data with API data
const findings = ref([])
const loading = ref(false)
const error = ref(null)

// Load findings from API
const loadFindings = async () => {
  loading.value = true
  error.value = null
  try {
    const response = await SiteFindingsApi.getFindings(route.params.id)
    findings.value = response.data
  } catch (err) {
    console.error('Error loading findings:', err)
    error.value = 'Failed to load findings'
  } finally {
    loading.value = false
  }
}

// Filters state
const filters = ref({
  categories: [],
  statuses: [],
  responsibilities: [],
  areas: [],
  levels: [],
  buildings: []
})

// Computed properties
const hasActiveFilters = computed(() => {
  return Object.values(filters.value).some(filter => filter.length > 0)
})

const activeFilters = computed(() => {
  const active = {}
  Object.entries(filters.value).forEach(([key, values]) => {
    if (values.length > 0) {
      active[key] = values
    }
  })
  return active
})

const uniqueAreas = computed(() => {
  const areas = getSiteAreas()
  return areas.map(area => ({
    title: area.name,
    value: area.id
  }))
})

const uniqueLevels = computed(() => {
  const levels = getSiteLevels()
  return levels.map(level => ({
    title: level.name,
    value: level.id
  }))
})

const uniqueBuildings = computed(() => {
  const buildings = getSiteBuildings()
  return buildings.map(building => ({
    title: building.name,
    value: building.id
  }))
})

const filteredFindings = computed(() => {
  if (!findings.value) return []
  
  return findings.value.filter(finding => {
    const categoryMatch = filters.value.categories.length === 0 || 
      (finding.category && filters.value.categories.includes(finding.category))
    
    const statusMatch = filters.value.statuses.length === 0 || 
      (finding.status && filters.value.statuses.includes(finding.status))
    
    const responsibilityMatch = filters.value.responsibilities.length === 0 || 
      (finding.responsibility && filters.value.responsibilities.includes(finding.responsibility))
    
    const areaMatch = filters.value.areas.length === 0 || 
      (finding.site_area_id && filters.value.areas.includes(finding.site_area_id))
    
    const levelMatch = filters.value.levels.length === 0 || 
      (finding.site_level_id && filters.value.levels.includes(finding.site_level_id))
    
    const buildingMatch = filters.value.buildings.length === 0 || 
      (finding.site_building_id && filters.value.buildings.includes(finding.site_building_id))
    
    return categoryMatch && statusMatch && responsibilityMatch && 
           areaMatch && levelMatch && buildingMatch
  })
})

// Helper functions
const removeFilter = (filterName, value) => {
  filters.value[filterName] = filters.value[filterName].filter(v => v !== value)
}

const clearAllFilters = () => {
  filters.value = {
    categories: [],
    statuses: [],
    responsibilities: [],
    areas: [],
    levels: [],
    buildings: []
  }
}

// Table headers definition
const headers = [
  {
    title: 'קטגוריה',
    align: 'start',
    key: 'category',
  },
  {
    title: 'סטטוס',
    align: 'start',
    key: 'status',
  },
  {
    title: 'תיאור',
    align: 'start',
    key: 'description',
  },
  {
    title: 'אחריות',
    align: 'start',
    key: 'responsibility',
  },
  {
    title: 'מיקום',
    align: 'start',
    key: 'location',
  },
  {
    title: 'תמונות',
    align: 'start',
    key: 'images',
    width: '32px',
  },
  {
    title: 'בניין',
    align: 'start',
    key: 'site_building_name',
  },
  {
    title: 'קומה',
    align: 'start',
    key: 'site_level_name',
  },
  {
    title: 'אזור',
    align: 'start',
    key: 'site_area_name',
  },
  {
    title: 'נוצר ב',
    align: 'start',
    key: 'created_at',
  },
]

// Update/add these refs
const selectedFinding = ref(null)
const isEditModalOpen = ref(false)
const modalAction = computed(() => selectedFinding.value ? 'edit' : 'add')

// Update the handleRowClick method
const handleRowClick = (event, item) => {
  selectedFinding.value = JSON.parse(JSON.stringify(item.item))
  isEditModalOpen.value = true
}

// Update the handleSubmit method
const handleSubmit = async (formData) => {
  try {
    if (selectedFinding.value) {
      // Edit mode
      await SiteFindingsApi.updateFinding(route.params.id, selectedFinding.value.id, formData)
    } else {
      // Add mode
      await SiteFindingsApi.createFinding(route.params.id, formData)
    }
    await loadFindings()
    isEditModalOpen.value = false
    selectedFinding.value = null
  } catch (error) {
    console.error('Error saving finding:', error)
  }
}

// Report Export
const toast = useToast()
const isReportModalOpen = ref(false)
const siteRecipients = ref([])
const reportData = ref({
  selectedRecipients: [],
  topText: '',
  bottomText: '',
  rightTopText: `<p>לכבוד,<br>${site.value?.customer_name}</p>`,
  leftTopText: `<p>תאריך: ${new Date().toLocaleDateString('he-IL').replace(/\./g, '/')}</p>`,
  mainTitle: `דוח ממצאים לתאריך ${new Date().toLocaleDateString('he-IL').replace(/\./g, '/')}\n${site.value?.name || ''}, ${site.value?.address || ''}`
})

// Set default values
reportData.value.rightTopText = `<p>לכבוד,<br>${site.value?.customer_name}</p>`

const reportExportingStep = ref("collectData")
const reportExportedData = ref(null)

watch(isReportModalOpen, (newVal) => {
  if (!newVal) {
    reportExportingStep.value = "collectData"
    reportExportedData.value = null
  }
})


// Add these methods
const loadSiteRecipients = async () => {
  try {
    const response = await SitesApi.getSiteRecipients(route.params.id)
    siteRecipients.value = response.data
  } catch (error) {
    console.error('Error loading recipients:', error)
    toast.error('שגיאה בטעינת נמענים')
  }
}

const handleExportReport = async () => {
  reportExportingStep.value = 'generating'
  try {
    const payload = {
      recipients: reportData.value.selectedRecipients.map(r => r.id),
      topText: reportData.value.topText,
      bottomText: reportData.value.bottomText,
      findingsFilters: filters.value,
      mainTitle: reportData.value.mainTitle,
      rightTopText: reportData.value.rightTopText,
      leftTopText: reportData.value.leftTopText,
      files: []
    }

    const { data } = await SiteReportsApi.generateReport(route.params.id, payload)
    const siteReportId = data.id;

    reportExportingStep.value = 'processing'

    // Store interval ID and add clear condition
    const checkReportStatus = async () => {
      const { data } = await SiteReportsApi.getSiteReport(route.params.id, siteReportId)
      console.log('checkReportStatus', data)
      if (data.status === 'completed') {
        reportExportedData.value = data
        clearInterval(intervalId)
        reportExportingStep.value = 'completed'
      } else if (data.status === 'processing') {
        reportExportingStep.value = 'processing'
      } else if (data.status === 'failed') {
        reportExportingStep.value = 'failed'
      }
    }
    await checkReportStatus()
    const intervalId = setInterval(checkReportStatus, 4000)

  } catch (error) {
    console.error('Error creating report:', error)
    toast.error('שגיאה ביצירת הדוח. אנא נסה שוב.')
    reportExportingStep.value = 'collectData'
    reportExportedData.value = null
  }
}

const getFilterLabel = (key) => {
  const labels = {
    categories: 'קטגוריות',
    statuses: 'סטטוס',
    responsibilities: 'אחריות',
    areas: 'אזור',
    levels: 'קומה',
    buildings: 'בניין'
  }
  return labels[key] || key
}

// Update these with the other refs
const isFiltersExpanded = ref(false) // Set default to true
const windowWidth = ref(window.innerWidth)

// Add resize handler
const handleResize = () => {
  windowWidth.value = window.innerWidth
  if (!isMobile.value) {
    isFiltersExpanded.value = true
  }
}

// Add this with other computed properties
const isMobile = computed(() => windowWidth.value < 768) // 768px is the md breakpoint

// Update the onMounted and add onUnmounted
onMounted(async () => {
  await loadFindings()
  await loadSiteRecipients()
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style scoped>
.v-expand-transition {
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.v-expand-transition-enter-from,
.v-expand-transition-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
</style> 