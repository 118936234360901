<template>
  <div class="bg-[#f7f6f3] min-h-screen">
    <!-- Loading State -->
    <div v-if="isLoading" class="flex justify-center items-center h-64">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <template v-if="!isLoading && site">
      <!-- Site Header -->
      <div class="bg-white border-b border-gray-200">
        <div class="p-4 sm:p-4">
          <div class="flex flex-row justify-between gap-4">
            <!-- Right side: Site Image -->
            <div class="flex-shrink-0">
              <div v-if="site?.site_image" class="w-14 h-14 sm:w-16 sm:h-16 rounded-lg overflow-hidden">
                <img 
                  :src="Utils.getMediaUrl(site.site_image)" 
                  class="w-full h-full object-cover"
                  alt="Site image"
                />
              </div>
              <div 
                v-else 
                class="w-14 h-14 sm:w-16 sm:h-16 rounded-lg bg-gray-100 flex items-center justify-center"
              >
                <v-icon
                  icon="mdi-image-outline"
                  size="32"
                  class="text-gray-400"
                />
              </div>
            </div>

            <!-- Left side: Title and Info -->
            <div class="flex-grow flex justify-center flex-col">
              <!-- Top Row: Title + Tag -->
              <div class="flex items-center gap-2 mb-1">
                <h1 class="text-xl font-semibold">{{ site?.name }}</h1>
                <RChip 
                  v-if="site.tag"
                  :label="site.tag"
                  color="grey"
                >
                  {{ site.tag }}
                </RChip>
              </div>
              
              <!-- Site Info -->
              <div class="flex gap-0.5 text-gray-600 sm:flex-row flex-col sm:items-center sm-gap-2">
                <div class="text-sm">
                  <span class="font-medium">כתובת: </span>
                  {{ site?.address }}
                </div>
                <div class="text-gray-400 hidden sm:block">•</div>
                <div class="text-sm">
                  <span class="font-medium">לקוח: </span>
                  {{ site?.customer_name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Site Navigation -->
      <nav class="bg-white border-b border-gray-200 px-4 py-2">
        <div class="flex gap-4 overflow-x-auto">
          <RouterLink 
            v-for="(item, index) in navItems"  
            :key="index"
            :to="item.to"
            class="flex items-center gap-1 px-3 py-2 text-gray-600 hover:text-primary-600 hover:bg-gray-50 rounded-md whitespace-nowrap transition-colors duration-200"
            :class="{ '!text-purple-600 !bg-purple-50 !font-medium': isActiveRoute(item.to) }"
          >
            <v-icon 
              :icon="item.icon" 
              size="16"
              :class="{ '!text-purple-600': isActiveRoute(item.to) }"
            />
            <span>{{ item.text }}</span>
          </RouterLink>
        </div>
      </nav>

      <RouterView v-if="site" />
    </template>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useSite } from '@/composables/useSite'
import { useRoute } from 'vue-router'
import { Utils } from '@/common/Utils'

const route = useRoute()
const { 
  site,
  isLoading,
  loadSite,
  clearSite
} = useSite()

onMounted(async () => {
  await loadSite()
})

onUnmounted(() => {
  clearSite()
})

const navItems = [
  {
    icon: 'mdi-home',
    to: `/sites/${route.params.id}`,
    text: 'ראשי'
  },
  {
    icon: 'mdi-list-status',
    to: `/sites/${route.params.id}/findings`,
    text: 'כל הממצאים'
  },
  {
    icon: 'mdi-file-document',
    to: `/sites/${route.params.id}/reports`,
    text: 'דוחות'
  },
  {
    icon: 'mdi-home-group',
    to: `/sites/${route.params.id}/buildings-levels-areas`,
    text: 'ניהול מבנים וקומות'
  },
  {
    icon: 'mdi-map-marker',
    to: `/sites/${route.params.id}/area-types`,
    text: 'אזורים וצ׳קליסטים'
  },
  {
    icon: 'mdi-cog',
    to: `/sites/${route.params.id}/edit`,
    text: 'הגדרות אתר'
  },
  {
    icon: 'mdi-account-group',
    to: `/sites/${route.params.id}/recipients`,
    text: 'נמענים'
  }
]

const isActiveRoute = (path) => {
  // Special case for home route - active when we're on any site page
  if (path === `/sites/${route.params.id}`) {
    return route.path === path
  }
  return route.path === path || route.path.startsWith(path + '/')
}
</script> 