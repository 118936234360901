<template>
  <div class="min-h-screen bg-gradient-to-br from-purple-50 to-white relative flex items-center justify-center py-20 px-4 sm:px-6 sm: lg:px-8">
    <!-- Logo -->
    <img
        src="@/assets/images/reporto-logo.svg"
        alt="Reporto"
        class="absolute top-8 left-1/2 transform -translate-x-1/2 w-24"
    >

    <!-- Add side gradient decoration -->
    <div class="absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-purple-200/50 to-transparent"></div>
    <div class="absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-purple-200/50 to-transparent"></div>

    <div class="w-full max-w-2xl space-y-12 relative z-10">
      <!-- Static Title -->
      <h1 class="text-2xl sm:text-3xl text-center text-gray-900">
        נשמח <span class="text-purple-600 font-bold">להכיר</span> אותך יותר
      </h1>

      <!-- Progress Bar -->
      <div class="w-full flex justify-center">
        <div class="w-full max-w-[220px] sm:max-w-none">
          <div class="flex justify-between mb-0">
            <span v-for="step in 5" :key="step"
                :class="[currentStep >= step ? 'bg-purple-600' : 'bg-gray-200',
                  'w-1/5 h-1 sm:h-2 rounded-full mx-1 transition-all duration-300']">
            </span>
          </div>
        </div>
      </div>

      <!-- Welcome Screen -->
      <div v-if="currentStep === 1" class="text-center">
        <h2 class="text-2xl sm:text-3xl font-semibold text-gray-900 mb-8">
          תודה שבחרת להצטרף ל-Reporto
        </h2>
        <p class="text-gray-600 mb-12 leading-relaxed text-base sm:text-xl">
          רגע לפני שמתחילים,
          <br>
          נשמח למענה על מספר
          <span class="font-semibold text-purple-700">שאלות בודדות</span>
          כדי שנוכל להכיר אותך יותר ולעזור לך
          <span class="font-semibold text-purple-700">להפיק דוחות בדק בית</span>
          בדרך היעילה והמהירה ביותר.
        </p>
        <RButton
            class="px-10"
            lg
            @click="nextStep"
        >
          שנמשיך?
        </RButton>
      </div>

      <!-- Questions -->
      <template v-for="(question, idx) in questions" :key="idx">
        <div v-if="currentStep === idx + 2" class="space-y-8">
          <h3 class="text-xl sm:text-2xl font-bold text-gray-900 text-right">{{ question.question }}</h3>
          <div :class="[question.multiSelect ? 'grid grid-cols-1 gap-6' : 'grid grid-cols-1 gap-6']">
            <div v-for="option in question.options"
                :key="option"
                @click="selectOption(question.field, option, question.multiSelect)"
                :class="[
                   'border-2 p-4 sm:p-6 rounded-lg text-right transition duration-200 bg-white cursor-pointer flex items-center justify-between text-base sm:text-lg',
                   isOptionSelected(question.field, option)
                     ? 'border-purple-600 text-gray-900'
                     : 'border-gray-200 text-gray-700 hover:border-gray-300 hover:bg-gray-50'
                 ]">
              {{ option }} {{ getEmoji(question.field, option) }}
            </div>
          </div>
        </div>
      </template>

      <!-- Navigation Buttons -->
      <div v-if="currentStep > 1" class="flex justify-between mt-12 align-items-center">
        <RButton
            color="purple"
            outlined
            lg
            @click="previousStep"
        >
          הקודם
        </RButton>
        <RButton
            v-if="currentStep < questions.length + 1"
            color="purple"
            icon-after="mdi-chevron-left"
            lg
            :disabled="!canProceed"
            @click="nextStep"
        >
          הבא
        </RButton>
        <RButton
            v-else
            color="green"
            lg
            icon-after="mdi-check"
            :disabled="!canProceed"
            @click="finish"
            :loading="isSubmitting"
        >
          סיום ומעבר למערכת
        </RButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, reactive, computed, onMounted} from 'vue'
import OnboardingApi from '@/api/OnboardingApi'
import posthog from 'posthog-js'
import {useToast} from 'vue-toastification'
import {useRouter} from 'vue-router'
import {useStore} from 'vuex'

const toast = useToast()
const currentStep = ref(1)
const isSubmitting = ref(false)
const router = useRouter()
const store = useStore()

const answers = reactive({
  category: '',
  reportsPerMonth: '',
  employeeCount: '',
  workMethod: ''
})

const questions = [
  {
    question: 'מה תחום העיסוק העיקרי שלך?',
    field: 'category',
    options: ['בדק בית', 'איתור נזילות', 'אחר'],
    multiSelect: false
  },
  {
    question: 'כמות דוחות חודשית ממוצעת',
    field: 'reportsPerMonth',
    options: ['עד 10 דוחות', 'עד 50 דוחות', '50+ דוחות', 'לא מפיק דוחות כרגע'],
    multiSelect: false
  },
  {
    question: 'מספר עובדים',
    field: 'employeeCount',
    options: ['רק אני', 'עד 10 עובדים', 'עד 50 עובדים', '50+ עובדים'],
    multiSelect: false
  },
  {
    question: 'איך את/ה עובד/ת כיום?',
    field: 'workMethod',
    options: ['אקסל/וורד', 'עט ונייר', 'תוכנה אחרת', 'לא מתעד'],
    multiSelect: false
  }
]

const canProceed = computed(() => {
  if (currentStep.value === 1) return true

  const currentQuestion = questions[currentStep.value - 2]
  const answer = answers[currentQuestion.field]

  return currentQuestion.multiSelect ? answer.length > 0 : !!answer
})

const nextStep = () => {
  if (currentStep.value < questions.length + 1 && canProceed.value) {
    currentStep.value++
  }
}

const previousStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--
  }
}

const selectOption = (field, value, isMultiSelect) => {
  if (isMultiSelect) {
    if (!Array.isArray(answers[field])) {
      answers[field] = []
    }
    const index = answers[field].indexOf(value)
    if (index === -1) {
      answers[field].push(value)
    } else {
      answers[field].splice(index, 1)
    }
  } else {
    answers[field] = value
  }
}

const isOptionSelected = (field, value) => {
  const answer = answers[field]
  return Array.isArray(answer) ? answer.includes(value) : answer === value
}

// Capture onboarding_started event when component mounts
onMounted(() => {
  // Confetti script loading
  const script = document.createElement('script')
  script.src = 'https://cdn.jsdelivr.net/npm/tsparticles-confetti@2.12.0/tsparticles.confetti.bundle.min.js'
  script.onload = () => {
    setTimeout(() => {
      window.confetti({
        particleCount: 300,
        spread: 200,
        origin: {y: 0.5}
      })
    }, 1000)
  }
  document.head.appendChild(script)


  let showOnboarding = store.getters['auth/showOnboarding']
  if (!showOnboarding) {
    router.push('/')
  }

  // Track onboarding start
  posthog.capture('log:onboarding:started')
})

const finish = async () => {
  if (!canProceed.value) return

  isSubmitting.value = true

  try {
    await OnboardingApi.finishOnboarding({
      answers
    })

    // Track onboarding completion with answers
    posthog.capture('log:onboarding:finished', {
      category: answers.category,
      reportsPerMonth: answers.reportsPerMonth,
      employeeCount: answers.employeeCount,
      workMethod: answers.workMethod
    })

    // Trigger confetti
    if (window.confetti) {
      window.confetti({
        particleCount: 300,
        spread: 200,
        origin: {y: 0.5}
      })
    }

    setTimeout(() => {
      isSubmitting.value = false
      window.location.href = '/'
    }, 1000)

  } catch (error) {
    console.error('Error finishing onboarding:', error)
    toast.error('שגיאה בשליחת נתונים, נסה שנית מאוחר יותר')
    isSubmitting.value = false
  }
}

const getEmoji = (field, option) => {
  const emojiMap = {
    category: {
      'בדק בית': '🏠',
      'איתור נזילות': '💧',
      // 'פיקוח': '📋',
      'אחר': '✨'
    },
    reportsPerMonth: {
      'עד 10 דוחות': '',
      'עד 50 דוחות': '',
      '50+ דוחות': '',
      'לא ידוע לי': ''
    },
    employeeCount: {
      'רק אני': '💪',
      'עד 10 עובדים': '',
      'עד 50 עובדים': '',
      '50+ עובדים': ''
    },
    workMethod: {
      'אקסל/וורד': '📗',
      'עט ונייר': '📝',
      'תוכנה אחרת': '💻',
      'לא מתעד': '❌'
    }
  }

  return emojiMap[field]?.[option] || ''
}
</script>

<style>
/* Add RTL support */
* {
  direction: rtl;
}
</style>
