<template>
  <v-menu
      :offset="4"
      :close-on-content-click="true"
  >
    <template v-slot:activator="{ props }">
      <span
          v-bind="props"
          :class="[
            currentStatus?.color ? colorToBackgroundClassMap[currentStatus.color] : 'bg-gray-500',
            'text-white px-2 py-1 rounded-md cursor-pointer hover:opacity-90 inline-flex items-center gap-1 break-normal'
          ]"
      >
        {{ currentStatus?.status || 'ללא סטטוס' }}
        <v-icon size="14" icon="mdi-chevron-down" class="ml-n1" />
      </span>
    </template>

    <div class="min-w-[140px] sm:min-w-[200px] py-1 bg-white rounded-lg shadow-lg overflow-hidden">
      <div
          v-for="status in reportStatusesActive"
          :key="status.id"
          @click="updateReportStatus(status)"
          class="mx-1.5 px-3 py-2 cursor-pointer text-gray-700 text-xs sm:text-base flex items-center gap-2 hover:bg-gray-100 transition-colors relative"
          :class="{ 'font-semibold': currentStatus?.id === status.id }"
      >
        <div
            :class="[
              colorToBackgroundClassMap[status.color],
              'w-3 h-3 sm:w-4 sm:h-4 rounded-sm shrink-0'
            ]"
        ></div>
        <span class="text-[15px]">{{ status.status }}</span>
      </div>
    </div>
  </v-menu>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'vue-toastification'
import ReportsApi from '@/api/ReportsApi'
import { colorToBackgroundClassMap } from '@/common/constants'
import posthog from 'posthog-js'

// Props
const props = defineProps({
  reportId: {
    type: String,
    required: true
  },
  statusId: {
    type: Number,
    default: null
  }
})

// Store and Toast setup
const store = useStore()
const toast = useToast()

// Computed
const reportStatuses = computed(() => store.getters['auth/reportStatuses'])
const reportStatusesActive = computed(() => reportStatuses.value.filter(status => !status.is_disabled))
const currentStatus = computed(() => reportStatuses.value.find(status => status.id === props.statusId))

// Emits
const emit = defineEmits(['update:statusId'])

// Methods
const updateReportStatus = async (newStatus) => {
  posthog.capture("log:report_status_updated", {
    report_id: props.reportId,
    new_status: newStatus.id,
  })

  try {
    await ReportsApi.updateStatus(props.reportId, newStatus.id)
    emit('update:statusId', newStatus.id)
  } catch (error) {
    console.error('Failed to update report status:', error)
    toast.error('שגיאה בעדכון סטטוס הדוח')
  }
}
</script>
