<template>
    <RModal
      :modelValue="isOpen"
      @update:modelValue="$emit('update:modelValue', $event)"
      :title="modalTitle"
      max-width="800px"
    >
      <div class="space-y-4 pa-1">
          <RFieldSelect
            v-model="formData.category"
            :items="siteCategories.map(cat => ({ value: cat, text: cat }))"
            label="קטגוריה"
            required
          />
          <RFieldTextChoices
          disabled
            v-model="formData.status"
            :choices="siteStatuses.map(status => ({
              value: status.title,
              label: status.title,
              color: status.color
            }))"
            label="סטטוס"
            required
          />
          <RFieldTextarea
            v-model="formData.description"
            label="תיאור"
            type="textarea"
            required
          />
          <RFieldTextChoices
            v-model="formData.responsibility"
            :choices="siteResponsibilities.map(resp => ({ value: resp, label: resp }))"
            label="אחריות"
          />
          <RFieldTextChoices
            v-model="formData.location"
            :choices="areaTypeLocations.map(loc => ({ value: loc, label: loc }))"
            label="מיקום"
            clearable
          />
          <div class="space-y-2">
            <div 
              class="text-md font-medium text-gray-700 flex items-center justify-between p-3 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors cursor-pointer"
              @click="showNotes = !showNotes"
            >
              <div class="flex items-center gap-1">
                הערות
              </div>
              <v-icon
                :icon="showNotes ? 'mdi-chevron-down' : 'mdi-chevron-left'"
                size="small"
                class="text-gray-600"
              />
            </div>

            <RFieldTextarea
              v-show="showNotes"
              v-model="formData.notes"
              type="textarea"
            />
          </div>
          <FindingModalImages
            v-model="formData.images"
            class="mb-7"
          />
          <div>
            <RButton
              type="submit"
              color="purple"
              block
              lg
              @click="handleSubmit"
            >
              {{ submitButtonText }}
            </RButton>
          </div>
        </div>
    </RModal>
  </template>
  
  <script setup>
  import { ref, computed, watch } from 'vue'
  import { useSite } from '@/composables/useSite'
  import RFieldTextarea from '@/ui/RFieldTextarea.vue'
  import { useToast } from 'vue-toastification'
  import FindingModalImages from './FindingModalImages.vue'

  const props = defineProps({
    modelValue: {
      type: Boolean,
      required: true
    },
    finding: {
      type: Object,
      default: null
    },
    action: {
      type: String,
      required: true,
      validator: (value) => ['edit', 'add'].includes(value)
    }
  })
  
  const emit = defineEmits(['update:modelValue', 'submit'])
  
  const { siteCategories, siteResponsibilities, siteStatuses, site } = useSite()
  
  // Add toast
  const toast = useToast()
  
  // Form data
  const formData = ref({
    category: '',
    status: '',
    description: '',
    responsibility: '',
    location: '',
    notes: '',
    images: []
  })
  
  // Add this new ref for controlling notes visibility
  const showNotes = ref(false)
  
  // Watch for finding changes - update to also handle notes visibility
  watch(() => props.finding, (newFinding) => {
    if (newFinding) {
      formData.value = { 
        ...newFinding,
        images: newFinding.images || []
      }
      showNotes.value = !!newFinding.notes
    } else {
      formData.value = {
        category: '',
        status: '',
        description: '',
        responsibility: '',
        location: '',
        notes: '',
        images: []
      }
      showNotes.value = false
    }
  }, { immediate: true })
  
  // Computed properties
  const isOpen = computed({
    get: () => props.modelValue,
    set: (value) => emit('update:modelValue', value)
  })
  
  const modalTitle = computed(() => {
    return props.action === 'edit' ? 'עריכת ממצא' : 'הוספת ממצא'
  })
  
  const submitButtonText = computed(() => {
    return props.action === 'edit' ? 'שמור שינויים' : 'הוסף ממצא'
  })
  
  const areaTypeLocations = computed(() => {
    if (!formData.value?.site_area_type_id || !site.value?.area_types) {
      return []
    }
  
    const areaType = site.value.area_types.find(
      type => type.id === formData.value.site_area_type_id
    )
    
    if (areaType?.locations) {
      return areaType.locations.map(location => location.name) || []
    }
    
    return []
  })
  
  // Methods
  const handleSubmit = () => {
    // Validate required fields
    const missingFields = []
    
    if (!formData.value.category) missingFields.push('קטגוריה')
    if (!formData.value.status) missingFields.push('סטטוס')
    if (!formData.value.description) missingFields.push('תיאור')

    if (missingFields.length > 0) {
      toast.error(`נא למלא את השדות הבאים: ${missingFields.join(', ')}`)
      return
    }

    emit('submit', formData.value)
  }
  </script> 