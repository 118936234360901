<template>
  <RouterView/>
</template>

<script>
export default {
  name: "App",
  watch: {
    '$route': {
      immediate: true,
      handler(to) {
        let title = "Reporto";
        if (to.meta.title) {
          title = title + " - " + to.meta.title;
        }
        document.title = title;
      },
    }
  },
};
</script>
