<template>
  <RModal max-width="800px" v-model="shouldShowUpdatesModal" title="📢 עדכונים חדשים ברפורטו" @close="markAllSeen">
    <div class="bg-gray-100 mx-n3 my-n3 pt-3 pb-1 px-3">
      <!-- New Updates -->
      <div v-if="newUpdates.length === 0" class="text-center py-12">
        <div class="text-4xl mb-4">✅</div>
        <div class="text-gray-800 text-xl font-medium mb-2">אין עדכונים חדשים</div>
        <div class="text-gray-500 text-base max-w-sm mx-auto">
          כל העדכונים מסומנים כנקראו. נעדכן אותך כשיהיו חדשים!
        </div>
      </div>
      <div
        v-else
        v-for="update in newUpdates"
        class="border border-gray-200 bg-white mb-3 p-5 updatesModal-item"
      >
        <div class="mb-2">
          <h3 class="text-md font-semibold text-gray-900 flex-1 leading-relaxed">
            {{ update.title }}
            <span
              :class="[
                'px-1.5 py-0.5 text-sm font-medium mr-1',
                getTypeStyles(update.type)
              ]"
            >
              {{ getTypeTranslation(update.type) }}
            </span>
          </h3>
        </div>

        <div class="relative" :data-update-id="update.id">
          <div
            :class="[
              'mt-2 text-gray-600 text-sm leading-relaxed content-wrapper',
              expandedIds.includes(update.id) ? 'expanded' : ''
            ]"
          >
            <div v-html="update.content"></div>
          </div>
          <button
            v-if="shouldShowExpandButton(update)"
            @click="toggleExpand(update.id)"
            class="mt-2 text-blue-500 hover:text-blue-600 text-sm font-medium"
          >
            {{ expandedIds.includes(update.id) ? 'הצג פחות -' : 'קרא עוד >' }}
          </button>
        </div>

        <div class="mt-4 flex items-center text-gray-500 text-xs">
          {{ getRelativeTimeText(update.created_at) }}
        </div>
      </div>

      <!-- Show Previous Updates Button -->
      <div v-if="oldUpdates.length > 0" class="text-center mb-3">
        <button
          @click="toggleShowOldUpdates"
          class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          {{ showOldUpdates ? 'הסתר עדכונים קודמים' : 'הצג עדכונים קודמים' }}
        </button>
      </div>

      <!-- Old Updates -->
      <div v-if="showOldUpdates">
        <div
          v-for="update in oldUpdates"
          class="border border-gray-200 bg-white mb-3 p-5 updatesModal-item"
        >
          <div class="mb-2">
            <h3 class="text-md font-semibold text-gray-900 flex-1 leading-relaxed">
              {{ update.title }}
              <span
                :class="[
                  'px-1.5 py-0.5 text-sm font-medium mr-1',
                  getTypeStyles(update.type)
                ]"
              >
                {{ getTypeTranslation(update.type) }}
              </span>
            </h3>
          </div>

          <div class="relative" :data-update-id="update.id">
            <div
              :class="[
                'mt-2 text-gray-600 text-sm leading-relaxed content-wrapper',
                expandedIds.includes(update.id) ? 'expanded' : ''
              ]"
            >
              <div v-html="update.content"></div>
            </div>
            <button
              v-if="shouldShowExpandButton(update)"
              @click="toggleExpand(update.id)"
              class="mt-2 text-blue-500 hover:text-blue-600 text-sm font-medium"
            >
              {{ expandedIds.includes(update.id) ? 'הצג פחות -' : 'קרא עוד >' }}
            </button>
          </div>

          <div class="mt-4 flex items-center text-gray-500 text-xs">
            {{ getRelativeTimeText(update.created_at) }}
          </div>
        </div>
      </div>
    </div>
  </RModal>
</template>

<style lang="scss">
.updatesModal-item {
  border-radius: 4px;

  img {
    max-width: 450px;
    margin-top: 10px;
    @media (max-width: 500px) {
      max-width:100%;
    }
  }

  .content-wrapper {
    max-height: 400px;
    overflow: hidden;
    position: relative;

    &:not(.expanded) {
      &[data-overflow="true"]::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 30px;
        background: linear-gradient(transparent, white);
      }
    }

    &.expanded {
      max-height: none;
    }
  }
}
</style>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import UpdatesApi from "@/api/UpdatesApi";
import { posthog } from 'posthog-js'

// Add route
const route = useRoute()

// State
const shouldShowUpdatesModal = ref(false)
const updates = ref([])
const expandedIds = ref([])
const showOldUpdates = ref(false)

// Computed
const newUpdates = computed(() => {
  let updatesSeen = getUpdatesSeen();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  return updates.value.filter(update => {
    const updateDate = new Date(update.created_at);
    return !updatesSeen.includes(update.id) && updateDate >= oneMonthAgo;
  });
});

const oldUpdates = computed(() => {
  let updatesSeen = getUpdatesSeen();
  const oneMonthAgo = new Date();
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

  return updates.value.filter(update => {
    const updateDate = new Date(update.created_at);
    return updatesSeen.includes(update.id) || updateDate < oneMonthAgo;
  });
});

const getTypeTranslation = (type) => {
  const translations = {
    'feature': 'פיצ׳ר',
    'update': 'עדכון',
    'message': 'הודעה',
    'new': 'חדש',
    'improvement': 'שדרוג'
  }
  return translations[type] || type
}

const getTypeStyles = (type) => {
  const styles = {
    'feature': 'bg-emerald-50 text-emerald-700',
    'update': 'bg-blue-50 text-blue-700',
    'message': 'bg-gray-50 text-gray-700',
    'new': 'bg-green-50 text-green-700',
    'improvement': 'bg-purple-50 text-purple-700'
  }
  return styles[type] || 'bg-gray-50 text-gray-700'
}

const getRelativeTimeText = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diffInMinutes = Math.floor((now - date) / (1000 * 60));
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInWeeks = Math.floor(diffInDays / 7);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInDays / 365);

  // Format the full date in Hebrew
  const fullDate = date.toLocaleDateString('he-IL', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  // Create relative time text
  let relativeText;
  if (diffInMinutes < 1) {
    relativeText = 'כרגע';
  } else if (diffInMinutes === 1) {
    relativeText = 'לפני דקה';
  } else if (diffInMinutes < 60) {
    relativeText = 'לפני ' + diffInMinutes + ' דקות';
  } else if (diffInHours === 1) {
    relativeText = 'לפני שעה';
  } else if (diffInHours < 24) {
    relativeText = 'לפני ' + diffInHours + ' שעות';
  } else if (diffInDays === 1) {
    relativeText = 'לפני יום';
  } else if (diffInDays < 7) {
    relativeText = 'לפני ' + diffInDays + ' ימים';
  } else if (diffInWeeks === 1) {
    relativeText = 'לפני שבוע';
  } else if (diffInWeeks < 4) {
    relativeText = 'לפני ' + diffInWeeks + ' שבועות';
  } else if (diffInMonths === 1) {
    relativeText = 'לפני חודש';
  }  else if (diffInMonths === 2) {
    relativeText = 'לפני חודשיים';
  } else if (diffInMonths < 12) {
    relativeText = 'לפני ' + diffInMonths + ' חודשים';
  } else if (diffInYears === 1) {
    relativeText = 'לפני שנה';
  } else {
    relativeText = 'לפני ' + diffInYears + ' שנים';
  }

  return `${relativeText} - ${fullDate}`;
}

// Methods
const openModal = () => {
  shouldShowUpdatesModal.value = true
  posthog.capture("log:updates:modal_manually_opened");
}

const fetchUpdates = async () => {
  try {
    const { data } = await UpdatesApi.getUpdates();
    updates.value = data
  } catch (error) {
    console.error('Error fetching updates:', error)
  }
}

const markAllSeen = () => {
  let updatesSeen = getUpdatesSeen();
  newUpdates.value.forEach(update => {
    updatesSeen.push(update.id)
  });
  localStorage.setItem('cache/updatesSeen', JSON.stringify(updatesSeen));
}

const getUpdatesSeen = () => {
  let updatesSeen = localStorage.getItem('cache/updatesSeen');
  if (!updatesSeen) {
    updatesSeen = [];
  } else {
    try {
      updatesSeen = JSON.parse(updatesSeen);
    } catch (e) {
      updatesSeen = [];
    }
  }

  if (!Array.isArray(updatesSeen)) {
      updatesSeen = [];
  }

  return updatesSeen;
}

const toggleExpand = (id) => {
  posthog.capture("log:updates:toggle_read_more_clicked");
  const index = expandedIds.value.indexOf(id)
  if (index === -1) {
    expandedIds.value.push(id)
  } else {
    expandedIds.value.splice(index, 1)
  }
}

const toggleShowOldUpdates = () => {
  showOldUpdates.value = !showOldUpdates.value
  posthog.capture("log:updates:modal_old_updates_toggled");
}

const checkForNewUpdates = () => {
  if (newUpdates.value.length > 0) {
    shouldShowUpdatesModal.value = true
    posthog.capture("log:updates:modal_auto_opened");
  }
}

const shouldShowExpandButton = (update) => {
  // Create a temporary div to measure content height
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = update.content;
  tempDiv.style.width = '100%';
  tempDiv.style.position = 'absolute';
  tempDiv.style.visibility = 'hidden';
  document.body.appendChild(tempDiv);

  const height = tempDiv.offsetHeight;
  document.body.removeChild(tempDiv);

  // Add data-overflow attribute to content wrapper
  const contentWrapper = document.querySelector(`[data-update-id="${update.id}"] .content-wrapper`);
  if (contentWrapper) {
    contentWrapper.setAttribute('data-overflow', height > 400 ? 'true' : 'false');
  }

  // Show button if content height is greater than 100px or if it's already expanded
  return height > 400 || expandedIds.value.includes(update.id);
}

// Watch for route changes
watch(() => route.path, async (newPath) => {
  if (newPath === '/') {
    await fetchUpdates()
    checkForNewUpdates();
  }
}, { immediate: true })

// Expose
defineExpose({
  openModal
})

</script>
