async function getAppState(data) {
    const response = await window.axios.get(
        `/app-state.json?_t=${Date.now()}`,
        {
            baseURL: window.location.origin,
            params: data
        }
    );
    return response.data;
}


export default {
    getAppState,
};
