async function getReports(siteId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/reports`);
  return response.data;
}

async function getSiteReport(siteId, reportId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/reports/${reportId}`);
  return response.data;
}

async function generateReport(siteId, data) {
  const response = await window.axios.post(`/api/v1/sites/${siteId}/reports/generate`, data);
  return response.data;
}

export default {
    getReports,
    getSiteReport,
    generateReport
};
