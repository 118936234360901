import {computed} from 'vue'
import {useStore} from 'vuex'

export function useCompanyFeatures() {
    const store = useStore()
    const features = computed(() => store.getters['auth/features'])

    const hasFeature = (featureSlug) => {
        if (!features.value || !features.value[featureSlug]) {
            return false
        }
        const feature = features.value[featureSlug]
        return feature && feature.isActive === true
    }

    return {
        hasFeature
    }
}
