import { computed } from 'vue'
import { useStore } from 'vuex'

export function useRoleCheck() {
  const store = useStore()
  
  const user = computed(() => store.getters['auth/user'])
  
  const checkRole = (roles) => {
    if (!user.value) return false
    
    const currentUserRoleId = user.value.role_id
    let currentUserRoleName = null
    
    switch (currentUserRoleId) {
      case 2:
        currentUserRoleName = "admin"
        break
      case 3:
        currentUserRoleName = "user"
        break
      case 4:
        currentUserRoleName = "owner"
        break
    }
    
    return roles.includes(currentUserRoleName)
  }

  const getUserRoleName = () => {
    const currentUserRoleId = user.value.role_id
    let currentUserRoleName = null
    
    switch (currentUserRoleId) {
      case 2:
        currentUserRoleName = "admin"
        break
      case 3:
        currentUserRoleName = "user"
        break
      case 4:
        currentUserRoleName = "owner"
        break
    }

    return currentUserRoleName
  }

  return {
    checkRole,
    getUserRoleName
  }
} 