<template>
  <div v-if="isLoaded" class="r-page-edit">
    <div class="r-page-edit-header">
      <div class="r-page-edit-header-content">
        <div class="r-page-edit-header-title">
          📓 פרויקט /
          <span class="r-page-edit-header-title-number">{{
              project.number
            }}</span>
        </div>
        <div class="r-page-edit-header-subtitle">
          {{ project.name }}
          <span v-if="project.address">/ {{ project.address }}</span>
          <span v-if="project.customer_name">
            / {{ project.customer_name }}</span
          >
        </div>
      </div>
    </div>

    <div class="r-page-edit-nav">
      <button
          :class="{
          'is-active': currentNavItem === 'reports',
        }"
          class="r-page-edit-nav-item"
          @click="currentNavItem = 'reports'"
      >
        דוחות
      </button>
      <button
          :class="{
          'is-active': currentNavItem === 'files',
        }"
          class="r-page-edit-nav-item"
          @click="currentNavItem = 'files'"
      >
        תוכניות וקבצים
      </button>
      <button
          :class="{
          'is-active': currentNavItem === 'settings',
          'is-disabled': !$checkRole(['admin', 'owner']),
        }"
          class="r-page-edit-nav-item"
          @click="currentNavItem = 'settings'"
      >
        הגדרות
      </button>
    </div>

    <div class="r-page-edit-body">
      <div
          v-if="!isProjectValid"
          class="mt-6"
          style="background-color: #efdce4; padding: 12px; border-radius: 4px"
      >
        <h3 class="subtitle-1 font-weight-medium">חסרים פרטים בפרויקט</h3>

        <div class="body-2">חובה למלא שם פרויקט, כתובת ושם לקוח.</div>

        <RButton
            class="mt-3"
            sm
            color="red"
            @click="currentNavItem = 'settings'"
        >
          מעבר להגדרות פרויקט
        </RButton>
      </div>

      <div v-if="currentNavItem === 'reports'" class="px-5">
        <div
            v-if="!projectHasTemplates && $checkRole(['admin', 'owner'])"
            class="mt-6"
            style="background-color: #efdce4; padding: 12px; border-radius: 4px"
        >
          <h3 class="subtitle-1 font-weight-medium">
            חובה לשייך תבנית אחת לפחות כדי ליצור דוחות
          </h3>

          <div class="body-2">
            עברו ללשונית ״הגדרות פרויקט״ ושייכו תבנית לפרויקט.
          </div>

          <RButton
              class="mt-3"
              color="red"
              sm
              @click="currentNavItem = 'settings'"
          >
            מעבר להגדרות פרויקט
          </RButton>
        </div>

        <div class="r-page-table">
          <div class="r-page-table-header">
            <div class="r-page-table-header-content">
              <h3 class="r-page-table-header-title">
                דוחות פרויקט
                <span v-if="project.reports && project.reports.length"
                >({{ project.reports.length }})</span
                >
              </h3>
            </div>
            <div class="r-page-table-header-actions">
              <RSearchCompact v-model="searchQuery" :initial-open="true"/>
              <AddReportButton
                  v-if="projectHasTemplates"
                  mode="project"
                  :load-project="project"
              />
            </div>
          </div>

          <div class="r-page-table-body">
            <v-data-table
                v-if="isLoaded && project.reports.length"
                :headers="headers"
                :items="project.reports"
                :hide-default-footer="true"
                :items-per-page="-1"
                :search="searchQuery"
                no-results-text="לא נמצאו דוחות בפרויקט"
                class="elevation-0 project-reports-table"
                mobile-breakpoint="0"
            >
              <template #[`item.number`]="{ item }">
                <router-link
                    :to="{
                    name: 'reports.edit',
                    params: { id: item.id },
                  }"
                    class="text--primary"
                    style="
                    text-decoration: underline;
                    text-underline-position: under;
                  "
                >
                  {{ item.number }}
                </router-link>
              </template>
              <template #[`item.created_at`]="{ item }">
                {{ $Utils.presentHebrewDate(item.created_at) }}
              </template>
              <template #[`item.tag`]="{ item }">
                <template v-if="item.tag">{{ item.tag }}</template>
              </template>
              <template #[`item.actions`]="{ item }">
                <RActions>
                  <RAction
                      icon="mdi-text-box"
                      text="צפיה בדוח"
                      :to="{
                      name: 'reports.edit',
                      params: { id: item.id },
                    }"
                  />
                  <RAction
                      icon="mdi-send"
                      text="שליחה לרשימת תפוצה"
                      @click="sendEmails(item.id)"
                  />
                  <RAction
                      icon="mdi-link-off"
                      text="הסרת הדוח מפרויקט זה"
                      @click="detachReport(item.id)"
                  />
                </RActions>
              </template>
            </v-data-table>

            <div
                v-if="isLoaded && !project.reports.length"
                class="px-5 text-center d-flex justify-center flex-column align-center"
            >
              <img
                  src="@/assets/images/cta-first-report.png"
                  srcset="@/assets/images/cta-first-report@2x.png 2x"
                  alt="first-report"
                  style="pointer-events: none; max-width: 100%; height: auto"
              />
              <div class="rp-h4">בואו ניצור את הדוח הראשון שלכם בפרויקט!</div>
              <div class="rp-p2 mt-1 mb-4">4 קליקים ויצרתם דוח 🥳</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentNavItem === 'files'">
        <div class="r-page-edit-body-container">
          <RSimpleCard
              :loading="isUploading"
              :disabled="isUploading || !isProjectValid"
              title="תוכניות וקבצים"
              subtitle="לכאן אפשר להעלות תוכניות, אישורים וכל קובץ שקשור לפרויקט. מסוג
            Video, Word, Excel, Pdf. ועד 10 MB"
          >
            <input
                ref="fileInput"
                class="d-none"
                type="file"
                multiple
                @change="uploadFiles"
            />
            <v-treeview
                v-model="tree"
                style="margin-left: -16px; margin-right: -16px"
                :items="foldersTree"
                item-key="id"
                open-on-click
                item-text="name"
                item-title="name"
            >
              <template #[`prepend`]="{ item }">
                <RIcon v-if="item.type === 'folder'">
                  {{ item.open ? "mdi-folder-open" : "mdi-folder" }}
                </RIcon>
                <RIcon
                    v-else
                    :color="file_extensions[item.extension]?.color || 'red'"
                >
                  {{ file_extensions[item.extension]?.name || "mdi-file" }}
                </RIcon>
              </template>
              <template #title="{ item }">
                <span v-if="item.type === 'file'" @click="openFile(item)">
                  {{ item.name }}
                </span>
                <span v-if="item.type === 'folder'">
                  {{ item.name }}
                  <small>({{ item.children?.length || 0 }})</small>
                </span>
              </template>
              <template #[`append`]="{ item }">
                <RButton
                    v-if="item.type === 'folder'"
                    color="black"
                    xs
                    icon-before="mdi-upload"
                    outlined
                    class="ml-2 u-mobile-hidden tree-action-button"
                    @click.stop="openUploadFileToFolder(item.id)"
                >
                  העלאת קבצים
                </RButton>
                <RButton
                    v-if="item.type === 'folder'"
                    color="black"
                    xs
                    outlined
                    icon-before="mdi-plus"
                    class="ml-2 u-mobile-hidden tree-action-button"
                    @click.stop="openCreateFolderModal(item.id)"
                >
                  תת תיקייה
                </RButton>

                <RActions v-if="item.type === 'file'">
                  <RAction
                      icon="mdi-folder"
                      text="העברת קובץ"
                      @click="
                      openMoveFolderModal(
                        'file',
                        item.id,
                        item.name,
                        item.folder_parent_id
                      )
                    "
                  />
                  <RAction
                      icon="mdi-download"
                      text="הורדת קובץ"
                      target="_blank"
                      :href="item.original_url"
                      :download="item.file_name"
                  />
                  <RAction
                      icon="mdi-delete"
                      text="מחיקת קובץ"
                      @click="removeFile(item.id)"
                  />
                </RActions>

                <RActions v-if="item.type === 'folder'">
                  <RAction
                      icon="mdi-upload"
                      class="u-mobile-only"
                      text="העלאת קבצים"
                      @click="openUploadFileToFolder(item.id)"
                  />
                  <RAction
                      icon="mdi-plus"
                      class="u-mobile-only"
                      text="הוספת תת תיקייה"
                      @click="openCreateFolderModal(item.id)"
                  />
                  <RAction
                      icon="mdi-pencil"
                      text="עריכת תיקייה"
                      @click="openUpdateFolderModal(item)"
                  />
                  <RAction
                      icon="mdi-folder"
                      text="העברת תיקייה"
                      @click="
                      openMoveFolderModal(
                        'folder',
                        item.id,
                        item.name,
                        item.folder_parent_id
                      )
                    "
                  />
                  <RAction
                      icon="mdi-delete"
                      text="מחיקת תיקייה"
                      @click="removeFolder(item.id)"
                  />
                </RActions>
              </template>
            </v-treeview>

            <div class="pt-6 pb-1">
              <RButton
                  class="ml-2"
                  color="grey"
                  icon-before="mdi-folder"
                  @click="openCreateFolderModal(null)"
              >
                תיקייה חדשה
              </RButton>
              <RButton
                  color="grey"
                  icon-before="mdi-upload"
                  @click="openUploadFileToFolder(null)"
              >
                העלאת קבצים
              </RButton>
            </div>
          </RSimpleCard>
        </div>
      </div>

      <div v-if="currentNavItem === 'settings'">
        <div class="r-page-edit-body-container">
          <div class="project-settings-tab">
            <RSimpleCard
                title="הגדרות פרויקט"
                :loading="isLoading"
                :disabled="isLoading"
            >
              <RFields>
                <RFieldText
                    v-model="project.number"
                    type="number"
                    label="מספר פרויקט"
                />
                <RFieldText v-model="project.name" label="שם פרויקט"/>
                <RFieldText v-model="project.address" label="כתובת פרויקט"/>
                <RFieldText v-model="project.customer_name" label="שם לקוח"/>
                <RFieldText v-model="project.tag" label="תווית"/>

                <div class="tfusa">
                  <div>רשימת תפוצה למיילים (עד 40 מיילים)</div>
                  <form
                      class="d-flex flex-wrap align-items-center mb-4 mt-3"
                      @submit.prevent="addEmail"
                  >
                    <RFieldText
                        v-model="emailToAdd"
                        type="email"
                        style="flex: 1; margin-bottom: 0"
                    />
                    <RButton
                        :disabled="hasMaxEmails"
                        class="mr-2"
                        lg
                        height="40"
                        color="black"
                        type="submit"
                        outlined
                    >
                      הוספה
                    </RButton>
                  </form>

                  <v-table>
                    <thead>
                    <tr>
                      <th class="text-right">מייל</th>
                      <th class="text-right"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(email, index) in project.data.emails"
                        :key="index"
                    >
                      <td>{{ email }}</td>
                      <td class="text-left">
                        <RButtonIcon
                            icon="mdi-close"
                            @click="deleteEmail(email)"
                        >
                        </RButtonIcon>
                      </td>
                    </tr>
                    </tbody>
                  </v-table>
                </div>
                <RButton
                    class="mt-2"
                    color="primary"
                    lg
                    block
                    type="submit"
                    :loading="isLoading"
                    :disabled="
                    !project.number || !project.name || !project.name.trim()
                  "
                    @click="updateProject"
                >
                  שמירת שינויים
                </RButton>
              </RFields>
            </RSimpleCard>

            <RSimpleCard
                :loading="isLoading"
                :disabled="isLoading"
                title="תבניות שמשוייכות לפרויקט"
                subtitle="לחצו על שם התבנית כדי לשייך או להוציא אותה מהפרויקט"
            >
              <div class="select-list mt-3">
                <div
                    v-for="template in reportTemplates"
                    :key="template.id"
                    :class="{
                    'is-selected': projectHasTemplate(template.id),
                  }"
                    class="select-list-item"
                    @click="templateClicked(template.id)"
                >
                  {{ template.name }}
                </div>
              </div>
            </RSimpleCard>

            <RSimpleCard
                :loading="isLoading"
                :disabled="isLoading"
                title="משתמשים מורשים לפרויקט"
                subtitle="לחצו על שם המשתמש כדי לשייך או להוציא אותו מהפרויקט"
            >
              <RFieldSelect
                  v-model="project.visibility"
                  label="מי מורשה לפרויקט?"
                  :items="[
                  {
                    text: 'כולם',
                    value: 'public',
                  },
                  {
                    text: 'רק משתמשים מסויימים',
                    value: 'private',
                  },
                ]"
              />

              <div
                  v-if="project.visibility === 'private'"
                  class="select-list mt-3"
              >
                <div
                    v-for="user in users"
                    :key="user.id"
                    :class="{
                    'is-selected': projectHasUser(user.id),
                  }"
                    class="select-list-item"
                    @click="userClicked(user.id)"
                >
                  {{ user.name }}
                </div>
              </div>
            </RSimpleCard>

            <RSimpleCard
                :loading="isLoading"
                :disabled="isLoading"
                title="מחיקת הפרויקט"
                subtitle="ברגע שתמחקו את הפרויקט, לא תוכלו לשחזר אותו"
            >
              <RButton
                  icon-before="mdi-delete"
                  color="red"
                  @click="removeProject"
              >
                מחיקת הפרויקט הזה
              </RButton>
            </RSimpleCard>
          </div>
        </div>
      </div>
    </div>

    <RModal
        v-if="createFolderModal.isOpen"
        v-model="createFolderModal.isOpen"
        title="יצירת תיקייה חדשה"
        :disabled="createFolderModal.isLoading"
    >
      <RFields>
        <RFieldText v-model="createFolderModal.folderName" label="שם התיקייה"/>
        <RButton
            :disabled="!createFolderModal.folderName"
            color="primary"
            lg
            block
            :loading="createFolderModal.isLoading"
            @click="createFolder"
        >
          צרו תיקייה חדשה
        </RButton>
      </RFields>
    </RModal>

    <RModal
        v-if="updateFolderModal.isOpen"
        v-model="updateFolderModal.isOpen"
        :disabled="updateFolderModal.isLoading"
        title="עדכון תיקייה"
    >
      <RFields>
        <RFieldText v-model="updateFolderModal.folderName" label="שם התיקייה"/>
        <RButton
            :disabled="!updateFolderModal.folderName"
            color="primary"
            lg
            block
            :loading="updateFolderModal.isLoading"
            @click="updateFolder"
        >
          עדכון תיקייה
        </RButton>
      </RFields>
    </RModal>

    <RModal
        v-if="moveFolderModal.isOpen"
        v-model="moveFolderModal.isOpen"
        :disabled="moveFolderModal.isLoading"
        :title="moveFolderModalTitle"
    >
      <RFields>
        <RFieldText
            v-model="moveFolderModal.name"
            label="שם"
            realonly
            disabled
        />
        <RFieldSelect
            v-model="moveFolderModal.toFolderId"
            :items="moveToFolderOptions"
            label="העברה לתיקייה"
            clearable
        >
        </RFieldSelect>

        <RButton
            color="primary"
            lg
            block
            :loading="moveFolderModal.isLoading"
            @click="moveFolder"
        >
          העברה לתיקייה
        </RButton>
      </RFields>
    </RModal>
  </div>
  <page-loader v-else/>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
import ProjectsApi from "@/api/ProjectsApi";
import {mapGetters} from "vuex";
import AddReportButton from "@/components/AddReportButton.vue";
import {VTreeview} from "vuetify/labs/VTreeview";

export default {
  components: {AddReportButton, PageLoader, VTreeview},
  data() {
    return {
      currentNavItem: "reports",
      searchQuery: null,
      uploadFileToFolderId: null,
      createFolderModal: {
        isOpen: false,
        isLoading: false,
        folderName: null,
        parentFolderId: null,
      },
      updateFolderModal: {
        isOpen: false,
        folderId: null,
        isLoading: false,
        folderName: null,
      },
      moveFolderModal: {
        isOpen: false,
        type: null, // folder/file
        id: null,
        name: null,
        toFolderId: null,
        isLoading: false,
      },
      file_extensions: {
        mp4: {name: "mdi-play", color: "yellow"},
        mov: {name: "mdi-play", color: "yellow"},
        pdf: {name: "mdi-file-pdf", color: "red"},
        png: {name: "mdi-file-image", color: "orange"},
        jpg: {name: "mdi-file-image", color: "orange"},
        jpeg: {name: "mdi-file-image", color: "orange"},
        xls: {name: "mdi-file-excel", color: "green"},
        xlsx: {name: "mdi-file-excel", color: "green"},
        doc: {name: "mdi-file-word", color: "blue"},
        docx: {name: "mdi-file-word", color: "blue"},
      },
      tree: [],
      isUploading: false,
      isLoading: false,
      isLoaded: false,
      projectId: this.$route.params.id,
      emailToAdd: null,
      isProjectValid: false,
      project: {
        number: null,
        name: null,
        address: null,
        customer_name: null,
        visibility: "public",
        reportTemplates: [],
        reports: [],
        folders: [],
        media: [],
        data: {
          emails: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["company"]),
    moveFolderModalTitle() {
      return this.moveFolderModal.type === "folder"
          ? "העברת תיקייה לתיקייה"
          : "העברת קובץ לתיקייה";
    },
    headers() {
      let headers = [];

      headers.push({
        title: "מספר",
        value: "number",
        width: "85px",
        sortable: true,
      });

      headers.push({
        title: "תבנית",
        value: "name",
        sortable: true,
      });

      headers.push({
        title: "כתובת",
        value: "address",
        sortable: true,
      });

      headers.push({
        title: "לקוח",
        value: "customerName",
        sortable: true,
      });

      headers.push({
        title: "משתמש",
        value: "user_name",
        sortable: true,
      });

      headers.push({
        title: "תווית",
        value: "tag",
        sortable: true,
      });

      headers.push({
        title: "תאריך יצירה",
        value: "created_at",
        width: "180px",
        sortable: true,
      });

      headers.push({
        title: "פעולות",
        value: "actions",
        width: "80px",
        sortable: false,
      });

      return headers;
    },
    moveToFolderOptions() {
      let folders = this.project.folders.filter(
          (folder) => folder.id !== this.moveFolderModal.id
      );

      folders = folders.map((folder) => ({
        value: folder.id,
        text: folder.name,
      }));

      folders.unshift({value: null, text: "ללא תיקייה"}); // Adjust the text as needed

      return folders;
    },
    foldersTree() {
      // Create a map of folders with their children arrays
      const foldersById = this.project.folders.reduce((map, folder) => {
        map[folder.id] = {
          ...folder,
          type: 'folder',
          children: [],
          selectable: false,
          name: folder.name || '',
          id: folder.id,
          media_uuids: folder.media_uuids || []
        };
        return map;
      }, {});

      const rootFolders = [];

      // Build folder hierarchy
      this.project.folders.forEach((folder) => {
        const folderNode = foldersById[folder.id];

        if (folder.parent_id === null) {
          rootFolders.push(folderNode);
        } else if (foldersById[folder.parent_id]) {
          foldersById[folder.parent_id].children.push(folderNode);
        }
      });

      // Add media files to their respective folders
      this.project.folders.forEach((folder) => {
        if (folder.media_uuids?.length > 0) {
          const mediaItems = folder.media_uuids
              .map(mediaUuid => {
                const media = this.project.media[mediaUuid];
                if (!media) return null;

                return {
                  ...media,
                  id: `${media.uuid}`,
                  type: 'file',
                  name: media.name || media.file_name,
                  selectable: false,
                  folder_parent_id: folder.id
                };
              })
              .filter(Boolean);

          foldersById[folder.id].children.push(...mediaItems);
        }
      });

      // Add root-level media items (not in any folder)
      const rootMediaItems = Object.values(this.project.media)
          .filter(media => {
            return !this.project.folders.some(folder =>
                folder.media_uuids?.includes(media.uuid)
            );
          })
          .map(media => ({
            ...media,
            id: `${media.uuid}`,
            type: 'file',
            name: media.name || media.file_name,
            selectable: false,
            folder_parent_id: null
          }));

      return [...rootFolders, ...rootMediaItems];
    },
    hasMaxEmails() {
      return this.totalProjectEmails >= 40;
    },
    projectHasTemplates() {
      return this.project.reportTemplates.length > 0;
    },
    totalProjectEmails() {
      return this.project.data.emails ? this.project.data.emails.length : 0;
    },
    ...mapGetters("users", ["users"]),
    ...mapGetters("reportTemplates", ["reportTemplates"]),
  },
  watch: {
    "project.visibility": async function (newVisibility) {
      if (!this.isLoaded) {
        return;
      }
      this.isLoading = true;
 
      try {
        await ProjectsApi.updateProject(this.projectId, {
          visibility: newVisibility,
        });
        this.$toast.success("הפרויקט עודכן בהצלחה");
        this.isLoading = false;
      } catch (e) {
        this.$toast.error(
            this.$Utils.toastMessageFromResponse(e, "שגיאה בעדכון הפרויקט")
        );
        this.isLoading = false;
      }
    },
  },
  async created() {
    try {
      await this.fetchProject();
      await this.$store.dispatch("reportTemplates/fetchReportTemplates");
      if (this.$checkRole(["admin", "owner"])) {
        await this.$store.dispatch("users/fetchUsers");
      }
      this.isLoaded = true;
    } catch (e) {
      this.$toast.error(
          this.$Utils.toastMessageFromResponse(e, "שגיאה בטעינת הפרויקט")
      );
    }
  },
  methods: {
    openFile(file) {
      window.open(file.original_url, "_blank");
    },
    openMoveFolderModal(type, id, name, currentFolderId = null) {
      this.moveFolderModal.isOpen = true;
      this.moveFolderModal.type = type;
      this.moveFolderModal.id = id;
      this.moveFolderModal.name = name;
      this.moveFolderModal.toFolderId = currentFolderId;
      this.moveFolderModal.isLoading = false;
    },
    openUpdateFolderModal(folder) {
      this.updateFolderModal.isOpen = true;
      this.updateFolderModal.folderId = folder.id;
      this.updateFolderModal.isLoading = false;
      this.updateFolderModal.folderName = folder.name;
      this.updateFolderModal.parentFolderId = null;
    },
    openUploadFileToFolder(folderId = null) {
      this.uploadFileToFolderId = folderId;
      this.$refs.fileInput.click();
    },
    openCreateFolderModal(parentFolderId = null) {
      this.createFolderModal.isOpen = true;
      this.createFolderModal.isLoading = false;
      this.createFolderModal.folderName = null;
      this.createFolderModal.parentFolderId = parentFolderId;
    },
    folderIsEmpty(folderId) {
      let folderIsEmpty = true;

      this.project.folders.forEach((folder) => {
        if (folder.parent_id === folderId) {
          folderIsEmpty = false;
        }
        if (folder.id === folderId && folder.media_uuids.length) {
          folderIsEmpty = false;
        }
      });

      return folderIsEmpty;
    },
    async moveFolder() {
      this.moveFolderModal.isLoading = true;

      try {
        if (this.moveFolderModal.type === "folder") {
          await ProjectsApi.updateFolder(
              this.projectId,
              this.moveFolderModal.id,
              {
                parent_id: this.moveFolderModal.toFolderId,
              }
          );
        }

        if (this.moveFolderModal.type === "file") {
          await ProjectsApi.updateMediaFolder(
              this.projectId,
              this.moveFolderModal.id,
              this.moveFolderModal.toFolderId
          );
        }

        await this.fetchProject();
        this.$toast.success("הועבר בהצלחה לתיקייה");
        this.moveFolderModal.isLoading = false;
        this.moveFolderModal.isOpen = false;
      } catch (error) {
        this.$toast.error("שגיאה בהעברה לתיקייה");
        this.moveFolderModal.isLoading = false;
      }
    },
    async updateFolder() {
      if (!this.updateFolderModal.folderName) {
        this.$toast.error("יש להזין שם לתיקייה");
        return;
      }

      this.updateFolderModal.isLoading = true;

      try {
        await ProjectsApi.updateFolder(
            this.projectId,
            this.updateFolderModal.folderId,
            {
              name: this.updateFolderModal.folderName,
            }
        );
        await this.fetchProject();
        this.$toast.success("התיקייה עודכנה בהצלחה");
        this.updateFolderModal.isLoading = false;
        this.updateFolderModal.isOpen = false;
      } catch (error) {
        this.$toast.error("שגיאה בעדכון תיקייה");
        this.updateFolderModal.isLoading = false;
      }
    },
    async removeFolder(folderId) {
      if (!confirm("בטוח למחוק את התיקייה?")) {
        return;
      }

      if (!this.folderIsEmpty(folderId)) {
        this.$toast.error("לא ניתן למחוק תיקייה עם קבצים");
        return;
      }

      this.isUploading = true;
      try {
        await ProjectsApi.removeFolder(this.projectId, folderId);

        await this.fetchProject();
        this.$toast.success("התיקייה נמחקה בהצלחה");
        this.isUploading = false;
      } catch (error) {
        this.$toast.error("שגיאה במחיקת התיקייה");
        this.isUploading = false;
      }
    },
    async createFolder() {
      if (!this.createFolderModal.folderName) {
        this.$toast.error("יש להזין שם לתיקייה");
        return;
      }

      this.createFolderModal.isLoading = true;

      try {
        await ProjectsApi.createFolder(
            this.projectId,
            this.createFolderModal.folderName,
            this.createFolderModal.parentFolderId
        );
        await this.fetchProject();
        this.$toast.success("התיקייה נוצרה בהצלחה");
        this.createFolderModal.isLoading = false;
        this.createFolderModal.isOpen = false;
      } catch (error) {
        this.$toast.error("שגיאה ביצירת תיקייה");
        this.createFolderModal.isLoading = false;
      }
    },
    validateProject() {
      this.isProjectValid =
          this.project.name && this.project.customer_name && this.project.address;
      // this.project.reportTemplates.length > 0;
    },
    async removeFile(fileId) {
      if (!confirm("בטוח למחוק את הקובץ?")) {
        return;
      }

      this.isUploading = true;
      try {
        await ProjectsApi.removeMedia(this.projectId, fileId);

        await this.fetchProject();
        this.$toast.success("הקובץ נמחק בהצלחה");
        this.isUploading = false;
      } catch (error) {
        this.$toast.error("שגיאה במחיקת הקובץ");
        this.isUploading = false;
      }
    },
    async uploadFiles(event) {
      let files = event.target.files;
      this.isUploading = true;
      for (const file of files) {
        await this.uploadFile(file);
      }
      await this.fetchProject();
      this.isUploading = false;
      this.$refs.fileInput.value = "";
    },
    async uploadFile(file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop();

      // Validate Allowed File Extensions
      let allowedExtensions = [
        "pdf",
        "jpeg",
        "jpg",
        "png",
        "mp4",
        "mov",
        "doc",
        "docx",
        "xls",
        "xlsx",
      ];

      if (!allowedExtensions.includes(fileExtension)) {
        this.$toast.error(
            "סוג הקובץ אינו מורשה. מורשים: excel, word, pdf, images, videos"
        );
        return;
      }

      // Validate File Size
      const fileSize = file.size;
      const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 10) {
        this.$toast.error("הקובץ גדול מדי, מותר עד 10 מגה");
        return;
      }

      try {
        await ProjectsApi.uploadMedia(
            this.projectId,
            file,
            this.uploadFileToFolderId
        );
        this.$toast.success("הקובץ נוסף בהצלחה");
      } catch (error) {
        this.$toast.error("שגיאה בהעלאת הקובץ");
      }
    },
    async removeProject() {
      if (!confirm("בטוח למחוק את הפרויקט הזה?")) {
        return;
      }

      let loader = this.$loading.show();

      try {
        await ProjectsApi.removeProject(this.projectId);
        loader.hide();
        this.$toast.success("הפרוקט נמחק בהצלחה");
        this.$router.push("/projects");
      } catch (error) {
        loader.hide();
        this.$toast.error("שגיאה במחיקת הפרויקט");
      }
    },
    async fetchProject() {
      this.project = await ProjectsApi.getProject(this.projectId);
      this.validateProject();
    },
    userClicked(userId) {
      if (this.projectHasUser(userId)) {
        this.detachUser(userId);
      } else {
        this.attachUser(userId);
      }
    },
    projectHasUser(userId) {
      return this.project.users.some((user) => user.id === userId);
    },
    templateClicked(templateId) {
      if (this.projectHasTemplate(templateId)) {
        this.detachTemplate(templateId);
      } else {
        this.attachTemplate(templateId);
      }
    },
    async attachUser(userId) {

      this.isLoading = true;
      try {
        await ProjectsApi.attachUserToProject(this.projectId, userId);
        await this.fetchProject();
        this.$toast.success("המשתמש שוייך לפרויקט");
        this.isLoading = false;
      } catch (e) {
        this.$toast.error(
            this.$Utils.toastMessageFromResponse(e, "שגיאה בהוספת משתמש לפריקט")
        );
        this.isLoading = false;
      }
    },
    async detachUser(userId) {
      this.isLoading = true;
      await ProjectsApi.detachUserFromProject(this.projectId, userId);
      await this.fetchProject();
      this.$toast.success("המשתמש הוצא מהפרויקט");
      this.isLoading = false;
    },
    async attachTemplate(templateId) {

      this.isLoading = true;
      await ProjectsApi.attachTemplateToProject(this.projectId, templateId);
      await this.fetchProject();
      this.$toast.success("התבנית שוייכה לפרויקט");
      this.isLoading = false;
    },
    async detachTemplate(templateId) {
      this.isLoading = true;
      await ProjectsApi.detachTemplateFromProject(this.projectId, templateId);
      await this.fetchProject();
      this.$toast.success("התבנית הוצאה מהפרויקט");
      this.isLoading = false;
    },
    async updateProject() {
      this.isLoading = true;

      try {
        await ProjectsApi.updateProject(this.projectId, this.project);
        await this.fetchProject();
        this.$toast.success("הפרויקט עודכן בהצלחה");
        this.isLoading = false;
      } catch (e) {
        this.$toast.error(
            this.$Utils.toastMessageFromResponse(e, "שגיאה בעדכון הפרוקט")
        );
        this.isLoading = false;
      }
    },
    projectHasTemplate(templateId) {
      return this.project.reportTemplates.some(
          (template) => template.id === templateId
      );
    },
    deleteEmail(email) {
      this.project.data.emails = this.project.data.emails.filter(
          (e) => e !== email
      );
    },
    addEmail() {
      if (this.hasMaxEmails) {
        this.$toast.error("הגעת למספר המיילים המרבי");
        return;
      }
      if (!this.project.data.emails) {
        this.project.data = {...this.project.data, emails: []};
      }
      if (!this.$Utils.isValidEmail(this.emailToAdd)) {
        this.$toast.error("חובה להזין מייל תקין");
        return;
      }
      if (this.project.data.emails.includes(this.emailToAdd)) {
        this.$toast.error("המייל כבר נמצא ברשימת התפוצה");
        return;
      }

      this.project.data.emails.push(this.emailToAdd);
      this.emailToAdd = null;
    },
    async sendEmails(reportId) {
      if (!this.project.data.emails || this.project.data.emails.length === 0) {
        this.$toast.error("רשימת התפוצה ריקה");
        return;
      }

      if (
          !confirm(
              "בטוח לשלוח לרשימת התפוצה? " + this.project.data.emails.join(", ")
          )
      ) {
        return;
      }
      let loader = this.$loading.show();

      try {
        await ProjectsApi.sendEmails(this.projectId, reportId);
        this.$toast.success("הדוח נשלח בהצלחה לרשימת התפוצה");
      } catch (e) {
        this.$toast.error(
            this.$Utils.toastMessageFromResponse(
                e,
                "שגיאה בשליחת הדוח לרשימת התפוצה"
            )
        );
      }

      loader.hide();
    },
    async detachReport(reportId) {
      if (confirm("בטוח להסיר את הדוח מהפרויקט?")) {
        let loader = this.$loading.show();
        try {
          await ProjectsApi.detachReportFromProject(this.projectId, reportId);
          await this.fetchProject();
          this.$toast.success("הדוח הוסר מהפרויקט");
        } catch (e) {
          this.$toast.error(
              this.$Utils.toastMessageFromResponse(e, "שגיאה בהסרת הדוח מהפרויקט")
          );
        }
        loader.hide();
      }
    },
  },
};
</script>

<style lang="scss">
.v-treeview .v-list-item:not(:hover) .tree-action-button {
  opacity: 0;
  pointer-events: none;
}

.project-reports-table {
  th:nth-child(2) {
    min-width: 180px;
  }

  th:nth-child(3) {
    min-width: 180px;
  }

  th:nth-child(4) {
    min-width: 180px;
  }

  th:nth-child(5) {
    min-width: 120px;
  }

  th:nth-child(6) {
    min-width: 100px;
  }
}

.project-settings-tab {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
}

.select-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 6px;
  max-height: 450px;
  overflow: auto;

  &-item {
    padding: 10px;
    border: 1px solid var(--r-border-color);
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 4px;

    &:hover {
      background-color: #f5f5f5;
    }

    &.is-selected {
      //&:before {
      //  content: "✔";
      //  display: inline-block;
      //  margin-right: 10px;
      //  font-size: 12px;
      //  color: var(--r-color-green);
      //}
      border-right-color: var(--r-color-green);
      border-right-width: 3px;
      color: var(--r-color-green);
    }
  }
}

.r-simple-card {
  align-self: self-start;
}

.v-treeview-node {
  &[data-file="true"] {
    .v-treeview-node__level {
      display: none;
    }
  }
}
</style>
