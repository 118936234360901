<template>
  <RModal v-model="isSettingsReportModalOpen" title="הגדרות דוח">
    <RSimpleCard
      v-if="isDraft"
      class="mb-4 draft-to-published-card"
      title="הפוך מדוח טיוטה לדוח רגיל"
      subtitle=" אם תבחרו להפוך את הדוח לדוח רגיל, לא תוכלו להחזיר אותו לדוח לטיוטה."
    >
      <RButton sm color="green" @click="convertDraftToPublishedReport">
        הפוך מדוח טיוטה לדוח רגיל
      </RButton>
    </RSimpleCard>
    <form ref="updateCompanySettingsForm">
      <div class="update-report-settings-grid">
        <RFieldText v-model="number" label="מספר הדוח" maxlength="50" />
        <RFieldText v-model="tag" label="תווית הדוח" maxlength="150" />
        <RFieldText v-model="title" label="כותרת" maxlength="150" />
        <RFieldText v-model="customerName" label="שם/מספר לקוח" maxlength="150" />
        <RFieldText v-model="customerEmail" type="email" label="אימייל לקוח" maxlength="150" />
        <RFieldText v-model="customerPhone" label="טלפון לקוח" maxlength="150" />
        <RFieldText v-model="address" label="כתובת" maxlength="150" />
        <RFieldSelect
          v-model="preference_id"
          label="עיצוב"
          :items="preferencesOptions"
        />
        <RFieldDatePicker
          :value="visitDate"
          label="תאריך הביקור"
          @update:modelValue="visitDate = $event"
        />
        <RFieldText v-model="visitTime" type="time" label="שעת הביקור" />
        <RFieldSelect v-model="user_id" label="משתמש" :items="usersOptions" />
        <RFieldSelect
          v-if="projects.length"
          v-model="project_id"
          label="פרויקט"
          :items="projectsOptions"
          clearable
        />
        <RFieldSelect
          v-model="enableDefectStatus"
          label="הפעלת סטטוס ממצא"
          :items="[
            {
              value: true,
              text: 'כן',
            },
            {
              value: false,
              text: 'לא',
            },
          ]"
        />
        <RFieldSelect
          v-model="enableDefectRecheckStatus"
          label="הפעלת סטטוס בדיקה חוזרת"
          :items="[
            {
              value: true,
              text: 'כן',
            },
            {
              value: false,
              text: 'לא',
            },
          ]"
        />
      </div>
    </form>

    <RButton
      class="mt-8 mb-4"
      color="red"
      outlined
      icon-before="mdi-delete"
      @click="$refs.deleteModalRef.openModal()"
    >
      מחיקת דוח זה
    </RButton>

    <ReportDeleteModal ref="deleteModalRef" />
  </RModal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ReportDeleteModal from "./ReportDeleteModal.vue";
import ReportsApi from "@/api/ReportsApi";

export default {
  components: { ReportDeleteModal },
  data() {
    return {
      isSettingsReportModalOpen: false,
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
    ...mapGetters("preferences", ["preferences"]),
    ...mapGetters("projects", ["projects"]),
    ...mapGetters("users", ["users"]),
    number: {
      get() {
        if (!this.$store.state.report.report.number) {
          return "";
        }
        return this.$store.state.report.report.number;
      },
      async set(value) {
        await this.doUpdate({
          key: "number",
          value: value,
        });
      },
    },
    tag: {
      get() {
        if (!this.$store.state.report.report.tag) {
          return "";
        }
        return this.$store.state.report.report.tag;
      },
      async set(value) {
        await this.doUpdate({
          key: "tag",
          value: value,
        });
      },
    },
    title: {
      get() {
        if (!this.$store.state.report.report.title) {
          return "";
        }
        return this.$store.state.report.report.title;
      },
      async set(value) {
        await this.doUpdate({
          key: "title",
          value: value,
        });
      },
    },
    customerName: {
      get() {
        if (!this.$store.state.report.report.customerName) {
          return "";
        }
        return this.$store.state.report.report.customerName;
      },
      async set(value) {
        await this.doUpdate({
          key: "customerName",
          value: value,
        });
      },
    },
    customerEmail: {
      get() {
        if (!this.$store.state.report.report.customerEmail) {
          return "";
        }
        return this.$store.state.report.report.customerEmail;
      },
      async set(value) {
        await this.doUpdate({
          key: "customerEmail",
          value: value,
        });
      },
    },
    customerPhone: {
      get() {
        if (!this.$store.state.report.report.customerPhone) {
          return "";
        }
        return this.$store.state.report.report.customerPhone;
      },
      async set(value) {
        await this.doUpdate({
          key: "customerPhone",
          value: value,
        });
      },
    },
    address: {
      get() {
        if (!this.$store.state.report.report.address) {
          return "";
        }
        return this.$store.state.report.report.address;
      },
      async set(value) {
        await this.doUpdate({
          key: "address",
          value: value,
        });
      },
    },
    isDraft: {
      get() {
        return this.$store.state.report.report.is_draft;
      },
    },
    preference_id: {
      get() {
        return this.$store.state.report.report.preference_id;
      },
      async set(value) {
        await this.doUpdate({
          key: "preference_id",
          value: value,
        });
      },
    },
    project_id: {
      get() {
        return this.$store.state.report.report.project_id;
      },
      async set(value) {
        await this.doUpdate({
          key: "project_id",
          value: value,
        });
      },
    },
    visitDate: {
      get() {
        if (!this.$store.state.report.report.visitDate) {
          return "";
        }
        return this.$store.state.report.report.visitDate;
      },
      async set(value) {
        await this.doUpdate({
          key: "visitDate",
          value: value,
        });
      },
    },
    visitTime: {
      get() {
        if (!this.$store.state.report.report.visitTime) {
          return "";
        }
        return this.$store.state.report.report.visitTime;
      },
      async set(value) {
        await this.doUpdate({
          key: "visitTime",
          value: value,
        });
      },
    },
    user_id: {
      get() {
        return this.$store.state.report.report.user_id;
      },
      async set(value) {
        await this.doUpdate({
          key: "user_id",
          value: value,
        });
      },
    },
    enableDefectStatus: {
      get() {
        return this.$store.state.report.report.enableDefectStatus;
      },
      async set(value) {
        await this.doUpdate({
          key: "enableDefectStatus",
          value: value,
        });
      },
    },
    enableDefectRecheckStatus: {
      get() {
        return this.$store.state.report.report.enableDefectRecheckStatus;
      },
      async set(value) {
        await this.doUpdate({
          key: "enableDefectRecheckStatus",
          value: value,
        });
      },
    },
    preferencesOptions() {
      return this.preferences.map((preference) => {
        return {
          value: preference.id,
          text: preference.name,
        };
      });
    },
    projectsOptions() {
      return this.projects.map((project) => {
        return {
          value: project.id,
          text: project.name,
        };
      });
    },
    usersOptions() {
      return this.users.map((user) => {
        return {
          value: user.id,
          text: user.name,
        };
      });
    },
  },
  methods: {
    ...mapActions("report", ["doUpdate"]),
    openModal() {
      this.isSettingsReportModalOpen = true;
    },
    async convertDraftToPublishedReport() {
      if (confirm("בטוח להפוך את הדוח מדוח טיוטה לדוח לרגיל?")) {
        this.$posthog.capture("log:report:convert_draft_to_published");
        let loader = this.$loading.show();
        try {
          await ReportsApi.convertDraftToPublishedReport(
            this.$store.state.report.report.id
          );
          this.$toast.success("הדוח שונה בהצלחה מדוגמא לדוח רגיל");
          loader.hide();
          this.$router.go();
        } catch (err) {
          this.$toast.error(err);
          loader.hide();
        }
      }
    },
  },
};
</script>

<style lang="scss">
 .update-report-settings-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 14px 20px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }

  .app-field {
    margin-bottom: 0 !important;
  }
  .app-field:not(:last-child) {
    margin-bottom: 0 !important;
  }
}
.r-simple-card.draft-to-published-card {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background: #fafafa;
}
</style>
