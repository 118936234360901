<template>
  <div class="sm:p-6">
    <!-- Create Form -->
    <RModal v-model="showCreateForm" title="יצירת אתר חדש">
      <form @submit.prevent="createSite" class="space-y-4">
        <RFieldText
          v-model="newSite.name"
          label="שם"
          required
          placeholder="למשל: פרויקט בית חולים"
        />
        <RFieldText
          v-model="newSite.address"
          label="כתובת"
          type="textarea"
          placeholder="למשל: הרצל 103, תל אביב"
          required
        />
        <RFieldText
          v-model="newSite.customer_name"
          label="שם לקוח"
          placeholder="למשל: שם היזם"
          required
        />
        <div class="flex gap-2">
          <RButton
            type="submit"
            block
            lg
            :loading="isCreatingNewSite"
            :disabled="!newSite.name || !newSite.address || !newSite.customer_name"
          >
            צור אתר
          </RButton>
        </div>
      </form>
    </RModal>

    <!-- Sites List -->
    <div class="bg-white shadow sm:rounded-lg">
      <div class="p-4 border-b flex justify-between items-center">
        <div class="flex items-center gap-4">
          <h2 class="text-lg font-medium">
            רשימת אתרים
            <span class="text-gray-500 text-sm">({{ sites.length }})</span>
          </h2>
        </div>
        <RButton 
          @click="showCreateForm = true"
          icon-before="mdi-plus"
          color="purple"
        >
          הוסף אתר חדש
        </RButton>
      </div>

      <div class="divide-y divide-gray-200">
        <div 
          v-for="site in sites" 
          :key="site.id" 
          class="p-4 flex items-center gap-4 hover:bg-gray-50 transition-colors"
        >
          <!-- Site Icon -->
          <div class="flex-shrink-0">
            <v-icon size="24" class="text-gray-400">mdi-office-building-marker</v-icon>
          </div>
          
          <!-- Site Content -->
          <div class="flex-grow">
            <router-link 
              :to="`/sites/${site.id}`"
              class="block"
            >
              <div class="flex items-center mb-1">
                <h3 class="font-medium text-lg text-gray-800">{{ site.name }}</h3>
                
                <RChip v-if="site.tag" color="grey" class="mr-1">
                  {{ site.tag }}
                </RChip>
              </div>
              <div class="text-sm text-gray-600">
                <div class="flex gap-2 items-center">
                  <div class="flex items-center gap-1">
                    <v-icon size="16" class="text-gray-500">mdi-account</v-icon>
                    {{ site.customer_name }}
                  </div>
                  <div v-if="site.address" class="mt-1 flex items-center gap-1">
                    <v-icon size="16" class="text-gray-500">mdi-map-marker</v-icon>
                    {{ site.address }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <!-- Empty State -->
        <div v-if="sites.length === 0" class="p-8 text-center text-gray-500">
          לא נמצאו אתרים. צור את האתר הראשון שלך באמצעות הכפתור למעלה.
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import SitesApi from '@/api/SitesApi'
import { useRouter } from 'vue-router'

const router = useRouter()

const sites = ref([])
const isCreatingNewSite = ref(false)
const showCreateForm = ref(false)
const newSite = ref({
  name: '',
  customer_name: '',
  tag: '',
  address: '',
  description: ''
})

const loadSites = async () => {
  try {
    let response = await SitesApi.getSites();
    sites.value = response.data;
  } catch (error) {
    console.error('Error loading sites:', error)
  }
}

const createSite = async () => {
  isCreatingNewSite.value = true
  try {
    const response = await SitesApi.createSite(newSite.value)
    newSite.value = {
      name: '',
      customer_name: '',
      address: '',
    }
    showCreateForm.value = false
    await loadSites()
    await router.push(`/sites/${response.data.id}`)
  } catch (error) {
    console.error('Error creating site:', error)
  } finally {
    isCreatingNewSite.value = false
  }
}

onMounted(loadSites)
</script> 