async function finishOnboarding(data) {
  const response = await window.axios.post(
    "/api/v1/onboarding/finish",
    data
  );
  return response.data;
}


export default {
  finishOnboarding,
};
