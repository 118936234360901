<template>
  <div v-if="isLoaded">
    <div class="r-page-table">
      <div class="r-page-table-header">
        <div class="r-page-table-header-content">
          <h3 class="r-page-table-header-title">
            👥 משתמשים
            <span v-if="users && users.length">({{ users.length }})</span>
          </h3>
        </div>
        <div class="r-page-table-header-actions">
          <RSearchCompact v-model="searchQuery" :initial-open="true" />
          <RButton
            color="purple"
            lg
            :to="{
              name: 'users.add',
            }"
          >
            משתמש חדש
          </RButton>
        </div>
      </div>

      <div class="r-page-table-body">
        <v-data-table
          v-if="isLoaded && users.length"
          :headers="headers"
          :items="users"
          :hide-default-footer="true"
          :items-per-page="-1"
          :search="searchQuery"
          no-results-text="לא נמצאו תוצאות לחיפוש"
          class="elevation-0 users-table"
          mobile-breakpoint="0"
        >
          <template #[`item.name`]="{ item }">
            <router-link
              :to="{
                name: 'users.edit',
                params: { id: item.id },
              }"
              class="text--primary"
              style="text-decoration: underline; text-underline-position: under"
            >
              {{ item.name }}</router-link
            >
            <small v-if="!item.status" class="mr-1" style="opacity: 0.7"
              >(משתמש כבוי)</small
            >
          </template>
          <template #[`item.email`]="{ item }">
            {{ item.email }}
          </template>
          <template #[`item.permissions`]="{ item }">
            {{ $Utils.getRoleHebrewName(item.role_id) }}
          </template>
          <template #[`item.actions`]="{ item }">
            <RActions>
              <RAction
                :to="{
                  name: 'users.edit',
                  params: { id: item.id },
                }"
                icon="mdi-pencil"
                text="עריכה"
              ></RAction>
            </RActions>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
  <page-loader v-else />
</template>

<script>
import UsersApi from "@/api/UsersApi";
import PageLoader from "@/components/PageLoader.vue";

export default {
  components: { PageLoader },
  data() {
    return {
      searchQuery: null,
      isLoaded: false,
      users: [],
    };
  },
  computed: {
    headers() {
      let headers = [];

      headers.push({
        title: "שם",
        value: "name",
        sortable: true,
      });

      headers.push({
        title: "מייל",
        value: "email",
        sortable: true,
      });

      headers.push({
        title: "הרשאות",
        value: "permissions",
        sortable: true,
      });

      headers.push({
        title: "פעולות",
        value: "actions",
        width: "80px",
        sortable: false,
      });

      return headers;
    },
  },
  async created() {
    await this.fetchUsers();
    this.isLoaded = true;
  },
  methods: {
    async fetchUsers() {
      const data = await UsersApi.getUsers();
      this.users = data.data;
    },
  },
};
</script>

<style lang="scss">
.users-table {
  th:nth-child(2) {
    min-width: 180px;
  }
  th:nth-child(3) {
    min-width: 180px;
  }
  th:nth-child(4) {
    min-width: 180px;
  }
}
</style>
