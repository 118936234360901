<template>
  <div
      v-if="show && $checkRole(['owner', 'admin', 'user'])"
      class="bg-white rounded-lg shadow-sm border border-gray-200"
  >
    <!-- Header -->
    <div class="bg-gray-50 p-4 rounded-t-lg border-b border-gray-200">
      <div class="flex items-center gap-2">
        <div class="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
          <RIcon size="18">mdi-text-box-plus-outline</RIcon>
        </div>
        <h3 class="font-semibold text-md">יצירת הדוח הראשון שלי</h3>
      </div>
    </div>

    <div>
      <div
          v-for="(step, index) in tourSteps"
          :key="step.id"
          class="p-4 border-b border-gray-100"
          :class="{ 'opacity-50': !canShowStep(step) }"
      >
        <div class="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2 sm:gap-2">
          <div class="flex-1 flex items-center">
            <div class="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center shrink-0">
              <span class="text-gray-500 font-medium">{{ index + 1 }}</span>
            </div>
            <div class="flex-1 mr-3">
              <h4 class="text-md font-medium leading-tight">{{ step.title }}</h4>
              <p class="text-gray-500 text-sm">{{ step.description }}</p>
            </div>
          </div>
          <div class="flex items-center justify-center gap-2 mr-10 sm:mr-0">
            <template v-if="steps[step.id]">
              <span class="text-teal-600 text-sm font-semibold">הושלם</span>
              <div class="bg-teal-600 rounded-full w-4 h-4 flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-3 h-3 text-white">
                  <path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clip-rule="evenodd"/>
                </svg>
              </div>
            </template>
            <RButton
                v-else
                color="purple"
                size="sm"
                :to="step.route"
                :disabled="!canShowStep(step)"
            >
              {{ step.buttonText }}
            </RButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import TourService from '@/services/TourService'

export default {
  name: 'FirstReportTour',

  data: () => ({
    show: false,
    steps: {
      profile: false,
      createReport: false,
      addFinding: false,
    },
    tourSteps: [
      {
        id: 'profile',
        title: 'הוסיפו פרטי השכלה וניסיון',
        description: 'הפרטים האלה יופיעו בדוחות שתפיק',
        buttonText: 'לעמוד הפרופיל',
        route: '/profile?tour',
        requiresPrevious: null
      },
      {
        id: 'createReport',
        title: 'יצירת דוח',
        description: 'מלאו שם לקוח, כתובת ובחרו תבנית',
        buttonText: 'יצירת דוח',
        route: '/reports?tour=create-report',
        requiresPrevious: 'profile'
      },
      {
        id: 'addFinding',
        title: 'הוסיפו ממצא ראשון והורידו את הדוח',
        description: 'הוסיפו ממצא ראשון גם אם זה רק לדוגמא ולחצו הורדה',
        buttonText: 'הוספת ממצא והורדת הדוח',
        route: '/reports?tour=add-finding',
        requiresPrevious: 'createReport'
      }
    ]
  }),

  computed: {
    ...mapGetters('auth', ['company']),

    completedSteps() {
      return Object.values(this.steps).filter(step => step).length
    },

    allStepsCompleted() {
      return this.completedSteps === Object.keys(this.tourSteps).length
    }
  },

  created() {
    this.loadStepsProgress()
    this.show = !this.allStepsCompleted
  },

  methods: {
    loadStepsProgress() {
      this.steps = TourService.getProgress()
      if (!this.steps) {
        this.steps = {};
      }
    },
    
    canShowStep(step) {
      if (!step.requiresPrevious) return true
      return this.steps[step.requiresPrevious]
    }
  }
}
</script>
