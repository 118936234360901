import * as types from "./mutation-types";
import router from "@/router";
import {getRoleName} from "@/common/Utils";
import * as Sentry from "@sentry/vue";
import AuthApi from "@/api/AuthApi";
import posthog from "posthog-js";
import {appVersion} from "@/common/appVersion";

export const fetchMe = ({commit}) => {
    return AuthApi.getMe().then(
        (response) => {
            commit(types.SET_USER, response.data.data.user);
            commit(types.SET_IS_LOGGED_IN, true);

            if (response.data.data.company) {
                commit(types.SET_COMPANY, response.data.data.company);
            }
            if (response.data.data.companies) {
                commit(types.SET_COMPANIES, response.data.data.companies);
            }
            if (response.data.data.showOnboarding) {
                commit(types.SET_SHOW_ONBOARDING, response.data.data.showOnboarding);
            }

            if (response.data.data.isSelfSignup) {
                commit(types.SET_IS_SELF_SIGNUP, response.data.data.isSelfSignup);
            }

            if (response.data.data.isSelfSignup) {
                commit(types.SET_IS_SELF_SIGNUP, response.data.data.isSelfSignup);
            }

            if (response.data.data.company.features) {
                commit(types.SET_FEATURES, response.data.data.company.features);
            }

            window.Reporto = {
                userData: {
                    userId: response.data.data.user.id,
                    userEmail: response.data.data.user.email,
                    userDisplayName: response.data.data.user.name,
                    userPhone: response.data.data.user.phone,
                    createdAt: response.data.data.user.createdAt,
                    roleId: response.data.data.user.role_id,
                    roleName: getRoleName(response.data.data.user.role_id),
                },
                companyData: {
                    companyId: response.data.data.company.company_id,
                    companyName: response.data.data.company.name,
                    isOnTrial: !!response.data.data.company.isTrial,
                    daysLeftInTrial: response.data.data.company.daysLeftInTrial,
                    daysPassedSinceTrialStarted:
                    response.data.data.company.daysPassedSinceTrialStarted,
                    createdAt: response.data.data.company.createdAt,
                    category: response.data.data.company.category,
                },
            };

            if (response.data.data.company) {
                window.Reporto.userData.companyId =
                    response.data.data.company.company_id;
                window.Reporto.userData.companyName = response.data.data.company.name;
            }

            // BugSnag
            // if (typeof window.Bugsnag !== "undefined") {
            //   window.Bugsnag.setUser(
            //     window.Reporto.userData.userId,
            //     window.Reporto.userData.userEmail,
            //     window.Reporto.userData.userDisplayName
            //   );
            // }

            // Sentry
            if (typeof Sentry !== "undefined") {
                Sentry.setUser({
                    id: window.Reporto.userData.userId,
                    username: window.Reporto.userData.userDisplayName,
                    email: window.Reporto.userData.userEmail,
                });
            }

            // PostHog
            if (typeof posthog !== "undefined") {
                posthog.identify(window.Reporto.userData.userId, {
                    email: window.Reporto.userData.userEmail,
                    name: window.Reporto.userData.userDisplayName,
                    role: window.Reporto.userData.roleName,
                    companyName: window.Reporto.companyData.companyName,
                    companyId: window.Reporto.companyData.companyId,
                    createdAt: window.Reporto.userData.createdAt,
                    appVersion: appVersion,
                    isOnTrial: window.Reporto.companyData.isOnTrial,
                });

                posthog.register({
                    appVersion: appVersion,
                    isOnTrial: window.Reporto.companyData.isOnTrial,
                });
            }

            return Promise.resolve(response.data);
        },
        (error) => {
            console.error(error);
            posthog.capture("error:user_fetch_me", {error: error});
            commit(types.SET_IS_LOGGED_IN, false);
            commit(types.REMOVE_TOKEN);
            return Promise.reject(error);
        }
    );
};

export const logout = ({state, commit}) => {
    return new Promise((resolve, reject) => {
        if (!state.isLoggedIn) {
            resolve();
            return true;
        }

        AuthApi.logout().then(
            () => {
                commit(types.SET_LOGOUT);
                if (typeof posthog !== "undefined") {
                    posthog.reset();
                    posthog.capture("user_logout:submit");
                }
                router.push("/login");
            },
            (error) => {
                commit(types.SET_LOGOUT);
                router.push("/login");
                reject(error);
            }
        );
    });
};
