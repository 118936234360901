<template>
  <component
      :is="componentType"
      :to="to"
      :href="href"
      v-bind="$attrs"
      :class="buttonClass"
  >
    <v-icon v-if="iconBefore" :color="color">{{ iconBefore }}</v-icon>
    <slot></slot>
    <v-icon v-if="iconAfter" :color="color">{{ iconAfter }}</v-icon>
  </component>
</template>

<script>
export default {
  name: "RButton",
  props: {
    iconBefore: {
      type: String,
      default: null,
    },
    iconAfter: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "purple",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    md: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentType() {
      let type = "button";

      if (this.href) {
        type = "a";
      } else if (this.to) {
        type = "router-link";
      }

      return type;
    },
    buttonClass() {
      let classes = [];

      classes.push("r-button");

      // color
      classes.push(`r-button--${this.color}`);

      // outlined
      if (this.outlined) {
        classes.push("r-button--outlined");
      } else if (this.text) {
        classes.push("r-button--text");
      } else {
        classes.push("r-button--solid");
      }

      // block
      if (this.block) {
        classes.push("r-button--block");
      }

      // loading
      if (this.loading) {
        classes.push("r-button--loading");
      }

      // disabled
      if (this.disabled) {
        classes.push("r-button--disabled");
      }

      // xs
      if (this.xs) {
        classes.push("r-button--xs");
      }

      // sm
      if (this.sm) {
        classes.push("r-button--sm");
      }

      // md
      if (this.md) {
        classes.push("r-button--md");
      }

      // lg
      if (this.lg) {
        classes.push("r-button--lg");
      }

      return classes;
    },
  },
};
</script>
