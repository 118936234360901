async function getAreaTypes(siteId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/area-types`);
  return response.data;
}

async function createAreaType(siteId, data) {
  const response = await window.axios.post(`/api/v1/sites/${siteId}/area-types`, data);
  return response.data;
}

async function getAreaType(siteId, areaTypeId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/area-types/${areaTypeId}`);
  return response.data;
}

async function updateAreaType(siteId, areaTypeId, data) {
  const response = await window.axios.put(`/api/v1/sites/${siteId}/area-types/${areaTypeId}`, data);
  return response.data;
}

async function removeAreaType(siteId, areaTypeId) {
  const response = await window.axios.delete(`/api/v1/sites/${siteId}/area-types/${areaTypeId}`);
  return response.data;
}

async function addAreaTypeChecklistItem(siteId, areaTypeId, data) {
  const response = await window.axios.post(`/api/v1/sites/${siteId}/area-types/${areaTypeId}/checklist-item`, data);
  return response.data;
}

async function updateAreaTypeChecklistItem(siteId, areaTypeId, checklistItemId, data) {
  console.log('update checklistItemId', checklistItemId);
  const response = await window.axios.put(`/api/v1/sites/${siteId}/area-types/${areaTypeId}/checklist-item`, {
    checklistItemId: checklistItemId,
    ...data
  });
  return response.data;
}

async function deleteAreaTypeChecklistItem(siteId, areaTypeId, checklistItemId, data) {
  console.log('delete checklistItemId', checklistItemId)
  const response = await window.axios.delete(`/api/v1/sites/${siteId}/area-types/${areaTypeId}/checklist-item`, {
    data: {
      checklistItemId: checklistItemId,
      ...data
    }
  });
  return response.data;
}

export default {
  getAreaTypes,
  createAreaType,
  getAreaType,
  updateAreaType,
  removeAreaType,
  addAreaTypeChecklistItem,
  updateAreaTypeChecklistItem,
  deleteAreaTypeChecklistItem
}; 