<template>
  <div
      v-if="show && category_video"
      class="bg-white rounded-lg shadow-sm border border-gray-200"
  >
    <!-- Header -->
    <div class="bg-gray-50 p-4 rounded-t-lg border-b border-gray-200 flex items-center justify-between">
      <div class="flex items-center gap-2">
        <div class="w-8 h-8 bg-gray-200 rounded-full flex items-center justify-center">
          <RIcon size="18">mdi-play-circle-outline</RIcon>
        </div>
        <h3 class="font-semibold text-md">סרטון הדרכה אישי: {{ category }}</h3>
      </div>
      <button
          @click="closeClicked"
          class="text-gray-400 hover:text-gray-600"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
        </svg>
      </button>
    </div>

    <!-- Video Content -->
    <div class="p-4">

      <VideoCard :video-id="category_video"/>

      <!-- More Videos Button -->
      <div class="mt-4">
        <RButton
            block
            color="grey"
            to="/videos"
        >
          לכל סרטוני ההדרכה
        </RButton>
      </div>
    </div>
  </div>
</template>
<script>
import VideoCard from "@/components/VideoCard.vue";
import {mapGetters} from "vuex";

export default {
  components: {VideoCard},
  data: () => ({
    category: null,
    show: false,
  }),
  computed: {
    ...mapGetters("auth", ["company"]),
    category_video() {
      if (this.category === "בדק בית") {
        return "TN1Tmjy20Mc";
      }
      if (this.category === "קונסטרוקציה") {
        return "Zke1xIOG35Y";
      }
      if (this.category === "אדריכלות") {
        return "u_qDQjQs_AU";
      }
      if (this.category === "איתור נזילות") {
        return "8aEOPgF_jeo";
      }
      if (this.category === "פיקוח") {
        return "s7olnOdinH4";
      }
      if (this.category === "פיקוח דיירים") {
        return "-Od0ippi1p4";
      }
      if (this.category === "יועצי אלומיניום") {
        return "2Q39R0B5IRQ";
      }
      return false;
    },
  },
  created() {
    if (!this.getUserCache()) {
      this.show = true;
    }
    if (this.company && this.company.category) {
      this.category = this.company.category;
    }
  },
  methods: {
    getUserCache() {
      return window.localStorage.getItem("cache/hasBeenPrimaryVideoBanner");
    },
    addToUserCache() {
      window.localStorage.setItem("cache/hasBeenPrimaryVideoBanner", true);
    },
    closeClicked() {
      this.addToUserCache();
      this.show = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
