async function getBuildings(siteId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/buildings`);
  return response.data;
}

async function createBuilding(siteId, data) {
  const response = await window.axios.post(`/api/v1/sites/${siteId}/buildings`, data);
  return response.data;
}

async function getBuilding(siteId, buildingId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/buildings/${buildingId}`);
  return response.data;
}

async function updateBuilding(siteId, buildingId, data) {
  const response = await window.axios.put(`/api/v1/sites/${siteId}/buildings/${buildingId}`, data);
  return response.data;
}

async function removeBuilding(siteId, buildingId) {
  const response = await window.axios.delete(`/api/v1/sites/${siteId}/buildings/${buildingId}`);
  return response.data;
}

export default {
  getBuildings,
  createBuilding,
  getBuilding,
  updateBuilding,
  removeBuilding,
}; 