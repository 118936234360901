<template>
  <div class="p-6 rtl" dir="rtl">
    <h1 class="text-2xl font-bold mb-6">מפקח מסד נתונים IndexedDB</h1>
    
    <div class="mb-6">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-reverse space-x-8">
          <button
            v-for="tab in tabs"
            :key="tab.id"
            @click="activeTab = tab.id"
            :class="[
              activeTab === tab.id
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
            ]"
          >
            {{ tab.name }}
          </button>
        </nav>
      </div>
    </div>

    <div v-if="loading" class="flex justify-center items-center py-12">
      <div class="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
    </div>

    <div v-else-if="error" class="bg-red-50 p-4 rounded-md">
      <p class="text-red-600">{{ error.replace('Failed to fetch data:', 'שגיאה בטעינת נתונים:') }}</p>
    </div>

    <div v-else>
      <!-- All Items View -->
      <div v-if="activeTab === 'allItems'" class="space-y-4">
        <div v-if="items.length === 0" class="bg-gray-50 rounded-lg p-8 text-center">
          <div class="inline-flex items-center justify-center w-16 h-16 bg-gray-100 rounded-full mb-4">
            <svg class="w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"></path>
            </svg>
          </div>
          <h3 class="text-lg font-medium text-gray-900 mb-2">אין פריטים</h3>
          <p class="text-gray-500">לא נמצאו פריטים במסד הנתונים</p>
        </div>
        <div v-else v-for="(item, index) in items" :key="index" class="bg-white p-4 rounded-lg shadow">
          <div class="flex justify-between items-start">
            <div class="font-medium text-gray-900">{{ item.key }}</div>
            <div class="text-sm text-gray-500">
              {{ new Date(item.createdAt).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' }) }}
            </div>
          </div>
          <div class="mt-2 text-gray-600 text-right">
            <pre class="whitespace-pre-wrap">{{ JSON.stringify(item.value, null, 2) }}</pre>
          </div>
        </div>
      </div>

      <!-- Ordered Keys View -->
      <div v-if="activeTab === 'orderedKeys'" class="space-y-4">
        <div v-if="orderedKeys.length === 0" class="bg-gray-50 rounded-lg p-8 text-center">
          <div class="inline-flex items-center justify-center w-16 h-16 bg-gray-100 rounded-full mb-4">
            <svg class="w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"></path>
            </svg>
          </div>
          <h3 class="text-lg font-medium text-gray-900 mb-2">אין מפתחות מסודרים</h3>
          <p class="text-gray-500">לא נמצאו מפתחות מסודרים במסד הנתונים</p>
        </div>
        <div v-else v-for="(item, index) in orderedKeys" :key="index" class="bg-white p-4 rounded-lg shadow">
          <div class="flex justify-between items-center">
            <div class="font-medium text-gray-900">{{ item.key }}</div>
            <div class="text-sm text-gray-500">
              {{ new Date(item.createdAt).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' }) }}
            </div>
          </div>
        </div>
      </div>

      <!-- Cursor Keys View -->
      <div v-if="activeTab === 'cursorKeys'" class="space-y-4">
        <div v-if="cursorKeys.length === 0" class="bg-gray-50 rounded-lg p-8 text-center">
          <div class="inline-flex items-center justify-center w-16 h-16 bg-gray-100 rounded-full mb-4">
            <svg class="w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </div>
          <h3 class="text-lg font-medium text-gray-900 mb-2">אין מפתחות עם סמן</h3>
          <p class="text-gray-500">לא נמצאו מפתחות עם סמן במסד הנתונים</p>
        </div>
        <div v-else v-for="(item, index) in cursorKeys" :key="index" class="bg-white p-4 rounded-lg shadow">
          <div class="flex justify-between items-center">
            <div class="font-medium text-gray-900">{{ item.key }}</div>
            <div class="text-sm text-gray-500">
              {{ new Date(item.createdAt).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' }) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import indexedDBService from '@/services/IndexedDBService'
import { useToast } from 'vue-toastification'

const toast = useToast()
const loading = ref(false)
const error = ref(null)
const items = ref([])
const orderedKeys = ref([])
const cursorKeys = ref([])
const activeTab = ref('allItems')

const tabs = [
  { id: 'allItems', name: 'כל הפריטים' },
  { id: 'orderedKeys', name: 'מפתחות מסודרים' },
  { id: 'cursorKeys', name: 'מפתחות עם סמן' }
]

async function fetchData() {
  loading.value = true
  error.value = null
  
  try {
    const [allItems, orderedKeysData, cursorKeysData] = await Promise.all([
      indexedDBService.getAllItems(),
      indexedDBService.getAllKeysOrderedByCreatedAt(),
      indexedDBService.getAllKeysOrderedByCreatedAtWithCursor()
    ])
    
    items.value = allItems
    orderedKeys.value = orderedKeysData
    cursorKeys.value = cursorKeysData
  } catch (e) {
    error.value = 'שגיאה בטעינת נתונים: ' + e.message
    toast.error('שגיאה בטעינת נתונים')
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  fetchData()
})
</script>