<template>
  <v-card v-if="upload" outlined>
    <div>
      <v-card-title
          class="d-flex align-center justify-space-between flex-wrap mb-0"
      >
        <span>עריכת תמונה</span>
        <RButtonIcon icon="mdi-close" @click="closeEditImageX"/>
      </v-card-title>
      <div class="edit-image-content">
        <div
            class="d-flex align-center justify-space-between mb-1 images-edit-actions"
        >
          <div class="d-flex align-center mb-3 mr-n1">
            <v-btn
                id="draw"
                color="primary"
                :variant="activeTool !== toolType.freeDraw ? 'outlined' : 'flat'"
                class="mx-1 action-btn"
                density="compact"
                @click="selectFreeDraw"
            >
              <v-icon>mdi-pencil</v-icon>
              <span class="mx-2">שרטוט</span>
            </v-btn>

            <v-btn
                id="circle"
                color="primary"
                :variant="activeTool !== toolType.ellipse ? 'outlined' : 'flat'"
                class="mx-1 action-btn"
                density="compact"
                @click="selectEllipse"
            >
              <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
              <span class="mx-2">עיגול</span>
            </v-btn>

            <v-btn
                id="arrow"
                color="primary"
                :variant="activeTool !== toolType.arrow ? 'outlined' : 'flat'"
                class="mx-1 action-btn"
                density="compact"
                @click="selectArrow"
            >
              <v-icon>mdi-arrow-up-bold-outline</v-icon>
              <span class="mx-2">חץ</span>
            </v-btn>

            <v-btn
                id="line"
                color="primary"
                :variant="activeTool !== toolType.line ? 'outlined' : 'flat'"
                class="mx-1 action-btn d-none"
                density="compact"
                @click="selectLine"
            >
              <v-icon>mdi-minus</v-icon>
              <span class="mx-2">קו</span>
            </v-btn>

            <v-btn
                id="text"
                color="primary"
                :variant="activeTool !== toolType.text ? 'outlined' : 'flat'"
                class="mx-1 action-btn tool-bnt"
                density="compact"
                @click="selectText"
            >
              <v-icon>mdi-format-color-text</v-icon>
              <span class="mx-2">טקסט</span>
            </v-btn>
          </div>
          <div class="d-flex align-center mb-3 ml-n1">
            <v-btn
                color="primary"
                :variant="!!activeTool ? 'outlined' : 'flat'"
                class="mx-1 action-btn"
                density="compact"
                @click="crop"
            >
              <v-icon>mdi-crop</v-icon>
              <span class="mx-2">זום</span>
            </v-btn>

            <v-btn
                color="primary"
                :variant="!!activeTool ? 'outlined' : 'flat'"
                class="mx-1 action-btn"
                density="compact"
                @click="rotate"
            >
              <v-icon>mdi-crop-rotate</v-icon>
              <span class="mx-2">סיבוב 90°</span>
            </v-btn>

            <v-btn
                id="clearBtn"
                color="primary"
                :variant="!canUndo ? 'outlined' : 'flat'"
                class="mx-1 action-btn"
                density="compact"
                @click="undo"
            >
              <v-icon>mdi-undo</v-icon>
              <span class="mx-2">חזור</span>
            </v-btn>

            <v-btn
                id="clearBtn"
                color="primary"
                :variant="!!activeTool ? 'outlined' : 'flat'"
                class="mx-1 action-btn"
                density="compact"
                @click="reset"
            >
              <v-icon>mdi-cancel</v-icon>
              <span class="mx-2">נקה הכל</span>
            </v-btn>
          </div>
        </div>
        <div
            class="edit-image-toolbar d-flex justify-space-between align-center mb-1"
        >
          <div v-if="showCropperOptions" class="d-none">
            <input type="checkbox"
                v-model="preventWhiteSpace"
            />
            <span>מניעת מרחב לבן</span>
          </div>
          <div v-if="showColorsOptions" class="edit-image-toolbar-colors">
            <span
                v-for="color in colors"
                :key="color"
                :data-color="color"
                :class="{
                'is-active': currentColor === color,
              }"
                :style="{ 'background-color': color }"
                @click="() => setColor(color)"
                @mouseup.prevent.stop=""
                @touchend.prevent.stop="() => setColor(color)"
            ></span>
          </div>

          <div v-if="showTextOptions" class="d-flex align-items-center">
            <select
                :value="fontSize"
                class="font-size-select"
                @input="setFontSize"
            >
              <option
                  v-for="(size, index) in fontSizes"
                  :key="index"
                  :value="size"
              >
                {{ size }}
              </option>
            </select>

            <v-btn
                id="font-bold-btn"
                :variant="!isBoldText ? 'outlined' : 'flat'"
                class="mr-2"
                color="primary"
                density="compact"
                @click="switchFontWeight"
            >B
            </v-btn
            >
          </div>
        </div>

        <div
            class="edit-image-canvas"
            :class="!activeTool ? 'cursor-move' : ''"
            style="width: 'fit-content'"
        >
          <div id="canvas-wrapper" class="position-relative">
            <canvas
                id="canvas"
                ref="canvasRef"
                class="canvas old-canvas"
                :width="canvasSize"
                :height="canvasSize"
                data-canvas="manual"
            ></canvas>
          </div>
        </div>

        <RButton block lg color="purple" type="submit" @click="finishEditImage">
          שמירת תמונה
        </RButton>
      </div>
    </div>
  </v-card>
</template>

<script>
import {Utils} from "@/common/Utils";
import {CanvasEditor} from "./canvasEditor/CanvasEditor";
import {toolType} from "./canvasEditor/toolType";
import {DEFAULT_MAX_IMAGE_SIZE} from "@/common/settings";
import localService from "@/services/LocalService";
import {onUnmounted} from "vue";

const DEFAULT_COLOR = "#DF302F";
const DEFAULT_FONT_SIZE = 14;

export default {
  props: {
    keepBase64: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: Object,
      default: () => {
        return {};
      },
    },
    closeEditImage: {
      type: Function,
      required: true,
    },
    updateEditImage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      currentColor: DEFAULT_COLOR,
      colors: [
        "#5D21D2",
        "#09916E",
        "#1e429f",
        "#EF810E",
        "#DF302F",
        "#000000",
        "#ffffff",
        "#DADADA",
      ],
      canvasEditor: null,
      activeTool: null,
      canvasSize: DEFAULT_MAX_IMAGE_SIZE,
      canvasElementSize: 0,
      canUndo: false,
      canvasObserver: null,
      preventWhiteSpace: true,
      fontSize: DEFAULT_FONT_SIZE,
      fontSizes: [
        12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
        30,
      ],
      isBoldText: false,
    };
  },
  computed: {
    toolType() {
      return toolType;
    },
    imageUrl() {
      if (!this.upload.path) {
        return "";
      }
      if (this.upload.path.length > 50) {
        return this.upload.path;
      }

      return this.$Reporto.globals.MEDIA_URL + this.upload.path;
    },
    initialImageUrl() {
      if (this.upload.initialPath.length > 50) {
        return this.upload.initialPath;
      }
      if (this.upload.initialPath) {
        return this.$Reporto.globals.MEDIA_URL + this.upload.initialPath;
      } else {
        return this.imageUrl;
      }
    },
    showColorsOptions() {
      return !!this.activeTool;
    },
    showTextOptions() {
      return this.activeTool === toolType.text;
    },
    showCropperOptions() {
      return !this.activeTool;
    },
  },
  watch: {
    activeTool: {
      handler(value) {
        if (this.canvasEditor) {
          this.canvasEditor.setTool(value);
        }
      },
    },
    currentColor: {
      handler(value) {
        if (this.canvasEditor) {
          this.canvasEditor.setColor(value);
        }
      },
      immediate: true,
    },
    fontSize: {
      handler(value) {
        if (this.canvasEditor) {
          this.canvasEditor.setFontSize(value);
        }
      },
      immediate: true,
    },
    canvasElementSize: {
      handler(value) {
        if (value) {
          this.initCanvas();
        }
      },
      immediate: true,
    },
    preventWhiteSpace: {
      handler(value) {
        if (this.canvasEditor) {
          this.canvasEditor.preventWhiteSpace = value;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.subscribeCanvasObserver();
    this.loadEditorSettings();
  },
  created() {
    const modalId = Utils.guid();
    onUnmounted(() => {
      if (this.canvasEditor) {
        // this.canvasEditor.removeListener("canUndo", this.setCanUndo);
        this.canvasEditor.destruct();
        this.canvasEditor = null;
      }
      this.unsubscribeCanvasObserver();
    });
  },
  methods: {
    removeCanvases() {
      let canvases = document.getElementsByTagName("canvas");

      for (var i = 0; i < canvases.length; i++) {
        let canvas = canvases[i];
        canvas.width = 1;
        canvas.height = 1;

        const ctx = canvas.getContext("2d");
        ctx && ctx.clearRect(0, 0, 1, 1);

        canvas.remove();
        canvas = null;
      }

      // last canvas
      // let canvasLast = document.getElementById("canvas-wrapper");
      // canvasLast.width = 1;
      // canvasLast.height = 1;
      //
      // const ctx = canvasLast.getContext("2d");
      // ctx && ctx.clearRect(0, 0, 1, 1);
      //
      // canvasLast.remove();
      // canvasLast = null;
    },
    async initCanvas() {
      const image = await this.createImage(this.imageUrl);
      if (this.canvasSize > image.width || this.canvasSize > image.height) {
        this.canvasSize = Math.min(image.width, image.height);
      }

      this.canvasEditor = new CanvasEditor({
        canvasId: "canvas",
        image,
        imageUrl: this.imageUrl,
        initialImageUrl: this.initialImageUrl,
        width: this.canvasSize,
        height: this.canvasSize,
        canvasElementSize: this.canvasElementSize,
      });
      this.canvasEditor.setColor(this.currentColor);
      this.canvasEditor.setFontSize(this.fontSize);
      this.canvasEditor.setFontWeight(this.isBoldText ? 700 : 400);
      this.canvasEditor.on("canUndo", this.setCanUndo);
      this.canvasEditor.on("changeTool", this.selectTool);
    },
    async createImage(src) {
      return new Promise((resolve, reject) => {
        try {
          const image = new Image();
          image.onload = () => {
            resolve(image);
          };
          image.onerror = (err) => {
            reject(err);
          };
          image.crossOrigin = "Anonymous";
          image.src = src;
        } catch (err) {
          reject(err);
        }
      });
    },
    setCanUndo(canUndo) {
      this.canUndo = canUndo;
    },
    selectFreeDraw() {
      this.selectTool(toolType.freeDraw);
    },
    selectEllipse() {
      this.selectTool(toolType.ellipse);
    },
    selectArrow() {
      this.selectTool(toolType.arrow);
    },
    selectLine() {
      this.selectTool(toolType.line);
    },
    selectText() {
      this.selectTool(toolType.text);
    },
    selectTool(tool) {
      this.activeTool = this.activeTool === tool ? null : tool;
    },
    setColor(color) {
      this.currentColor = color;
      this.saveEditorSettings({
        color: this.currentColor,
        fontSize: this.fontSize,
        isBoldText: this.isBoldText,
      });
    },
    setFontSize(event) {
      this.fontSize = event.target.value;
      this.saveEditorSettings({
        color: this.currentColor,
        fontSize: this.fontSize,
        isBoldText: this.isBoldText,
      });
    },
    switchFontWeight() {
      this.isBoldText = !this.isBoldText;
      this.saveEditorSettings({
        color: this.currentColor,
        fontSize: this.fontSize,
        isBoldText: this.isBoldText,
      });
      if (this.canvasEditor) {
        this.canvasEditor.setFontWeight(this.isBoldText ? 700 : 400);
      }
    },
    crop() {
      this.selectTool(null);
    },
    rotate() {
      if (this.canvasEditor) {
        this.canvasEditor.rotate();
      }
    },
    undo() {
      if (this.canvasEditor) {
        this.canvasEditor.undo();
      }
    },
    reset() {
      if (this.canvasEditor) {
        this.canvasEditor.reset();
      }
    },
    loadEditorSettings() {
      const item = localService.getItem(localService.keys.imageEditorSettings);
      if (item) {
        this.currentColor = item.color || DEFAULT_COLOR;
        this.fontSize = item.fontSize || DEFAULT_FONT_SIZE;
        this.isBoldText = !!item.isBoldText;
      }
    },
    saveEditorSettings(settings) {
      localService.setItem(localService.keys.imageEditorSettings, settings);
    },
    closeEditImageX() {
      this.removeCanvases();
      this.closeEditImage();
    },
    finishEditImage() {
      // get base64 image
      let time = new Date().getTime();
      const base64_to_upload = this.canvasEditor.getResult();

      // upload base64 image
      if (this.keepBase64) {
        this.removeCanvases();
        this.updateEditImage(base64_to_upload);
        this.closeEditImage();
      } else {
        let loader = this.$loading.show();

        this.$Utils.uploadImageBase64(base64_to_upload).then(({data}) => {
          this.removeCanvases();
          this.updateEditImage(data.path);
          this.closeEditImage();

          loader.hide();
        });
      }
      console.log("finishEditImage", new Date().getTime() - time);
    },
    subscribeCanvasObserver() {
      const {canvasRef} = this.$refs;
      if (canvasRef) {
        this.canvasObserver = new ResizeObserver(() => {
          this.canvasElementSize = canvasRef.offsetWidth;
        });
        this.canvasObserver.observe(canvasRef);
      }
    },
    unsubscribeCanvasObserver() {
      const {canvasRef} = this.$refs;
      if (this.canvasObserver && canvasRef) {
        this.canvasObserver.unobserve(canvasRef);
      }
      this.canvasObserver = null;
    },
  },
};
</script>
<style lang="scss">
.font-size-select {
  background: rgb(255, 255, 255);
  border: 1px solid #5e21d2;
  border-radius: 4px;
  text-align: center;
  width: 40px;
  color: #5e21d2;
  font-size: 0.75rem;
  font-weight: 500;
  position: relative;
  appearance: auto;
  cursor: pointer;
}

.edit-image-content {
  padding: 16px;

  .edit-image-canvas {
    display: flex;
    justify-content: center;

    #canvas-wrapper {
      overflow: hidden;
      direction: ltr;

      canvas {
        direction: rtl;
      }

      input::placeholder {
        color: var(--placeholder-color);
      }
    }
  }

  @media (max-width: 766px) {
  }

  .edit-image-toolbar {
    #font-size-selector {
      width: 85px;
      margin-bottom: 0 !important;
    }

    #font-bold-btn {
      min-width: 30px;
    }
  }


  .images-edit-actions {
    @media (max-width: 550px) {
      //overflow-y: scroll;
      //margin-right: -16px;
      //margin-left: -16px;
    }

    .action-btn {
      padding: 0 5px !important;
      height: 32px;
      font-size: 14px;
      margin: 0 5px 0 0 !important;
      letter-spacing: 0;
      min-width: 0;

      .v-btn__content {
        span {
          margin: 0 1px 0 0 !important;
        }
      }

      i {
        font-size: 14px !important;
      }
    }

    @media (max-width: 600px) {
      .action-btn {
        font-size: 12px;
        height: 30px;
      }
    }

    @media (max-width: 550px) {
      .action-btn {
        padding: 0px 4px !important;
        font-size: 11px;
        margin: 0 5px 0 0 !important;
        min-width: 38px !important;

        .v-btn__content {
          span {
            margin: 0 0px 0 0 !important;
            display: none;
          }
        }

        i {
          font-size: 16px !important;
        }
      }
    }

    @media (max-width: 400px) {
      .action-btn {
        margin: 0 4px 0 0 !important;
        min-width: 32px !important;

        i {
          font-size: 14px !important;
        }
      }
    }

    //@media (max-width: 400px) {
    //  .action-btn {
    //    .v-btn__content span {
    //      margin: 0 !important;
    //    }
    //    i {
    //      //display: none;
    //    }
    //  }
    //}
  }
}

#textOnCanvasInput:focus {
  outline: none;
}
</style>
