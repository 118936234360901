//./plugins/posthog.js
import posthog from "posthog-js";

export default {
    install(app) {
        app.config.globalProperties.$posthog = posthog.init(
            "phc_p0Iip3nUZa6Ev78rIBm4qHMlXkP6CFvzM7FeR4JOqSF",
            {
                api_host: "https://us.i.posthog.com",
                maskAllInputs: false,
                maskInputOptions: {
                    password: true
                },
                enable_recording_console_log: true,
                person_profiles: "identified_only",
                autocapture: false,
                persistence: "localStorage+cookie"
            }
        );
    },
};