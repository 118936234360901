<template>
  <ProjectsList title="פרויקטים" />
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
import VideoButton from "@/components/VideoButton.vue";
import ProjectsList from "@/components/ProjectsList.vue";

export default {
  components: {
    ProjectsList,
    VideoButton,
    PageLoader,
  },
  data() {
    return {
      searchQuery: null,
      projects: [],
      isLoaded: false,
    };
  },
  computed: {
    headers() {
      let headers = [];

      headers.push({
        title: "מספר",
        value: "number",
        width: "100px",
        sortable: true,
      });

      headers.push({
        title: "שם פרויקט",
        value: "name",
        sortable: true,
      });

      headers.push({
        title: "כתובת",
        value: "address",
        sortable: true,
      });

      headers.push({
        title: "לקוח",
        value: "customer_name",
        sortable: true,
      });

      headers.push({
        title: "תווית",
        value: "tag",
        sortable: true,
      });

      if (window.innerWidth > 767) {
        headers.push({
          title: "תאריך יצירה",
          value: "created_at",
          width: "180px",
          sortable: true,
        });
      }

      headers.push({
        title: "פעולות",
        value: "actions",
        width: "80px",
        sortable: false,
      });

      return headers;
    },
  },
  async created() {
    await this.fetchProjects();
    this.isLoaded = true;
  },
  methods: {
    async fetchProjects() {
      await this.$store.dispatch("projects/fetchProjects");
      this.projects = this.$store.state.projects.projects;
    },
  },
};
</script>

<style lang="scss">
.projects-table {
  th:nth-child(2) {
    min-width: 180px;
  }
  th:nth-child(3) {
    min-width: 180px;
  }
  th:nth-child(4) {
    min-width: 180px;
  }
}
</style>
