<template>
  <v-list-item
    :to="to"
    v-bind="$attrs"
    :value="text"
    @click="$emit('click', $event)"
    class="r-action-item"
  >
    <template #prepend>
      <v-icon :color="color" class="r-action-icon">
        {{ loading ? 'mdi-loading mdi-spin' : icon }}
      </v-icon>
    </template>

    <v-list-item-title class="r-action-title">{{ text }}</v-list-item-title>
  </v-list-item>
</template>

<script>
export default {
  name: "RAction",
  inheritAttrs: false,
  emits: ["click"],
  props: {
    color: {
      type: String,
      default: "#7A7A7A"
    },
    to: {
      type: [String, Object],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false
    },
  },
};
</script>

<style >
.r-action-item {
  display: flex;
  align-items: center;
  font-size: 13px !important;
}

.r-action-icon {
  font-size: 1rem;
}

.r-action-title {
  flex: 1;
  font-size: 13px;
}

.v-list-item__spacer {
  width: 10px !important;
}

.v-list-item__prepend > .v-badge .v-icon, .v-list-item__prepend > .v-icon, .v-list-item__append > .v-badge .v-icon, .v-list-item__append > .v-icon {
  opacity:1;
}

@keyframes fast-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mdi-spin {
  animation: fast-spin 0.6s linear infinite !important; /* Default is usually 2s, making it 0.6s for faster spin */
}
</style>
