<template>
  <RModal v-model="isCloneReportModalOpen" title="שכפול דוח">
    <RFields>
      <RFieldCheckbox
        v-model="clearDefectsUploads"
        label="ללא תמונות בממצאים"
      />
      <RFieldCheckbox
        v-model="clearChecklistsAndTablesUploads"
        label="ללא תמונות בטבלאות וצ׳קליסטים"
      />
      <RFieldTextChoices
        v-model="cloneReportTag"
        label="תווית הדוח המשוכפל"
        :choices="cloneHintsArray"
      />
      <RButton
        color="primary"
        block
        lg
        :disabled="cloneReportTag && cloneReportTag.length <= 0"
        type="submit"
        @click="cloneReport"
      >
        שכפול דוח
      </RButton>
    </RFields>
  </RModal>
</template>

<script>
import { cloneHints } from "@/common/settings";
import ReportsApi from "@/api/ReportsApi";

export default {
  props: {
    reportId: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      isCloneReportModalOpen: false,
      clearDefectsUploads: false,
      clearChecklistsAndTablesUploads: false,
      cloneReportTag: "בדק חוזר",
      cloneHints,
    };
  },
  computed: {
    cloneHintsArray() {
      return this.cloneHints.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
    },
  },
  methods: {
    openModal() {
      this.isCloneReportModalOpen = true;
    },
    async cloneReport() {
      let loader = this.$loading.show();

      try {
        const data = await ReportsApi.cloneReport(this.reportId, {
          tag: this.cloneReportTag,
          clearDefectsUploads: this.clearDefectsUploads,
          clearChecklistsAndTablesUploads: this.clearChecklistsAndTablesUploads,
        });
        this.$toast.success("דוח חוזר נוצר בהצלחה!");
        this.$posthog.capture("log:report:clone", {
          clearDefectsUploads: this.clearDefectsUploads,
          clearChecklistsAndTablesUploads: this.clearChecklistsAndTablesUploads,
        });
        loader.hide();
        this.$emit("close");
        await this.$router.push({
          name: "reports.edit",
          params: { id: data.id },
        });
      } catch (err) {
        this.$toast.error(err);
        loader.hide();
      }
    },
  },
};
</script>
