<template>
  <div>
    <div class="r-page-report-header">
      <div class="r-page-report-header-content">
        <div class="r-page-report-header-title">
          <span class="r-page-report-header-title-title"
          >כתובת: {{ report.address }}</span
          >
          <div class="r-page-report-header-status">
            <div
                v-if="report.tag || report.is_draft"
                class="r-page-report-header-tags"
            >
              <span v-if="report.tag" class="r-page-report-header-tag">{{
                  report.tag.trim()
                }}</span>
              <span v-if="report.is_draft" class="r-page-report-header-draft"
              >טיוטה</span
              >
            </div>
          </div>
        </div>
        <div class="r-page-report-header-subtitle">
          <span v-if="report.number"> מספר דוח: {{ report.number }}</span>
          <span v-if="report.customerName"
          >לקוח: {{ report.customerName }}</span
          >
          <span v-if="report.name">תבנית: {{ report.name }}</span>
          <span v-if="report.project_id && report.project">
            פרויקט:
            <router-link
                :to="{
                name: 'projects.edit',
                params: { id: report.project.id },
              }"
            >
              {{ report.project.name }}
            </router-link>
          </span>

          <span>
            סטטוס:
             <ReportStatusSelect
                 :report-id="report.id"
                 :status-id="report.status_id"
                 @update:status-id="(newStatusId) => report.status_id = newStatusId"
             />
          </span>
        </div>
      </div>
      <div class="r-page-report-header-actions">
        <RButton
            :disabled="!hasInternet || isSyncing"
            v-show="
            !user.permissionDisableSendReportToEmail &&
            reportId !== '00b5c320-c96e-46ee-ae56-9651ee0b3bf7'
          "
            icon-before="mdi-email"
            outlined
            color="green"
            @click="openSendModal"
        >
          שליחה למייל
        </RButton>

        <RActions>
          <template #activator="{ props }">
            <button
                v-bind="props"
                role="button"
                class="r-button r-button--blue r-button--outlined"
                v-show="
                !user.permissionDisableSendReportToEmail &&
                reportId !== '00b5c320-c96e-46ee-ae56-9651ee0b3bf7'
              "
            >
            <i
                  aria-hidden="true"
                  class="v-icon notranslate mdi mdi-download theme--light"
              ></i>
              הורדה
            </button>
          </template>

          <RAction
              icon="mdi-file-pdf"
              color="red"
              text="הורדת PDF"
              :loading="isDownloadingReport"
              @click="openShareReportModal"
              :disabled="!hasInternet || isSyncing || isDownloadingReport || isExportingToExcelReport"
          ></RAction>
          <RAction
              icon="mdi-file-excel"
              color="green"
              text="הורדת EXCEL"
              @click="exportToExcelClicked"
              :loading="isExportingToExcelReport"
              :disabled="!hasInternet || isSyncing || isDownloadingReport || isExportingToExcelReport"
          ></RAction>
        </RActions>
        
        <RActions>
          <template #activator="{ props }">
            <button
                v-bind="props"
                role="button"
                class="r-button r-button--black r-button--outlined"
            >
              פעולות
              <i
                  aria-hidden="true"
                  class="v-icon notranslate mdi mdi-dots-vertical theme--light"
              ></i>
            </button>
          </template>

          <RAction
              @click="openSettingsModal"
              icon="mdi-cog"
              text="הגדרות דוח"
          ></RAction>
          <RAction
              @click="openPreviewModal"
              icon="mdi-eye"
              text="תצוגה מקדימה"
              :disabled="!hasInternet || isSyncing"
          ></RAction>
          <RAction
              @click="openCloneModal"
              icon="mdi-content-copy"
              text="שכפול דוח"
              :disabled="!hasInternet || isSyncing"
          ></RAction>
          <RAction
              link
              :to="{
              name: 'reports.editTemplate',
              params: { id: reportId },
            }"
              icon="mdi-view-dashboard"
              text="עריכת התבנית"
              :disabled="!hasInternet || isSyncing"
          ></RAction>
        </RActions>
      </div>
    </div>

    <ReportSendModal ref="sendModalRef" :report-id="reportId"/>

    <ReportSettingsModal ref="settingsModalRef"/>

    <ReportCloneModal ref="cloneModalRef" :report-id="reportId"/>

    <ReportPreviewModal ref="previewModalRef" :report-id="reportId"/>

    <RModal v-model="isShareReportModalOpen" title="שיתוף והורדת הדוח">
      <div v-if="shareReportModalType === 'pdf'" class="flex justify-center items-center mt-2">
        <div class="p-3 rounded-full bg-red-50">
          <v-icon icon="mdi-file-pdf" size="x-large" class="text-red-500"/>
        </div>
      </div>
      <div v-if="shareReportModalType === 'excel'" class="flex justify-center items-center mt-2">
        <div class="p-3 rounded-full bg-green-50">
          <v-icon icon="mdi-file-excel" size="x-large" class="text-green-500"/>
        </div>
      </div>
      <div class="flex justify-center items-center mt-3 text-lg font-bold">
        <template v-if="shareReportModalType === 'pdf'">
          PDF הדוח מוכן לשיתוף והורדה
        </template>
        <template v-if="shareReportModalType === 'excel'">
          אקסל הדוח מוכן לשיתוף והורדה
        </template>
      </div>
      <div class="flex justify-center items-center mt-1 mb-8 text-xs">
        במידה ותעשו שינויים נוספים בדוח תצטרכו ליצור קישור חדש לשיתוף והורדה.
      </div>
      <div class="share-link-container">
        <input
            type="text"
            id="short-report-link"
            v-model="shortReportLink"
            readonly
            @click="copyReportLink"
            class="share-link-input"
            :title="'לחץ להעתקה'"
        />
        <span class="share-link-label">קישור לשיתוף הדוח</span>
      </div>

      <div class="share-report-buttons mt-3">
        <RButton
            lg
            icon-before="mdi-whatsapp"
            color="green"
            @click="shareReportLinkOnWhatsapp"
        >
          שיתוף ב-Whatsapp
        </RButton>
        <RButton
            v-if="shareReportModalType === 'pdf'"
            lg
            icon-before="mdi-download"
            color="blue"
            @click="downloadReportLink"
        >
          הורדת הדוח PDF
        </RButton>
        <RButton
            v-if="shareReportModalType === 'excel'"
            lg
            icon-before="mdi-file-excel"
            color="blue"
            @click="downloadReportLink"
        >
          הורדת הדוח EXCEL
        </RButton>
        <RButton
            lg
            icon-before="mdi-content-copy"
            color="purple"
            @click="copyReportLink"
        >
          העתקת קישור הדוח
        </RButton>
      </div>
    </RModal>
    
    <RModal v-model="isExcelPromoModalOpen" title="ייצוא לאקסל">
      <div class="flex flex-col items-center max-w-md mx-auto">
        <!-- Icon Section -->
        <div class="mb-5 mt-3">
          <div class="p-3 rounded-full bg-green-50 ring-8 ring-green-50">
            <v-icon 
              icon="mdi-file-excel" 
              size="x-large" 
              class="text-green-500 text-3xl"
            />
          </div>
        </div>
        
        <!-- Content Section -->
        <div class="text-center w-full">
          <h3 class="text-xl font-bold text-gray-900 mb-2">
            ייצוא דוחות לאקסל
          </h3>
          
          <p class="text-gray-600 text-sm leading-relaxed max-w-sm mx-auto">
            ייצוא לאקסל מאפשר לך לנתח ולעבד את נתוני הדוח בקלות.
            <br>
            כדי להשתמש בתכונה זו, 
            <span class="font-medium">יש להפעיל אותה בעמוד הגדרות החברה.</span>
          </p>

          <!-- Owner Section -->
          <div class="pt-4">
            <template v-if="checkRole('owner')">
              <RButton 
                color="green"
                class="mb-3"
                icon-after="mdi-chevron-left"
                :to="{ name: 'company-settings' }"
              >
                הפעלה דרך הגדרות חברה
              </RButton>
            </template>
            <RAlert 
              v-else 
              type="warning" 
            >
              רק משתמש המוגדר כבעלים יכול להפעיל תכונה זו.
              <p class="text-xs mt-1 text-amber-600">
                אנא פנה למשתמש בדרגת בעלים חברה להפעלת התכונה
              </p>
            </RAlert>
          </div>
        </div>
      </div>
    </RModal>
  </div>
</template>

<script setup>
import {useBackgroundSync} from '@/composables/useBackgroundSync';
import {useStore} from 'vuex';
import ReportSendModal from "./ReportSendModal";
import ReportCloneModal from "./ReportCloneModal";
import ReportPreviewModal from "./ReportPreviewModal";
import ReportsApi from "@/api/ReportsApi";
import ReportSettingsModal from "./ReportSettingsModal.vue";
import {Utils} from "@/common/Utils";
import {ref, computed} from 'vue';
import {useToast} from 'vue-toastification';
import ReportStatusSelect from "@/components/ReportStatusSelect.vue";
import posthog from 'posthog-js'
import {useCompanyFeatures} from "@/composables/useCompanyFeatures";
import {useRouter} from 'vue-router';
import {useRoleCheck} from "@/composables/useRoleCheck";

const {hasFeature} = useCompanyFeatures()
const {getUserRoleName, checkRole} = useRoleCheck()

const store = useStore();
const {isSyncing, hasInternet} = useBackgroundSync();

const shareReportModalType = ref("pdf");
const isShareReportModalOpen = ref(false);
const shortReportLink = ref(null);
const isDownloadingReport = ref(false);
const toast = useToast();

const sendModalRef = ref(null);
const settingsModalRef = ref(null);
const cloneModalRef = ref(null);
const previewModalRef = ref(null);

// Vuex getters using composition API
const report = computed(() => store.getters['report/report']);
const user = computed(() => store.getters['auth/user']);

const reportId = computed(() => report.value.id);

// Status text for the indicator
const statusText = computed(() => {
  if (!hasInternet.value) return 'אין חיבור לאינטרנט';
  if (isSyncing.value) return 'מסנכרן...';
  return '';
});

// Methods
const openSendModal = () => {
  sendModalRef.value.openModal();
};

const openSettingsModal = () => {
  settingsModalRef.value.openModal();
};

const openCloneModal = () => {
  cloneModalRef.value.openModal();
};

const openPreviewModal = () => {
  previewModalRef.value.openModal();
};

const shareReportLinkOnWhatsapp = () => {
  if (!shortReportLink.value) {
    toast.error("שגיאה בשיתוף הדוח ל-WhatsApp");
    return;
  }

  if (window.innerWidth > 1200) {
    window.open(
        `https://api.whatsapp.com/send?text=${shortReportLink.value}`,
        "_blank"
    );
  } else {
    const anchor = document.createElement("a");
    anchor.href = `https://api.whatsapp.com/send?text=${shortReportLink.value}`;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  }
};

const downloadReportLink = () => {
  if (!shortReportLink.value) {
    toast.error("שגיאה בהורדת הדוח");
    return;
  }

  const anchor = document.createElement("a");
  anchor.href = shortReportLink.value;
  if (!Utils.isIOSDevice() && window.innerWidth > 1100) {
    anchor.target = "_blank";
  }

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

const copyReportLink = () => {
  if (!shortReportLink.value) {
    toast.error("שגיאה בהעתקת הדוח");
    return;
  }

  // Try modern clipboard API first
  if (navigator?.clipboard?.writeText) {
    navigator.clipboard.writeText(shortReportLink.value)
        .then(() => {
          toast.success("הקישור הועתק בהצלחה");
        })
        .catch(() => {
          fallbackCopy();
        });
  } else {
    fallbackCopy();
  }
};

const fallbackCopy = () => {
  try {
    // Create temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = shortReportLink.value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    toast.success("הקישור הועתק בהצלחה");
  } catch (err) {
    toast.error("שגיאה בהעתקת הקישור");
  }
};

const openShareReportModal = async () => {
  isDownloadingReport.value = true;
  posthog.capture("log:report:download");
  let toastExporting = toast.info("ייצוא ל-pdf בתהליך...", {
    timeout: 30000
  });
  try {
    shortReportLink.value = await ReportsApi.getDownloadReportShortLink(reportId.value);
    shareReportModalType.value = "pdf";
    isShareReportModalOpen.value = true;
  } catch (e) {
    console.error(e);
    toast.error("שגיאה בשיתוף הדוח");
    posthog.capture("error:report:download", {
      reportId: reportId.value,
      error: e.message,
    });
  } finally {
    isDownloadingReport.value = false;
    toast.dismiss(toastExporting);
  }
};


// Excel Export
const isExportingToExcelReport = ref(false);
const isExcelPromoModalOpen = ref(false);

const promoMessage = computed(() => {
  return user.value.role === 'owner'
    ? 'ניתן להפעיל את התכונה הזו בהגדרות החברה'
    : 'רק בעל החברה יכול להפעיל תכונה זו';
});


const exportToExcelClicked = () => {
  if (!hasFeature('exportReportsToExcel')) {
    isExcelPromoModalOpen.value = true;
    posthog.capture("log:report:excel:promo_modal_open");
  } else {
    exportToExcel();
  }
}

const exportToExcel = async () => {
  if (!hasFeature('exportReportsToExcel')) {
    isExcelPromoModalOpen.value = true;
    return;
  }
  
  isExportingToExcelReport.value = true;
  posthog.capture("log:report:excel");
  let toastExporting = toast.info("ייצוא לאקסל בתהליך...", {
    timeout: 30000
  });
  try {
    const { data } = await ReportsApi.exportReportToExcel(reportId.value);
    shareReportModalType.value = "excel";
    shortReportLink.value = data.shortLink;
    isShareReportModalOpen.value = true;
    toast.success("הדוח נוצר באקסל בהצלחה");
  } catch (e) {
    toast.error("שגיאה בייצוא לאקסל");
    console.error(e);
    posthog.capture("error:report:excel", {
      reportId: reportId.value,
      error: e.message,
    });
  } finally {
    isExportingToExcelReport.value = false;
    toast.dismiss(toastExporting);
  }
};
</script>

<style scoped lang="scss">
.share-report-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
}

.share-report-button {
  @media (max-width: 767px) {
    width: 100%;
  }
}

.r-page-report-header-status {
  display: flex;
  align-items: center;
  gap: 12px;
}

.share-link-container {
  position: relative;
  margin: 1rem 0;
}

.share-link-input {
  width: 100%;
  padding: 12px 16px;
  font-size: 14px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #f8fafc;
  color: #1e293b;
  transition: all 0.2s ease;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #f1f5f9;
    border-color: #cbd5e1;
  }

  &:focus {
    border-color: #3b82f6;
    box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
    background-color: white;
  }

  &::selection {
    background-color: #bfdbfe;
  }
}

.share-link-label {
  position: absolute;
  top: -10px;
  right: 10px;
  padding: 0 6px;
  font-size: 12px;
  color: #64748b;
  background-color: white;
  border-radius: 4px;
}

.share-link-container::after {
  content: 'לחץ להעתקה';
  pointer-events: none;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  color: #64748b;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.share-link-container:hover::after {
  opacity: 1;
}

:deep(.v-select) {
  .v-field {
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  }

  .v-field__input {
    min-height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  }
}

:deep(.v-list) {
  border-radius: 8px !important;
  min-width: 200px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
}

:deep(.v-list-item) {
  min-height: 36px !important;
  margin: 4px !important;
  border-radius: 6px !important;
}

/* Remove default active and hover states since we're using custom colors */
:deep(.v-list-item--active),
:deep(.v-list-item:hover) {
  background: inherit !important;
  opacity: 0.9;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  :deep(.v-icon) {
    padding: 1rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.05);
  }
}
</style>
