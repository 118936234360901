import posthog from 'posthog-js'

export const TourService = {
    STORAGE_KEY: 'cache/firstReportSteps',

    getProgress() {
        const savedProgress = window.localStorage.getItem(this.STORAGE_KEY)
        return savedProgress ? JSON.parse(savedProgress) : {}
    },

    setStepComplete(step) {
        const progress = this.getProgress()
        progress[step] = true
        window.localStorage.setItem(this.STORAGE_KEY, JSON.stringify(progress))
        posthog.capture('log:tour_step_complete', { step })
    },

    isStepComplete(step) {
        const progress = this.getProgress()
        return !!progress[step]
    },

    resetProgress() {
        window.localStorage.removeItem(this.STORAGE_KEY)
    }
}

export default TourService
