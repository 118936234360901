<template>
  <!-- Full Modal -->
  <RModal
      v-if="showUpgradeModal && !isMinimized"
      :model-value="true"
      @close="isMinimized = true"
      title="גרסה חדשה זמינה ✨"
  >
    <div class="text-center mb-4 mt-4">
      <span class="text-4xl">🚀</span>
    </div>
    <p class="text-gray-600 text-lg font-weight-medium text-center mb-2">
      גרסה חדשה ומשופרת של האפליקציה זמינה!
    </p>
    <p class="text-gray-500 text-sm text-center mb-4">
      כדי ליהנות מהתכונות החדשות והשיפורים, יש ללחוץ על הכפתור למטה.
    </p>
    <RButton @click="refreshPage" lg block class="mt-4" icon-before="mdi-rocket">
      שדרג עכשיו
    </RButton>
  </RModal>

  <!-- Minimized Notification -->
  <div v-if="showUpgradeModal && isMinimized"
      class="fixed top-4 right-4 bg-gray-900 rounded-lg shadow-lg p-4 flex items-center gap-3 cursor-pointer z-[9999] hover:bg-gray-800 transition-colors animate-bounce-gentle"
      @click="isMinimized = false">
    <v-icon class="text-yellow-400 text-xl">mdi-rocket</v-icon>
    <span class="text-yellow-100 font-medium">גרסה חדשה זמינה - לחץ לשדרוג</span>
  </div>
</template>

<script>
import VersionApi from "@/api/VersionApi";

export default {
  name: "ForceRefresh",
  data() {
    return {
      currentAppVersion: null,
      showUpgradeModal: false,
      versionCheckInterval: null,
      isMinimized: false,
      checkEveryMinutes: 120
    };
  },
  async mounted() {
    await this.fetchAndSetVersion();

    // Check version every 120 minutes
    this.versionCheckInterval = setInterval(async () => {
      const newVersion = await this.fetchVersion();
      if (newVersion !== "error" && this.currentAppVersion && newVersion !== this.currentAppVersion) {
        this.showUpgradeModal = true;
        this.$posthog.capture("log:version_upgrade_opened", {
          currentVersion: this.currentAppVersion,
          newVersion,
          isMinimized: this.isMinimized
        });
        clearInterval(this.versionCheckInterval);
      }
    }, this.checkEveryMinutes * 60 * 1000);
  },
  methods: {
    async fetchVersion() {
      try {
        const data = await VersionApi.getAppState();
        return data.version;
      } catch (error) {
        this.$posthog.capture("log:version_upgrade_fetch_failed", {error});
        console.error('Failed to fetch app version:', error);
        return "error";
      }
    },
    async fetchAndSetVersion() {
      this.currentAppVersion = await this.fetchVersion();
    },
    refreshPage() {
      this.$posthog.capture("log:version_upgrade_clicked", {
        currentAppVersion: this.currentAppVersion,
        isMinimized: this.isMinimized
      });
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
  },
};
</script>

<style>
/* Add to your global CSS or Tailwind config */
@keyframes bounce-gentle {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}

.animate-bounce-gentle {
  animation: bounce-gentle 2s infinite;
}
</style>
