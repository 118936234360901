<template>
  <div class="p-4">
    <!-- Site Details Card -->
    <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4 mb-6">
      <div class="p-6">
        <h2 class="text-lg font-medium mb-6">פרטי אתר</h2>
        <div class="space-y-8">
          <!-- Site Details Section -->
          <div>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              <RFieldText
                v-model="editingSite.name"
                label="שם"
                required
              />
              <RFieldText
                v-model="editingSite.customer_name"
                label="לקוח"
              />
              <RFieldText
                v-model="editingSite.address"
                label="כתובת"
              />
              <RFieldText
                v-model="editingSite.tag"
                label="תווית"
              />
            </div>
          </div>

          <!-- Site Image Section -->
          <div>
            <label class="block text-sm font-medium text-gray-700">תמונת אתר</label>
            <div class="mt-1">
              <div v-if="editingSite.site_image || imagePreview || isUploading" class="relative inline-block">
                <img 
                  v-if="!isUploading"
                  :src="Utils.getMediaUrl(editingSite.site_image)"
                  class="max-w-[150px] h-auto rounded-md sm:max-w-[200px]"
                  :class="{ 'opacity-50': isUploading }"
                  alt="Site image"
                />
                <button
                  @click="removeImage"
                  type="button"
                  class="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </button>
                <!-- Add loading overlay -->
                <div v-if="isUploading" class="inset-0 h-32 w-32 flex items-center justify-center bg-purple-400 rounded-md">
                  <v-icon
                    icon="mdi-loading"
                    class="animate-spin text-white"
                    size="large"
                  />
                </div>
              </div>
              
              <div 
                v-else
                @click="$refs.fileInput.click()"
                class="h-32 w-32 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center cursor-pointer hover:border-purple-500"
                :class="{ 'opacity-50 pointer-events-none': isUploading }"
              >
                <div class="text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-8 w-8 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                  </svg>
                  <span class="mt-2 block text-sm font-medium text-gray-500">
                    העלה תמונה
                  </span>
                </div>
              </div>
              
              <input
                ref="fileInput"
                type="file"
                @change="handleImageSelect"
                accept="image/*"
                class="hidden"
                :disabled="isUploading"
              >
            </div>
          </div>

          <!-- Lists Section -->
          <RButton lg :loading="isUpdating" @click="updateSiteDetails">
              שמור שינויים
          </RButton>
        </div>
      </div>
    </div>

    <!-- Statuses Card -->
    <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4 mb-6">
      <div class="p-6">
        <h2 class="text-lg font-medium mb-4">סטטוסים</h2>
        <draggable 
            v-model="editingSite.statuses"
            item-key="id"
            handle=".status-handle"
            :move="() => true"
            v-bind="dragOptions"
            class="grid grid-cols-1 gap-2"
          >
            <template #item="{ element }">
              <div class="flex items-center justify-between p-3 rounded-lg border border-gray-200 bg-white transition-shadow">
                <div class="flex items-center gap-3 flex-1">
                  <v-icon
                    icon="mdi-drag"
                    class="status-handle cursor-move text-gray-400"
                  />
                  <div class="relative">
                    <v-menu>
                      <template v-slot:activator="{ props }">
                        <div
                          v-bind="props"
                          :class="[
                            'w-8 h-8 rounded-full cursor-pointer',
                            colorToBackgroundClassMap[element.color] || 'bg-gray-200'
                          ]"
                        ></div>
                      </template>
                      <v-card>
                        <v-card-text class="pa-3">
                          <div class="grid grid-cols-4 gap-2">
                            <div
                              v-for="color in allowedColors"
                              :key="color"
                              :class="['w-6 h-6 rounded-lg cursor-pointer', colorToBackgroundClassMap[color]]"
                              @click="updateStatusColor(element, color)"
                            ></div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                  <input
                    type="text"
                    v-model="element.text"
                    class="font-medium border-none focus:outline-none focus:ring-2 focus:ring-purple-500 rounded px-2 py-1 w-full"
                  />
                </div>
                <button @click="removeStatus(index)" 
                        class="text-gray-400 hover:text-red-500 transition-colors">
                  <v-icon icon="mdi-delete" />
                </button>
              </div>
            </template>
          </draggable>
          
          <div class="mt-3">
            <div class="flex gap-2">
              <RFieldText v-model="newStatus.text" placeholder="טקסט סטטוס" class="w-full" />
              <RButton color="black" :disabled="!newStatus.text" @click="addStatus">
                הוסף
              </RButton>
            </div>
          </div>

          <RButton class="mt-6" lg :loading="isUpdating" @click="updateSiteDetails">
              שמור שינויים
          </RButton>
      </div>
    </div>

    <!-- Responsibilities Card -->
    <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4 mb-6">
      <div class="p-6">
        <h2 class="text-lg font-medium mb-4">אחריות</h2>
        <draggable 
            v-model="editingSite.responsibilities"
            item-key="id"
            handle=".status-handle"
            :move="() => true"
            v-bind="dragOptions"
            class="grid grid-cols-1 gap-2"
          >
            <template #item="{ element, index }">
              <div class="flex items-center justify-between p-3 rounded-lg border border-gray-200 bg-white transition-shadow">
                <div class="flex items-center gap-3 flex-1">
                  <v-icon
                    icon="mdi-drag"
                    class="status-handle cursor-move text-gray-400"
                  />
                  <input
                    type="text"
                    v-model="editingSite.responsibilities[index]"
                    class="font-medium border-none focus:outline-none focus:ring-2 focus:ring-purple-500 rounded px-2 py-1 w-full"
                  />
                </div>
                <button @click="removeResponsibility(index)" 
                        class="text-gray-400 hover:text-red-500 transition-colors">
                  <v-icon icon="mdi-delete" />
                </button>
              </div>
            </template>
          </draggable>
          
          <div class="mt-3">
            <div class="flex gap-2">
              <RFieldText v-model="newResponsibility" placeholder="הוסף אחריות חדשה" class="w-full" />
              <RButton color="black" :disabled="!newResponsibility" @click="addResponsibility">
                הוסף
              </RButton>
            </div>
          </div>


          <RButton class="mt-6" lg :loading="isUpdating" @click="updateSiteDetails">
              שמור שינויים
          </RButton>
      </div>
    </div>

    <!-- Categories Card -->
    <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4 mb-6">
      <div class="p-6">
        <h2 class="text-lg font-medium mb-6">קטגוריות אתר</h2>
        <div>
          <draggable 
            v-model="editingSite.categories"
            item-key="id"
            handle=".status-handle"
            :move="() => true"
            v-bind="dragOptions"
            class="grid grid-cols-1 gap-2"
          >
            <template #item="{ element, index }">
              <div class="flex items-center justify-between p-3 rounded-lg border border-gray-200 bg-white transition-shadow">
                <div class="flex items-center gap-3 flex-1">
                  <v-icon
                    icon="mdi-drag"
                    class="status-handle cursor-move text-gray-400"
                  />
                  <input
                    type="text"
                    v-model="editingSite.categories[index]"
                    class="font-medium border-none focus:outline-none focus:ring-2 focus:ring-purple-500 rounded px-2 py-1 w-full"
                  />
                </div>
                <button @click="removeCategory(index)" 
                        class="text-gray-400 hover:text-red-500 transition-colors">
                  <v-icon icon="mdi-delete" />
                </button>
              </div>
            </template>
          </draggable>
          
          <div class="mt-3">
            <div class="flex gap-2">
              <RFieldText v-model="newCategory" placeholder="הוסף קטגוריה חדשה" class="w-full" />
              <RButton color="black" :disabled="!newCategory" @click="addCategory">
                הוסף
              </RButton>
            </div>
          </div>

          <RButton class="mt-6" lg :loading="isUpdating" @click="updateSiteDetails">
              שמור שינויים
          </RButton>
        </div>
      </div>
    </div>

    <!-- Delete Site Card -->
    <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4">
      <div class="p-6">
        <div class="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
          <div>
            <h2 class="text-lg font-medium text-red-600">מחק אתר</h2>
            <p class="mt-1 text-sm text-gray-500">פעולה זו אינה ניתנת לביטול. כל הנתונים הקשורים לאתר זה יימחקו לצמיתות.</p>
          </div>
          <RButton
            @click="deleteSite"
            icon-before="mdi-delete"
            color="red"
          >
            מחק אתר
          </RButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SitesApi from '@/api/SitesApi'
import { Utils } from '@/common/Utils'
import draggable from 'vuedraggable'
import { useToast } from 'vue-toastification'
import { colorToBackgroundClassMap, allowedColors } from '@/common/constants'
import RButton from '@/ui/RButton.vue'
import { useSite } from '@/composables/useSite'
import RFieldText from '@/ui/RFieldText.vue'

const route = useRoute()
const router = useRouter()
const siteId = route.params.id
const isUpdating = ref(false)
const toast = useToast()
  
// Replace editingSite ref with useSite composable
const { site: editingSite } = useSite()

const isUploading = ref(false)
const fileInput = ref(null)
const selectedFile = ref(null)
const imagePreview = ref(null)

const handleImageSelect = async (event) => {
  const file = event.target.files[0]
  if (file) {
    isUploading.value = true
    
    try {
      // Read file and upload
      const reader = new FileReader()
      reader.onload = async (e) => {
        try {
          const response = await Utils.uploadImageBase64(e.target.result)
          editingSite.value.site_image = response.data.path
          toast.success('התמונה הועלתה בהצלחה')
        } catch (error) {
          console.error('Error uploading image:', error)
          toast.error('שגיאה בהעלאת התמונה')
          removeImage()
        } finally {
          isUploading.value = false
          // Clear the file input
          if (fileInput.value) {
            fileInput.value.value = null
          }
        }
      }
      reader.onerror = () => {
        console.error('Error reading file:', reader.error)
        toast.error('שגיאה בקריאת הקובץ')
        removeImage()
        isUploading.value = false
      }
      reader.readAsDataURL(file)
    } catch (error) {
      console.error('Error handling image:', error)
      toast.error('שגיאה בטיפול בתמונה')
      removeImage()
      isUploading.value = false
    }
  }
}

const removeImage = () => {
  editingSite.value.site_image = null
  if (imagePreview.value) {
    URL.revokeObjectURL(imagePreview.value)
    imagePreview.value = null
  }
  if (fileInput.value) {
    fileInput.value.value = null
  }
}

// Add new refs for managing lists
const newCategory = ref('')
const newResponsibility = ref('')
const newStatus = ref({ text: '', color: '#000000' })

// Initialize arrays in editingSite if they don't exist
const initializeLists = () => {
  if (!editingSite.value.categories) editingSite.value.categories = []
  if (!editingSite.value.statuses) editingSite.value.statuses = []
  if (!editingSite.value.responsibilities) editingSite.value.responsibilities = []
}

// List management functions
const addCategory = () => {
  if (newCategory.value.trim()) {
    editingSite.value.categories.push(newCategory.value.trim())
    newCategory.value = ''
  }
}

const addStatus = () => {
  if (newStatus.value.text.trim()) {
    editingSite.value.statuses.push({ ...newStatus.value })
    newStatus.value = { text: '', color: '#000000' }
  }
}

const addResponsibility = () => {
  if (newResponsibility.value.trim()) {
    editingSite.value.responsibilities.push(newResponsibility.value.trim())
    newResponsibility.value = ''
  }
}

const removeCategory = (index) => {
  editingSite.value.categories.splice(index, 1)
}

const removeStatus = (index) => {
  editingSite.value.statuses.splice(index, 1)
}

const removeResponsibility = (index) => {
  editingSite.value.responsibilities.splice(index, 1)
}

const updateSiteDetails = async () => {
  isUpdating.value = true
  try {
    // Send the rest of the data as JSON
    const siteData = {
      name: editingSite.value.name,
      customer_name: editingSite.value.customer_name,
      address: editingSite.value.address,
      number: editingSite.value.number,
      tag: editingSite.value.tag,
      description: editingSite.value.description,
      categories: editingSite.value.categories,
      statuses: editingSite.value.statuses,
      responsibilities: editingSite.value.responsibilities,
      site_image: editingSite.value.site_image,
    }

    const response = await SitesApi.updateSite(editingSite.value.id, siteData)
    if (response.data) {
      toast.success('הפרטים עודכנו בהצלחה');
      // router.push(`/sites/${siteId}`)
    }
  } catch (error) {
    console.error('Error updating site:', error)
  } finally {
    isUpdating.value = false
  }
}

const deleteSite = async () => {
  if (!confirm('האם אתה בטוח שברצונך למחוק אתר זה?')) return

  try {
    await SitesApi.removeSite(editingSite.value.id)
    toast.success('האתר נמחק בהצלחה')
    router.push('/sites')
  } catch (error) {
    console.error('Error deleting site:', error)
    toast.error('שגיאה במחיקת האתר')
  }
}

// Clean up object URL when component is unmounted
onUnmounted(() => {
  if (imagePreview.value) {
    URL.revokeObjectURL(imagePreview.value)
  }
})


// Add dragOptions
const dragOptions = {
  animation: 200,
  group: 'description',
  disabled: false,
  ghostClass: 'ghost'
}

const updateStatusColor = (status, colorName) => {
  status.color = colorName
}
</script> 

<style scoped>
.status-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style> 