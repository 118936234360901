<template>
  <ReportsList />
</template>

<script>
import ReportsList from "@/components/ReportsList";

export default {
  components: {
    ReportsList,
  },
};
</script>
