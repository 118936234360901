<template>
  <div class="p-4">
    <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4">
      <!-- Header -->
      <div class="p-4 border-b flex justify-between items-center">
        <div class="flex items-center gap-4">
          <h2 class="text-lg font-medium">
            דוחות אתר
            <span class="text-gray-500 text-sm">({{ reports.length }})</span>
          </h2>
        </div>
      </div>

      <!-- Reports List -->
      <div class="divide-y divide-gray-200">
        <div v-if="!reports.length" class="p-4 text-center text-gray-500">
          אין דוחות באתר זה
        </div>
        
        <div 
          v-for="report in reports" 
          :key="report.id"
          class="p-4 flex items-start gap-4 hover:bg-gray-50 transition-colors cursor-pointer"
          @click="openReportDetails(report)"
        >
          <!-- Report Icon -->
          <div class="flex-shrink-0">
            <v-icon 
              size="24" 
              :class="report.status === 'completed' ? 'text-green-500' : 'text-gray-400'"
            >
              {{ report.status === 'completed' ? 'mdi-file-check' : 'mdi-file-outline' }}
            </v-icon>
          </div>
          
          <!-- Report Content -->
          <div class="flex-grow">
            <div class="flex items-center gap-2 mb-1">
              <h3 class="font-medium">דוח מספר {{ report.id.slice(0, 8) }}</h3>
              <span class="text-sm px-2 py-0.5 rounded-full" 
                :class="report.status === 'completed' ? 'bg-green-100 text-green-600' : report.status === 'processing' || report.status === 'generating' ? 'bg-yellow-100 text-yellow-600' : 'bg-red-100 text-red-600'"
              >
                <template v-if="report.status === 'completed'">
                  הושלם
                </template>
                <template v-else-if="report.status === 'processing' || report.status === 'generating'">
                  בתהליך
                </template>
                <template v-else-if="report.status === 'failed'">
                  נכשל
                </template>
              </span>
            </div>

            <!-- Recipients -->
            <div class="text-sm text-gray-600 mt-2">
              <div class="flex items-center gap-2">
                <v-icon size="16" class="text-gray-500">mdi-account-multiple</v-icon>
                <span>נשלח אל:</span>
                <div class="flex gap-2">
                  <span v-for="recipient in report.recipients" :key="recipient.id">
                    {{ recipient.name }}
                    <span class="text-gray-400">({{ recipient.role }})</span>
                  </span>
                </div>
              </div>
            </div>

            <!-- Creation Date -->
            <div class="text-sm text-gray-500 mt-1 flex items-center gap-1">
              <v-icon size="16">mdi-clock-outline</v-icon>
              <span>נוצר בתאריך</span>
              {{ new Date(report.created_at).toLocaleDateString('he-IL') }}
              <span class="text-gray-400">|</span>
              <span>על ידי</span>
              {{ report.created_by_user_name }}
            </div>

          </div>

          <!-- Actions -->
           <RActions @click.stop>
            <RAction 
              text="פרטים"
              icon="mdi-eye"
              @click="openReportDetails(report)"
            />
            <RAction 
              text="הורדה"
              icon="mdi-download"
              @click="downloadReport(report)"
            />  
           </RActions>
        </div>
      </div>
    </div>

    <!-- Report Details Modal -->
    <r-modal
      v-model="showDetailsDialog"
      :title="`פרטי דוח מספר ${selectedReport?.id.slice(0, 8)}`"
      size="lg"
    >
      <div class="space-y-6" v-if="selectedReport">
        <!-- Header Info -->
        <div class="flex flex-wrap items-center justify-between gap-3">
          <div class="space-y-1">
            <div class="text-sm text-gray-500">
              נוצר בתאריך: {{ new Date(selectedReport.created_at).toLocaleDateString('he-IL') }}
            </div>
          </div>
          <div class="flex items-center gap-3">
            <RButton
              size="sm"
              @click="downloadReport(selectedReport)"
              icon-before="mdi-download"
            >
             הורדה
          </RButton>
            <span class="px-3 py-1 rounded-full text-sm" 
              :class="selectedReport.status === 'completed' ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-600'"
            >
              {{ selectedReport.status === 'completed' ? 'נשלח' : 'בתהליך' }}
            </span>
          </div>
        </div>

        <!-- Recipients -->
        <div class="bg-gray-100 p-4 rounded-lg">
          <h4 class="font-medium mb-3">נמענים</h4>
          <div class="space-y-3">
            <div v-for="recipient in selectedReport.recipients" :key="recipient.id" 
              class="flex items-center gap-3"
            >
              <v-icon size="20" class="text-gray-500">mdi-account</v-icon>
              <div class="text-sm">
                <span class="font-medium">{{ recipient.name }}</span>
                <span class="text-gray-500 mx-1">|</span>
                <span class="text-gray-600">{{ recipient.role }}</span>
                <div class="text-gray-600">{{ recipient.email }}</div>
              </div>
            </div>
          </div>
        </div>

        <!-- Report Content -->
        <div class="space-y-4">
          <div>
            <h4 class="font-medium mb-2">טקסט עליון</h4>
            <div class="bg-gray-100 p-3 rounded" v-html="selectedReport.topText"></div>
          </div>
          <div>
            <h4 class="font-medium mb-2">טקסט תחתון</h4>
            <div class="bg-gray-100 p-3 rounded" v-html="selectedReport.bottomText"></div>
          </div>
        </div>

        <!-- Findings Filters -->
        <div v-if="hasAnyFilters" class="space-y-3">
          <h4 class="font-medium">סינון ממצאים</h4>
          
          <div v-if="selectedReport.findingsFilters.areas.length" class="bg-gray-100 p-3 rounded">
            <div class="text-sm text-gray-500 mb-2">אזורים:</div>
            <div class="flex flex-wrap gap-2">
              <span v-for="item in selectedReport.findingsFilters.areas" :key="item"
                class="px-2 py-1 bg-white rounded-full text-sm"
              >{{ item }}</span>
            </div>
          </div>

          <div v-if="selectedReport.findingsFilters.categories.length" class="bg-gray-100 p-3 rounded">
            <div class="text-sm text-gray-500 mb-2">קטגוריות:</div>
            <div class="flex flex-wrap gap-2">
              <span v-for="item in selectedReport.findingsFilters.categories" :key="item"
                class="px-2 py-1 bg-white rounded-full text-sm"
              >{{ item }}</span>
            </div>
          </div>

          <div v-if="selectedReport.findingsFilters.responsibilities.length" class="bg-gray-100 p-3 rounded">
            <div class="text-sm text-gray-500 mb-2">אחריות:</div>
            <div class="flex flex-wrap gap-2">
              <span v-for="item in selectedReport.findingsFilters.responsibilities" :key="item"
                class="px-2 py-1 bg-white rounded-full text-sm"
              >{{ item }}</span>
            </div>
          </div>
        </div>
      </div>
    </r-modal>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useSite } from '@/composables/useSite'
import SiteReportsApi from '@/api/SiteReportsApi'

const { site } = useSite()
const reports = ref([])
const showDetailsDialog = ref(false)
const selectedReport = ref(null)

const loadReports = async () => {
  try {
    const data = await SiteReportsApi.getReports(site.value.id)
    reports.value = data.data
  } catch (error) {
    console.error('Error loading reports:', error)
  }
}

const openReportDetails = (report) => {
  selectedReport.value = report
  showDetailsDialog.value = true
}

const hasAnyFilters = computed(() => {
  if (!selectedReport.value) return false
  const filters = selectedReport.value.findingsFilters
  return filters.areas.length > 0 || 
         filters.categories.length > 0 || 
         filters.responsibilities.length > 0
})

const downloadReport = (report) => {
  window.open(report.full_pdf_url, '_blank')
}

onMounted(() => {
  loadReports()
})
</script>