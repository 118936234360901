<template>
  <div v-if="isLoaded" class="r-page-report">
    <ReportHeader/>
    <template v-if="isReportWithDefects">
      <nav class="bg-white border-b border-gray-200 px-4 py-2">
        <div class="flex gap-2 overflow-x-auto">
          <button
            v-if="isReportWithDefects"
            @click="currentNavItem = 'defects'"
            class="flex items-center gap-1 px-3 py-2 text-gray-600 hover:text-primary-600 hover:bg-gray-50 rounded-md whitespace-nowrap transition-colors duration-200"
            :class="{ '!text-purple-600 !bg-purple-50 !font-medium': currentNavItem === 'defects' }"
          >
            <v-icon 
              icon="mdi-text-box-outline"
              size="16"
              :class="{ '!text-purple-600': currentNavItem === 'defects' }"
            />
            <span>רשימת ממצאים</span>
          </button>

          <button
            v-if="isReportWithLists"
            @click="currentNavItem = 'lists'"
            class="flex items-center gap-1 px-3 py-2 text-gray-600 hover:text-primary-600 hover:bg-gray-50 rounded-md whitespace-nowrap transition-colors duration-200"
            :class="{ '!text-purple-600 !bg-purple-50 !font-medium': currentNavItem === 'lists' }"
          >
            <v-icon 
              icon="mdi-format-list-checks"
              size="16"
              :class="{ '!text-purple-600': currentNavItem === 'lists' }"
            />
            <span>צ׳קליסטים וטבלאות</span>
          </button>

          <button
            @click="currentNavItem = 'texts'"
            class="flex items-center gap-1 px-3 py-2 text-gray-600 hover:text-primary-600 hover:bg-gray-50 rounded-md whitespace-nowrap transition-colors duration-200"
            :class="{ '!text-purple-600 !bg-purple-50 !font-medium': currentNavItem === 'texts' }"
          >
            <v-icon 
              icon="mdi-alert-circle-outline"
              size="16"
              :class="{ '!text-purple-600': currentNavItem === 'texts' }"
            />
            <span>פרטים נוספים</span>
          </button>
        </div>
      </nav>
      <div class="r-page-report-body">
        <div v-if="currentNavItem === 'defects'">
          <ReportDefects v-if="isReportWithDefects"/>
        </div>
        <div v-if="currentNavItem === 'lists'">
          <ReportListsItems/>
        </div>
        <div v-if="currentNavItem === 'texts'">
          <ReportTextsItems/>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="r-page-report-body">
        <ReportItems/>
      </div>
    </template>
  </div>
  <PageLoader v-else/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ReportHeader from "./components/ReportHeader";
import ReportItems from "./components/ReportItems";
import ReportDefects from "./components/ReportDefects";
import ReportListsItems from "./components/ReportListsItems";
import ReportTextsItems from "./components/ReportTextsItems";
import {Utils} from "@/common/Utils";
import PageLoader from "@/components/PageLoader.vue";
import {useBackgroundSync} from '@/composables/useBackgroundSync';
import {useShepherd} from 'vue-shepherd'
import {onMounted} from 'vue'
import {useRoute} from 'vue-router'
import TourService from '@/services/TourService'

const {isSyncing, hasInternet} = useBackgroundSync();

export default {
  setup() {
    const route = useRoute()
    let shouldStartAddFindingTour = false;
    if (route.fullPath.includes('?tour=add-finding')) {
      shouldStartAddFindingTour = true;
    }

    const addFindingTour = useShepherd({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shepherd-theme-default',
        scrollTo: true
      }
    });

    let addFindingButtonClass = '.add-finding-button--desktop';
    if (window.innerWidth < 640) {
      addFindingButtonClass = '.add-finding-button--mobile';
    }

    addFindingTour.addStep({
      id: 'step-1',
      text: '<b>הוסיפו ממצא ראשון</b> <br> <small>(אפשר גם להכניס סתם משהו לדוגמא כרגע)</small>',
      attachTo: {element: addFindingButtonClass, on: 'bottom'},
      buttons: [
        {
          text: 'הבא',
          action: () => addFindingTour.next()
        }
      ]
    })

    addFindingTour.addStep({
      id: 'step-2',
      text: '<b>אח״כ לחצו הורדה</b> <br> והורידו את הדוח כ-PDF',
      attachTo: {element: '#download-report-button', on: 'bottom'},
      buttons: [
        {
          text: 'סיום',
          action: () => {
            TourService.setStepComplete('addFinding')
            addFindingTour.complete()
          }
        }
      ]
    })

    onMounted(() => {
      if (shouldStartAddFindingTour) {
        setTimeout(() => {
          addFindingTour.start()
        }, 1000)
      }
    })

    return {shouldStartAddFindingTour}
  },
  components: {
    ReportItems,
    ReportTextsItems,
    ReportListsItems,
    PageLoader,
    ReportDefects,
    ReportHeader,
  },

  async beforeRouteUpdate(to, from, next) {
    this.isLoaded = false;
    if (to.params.id !== undefined) {
      this.reportId = to.params.id;
      await this.initialize();
    }
    return next();
  },

  data() {
    return {
      currentNavItem: "texts",
      isLoaded: false,
      reportId: this.$route.params.id,
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
    ...mapGetters("stocks", ["stocks"]),
    isReportWithLists() {
      let withLists = false;
      if (this.report.data.items) {
        for (const [, item] of Object.entries(this.report.data.items)) {
          if (item.type === "repeater" || item.type === "table") {
            withLists = true;
          }
        }
      }
      return withLists;
    },
    isReportWithDefects() {
      let withDefects = false;
      if (this.report.data.items) {
        for (const [, item] of Object.entries(this.report.data.items)) {
          if (
              item.type === "defectsTable" ||
              item.type === "miniDefectsTable"
          ) {
            withDefects = true;
          }
        }
      }
      return withDefects;
    },
    stock() {
      let stockFound = false;

      if (!this.report.stock_id) {
        return;
      }
      if (!this.stocks) {
        return;
      }

      this.stocks.forEach((stock) => {
        if (stock.id === this.report.stock_id) {
          stockFound = stock;
        }
      });

      return stockFound;
    },
  },
  async created() {
    await this.initialize();
    // this.preloadAllImages();
  },

  beforeRouteLeave(to, from, next) {
    let warningMsg;
    if (!hasInternet.value) {
      this.$posthog.capture("log:report:before_leave_no_internet_warning", {
        report_id: this.reportId,
      });
      warningMsg =
          "רגע! שמנו לב שאתם במצב ללא קליטת אינטרנט. אם תעזבו את העמוד הזה תצטרכו חיבור אינטרנט כדי לחזור. בטוחים שתרצו לעזוב את העמוד?";
    } else if (isSyncing.value) {
      this.$posthog.capture("log:report:before_leave_syncing_warning", {
        report_id: this.reportId,
      });
      warningMsg =
          "רגע! שמנו לב שלא הסתנכרנו כל השינויים עדיין. תרצו להמתין לסנכרון השינויים באופן מלא?";
    }

    if (warningMsg) {
      const answer = window.confirm(warningMsg);
      next(!!answer);
    } else {
      next();
    }
  },

  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),
    async initialize() {
      await this.$store.dispatch("report/getReport", {
        reportId: this.reportId,
      });

      if (this.isReportWithLists) {
        this.currentNavItem = "lists";
      }

      if (this.isReportWithDefects) {
        await this.$store.dispatch("stocks/fetchStocks");
        this.currentNavItem = "defects";
      }

      this.isLoaded = true;

      if (this.$checkRole(["admin", "owner"])) {
        await this.$store.dispatch("users/fetchUsers");
      }
      await this.$store.dispatch("preferences/fetchPreferences");
      await this.$store.dispatch("projects/fetchProjects");
    },
    // preloadAllImages() {
    //   let objFlatten = Utils.flattenObject({
    //     // ...this.report,
    //     ...this.stock,
    //   });

    //   for (const [, value] of Object.entries(objFlatten)) {
    //     if (typeof value === "string" && value.includes("images/")) {
    //       let img = new Image();
    //       img.src = this.$Reporto.globals.MEDIA_URL + value;
    //     }
    //   }
    // },
  },
};
</script>
