import { id } from "vuetify/lib/locale/index.mjs";

export const MAX_IMAGE_SIZE = 1400;

export const toolType = Object.freeze({
	freeDraw: "FREE_DRAW",
	ellipse: "ELLIPSE",
	arrow: "ARROW",
	line: "LINE",
	text: "TEXT",
	zoom: "ZOOM",
	ratio: "RATIO",
	rotate: "ROTATE",
	reset: "RESET",
	undo: "UNDO",
});

export const editOptions = [
	{
		id: 'draw',
		icon: 'mdi-pencil',
		iconSize: 20,
		showColors: true,
		label: "צייר",
		tools: [
			{ id: "free-draw", type: toolType.freeDraw, icon: "mdi-draw", label: "שרטוט" },
			{
				id: "circle",
				type: toolType.ellipse,
				icon: "mdi-checkbox-blank-circle-outline",
				label: "עיגול",
			},
			{
				id: "arrow",
				type: toolType.arrow,
				icon: "mdi-arrow-up-bold-outline",
				label: "חץ",
			},
			{
				id: "line",
				type: toolType.line,
				icon: "mdi-minus",
				label: "קו",
			},
		]
	},
	{
		id: 'text',
		type: toolType.text,
		icon: 'mdi-format-color-text',
		iconSize: 23,
		label: 'טקסט',
		showColors: true,
		showFontSizes: true,
	},
	{
		id: 'crop',
		icon: 'mdi-crop',
		iconSize: 20,
		label: "ערוך",
		tools: [
			{
				id: "zoom",
				icon: "mdi-crop",
				label: "זום",
				type: toolType.zoom,
			},
			{
				id: "aspect-raito",
				icon: "mdi-aspect-ratio",
				label: (isSquare) => {
					return isSquare ? "ריבוע" : "רגיל"
				},
				type: toolType.ratio,
			},
			{
				id: "rotate",
				icon: "mdi-crop-rotate",
				label: "סיבוב 90°",
				type: toolType.rotate,
			},
			{
				id: "reset",
				icon: "mdi-cancel",
				label: "נקה הכל",
				type: toolType.reset,
			},
		]
	},
	{
		id: "undo",
		icon: "mdi-undo",
		label: "חזור",
		type: toolType.undo,
		iconSize: 20,
	}
]



// Predefined colors and font sizes
export const colors = [
	"#5D21D2",
	"#09916E",
	"#1e429f",
	"#EF810E",
	"#DF302F",
	"#000000",
	"#ffffff",
	"#CECECE",
];

export const fontSizes = [
	// {
	// 	title: "קטן",
	// 	value: 12,
	// },
	{
		title: "בינוני",
		value: 18,
	},
	{
		title: "גדול",
		value: 24,
	}
];
