<template>
  <div class="r-auth-wrapper">
    <div class="r-auth-inner">
      <div class="r-auth-logo">
        <img src="@/assets/images/reporto-logo.svg" alt="logo" />
      </div>
      <div class="r-auth-title">איפוס סיסמה</div>
      <div class="r-auth-text">
        הזן כאן את המייל שאיתו נרשמת, ונשלח לך קוד איפוס
      </div>
      <div class="r-auth-form">
        <form @submit.prevent="submitResetPasswordOtpToEmail">
          <RFields>
            <RFieldText v-model="email" type="email" label="דואר אלקטרוני" />

            <RButton
              color="primary"
              block
              lg
              type="submit"
              :disabled="!email"
              :loading="isLoading"
            >
              שלחו לי קוד איפוס למייל
            </RButton>
          </RFields>
        </form>
      </div>

      <RouterLink class="r-auth-back-button mt-4" :to="{ name: 'login' }">
        <span>חזרה למסך התחברות</span>
        <RIcon>mdi-arrow-left</RIcon>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import AuthApi from "@/api/AuthApi";

export default {
  data() {
    return {
      email: null,
      isLoading: false,
    };
  },
  methods: {
    async submitResetPasswordOtpToEmail() {
      this.isLoading = true;
      const email = this.email.trim().replace(/\s/g, "");
      try {
        const { data } = await AuthApi.resetPasswordOtpToEmail(email);
        const otpKey = data?.otp_key;
        if (otpKey) {
          window.localStorage.setItem("otp_key", otpKey);
          this.$posthog.capture("log:auth:reset_password_email_otp_sent");
          await this.$router.push({
            name: "forgot-password-email-verify",
            query: { email: this.email },
          });
        } else {
          this.$toast.error("שגיאה: בטוחים שהמייל נכון?");
        }
      } catch (err) {
        this.$toast.error("שגיאה: בטוחים שהמייל נכון?");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
