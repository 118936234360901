<template>
  <div v-if="$checkRole(['owner']) && !company.doneDemoMeeting && !getUserCache() && company.isTrial && show" class="mb-5 px-5">
    <div
  
  class="bg-gradient-to-r from-[#E4F0FF] to-white rounded-lg shadow-sm border border-gray-200"
>
  <div class="p-4 flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 sm:gap-2">
    <div class="flex-1">
      <div class="flex items-center gap-1 mb-1">
        <h3 class="font-semibold text-md">שיחה אישית אחד-על-אחד</h3>
        <div class="w-6 h-6 flex items-center justify-center">
          <img width="18" src="@/assets/images/icon-zoom.svg" alt="zoom" />
        </div>
        <button
          @click="alertClicked"
          class="text-gray-400 hover:text-gray-600 text-sm hover:underline ml-auto"
        >
        סגירה
        </button> 
      </div>
      <p class="text-gray-700 text-sm">
        אנחנו מציעים לקבוע איתנו שיחת קצרה בזום שבה נתאים את המערכת בדיוק לצרכים
        שלך ונענה על כל השאלות באופן אישי.
      </p>
    </div>
    <div class="sm:w-auto flex items-center gap-4">
      <RButton
        target="_blank"
        :href="demoMeetingUrl"
        color="blue"
      >
        תיאום שיחה בזום
      </RButton>
    </div>
  </div>
</div>
  
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["company", "demoMeetingUrl"]),
  },
  data() {
    return {
      show: true,
    };
  },
  methods: {
    alertClicked() {
      this.addToUserCache();
      this.show = false;
    },
    getUserCache() {
      return window.localStorage.getItem("cache/hasSeenDemoAlert");
    },
    addToUserCache() {
      window.localStorage.setItem("cache/hasSeenDemoAlert", true);
    },
  },
};
</script>
