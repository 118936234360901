<template>
  <div class="max-w-[700px] mx-auto px-4 py-[52px]" v-if="isLoaded">
    <RSimpleCard
        :loading="isLoading"
        :disabled="isLoading"
        title="משתמשים מורשים לתבנית"
        subtitle="לחצו על שם המשתמש כדי לשייך או להוציא אותו מהתבנית"
    >
      <div>
        תבנית:
        {{ template.name }}
      </div>

      <div class="mt-3 mb-6">
        <RButton
            sm
            color="grey"
            :to="{
            name: 'report-templates.edit',
            params: { id: template.id },
          }"
        >
          חזרה לתבנית
        </RButton>
      </div>

      <RFieldSelect
          v-model="template.visibility"
          label="הרשאות גישה"
          :items="[
          { text: 'כולם', value: 'public' },
          { text: 'רק משתמשים מסויימים', value: 'private' },
        ]"
      />

      <div v-if="template.visibility === 'private'" class="select-list mt-3">
        <div
            v-for="user in users"
            :key="user.id"
            :class="{
            'is-selected': templateHasUser(user.id),
          }"
            class="select-list-item"
            @click="userClicked(user.id)"
        >
          {{ user.name }}
        </div>
      </div>
    </RSimpleCard>
  </div>
  <page-loader v-else/>
</template>

<script>
import PageLoader from "@/components/PageLoader.vue";
import ReportTemplatesApi from "@/api/ReportTemplatesApi";
import {mapGetters} from "vuex";

export default {
  components: {PageLoader},
  data() {
    return {
      isLoading: false,
      isLoaded: false,
      templateId: this.$route.params.id,
      template: {
        visibility: "public",
        data: {
          emails: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters("users", ["users"]),
  },
  watch: {
    "template.visibility": async function (newVisibility) {
      if (!this.isLoaded) {
        return;
      }

      this.isLoading = true;

      try {
        await ReportTemplatesApi.updateTemplate(this.templateId, {
          visibility: newVisibility,
        });
        this.$toast.success("התבנית עודכן בהצלחה");
        this.isLoading = false;
      } catch (e) {
        this.$toast.error(
            this.$Utils.toastMessageFromResponse(e, "שגיאה בעדכון התבנית")
        );
        this.isLoading = false;
      }
    },
  },

  async created() {
    try {
      await this.fetchTemplate();
      await this.$store.dispatch("users/fetchUsers");
      this.isLoaded = true;
    } catch (e) {
      this.$toast.error(
          this.$Utils.toastMessageFromResponse(e, "שגיאה בטעינת התבנית")
      );
    }
  },
  methods: {
    async fetchTemplate() {
      this.template = await ReportTemplatesApi.getTemplate(this.templateId);
    },
    userClicked(userId) {
      if (this.templateHasUser(userId)) {
        this.detachUser(userId);
      } else {
        this.attachUser(userId);
      }
    },
    templateHasUser(userId) {
      return this.template.users.some((user) => user.id === userId);
    },

    async attachUser(userId) {

      this.isLoading = true;
      try {
        await ReportTemplatesApi.attachUserToTemplate(this.templateId, userId);
        await this.fetchTemplate();
        this.$toast.success("המשתמש שוייך לתבנית");
        this.isLoading = false;
      } catch (e) {
        this.$toast.error(
            this.$Utils.toastMessageFromResponse(e, "שגיאה בהוספת משתמש לפריקט")
        );
        this.isLoading = false;
      }
    },
    async detachUser(userId) {
      this.isLoading = true;
      await ReportTemplatesApi.detachUserFromTemplate(this.templateId, userId);
      await this.fetchTemplate();
      this.$toast.success("המשתמש הוצא מהתבנית");
      this.isLoading = false;
    },

    async updateTemplate() {
      this.isLoading = true;

      try {
        await ReportTemplatesApi.updateTemplate(this.templateId, this.template);
        this.$toast.success("התבנית עודכנה בהצלחה");
        this.isLoading = false;
      } catch (e) {
        this.$toast.error(
            this.$Utils.toastMessageFromResponse(e, "שגיאה בעדכון התבנית")
        );
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.select-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 6px;
  max-height: 450px;
  overflow: auto;

  &-item {
    padding: 10px;
    border: 1px solid var(--r-border-color);
    cursor: pointer;
    transition: background-color 0.3s;
    border-radius: 4px;

    &:hover {
      background-color: #f5f5f5;
    }

    &.is-selected {
      //&:before {
      //  content: "✔";
      //  display: inline-block;
      //  margin-right: 10px;
      //  font-size: 12px;
      //  color: var(--r-color-green);
      //}
      border-right-color: var(--r-color-green);
      border-right-width: 3px;
      color: var(--r-color-green);
    }
  }
}
</style>
