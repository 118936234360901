<template>
  <RModal v-model="isSendReportModalOpen" title="שליחת הדוח למייל">
    <RFieldSwitch v-model="exclude_prices" label="הפקת דוח ללא מחירים" />

    <RFieldTextChoices
      v-model="send_report_to_email"
      label="אימייל לקבלת הדוח (ניתן להוסיף עד 3 מיילים נוספים ע״י
          הפרדה בפסיק)"
      :choices="sendReportEmailsHints"
    />

    <RButton
      color="primary"
      block
      lg
      class="mt-n3"
      :disabled="!send_report_to_email || send_report_to_email.length <= 0"
      @click="sendReportToEmail"
    >
      שליחה למייל
    </RButton>
  </RModal>
</template>

<script>
import { mapGetters } from "vuex";
import ReportsApi from "@/api/ReportsApi";

export default {
  props: {
    reportId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSendReportModalOpen: false,
      send_report_to_email: "",
      customerEmail: "",
      exclude_prices: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("report", ["report"]),
    sendReportEmailsHints() {
      let send_report_emails = [
        { label: "המייל שלי", value: this.user.email, color: "black" },
      ];

      if (this.customerEmail && this.validateEmails(this.customerEmail)) {
        send_report_emails.push({
          label: "מייל הלקוח",
          value: this.customerEmail,
        });
        send_report_emails.push({
          label: "המייל שלי + הלקוח",
          value: this.user.email + "," + this.customerEmail,
        });
      }

      return send_report_emails;
    },
  },
  created() {
    if (this.report.customerEmail) {
      this.customerEmail = this.report.customerEmail;
    }
  },
  methods: {
    openModal() {
      this.isSendReportModalOpen = true;
    },
    async sendReportToEmail() {
      if (!this.validateEmails(this.send_report_to_email)) {
        this.$toast.error("חובה להזין מייל");
        return;
      }

      if (this.send_report_to_email.split(",").length > 3) {
        this.$toast.error("הכנסת יותר מדי מיילים. מקסימום 3");
        return;
      }

      let loader = this.$loading.show();

      try {
        await ReportsApi.sendReport(this.reportId, {
          send_to_emails: this.send_report_to_email.split(","),
          exclude_prices: this.exclude_prices,
        });
        this.$toast.success("נהדר! הדוח בדרך למייל שלכם...");
        this.$posthog.capture("log:report:send");
        this.isSendReportModalOpen = false;
        loader.hide();
      } catch (err) {
        this.$toast.error(`שגיאה בשליחת הדוח: ${err}`);
        loader.hide();
      }
    },
    validateEmails(value) {
      if (typeof value === "undefined" || value === null || value === "") {
        return true;
      }
      return /^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(
        value
      );
    },
  },
};
</script>
