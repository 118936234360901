<template>
  <div>
    <RButton color="blue" icon-after="mdi-play" @click="playVideo">
      {{ buttonText }}
    </RButton>
    <RModal v-model="isVideoDialogOpen" size="lg">
      <div class="video-container">
        <iframe
            v-if="isVideoDialogOpen"
            :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`"
            width="100%"
            height="400"
            frameborder="0"
            allowfullscreen
        ></iframe>
      </div>
    </RModal>
  </div>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    videoId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVideoDialogOpen: false,
    };
  },
  methods: {
    getThumbnailUrl(video) {
      return `https://img.youtube.com/vi/${video.id}/mqdefault.jpg`;
    },
    playVideo() {
      this.isVideoDialogOpen = true;
    },
  },
};
</script>

<style scoped>
.video-thumbnail {
  position: relative;
}

.play-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.video-container {
  position: relative;
  padding-bottom: 50%;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.v-dialog__content {
  align-items: center;
}
</style>
