async function getSites() {
  const response = await window.axios.get('/api/v1/sites');
  return response.data;
}

async function createSite(data) {
  const response = await window.axios.post('/api/v1/sites', data);
  return response.data;
}

async function getSite(siteId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}`);
  return response.data;
}

async function updateSite(siteId, data) {
  return window.axios.put(`/api/v1/sites/${siteId}`, data);
}

async function removeSite(siteId) {
  const response = await window.axios.delete(`/api/v1/sites/${siteId}`);
  return response.data;
}

async function getSiteRecipients(siteId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/recipients`);
  return response.data;
}

async function createSiteRecipient(siteId, data) {
  const response = await window.axios.post(`/api/v1/sites/${siteId}/recipients`, data);
  return response.data;
}

async function updateSiteRecipient(siteId, recipientId, data) {
  const response = await window.axios.put(`/api/v1/sites/${siteId}/recipients/${recipientId}`, data);
  return response.data;
}

async function removeSiteRecipient(siteId, recipientId) {
  const response = await window.axios.delete(`/api/v1/sites/${siteId}/recipients/${recipientId}`);
  return response.data;
}


export default {
  getSites,
  createSite,
  getSite,
  updateSite,
  removeSite,
  getSiteRecipients,
  createSiteRecipient,
  updateSiteRecipient,
  removeSiteRecipient,
}; 