import {Utils} from "@/common/Utils";
import {nextTick, reactive, ref} from "vue";
import {toolType} from "@/components/EditImageModal-v3/const";
import localService from "@/services/LocalService";

const CANVAS_MAX_SIZE = 500
const PLACEHOLDER_TEXT = "טקסט"; // The default placeholder
const INITIAL_STAGE_CONFIG = {
    perfectDrawEnabled: false,
    shadowForStrokeEnabled: false,
    hitGraphEnabled: false,
    clearBeforeDraw: true,
    imageSmoothingEnabled: false,
    width: CANVAS_MAX_SIZE,
    height: CANVAS_MAX_SIZE,
    scaleX: 1,
    scaleY: 1,
    x: 0,
    y: 0,
}

const LAYER_CONFIG = {
    perfectDrawEnabled: false,
    clearBeforeDraw: true,
}

const IMAGE_CONFIG = {
    imageSmoothingEnabled: false,
    hitStrokeWidth: 0,
}

// Konva data
const konvaRefs = ref(null);
const shapesLayer = ref(null);
const imageLayer = ref(null);
const stage = ref(null);
const shapes = reactive({elements: []});
const stageConfig = ref({...INITIAL_STAGE_CONFIG});

// Image helpers
const imgObj = ref(null)
const image = ref(null);
const imageX = ref(0);
const imageY = ref(0);
const oldImageX = ref(imageX.value);
const oldImageY = ref(imageY.value);
const imageWidth = ref(CANVAS_MAX_SIZE);
const imageHeight = ref(CANVAS_MAX_SIZE);
const originalWidth = ref(0);
const originalHeight = ref(0);

// Tools toolbox
const activeOption = ref(null);
const activeTool = ref(null);
const canUndo = ref(false);
const isSquare = ref(true);
const currentShape = ref(null);
const currentColor = ref("#000000");
const fontSize = ref(14);
const isBold = ref(false);

// Touch zoom helpers
const mobileZoomState = reactive({
    lastTouchDist: null,
    initialScale: 1,
});

export const useEditImage = () => {

    const init = async (refs, upload) => {
        imgObj.value = upload;
        konvaRefs.value = refs
        loadEditorSettings();
        await loadImage(upload.path);
        await nextTick();

        shapesLayer.value = konvaRefs.value.shapesLayerRef?.getNode(); // Get the Konva layer
        imageLayer.value = konvaRefs.value.imageLayerRef?.getNode(); // Get the Konva layer
        stage.value = konvaRefs.value.stageRef?.getStage(); // Get the Konva stage
    }

    async function loadImage(imgPath) {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
            image.value = img;
            originalWidth.value = img.width;
            originalHeight.value = img.height;
            const aspectRatio = img.width / img.height;

            // Determine maxSize based on mobile constraints
            const maxSize = getMaxCanvasSize(); // This will be at most 350px or device width if smaller

            stageConfig.value.x = 0;
            stageConfig.value.y = 0;
            // console.log('isSquare', isSquare)

            if (isSquare.value) {
                // Square mode
                stageConfig.value.width = maxSize;
                stageConfig.value.height = maxSize
                // console.log('maxSize', maxSize)


                // Scale image to cover the square fully
                if (aspectRatio > 1) {
                    imageWidth.value = maxSize * aspectRatio;
                    imageHeight.value = maxSize;
                } else {
                    imageWidth.value = maxSize;
                    imageHeight.value = maxSize / aspectRatio;
                }

                // Center the image inside the square
                imageX.value = -(imageWidth.value - maxSize) / 2;
                imageY.value = -(imageHeight.value - maxSize) / 2;

            } else {
                if (aspectRatio > 1) {
                    // Landscape
                    stageConfig.value.width = maxSize;
                    stageConfig.value.height = maxSize / aspectRatio;
                } else {
                    // Portrait or square
                    stageConfig.value.width = maxSize * aspectRatio;
                    stageConfig.value.height = maxSize;
                }

                imageWidth.value = stageConfig.value.width;
                imageHeight.value = stageConfig.value.height;

                imageX.value = 0;
                imageY.value = 0;
            }

            oldImageX.value = imageX.value;
            oldImageY.value = imageY.value;
        };

        if (Utils.isBase64(imgPath)) {
            img.src = imgPath;
        } else {
            img.src = `${imgObj.value.mediaUrl}${imgPath}`;
        }
    }

    const toggleAspectRatio = () => {
        const prevsISquare = isSquare.value
        const prevActiveOption = activeOption.value
        const prevActiveTool = activeTool.value
        resetCanvas();
        isSquare.value = !prevsISquare;
        activeOption.value = prevActiveOption
        activeTool.value = prevActiveTool
        loadImage(imgObj.value.initialPath);
        saveEditorSettings()
    }

    const setColor = (color) => {
        currentColor.value = color;
        saveEditorSettings()
    }

    const setFontSize = (size) => {
        fontSize.value = size;
        saveEditorSettings()
    }

    const selectOption = (option) => {
        if (option?.id === 'undo') return
        if (activeOption.value && activeOption.value.id === option?.id) return
        activeOption.value = option;
        activeTool.value = option?.type === toolType.text ? option.type : null;
    }

    const selectTool = (tool) => {
        activeTool.value = tool;
    }

    const toggleBold = () => {
        isBold.value = !isBold.value;
        saveEditorSettings()
    }

    const undoAction = () => {
        if (shapes.elements.length === 0) {
            return; // No actions to undo
        }
        const lastShape = shapes.elements.pop();
        lastShape.destroy();
        shapesLayer.value.batchDraw();
        canUndo.value = shapes.elements.length > 0;
    }

    const resetCanvas = (shouldReload = true) => {
        // Reset canvas
        shapes.elements.forEach((shape) => shape.destroy());
        shapes.elements = [];
        canUndo.value = false;
        currentShape.value = null;
        shapesLayer.value.batchDraw();
        activeTool.value = null;
        activeOption.value = null;
        stage.value.rotation(0);

        stageConfig.value = {
            ...stageConfig.value,
            scaleX: 1,
            scaleY: 1,
            x: 0,
            y: 0
        };

        if (shouldReload) loadImage(imgObj.value.initialPath);
        saveEditorSettings()
    }

    const destroyCanvas = () => {
        resetCanvas(false)
        shapesLayer.value.destroyChildren();
        imageLayer.value.destroyChildren();
        image.value = null;
        imgObj.value = null;
        stage.value.destroy();
        activeTool.value = null
        shapesLayer.value = null;
        imageLayer.value = null;
        stage.value = null;
        stageConfig.value = {...INITIAL_STAGE_CONFIG};
    }

    const rotateCanvas = async () => {
        if (!image.value) return;

        const currentRotation = stage.value.rotation() || 0;
        const newRotation = (currentRotation + 90) % 360; // Rotate 90 degrees clockwise

        // Reset stage rotation
        stage.value.rotation(0);

        // Create an offscreen canvas for rotation
        const offscreenCanvas = document.createElement("canvas");
        const offscreenContext = offscreenCanvas.getContext("2d");

        // Adjust the offscreen canvas size for rotation
        const isLandscape = newRotation === 90 || newRotation === 270;
        offscreenCanvas.width = isLandscape ? image.value.height : image.value.width;
        offscreenCanvas.height = isLandscape ? image.value.width : image.value.height;

        // Rotate and draw the image on the offscreen canvas
        offscreenContext.translate(offscreenCanvas.width / 2, offscreenCanvas.height / 2);
        offscreenContext.rotate((newRotation * Math.PI) / 180);
        offscreenContext.drawImage(
            image.value,
            -image.value.width / 2,
            -image.value.height / 2
        );

        const rotatedImageURL = offscreenCanvas.toDataURL();
        imgObj.value.path = rotatedImageURL;

        const prevsISquare = isSquare.value
        const prevActiveOption = activeOption.value
        const prevActiveTool = activeTool.value
        resetCanvas(false);
        isSquare.value = prevsISquare;
        activeOption.value = prevActiveOption
        activeTool.value = prevActiveTool

        await loadImage(rotatedImageURL);

        shapesLayer.value.batchDraw();
        imageLayer.value.batchDraw();
    }

    function zoomToPoint(pointer, newScale) {
        // console.log('[zoomToPoint] Requesting scale:', newScale, 'pointer:', pointer);

        const oldScale = stageConfig.value.scaleX;
        const clampedScale = Math.min(Math.max(newScale, 1), 4);
        // console.log('[zoomToPoint] oldScale:', oldScale, 'clampedScale:', clampedScale);

        if (clampedScale === 1 && oldScale > 1) {
            // console.log('[zoomToPoint] Resetting to scale 1 layout');
            resetToScaleOne();
            constrainStagePosition(1);
            stage.value.batchDraw();
            return;
        }

        const imgCoordX = (pointer.x - stageConfig.value.x - imageX.value * oldScale) / oldScale;
        const imgCoordY = (pointer.y - stageConfig.value.y - imageY.value * oldScale) / oldScale;

        stageConfig.value.scaleX = clampedScale;
        stageConfig.value.scaleY = clampedScale;

        stageConfig.value.x = pointer.x - (imgCoordX * clampedScale + imageX.value * clampedScale);
        stageConfig.value.y = pointer.y - (imgCoordY * clampedScale + imageY.value * clampedScale);

        constrainStagePosition(clampedScale);
        stage.value.batchDraw();
        // console.log('[zoomToPoint] Zoom applied. scale:', clampedScale, 'x:', stageConfig.value.x, 'y:', stageConfig.value.y);
    }

    function handleZoom(event) {
        if (activeTool.value !== toolType.zoom) return; // No zoom if tool active

        event.evt.preventDefault();

        const scaleBy = 1.03;
        const oldScale = stageConfig.value.scaleX;
        let newScale = event.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

        const pointer = stage.value.getPointerPosition();
        zoomToPoint(pointer, newScale);
    }

    function resetToScaleOne() {
        // Reset scale
        stageConfig.value.scaleX = 1;
        stageConfig.value.scaleY = 1;

        // Recalculate image positions and sizes based on original logic
        if (isSquare.value) {
            const aspectRatio = image.value.width / image.value.height;
            const maxSize = getMaxCanvasSize();
            stageConfig.value.width = maxSize;
            stageConfig.value.height = maxSize;

            if (aspectRatio > 1) {
                imageWidth.value = maxSize * aspectRatio;
                imageHeight.value = maxSize;
            } else {
                imageWidth.value = maxSize;
                imageHeight.value = maxSize / aspectRatio;
            }
            imageX.value = -(imageWidth.value - maxSize) / 2;
            imageY.value = -(imageHeight.value - maxSize) / 2;

        } else {
            // Aspect ratio mode
            const aspectRatio = image.value.width / image.value.height;
            const maxSize = getMaxCanvasSize();
            if (aspectRatio > 1) {
                stageConfig.value.width = maxSize;
                stageConfig.value.height = maxSize / aspectRatio;
            } else {
                stageConfig.value.width = maxSize * aspectRatio;
                stageConfig.value.height = maxSize;
            }
            imageWidth.value = stageConfig.value.width;
            imageHeight.value = stageConfig.value.height;
            imageX.value = 0;
            imageY.value = 0;
        }

        // Position the stage so the image is centered/contained
        // We'll rely on constrainStagePosition(1) right after this
        stageConfig.value.x = 0;
        stageConfig.value.y = 0;
    }

    const constrainStagePosition = (scale) => {
        const canvasWidth = stageConfig.value.width;
        const canvasHeight = stageConfig.value.height;

        const imageScaledWidth = imageWidth.value * scale;
        const imageScaledHeight = imageHeight.value * scale;

        let newX = stageConfig.value.x;
        let newY = stageConfig.value.y;

        // If image smaller than canvas width, center horizontally:
        if (imageScaledWidth <= canvasWidth) {
            newX = (canvasWidth - imageScaledWidth) / 2;
        } else {
            // If larger, clamp horizontally
            const minX = canvasWidth - imageScaledWidth;
            newX = Math.min(Math.max(newX, minX), 0);
        }

        // If image smaller than canvas height, center vertically:
        if (imageScaledHeight <= canvasHeight) {
            newY = (canvasHeight - imageScaledHeight) / 2;
        } else {
            // If larger, clamp vertically
            const minY = canvasHeight - imageScaledHeight;
            newY = Math.min(Math.max(newY, minY), 0);
        }

        stageConfig.value.x = newX;
        stageConfig.value.y = newY;
    }

    function getTouchDistance(touches) {
        const [t1, t2] = touches;
        const dx = t2.clientX - t1.clientX;
        const dy = t2.clientY - t1.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    }

    function getTouchesMidpoint(touches) {
        const [t1, t2] = touches;
        const midpointX = (t1.clientX + t2.clientX) / 2;
        const midpointY = (t1.clientY + t2.clientY) / 2;
        return {x: midpointX, y: midpointY};
    }

    function stageCoordinatesFromClient(clientX, clientY) {
        const rect = stage.value.container().getBoundingClientRect();
        return {
            x: clientX - rect.left,
            y: clientY - rect.top,
        };
    }

    function onTouchStart({evt}) {
        // console.log('[onTouchStart]', evt.touches.length, 'touches');

        if (evt.touches.length === 2) {
            evt.preventDefault();
            evt.stopPropagation();
            // console.log('[onTouchStart] Starting pinch');

            mobileZoomState.lastTouchDist = getTouchDistance(evt.touches);
            mobileZoomState.initialScale = stageConfig.value.scaleX;
            // console.log('[onTouchStart] lastTouchDist:', mobileZoomState.lastTouchDist, 'initialScale:', mobileZoomState.initialScale);

            // We'll store the midpoint in stage coordinates:
            const midpoint = getTouchesMidpoint(evt.touches);
            mobileZoomState.midpoint = stageCoordinatesFromClient(midpoint.x, midpoint.y);
            // console.log('[onTouchStart] midpoint (client):', midpoint, 'midpoint (stage):', mobileZoomState.midpoint);

        } else if (evt.touches.length === 1) {
            // Single touch - could be pan or draw
            startDrawing(evt);
        }
    }

    function onTouchMove({evt}) {
        // console.log('evt from onTouchMove', evt)

        // If we have two touches, it's a pinch
        if (evt.touches.length === 2 && mobileZoomState.lastTouchDist) {
            // Prevent default scrolling/pinch behavior
            evt.preventDefault();
            evt.stopPropagation();

            const newDist = getTouchDistance(evt.touches);
            // console.log('[onTouchMove] Pinch moving. New distance:', newDist, 'Old distance:', mobileZoomState.lastTouchDist);

            const scaleRatio = newDist / mobileZoomState.lastTouchDist;
            const newScale = mobileZoomState.initialScale * scaleRatio;
            // console.log('[onTouchMove] Calculated newScale:', newScale);

            const midpoint = getTouchesMidpoint(evt.touches);
            const stagePointer = stageCoordinatesFromClient(midpoint.x, midpoint.y);
            // console.log('[onTouchMove] midpoint (client):', midpoint, 'stagePointer:', stagePointer);

            zoomToPoint(stagePointer, newScale);

        } else if (evt.touches.length === 1) {
            // Single finger move => draw or pan
            draw(evt);
        }
    }

    function onTouchEnd({evt}) {
        // console.log('[onTouchEnd]', evt.touches.length, 'touches remain');

        if (evt.touches.length < 2) {
            // Reset pinch state
            // console.log('[onTouchEnd] Resetting pinch state');
            mobileZoomState.lastTouchDist = null;
            mobileZoomState.initialScale = stageConfig.value.scaleX;
        }

        finishDrawing();
    }

    const startDrawing = (event) => {
        if (!activeTool.value || !shapesLayer.value) return;
        const scale = stageConfig.value.scaleX || 1;
        const pos = shapesLayer.value.getRelativePointerPosition();

        switch (activeTool.value) {

            case toolType.arrow:
                currentShape.value = new Konva.Arrow({
                    points: [pos.x, pos.y, pos.x, pos.y], // Start and end at the same point initially
                    stroke: currentColor.value,
                    fill: currentColor.value, // Arrowhead color matches the line
                    strokeWidth: 2 / scale,
                    pointerLength: 10 / scale, // Length of the arrowhead
                    pointerWidth: 10 / scale, // Width of the arrowhead
                    lineCap: "round",
                    lineJoin: "round",
                });
                break;

            case toolType.freeDraw:
                currentShape.value = new Konva.Line({
                    points: [pos.x, pos.y],
                    stroke: currentColor.value,
                    strokeWidth: 2 / scale,
                    lineCap: "round",
                    lineJoin: "round",
                });
                break;

            case toolType.line:
                currentShape.value = new Konva.Line({
                    points: [pos.x, pos.y, pos.x, pos.y],
                    stroke: currentColor.value,
                    strokeWidth: 2 / scale,
                    lineCap: "round",
                    lineJoin: "round",
                });
                break;

            case toolType.ellipse:
                currentShape.value = new Konva.Ellipse({
                    x: pos.x,
                    y: pos.y,
                    radiusX: 0.5,
                    radiusY: 0.5,
                    stroke: currentColor.value,
                    strokeWidth: 2 / scale,
                });

                currentShape.value.startX = pos.x;
                currentShape.value.startY = pos.y;
                break;

            case toolType.text:
                // Create a new Konva.Text node
                const textNode = new Konva.Text({
                    x: pos.x,
                    y: pos.y,
                    text: "טקסט", // Default text
                    fontSize: fontSize.value,
                    fill: currentColor.value,
                    fontStyle: isBold.value ? "bold" : "normal",
                    draggable: true,
                    align: 'right' // ensure right alignment
                });

                textNode.on("dblclick dbltap", () => enableTextEditing(textNode)); // Enable editing on double-click

                shapesLayer.value.add(textNode);
                shapesLayer.value.batchDraw();

                shapes.elements.push(textNode);
                enableTextEditing(textNode); // Immediately make editable after creation

                activeTool.value = null
                return;
        }

        if (currentShape.value) {
            shapesLayer.value.add(currentShape.value);
        }
    }

    const draw = (event) => {
        if (!currentShape.value || activeTool.value === toolType.text) return;

        const pos = shapesLayer.value.getRelativePointerPosition();

        switch (activeTool.value) {
            case toolType.freeDraw:
                currentShape.value.points([...currentShape.value.points(), pos.x, pos.y]);
                break;

            case toolType.line:
            case toolType.arrow:
                const points = currentShape.value.points();
                currentShape.value.points([points[0], points[1], pos.x, pos.y]); // Update end point of line/arrow
                break;


            case toolType.ellipse: {
                const centerX = (currentShape.value.startX + pos.x) / 2;
                const centerY = (currentShape.value.startY + pos.y) / 2;

                const radiusX = Math.abs(pos.x - currentShape.value.startX) / 2;
                const radiusY = Math.abs(pos.y - currentShape.value.startY) / 2;

                currentShape.value.x(centerX);
                currentShape.value.y(centerY);
                currentShape.value.radiusX(radiusX);
                currentShape.value.radiusY(radiusY);
                break;
            }
        }
        shapesLayer.value.batchDraw();
    }

    const finishDrawing = () => {
        if (!currentShape.value) return;

        shapesLayer.value.batchDraw();
        shapes.elements.push(currentShape.value);
        canUndo.value = shapes.elements.length > 0;
        currentShape.value = null;
    }

    const enableTextEditing = (textNode) => {
        const textPosition = textNode.absolutePosition();

        const textarea = document.createElement("textarea");
        const canvasContainer = stage.value.container().getBoundingClientRect();
        const elCanvas = document.querySelector(".konvajs-content");
        elCanvas.appendChild(textarea);

        // Before editing starts, save the old width so we know how much the text may change
        const oldWidth = textNode.width();

        textarea.value = textNode.text();
        textarea.setAttribute("dir", "rtl");
        textarea.setAttribute('inputmode', 'text');
        textarea.setAttribute('autocapitalize', 'none');
        textarea.setAttribute('autocorrect', 'off');
        textarea.setAttribute('spellcheck', 'false');
        textarea.setAttribute('type', 'text');

        // Position and style the textarea
        textarea.style.position = "absolute";
        textarea.style.top = `${textPosition.y}px`;
        textarea.style.right = `${canvasContainer.width - textPosition.x - textNode.width()}px`;
        textarea.style.fontSize = `${textNode.fontSize()}px`;
        textarea.style.border = "none";
        textarea.style.padding = "0 0 0px 0";
        textarea.style.margin = "0px";
        textarea.style.overflow = "hidden";
        textarea.style.background = "none";
        textarea.style.outline = "none";
        textarea.style.resize = "none";
        textarea.style.lineHeight = textNode.lineHeight();
        textarea.style.fontFamily = textNode.fontFamily();
        textarea.style.fontWeight = textNode.fontStyle();
        textarea.style.color = textNode.fill();
        textarea.style.textAlign = "right";


        const removeTextarea = () => {
            elCanvas.removeEventListener("pointerdown", handleClickOutside);
            textarea.parentNode.removeChild(textarea);
            textNode.show();
            shapesLayer.value.batchDraw();
            canUndo.value = shapes.elements.length > 0;
        };

        const handleFirstInput = (e) => {
            if (textarea.value === PLACEHOLDER_TEXT && e.key.length === 1 && !e.ctrlKey && !e.metaKey && !e.altKey) {
                textarea.value = "";
            }
            textarea.removeEventListener("keydown", handleFirstInput);
        };

        textarea.addEventListener("keydown", (e) => {
            if (e.key === "Enter" && !e.shiftKey) {
                finalizeText();
            } else if (e.key === "Escape") {
                removeTextarea();
            }
        });

        textarea.addEventListener("blur", finalizeText);
        textarea.addEventListener("keydown", handleFirstInput);
        elCanvas.addEventListener("pointerdown", handleClickOutside);

        textNode.hide();
        shapesLayer.value.batchDraw();

        function finalizeText() {
            textNode.text(textarea.value);
            const newWidth = textNode.width();
            const delta = newWidth - oldWidth;
            textNode.x(textNode.x() - delta);
            textNode.y(textNode.y());
            removeTextarea();
        }

        function handleClickOutside(e) {
            // Only blur if it's a single touch (not part of a multi-touch gesture)
            if (e.touches && e.touches.length > 1) {
                return;
            }
            // If the user taps/clicks on something that is not the textarea, blur it.
            // Check if e.target is the textarea itself
            if (e.target !== textarea) {
                textarea.blur();
            }
        }

        // Mobile required immidiate focus - Desktop require
        if (window.innerWidth < 780) {
            textarea.focus();
            textarea.select();
        } else {
            setTimeout(() => {
                textarea.focus();
                textarea.select();
            })
        }
    };

    const constrainImage = (event) => {
        const imageNode = event.target;

        const canvasWidth = stageConfig.value.width;
        const canvasHeight = stageConfig.value.height;

        const scale = stageConfig.value.scaleX; // Assuming scaleX = scaleY
        const stageX = stageConfig.value.x;
        const stageY = stageConfig.value.y;

        // Calculate visible area in stage coordinates
        // When scaled, one "visible viewport" pixel = 1/scale in stage coordinates.
        const visibleStageWidth = canvasWidth / scale;
        const visibleStageHeight = canvasHeight / scale;

        // The top-left corner of the visible region in stage coords:
        const visibleLeft = -stageX / scale;
        const visibleTop = -stageY / scale;

        // The bottom-right corner of the visible region in stage coords:
        const visibleRight = visibleLeft + visibleStageWidth;
        const visibleBottom = visibleTop + visibleStageHeight;

        // Image dimensions are defined in stage coordinates already
        const iw = imageWidth.value;
        const ih = imageHeight.value;

        // To ensure no whitespace:
        // - The image must at least cover from visibleLeft to visibleRight horizontally
        // - and from visibleTop to visibleBottom vertically.

        // Thus, the imageX must be between (visibleRight - iw) and visibleLeft
        // And imageY must be between (visibleBottom - ih) and visibleTop
        let minX = visibleRight - iw;
        let maxX = visibleLeft;
        let minY = visibleBottom - ih;
        let maxY = visibleTop;

        // Current position
        const currentX = imageNode.x();
        const currentY = imageNode.y();

        // Constrain the position
        const constrainedX = Math.min(Math.max(currentX, minX), maxX);
        const constrainedY = Math.min(Math.max(currentY, minY), maxY);

        imageNode.position({x: constrainedX, y: constrainedY});

        // Update reactive refs
        imageX.value = constrainedX;
        imageY.value = constrainedY;

        // Now move the shapes layer by the same delta
        const dx = constrainedX - oldImageX.value;
        const dy = constrainedY - oldImageY.value;

        shapesLayer.value.x(shapesLayer.value.x() + dx);
        shapesLayer.value.y(shapesLayer.value.y() + dy);

        // Update old positions
        oldImageX.value = constrainedX;
        oldImageY.value = constrainedY;
    };

    function loadEditorSettings() {
        const settings = localService.getItem(
            localService.keys.imageEditorSettings
        );

        if (settings) {
            currentColor.value = settings.color || DEFAULT_COLOR;
            fontSize.value = settings.fontSize || DEFAULT_FONT_SIZE;
            isBold.value = !!settings.isBold;
            isSquare.value = settings.isSquare === undefined ? true : settings.isSquare;
        }
    }

    function saveEditorSettings() {
        const settings = {
            color: currentColor.value,
            fontSize: fontSize.value,
            isBold: isBold.value,
            isSquare: isSquare.value
        };
        localService.setItem(localService.keys.imageEditorSettings, settings);
    }

    return {
        state: {
            stageConfig,
            shapesLayer,
            image,
            imageX,
            imageY,
            imageWidth,
            imageHeight,
            originalWidth,
            originalHeight,
            shapes,
            activeTool,
            activeOption,
            canUndo,
            isSquare,
            isBold,
            currentShape,
            currentColor,
            fontSize,
            LAYER_CONFIG,
            IMAGE_CONFIG,
        },
        options: {
            init,
            loadImage,
            toggleAspectRatio,
            setColor,
            setFontSize,
            selectTool,
            selectOption,
            toggleBold,
            undoAction,
            resetCanvas,
            rotateCanvas,
            handleZoom,
            startDrawing,
            draw,
            finishDrawing,
            constrainImage,
            destroyCanvas,
            onTouchStart,
            onTouchMove,
            onTouchEnd
        }
    }
}

function getMaxCanvasSize() {
    const deviceWidth = window.innerWidth || CANVAS_MAX_SIZE;
    // Take the minimum of deviceWidth and 350px since we want a max of 350px on mobile.
    if (window.innerWidth <= 600) {
        return Math.min(deviceWidth, CANVAS_MAX_SIZE);
    } else {
        return CANVAS_MAX_SIZE;
    }
}
