import {createStore} from "vuex";

import auth from "./modules/auth";
import report from "./modules/report";
import preferences from "./modules/preferences";
import reportTemplates from "./modules/reportTemplates";
import stocks from "./modules/stocks";
import users from "./modules/users";
import updates from "./modules/updates";
import projects from "./modules/projects";

const initialState = {};

// Create store using createStore instead of new Vuex.Store
export default createStore({
    state: initialState,
    getters: {},
    mutations: {},
    actions: {},

    modules: {
        auth,
        report,
        preferences,
        reportTemplates,
        stocks,
        users,
        updates,
        projects,
    },
});
