const DB_NAME = "reportoDB";
const STORE_NAME = "keyValueStore2";
const DB_VERSION = 8;

class IndexedDBService {
  #objectStoreActionResult(func) {
    return new Promise((resolve, reject) => {
      this.#createStoreIfNeeded()
        .then(() => this.#openDB())
        .then((db) => {
          const transaction = db.transaction(STORE_NAME, "readwrite");
          const store = transaction.objectStore(STORE_NAME);
          const request = func(store);

          request.onsuccess = (event) => resolve(event.target.result?.value || event.target.result);
          request.onerror = () => reject(request.error);
        })
        .catch(reject);
    });
  }

  #openDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);
      request.onerror = () => reject(request.error);
      request.onsuccess = (event) => resolve(event.target.result);
    });
  }

  #createStoreIfNeeded() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(DB_NAME, DB_VERSION);
  
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          const store = db.createObjectStore(STORE_NAME, { keyPath: "key" });
          store.createIndex("createdAt", "createdAt");
        }
      };

      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve();
    });
  }

  async setItem(key, value) {
    return this.#objectStoreActionResult((store) => 
      store.put({ key: String(key), value, createdAt: Date.now() })
    );
  }

  async getItem(key) {
    return this.#objectStoreActionResult((store) => 
      store.get(String(key))
    );
  }

  async removeItem(key) {
    return this.#objectStoreActionResult((store) => 
      store.delete(String(key))
    );
  }

  async getAllKeys() {
    return this.#objectStoreActionResult((store) => 
      store.getAllKeys()
    );
  }

  async getAllItems() {
    return this.#objectStoreActionResult((store) => 
      store.getAll()
    ).then(items => {
      return items;
    });
  }

  async getAllKeysOrderedByCreatedAtWithCursor() {
    return new Promise((resolve, reject) => {
      this.#createStoreIfNeeded()
        .then(() => this.#openDB())
        .then((db) => {
          const transaction = db.transaction(STORE_NAME, "readwrite");
          const store = transaction.objectStore(STORE_NAME);
          const index = store.index("createdAt");
          const keys = [];
          
          const request = index.openCursor();
          
          request.onsuccess = (event) => {
            const cursor = event.target.result;
            if (cursor) {
              keys.push({ key: cursor.value.key, createdAt: cursor.value.createdAt });
              cursor.continue();
            } else {
              resolve(keys);
            }
          };
          
          request.onerror = () => reject(request.error);
        })
        .catch(reject);
    });
  }

  async getAllKeysOrderedByCreatedAt() {
    return this.#objectStoreActionResult((store) => {
      const index = store.index("createdAt");
      return index.getAll(null, null, "next");
    }).then(items => {
      return items.sort((a, b) => a.createdAt - b.createdAt);
    });
  }
}

const indexedDBService = new IndexedDBService();
export default indexedDBService;
