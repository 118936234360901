import {createRouter, createWebHistory} from "vue-router";
import store from "./store";

import LayoutLogin from "@/components/LayoutLogin.vue";

import Login from "@/views/auth/Login.vue";
import LoginPhone from "@/views/auth/LoginPhone.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ForgotPasswordEmail from "@/views/auth/ForgotPasswordEmail.vue";
import ForgotPasswordEmailVerify from "@/views/auth/ForgotPasswordEmailVerify.vue";
import ForgotPasswordPhone from "@/views/auth/ForgotPasswordPhone.vue";
import ForgotPasswordPhoneVerify from "@/views/auth/ForgotPasswordPhoneVerify.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import ResetPasswordSuccess from "@/views/auth/ResetPasswordSuccess.vue";
import VerifySuccess from "@/views/auth/VerifySuccess.vue";
import VerifyError from "@/views/auth/VerifyError.vue";
import VerifySent from "@/views/auth/VerifySent.vue";
import VerifyPhone from "@/views/auth/VerifyPhone.vue";

/*
 |--------------------------------------------------------------------------
 | Views
 |--------------------------------------------------------------------------|
 */

// Layouts
import LayoutBasic from "@/components/LayoutBasic.vue";

// Home
import Home from "./views/home/Home.vue";

// Profile
import Profile from "./views/profile/Profile.vue";

// Inspect
import Inspect from "./views/Inspect.vue";

// Onboarding
import Onboarding from "./views/Onboarding.vue";

// Videos
import Videos from "./views/Videos.vue";

// Company
import ChangeCompany from "./views/ChangeCompany.vue";

// Reports
import ReportIndex from "./views/reports/Index.vue";
import ReportEdit from "./views/reports/Edit.vue";
import ReportEditTemplate from "./views/reports/TemplateEdit.vue";

// ReportTemplates
import ReportTemplateIndex from "./views/reportTemplates/Index.vue";
import ReportTemplateEdit from "./views/reportTemplates/Edit.vue";
import ReportTemplatePermissions from "./views/reportTemplates/Permissions.vue";

// Preferences
import PreferenceIndex from "./views/preferences/Index.vue";
import PreferenceEdit from "./views/preferences/edit/Edit.vue";
import PreferenceAdd from "./views/preferences/Create.vue";

// Company Settings
import CompanySettings from "./views/companySettings/CompanySettings.vue";

// Users
import UsersIndex from "./views/users/Index.vue";
// import UsersAdd from "./views/users/Create.vue";
import UsersEdit from "./views/users/Edit.vue";

// Stocks
import StocksIndex from "./views/stocks/Index.vue";
import StocksAdd from "./views/stocks/Create.vue";
import StocksEdit from "./views/stocks/Edit.vue";
import StocksMerge from "./views/stocks/Merge.vue";

// Projects
import ProjectsIndex from "./views/projects/Index.vue";
import ProjectsAdd from "./views/projects/Create.vue";
import ProjectsEdit from "./views/projects/Edit.vue";

// Sites
import SitesView from "./views/sites/SitesView.vue";
import SiteView from "./views/sites/SiteView.vue";
import SiteBuildingsLevelsAreas from "./views/sites/SiteBuildingsLevelsAreas.vue";
import SiteAreaTypes from "./views/sites/SiteAreaTypes.vue";
import SiteAreaType from "./views/sites/SiteAreaType.vue";
import SiteDetails from "./views/sites/SiteDetails.vue";
import SiteRecipients from "./views/sites/SiteRecipients.vue";
import SiteFindings from "./views/sites/SiteFindings.vue";
import SiteReports from "./views/sites/SiteReports.vue";

import SiteBuildings from "./views/sites/SiteBuildings.vue";
import SiteArea from "./views/sites/SiteArea.vue";
import SiteLevel from "./views/sites/SiteLevel.vue";
import SiteBuilding from "./views/sites/SiteBuilding.vue";

const routes = [
    {
        path: "/",
        component: LayoutLogin,
        meta: {redirectIfAuthenticated: true},
        children: [
            {
                path: "",
                component: Login,
                name: "login",
                alias: "/login",
                meta: {requiresGuest: true},
            },
            {
                path: "login/phone",
                component: LoginPhone,
                name: "login-phone",
                meta: {requiresGuest: true},
            },
            {
                path: "login/phone/verify",
                component: VerifyPhone,
                name: "login-phone-verify",
                meta: {requiresGuest: true},
            },
            {
                path: "forgot-password",
                component: ForgotPassword,
                name: "forgot-password",
                meta: {requiresGuest: true},
            },
            {
                path: "forgot-password/email",
                component: ForgotPasswordEmail,
                name: "forgot-password-email",
                meta: {requiresGuest: true},
            },
            {
                path: "forgot-password/email/verify",
                component: ForgotPasswordEmailVerify,
                name: "forgot-password-email-verify",
                meta: {requiresGuest: true},
            },
            {
                path: "forgot-password/phone",
                component: ForgotPasswordPhone,
                name: "forgot-password-phone",
                meta: {requiresGuest: true},
            },
            {
                path: "forgot-password/phone/verify",
                component: ForgotPasswordPhoneVerify,
                name: "forgot-password-phone-verify",
                meta: {requiresGuest: true},
            },
            {
                path: "reset-password",
                component: ResetPassword,
                name: "reset-password",
                meta: {requiresGuest: true},
            },
            {
                path: "reset-password/success",
                component: ResetPasswordSuccess,
                name: "reset-password-success",
                meta: {requiresGuest: true},
            },
            {
                path: "verify/success",
                component: VerifySuccess,
                name: "verify.success",
                meta: {requiresGuestOrAuth: true},
            },
            {
                path: "verify/error",
                component: VerifyError,
                name: "verify.error",
                meta: {requiresGuestOrAuth: true},
            },
            {
                path: "verify/sent",
                component: VerifySent,
                name: "verify.sent",
                meta: {requiresGuestOrAuth: true},
            },
        ],
    },

    {
        path: "/",
        component: LayoutBasic,
        meta: {requiresAuth: true},
        children: [
            {
                path: "/",
                name: "home",
                component: Home,
            },
            {
                path: "/onboarding",
                name: "onboarding",
                component: Onboarding,
                meta: {title: "נשמח להכיר אותך", hideHeader: true},
            },
            {
                path: "/profile/",
                name: "profile",
                component: Profile,
                meta: {title: "הפרופיל שלי"},
            },
            {
                path: "/inspect/",
                name: "inspect",
                component: Inspect,
                meta: {title: "בדיקת מערכת"},
            },
            {
                path: "/videos/",
                name: "videos",
                component: Videos,
                meta: {title: "סרטוני הדרכה"},
            },
            {
                path: "/change-company/",
                name: "change-company",
                component: ChangeCompany,
                meta: {title: "החלפת חברה"},
            },
            {
                path: "/reports/",
                name: "reports",
                component: ReportIndex,
                meta: {title: "הדוחות שלי"},
            },
            {
                path: "/reports/edit/:id",
                name: "reports.edit",
                component: ReportEdit,
                meta: {title: "עריכת דוח"},
            },
            {
                path: "/reports/edit-template/:id",
                name: "reports.editTemplate",
                component: ReportEditTemplate,
                meta: {title: "עריכת תבנית דוח"},
            },
            {
                path: "/report-templates/",
                name: "report-templates.index",
                component: ReportTemplateIndex,
                meta: {title: "תבניות דוחות"},
            },
            {
                path: "/report-templates/edit/:id",
                name: "report-templates.edit",
                component: ReportTemplateEdit,
                meta: {title: "עריכת תבנית"},
            },
            {
                path: "/report-templates/permissions/:id",
                name: "report-templates.permissions",
                component: ReportTemplatePermissions,
                meta: {title: "הרשאות תבנית", roles: ["admin", "owner"]},
            },
            {
                path: "/preferences/",
                name: "preferences.index",
                component: PreferenceIndex,
                meta: {title: "עיצובים (לוגו עליון ותחתון)"},
            },
            {
                path: "/preferences/add",
                name: "preferences.add",
                component: PreferenceAdd,
                meta: {title: "הוספת עיצוב", roles: ["admin", "owner"]},
            },
            {
                path: "/preferences/edit/:id",
                name: "preferences.edit",
                component: PreferenceEdit,
                meta: {title: "עריכת עיצוב"},
            },
            {
                path: "/users",
                name: "users",
                component: UsersIndex,
                meta: {title: "משתמשים", roles: ["admin", "owner"]},
            },
            {
                path: "/users/add",
                name: "users.add",
                component: () => import("./views/users/Create.vue"),
                meta: {title: "הוספת משתמש", roles: ["admin", "owner"]},
            },
            {
                path: "/users/edit/:id",
                name: "users.edit",
                component: UsersEdit,
                meta: {title: "עריכת משתמש", roles: ["admin", "owner"]},
            },
            {
                path: "/stocks",
                name: "stocks",
                component: StocksIndex,
                meta: {title: "מאגרים", roles: ["admin", "owner"]},
            },
            {
                path: "/stocks/add",
                name: "stocks.add",
                component: StocksAdd,
                meta: {title: "הוספת מאגר", roles: ["admin", "owner"]},
            },
            {
                path: "/stocks/edit/:id",
                name: "stocks.edit",
                component: StocksEdit,
                meta: {title: "עריכת מאגר", roles: ["admin", "owner"]},
            },
            {
                path: "/stocks/merge/:id",
                name: "stocks.merge",
                component: StocksMerge,
                meta: {title: "איחוד מאגרים", roles: ["admin", "owner"]},
            },
            {
                path: "/projects",
                name: "projects",
                component: ProjectsIndex,
                meta: {
                    title: "פרויקטים",
                },
            },
            {
                path: "/projects/add",
                name: "projects.add",
                component: ProjectsAdd,
                meta: {
                    title: "הוספת פרויקט",
                    roles: ["admin", "owner"],
                },
            },
            {
                path: "/projects/edit/:id",
                name: "projects.edit",
                component: ProjectsEdit,
                meta: {
                    title: "עריכת פרויקט",
                },
            },
            {
                path: "/sites",
                name: "sites",
                component: SitesView,
                meta: {title: "אתרים"},
            },
            {
                path: "/sites/:id",
                component: SiteView,
                meta: {title: "אתר", hideHeader: false},
                children: [
                    {
                        path: "buildings-levels-areas",
                        name: "site-buildings-levels-areas",
                        component: SiteBuildingsLevelsAreas,
                        meta: {title: "מבנים וקומות"}
                    },
                    {
                        path: "area-types",
                        name: "area-types",
                        component: SiteAreaTypes,
                        meta: {title: "סוגי אזורים"}
                    },
                    {
                        path: "area-types/:areaTypeId",
                        name: "area-types.edit",
                        component: SiteAreaType,
                        meta: {title: "עריכת סוג אזור"}
                    },
                    {
                        path: "edit",
                        name: "site-edit",
                        component: SiteDetails,
                        meta: {title: "עריכת אתר"}
                    },
                    {
                        path: "recipients",
                        name: "site-recipients",
                        component: SiteRecipients,
                        meta: {title: "נמענים"}
                    },
                    {
                        path: "findings",
                        name: "site-findings",
                        component: SiteFindings,
                        meta: {title: "ממצאים"}
                    },
                    {
                        path: "reports",
                        name: "site-reports",
                        component: SiteReports,
                        meta: {title: "דוחות"}
                    },
                    {
                        path: "",
                        name: "site-buildings",
                        component: SiteBuildings,
                        meta: {title: "בניינים"}
                    },
                    {
                        path: "buildings/:buildingId",
                        name: "site-building",
                        component: SiteBuilding,
                        meta: {title: "בניין"}
                    },
                    {
                        path: "levels/:levelId",
                        name: "site-level",
                        component: SiteLevel,
                        meta: {title: "קומה"}
                    },
                    {
                        path: "areas/:areaId",
                        name: "site-area",
                        component: SiteArea,
                        meta: {title: "אזור"}
                    }
                ]
            },
            {
                path: "/company-settings",
                name: "company-settings",
                component: CompanySettings,
                meta: {title: "הגדרות חברה", roles: ["owner"]},
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Create a standalone function for role checking that doesn't rely on composition API
const checkRouteRoles = (roles, store) => {

    const user = store.getters['auth/user']
    if (!user) return false

    const currentUserRoleId = user.role_id
    let currentUserRoleName = null

    switch (currentUserRoleId) {
        case 2:
            currentUserRoleName = "admin"
            break
        case 3:
            currentUserRoleName = "user"
            break
        case 4:
            currentUserRoleName = "owner"
            break
    }

    return roles.includes(currentUserRoleName)
}

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
    const requiresNoCompany = to.matched.some((x) => x.meta.requiresNoCompany);
    const requiresGuest = to.matched.some((x) => x.meta.requiresGuest);
    const requiresGuestOrAuth = to.matched.some((x) => x.meta.requiresGuestOrAuth);
    const isLoggedIn = store.getters["auth/isLoggedIn"];
    const hasToken = store.getters["auth/token"];

    if (requiresGuestOrAuth) return next()
    if (requiresNoCompany && store.getters["auth/hasCompany"]) return next()

    if (isLoggedIn) {
        if (requiresGuest) {
            return next({name: "home"});
        }
        if (requiresAuth) {
            return handleLoggedInUser(next, store, to);
        }
    }

    if (!isLoggedIn) {
        if (requiresGuest) {
            return handleRequiresGuest(next, store, hasToken);
        }
        if (requiresAuth) {
            return handleRequiresAuth(next, store, to, hasToken);
        }
    }

    return next();
});

async function fetchUser(store) {
    try {
        await store.dispatch("auth/fetchMe");
    } catch (e) {
        throw new Error("Fetch user failed");
    }
}

function checkRoles(to, store) {
    if (!to.meta.roles) return true
    return to.matched.some((route) => route.meta.roles) && checkRouteRoles(to.meta.roles, store);
}


async function handleRequiresGuest(next, store, hasToken) {
    if (!hasToken) return next();

    try {
        await fetchUser(store);
        if (store.getters["auth/isLoggedIn"]) {
            return next({name: "home"});
        } else {
            return next({name: "login"});
        }
    } catch (e) {
        return next({name: "login"});
    }
}

async function handleRequiresAuth(next, store, to, hasToken) {
    if (!hasToken) return next({name: "login"});

    try {
        await fetchUser(store);
        if (!store.getters["auth/hasCompany"]) {
            return next({name: "no-company"});
        } else if (store.getters["auth/isLoggedIn"]) {
            if (store.getters["auth/showOnboarding"] && to.name !== "onboarding") {
                return next({name: "onboarding"});
            }
            if (!checkRoles(to, store)) {
                return next({name: "home"});
            }
            return next();
        } else {
            return next({name: "login"});
        }
    } catch (e) {
        console.log('e', e);
        return next({name: "login"});
    }
}

function handleLoggedInUser(next, store, to) {
    if (store.getters["auth/showOnboarding"] && to.name !== "onboarding") {
        return next({name: "onboarding"});
    }
    if (to.matched.some((route) => route.meta.roles) && !checkRoles(to, store)) {
        return next({name: "home"});
    }
    return next();
}

export default router;
