async function getFindings(siteId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/findings`);
  return response.data;
}

async function createFinding(siteId, siteAreaId, data) {
  const response = await window.axios.post(`/api/v1/sites/${siteId}/findings`, {
    site_area_id: siteAreaId,
    ...data
  });
  return response.data;
}

async function getFinding(siteId, findingId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/findings/${findingId}`);
  return response.data;
}

async function updateFinding(siteId, findingId, data) {
  const response = await window.axios.put(`/api/v1/sites/${siteId}/findings/${findingId}`, data);
  return response.data;
}

async function removeFinding(siteId, findingId) {
  const response = await window.axios.delete(`/api/v1/sites/${siteId}/findings/${findingId}`);
  return response.data;
}

export default {
  getFindings,
  createFinding,
  getFinding,
  updateFinding,
  removeFinding,
}; 