<template>
  <div class="video-card">
    <div
      class="video-card__thumbnail u-cursor-pointer"
      @click="isVideoDialogOpen = true"
    >
      <div class="video-thumbnail">
        <img
          :src="getThumbnailUrl(videoId)"
          :alt="videoId"
          style="width: 100%; height: auto"
        />
      </div>
    </div>

    <RModal v-model="isVideoDialogOpen" size="lg">
      <div class="video-container">
        <iframe
          :src="`https://www.youtube.com/embed/${videoId}?autoplay=1`"
          width="100%"
          height="400"
          frameborder="0"
          allowfullscreen
        ></iframe>
      </div>
    </RModal>
  </div>
</template>

<script>
export default {
  props: {
    videoId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVideoDialogOpen: false,
    };
  },
  methods: {
    getThumbnailUrl(videoId) {
      return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
    },
    playVideo() {
      this.isVideoDialogOpen = true;
    },
  },
};
</script>

<style scoped>
.video-thumbnail {
  position: relative;
}

.play-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.video-container {
  position: relative;
  padding-bottom: 50%;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.v-dialog__content {
  align-items: center;
}
</style>
