import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import SitesApi from '@/api/SitesApi'

// Create shared state outside the function
const site = ref(null)
const isLoading = ref(false)
const siteId = ref(null)

export function useSite() {
  const route = useRoute()
  siteId.value = route.params.id
  // Computed properties for site data
  const siteCategories = computed(() => {
    return site.value?.categories || []
  })

  const siteResponsibilities = computed(() => {
    return site.value?.responsibilities || []
  })

  const siteStatuses = computed(() => {
    if (!site.value?.statuses) return []
    return site.value.statuses.map(status => ({
      title: status.text,
      value: status.text,
      color: status.color
    }))
  })

  // Methods
  const loadSite = async () => {
    isLoading.value = true
    try {
      const response = await SitesApi.getSite(route.params.id)
      site.value = response.data
    } catch (error) {
      console.error('Error loading site:', error)
    } finally {
      isLoading.value = false
    }
  }

  const getStatusColor = (statusText) => {
    const status = siteStatuses.value.find(s => s.title === statusText)
    return status ? status.color.replace('bg-', '') : ''
  }

  const clearSite = () => {
    site.value = null
  }

  const getAreaTypeName = (typeId) => {
    const areaType = site.value?.area_types?.find(type => type.id === typeId)
    return areaType?.name || ''
  }

  const getSiteBuildings = () => {
    let buildings = [];
    site.value?.buildings.forEach(building => {
      buildings.push(building)
    })
    return buildings
  }

  const getSiteLevels = () => {
    let levels = [];
    site.value?.buildings.forEach(building => {
      building.levels.forEach(level => {
        levels.push(level)
      })
    })
    return levels
  }

  const getSiteAreas = () => {
    let areas = [];
    site.value?.buildings.forEach(building => {
      building.levels.forEach(level => {
        level.areas.forEach(area => {
          areas.push(area)
        })
      })
    })
    return areas
  }

  return {
    site,
    siteId,
    isLoading,
    siteCategories,
    siteResponsibilities,
    siteStatuses,
    loadSite,
    getStatusColor,
    clearSite,
    getAreaTypeName,
    getSiteBuildings,
    getSiteLevels,
    getSiteAreas
  }
} 