<template>
  <RSimpleCard
    :mobile-fullwidth="true"
    title="מוצרים נוספים"
    subtitle="מוצרים נוספים במערכת רפורטו שיכולים לעזור לך להפיק יותר"
    class="mb-4"
  >
    <div class="flex flex-col">
      <div v-if="isLoading" class="flex justify-center py-8">
        <RIconLoading class="w-8 h-8 text-primary-500" />
      </div>
      
      <div v-else class="flex flex-col divide-y divide-gray-200 -mx-4">
        <div
          v-for="feature in features"
          :key="feature.id"
          class="p-4 bg-gray-100/50" 
        >
          <div class="flex items-start justify-between gap-2">
            <div class="flex-1">
              <div class="flex items-center gap-2">
                <h4 class="text-lg font-semibold text-gray-900">⭐ {{ feature.name }}</h4>
                <div v-if="feature.isActive" class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                  פעיל
                </div>

                <div v-if="!feature.isActive" class="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20"> 
                  לא פעיל
                </div>

                <div 
                  v-if="feature.isFree"
                  class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                >
                  חינם
                </div>
              </div>
              <p class="text-sm text-gray-500 mt-1">{{ feature.description }}</p>
              <div 
                class="text-sm font-medium flex items-center gap-1 text-gray-800 mt-1"
              >
                <span :class="[
                  'text-base font-semibold',
                  { 'line-through': feature.isFree }
                ]">
                  ₪{{ feature.price }}
                </span>
                <span class="text-gray-500 text-xs">/</span>
                <span class="text-gray-500 text-xs">תוספת לחודש</span>
              </div>
            </div>
            <RButton
              :color="feature.isActive ? 'red' : 'green'"
              :loading="loadingFeatures[feature.id]"
              :icon-before="feature.isActive ? 'mdi-close' : 'mdi-rocket'"
              @click="toggleFeature(feature)"
              :outlined="feature.isActive"
            >
              {{ feature.isActive ? 'בטל' : 'הפעל' }}
            </RButton>
          </div>
        </div>
      </div>
    </div>
  </RSimpleCard>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useToast } from 'vue-toastification'
import CompanyApi from '@/api/CompanyApi'
import posthog from 'posthog-js'

const toast = useToast()
const features = ref([])
const isLoading = ref(true)
const loadingFeatures = ref({})

async function loadFeatures() {
  try {
    isLoading.value = true
    const response = await CompanyApi.getCompanyFeatures()
    features.value = response.data
  } catch (error) {
    console.error('error', error)
    toast.error('שגיאה בטעינת המוצרים')
  } finally {
    isLoading.value = false
  }
}

async function toggleFeature(feature) {
  loadingFeatures.value[feature.id] = true
  
  try {
    if (feature.isActive) {
      await CompanyApi.disableCompanyFeature(feature.id)
      posthog.capture('log:company_feature_disabled', {
        feature_id: feature.id,
        feature_name: feature.name
      })
      toast.success('המוצר בוטל. מרעננים את הדף בעוד 10 שניות.', {
        timeout: 10000
      })
      setTimeout(() => {
        window.location.reload()
      }, 10000)
    } else {
      await CompanyApi.enableCompanyFeature(feature.id)
      toast.success('המוצר הופעל, החיוב יתבצע בתחילת תקופת התשלום הבאה. מרעננים את הדף בעוד 10 שניות.', {
        timeout: 10000
      })
      posthog.capture('log:company_feature_enabled', {
        feature_id: feature.id,
        feature_name: feature.name
      })

      setTimeout(() => {
        window.location.reload()
      }, 10000)
    }
    
    // Reload features to get updated state
    await loadFeatures()
  } catch (error) {
    toast.error('שגיאה בעדכון המוצר')
  } finally {
    loadingFeatures.value[feature.id] = false
  }
}

onMounted(() => {
  loadFeatures()
})
</script>