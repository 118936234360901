<template>
   <div v-if="
      $checkRole(['admin', 'owner']) &&
      company.daysLeftInTrial &&
      company.daysLeftInTrial <= 15 &&
      !company.hasCreditCard
    " class="mb-5 px-5">
  <div
    class="bg-gradient-to-r from-[#FFF8E1] to-white rounded-lg shadow-sm border border-gray-200"
  >
    <div class="p-4 flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 sm:gap-2">
      <div class="flex-1">
        <div class="flex items-center gap-2 mb-1">
          <h3 class="font-semibold text-md">
            תקופת הניסיון תיגמר בעוד {{ company.daysLeftInTrial }} ימים
          </h3>
        </div>
        <p class="text-gray-700 text-sm">
          כדי להמשיך להשתמש במערכת יש להזין פרטי אשראי לתשלום.
          במידה ולא יוכנס אשראי המערכת תיסגר באופן אוטומטי.
        </p>
      </div>
      <div class="sm:w-auto">
        <RButton
          v-if="$checkRole(['owner'])"
          color="yellow"
          to="/company-settings/?credit-card-modal=1"

        >
          עדכון פרטי אשראי
        </RButton>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", [
      "displayName",
      "displayCompanyName",
      "companyLogoUrl",
      "company",
    ]),
  },
};
</script>
