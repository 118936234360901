<template>
  <div class="rp-report__defects">
    <v-dialog
        v-if="reportDefectModal.isOpen"
        v-model="reportDefectModal.isOpen"
        :max-width="700"
        :persistent="true"
    >
      <report-defect-modal
          :initial-defect="reportDefectModal.defect"
          :mode="reportDefectModal.mode"
          :get-defect-invalid-fields="getDefectInvalidFields"
          @close="closeReportDefectModal"
          @handle-submit="submitDefect"
          @handle-remove="removeDefect"
      />
    </v-dialog>

    <div class="rp-defects__header">
      <div class="rp-defects__title">
        רשימת ממצאים
        <small> ({{ totalSortedDefectsList }}) </small>
      </div>

      <div class="rp-defects__actions">
        <div
            v-if="defectsList && Object.values(defectsList).length"
            class="relative flex-1 sm:flex-none sm:w-64"
        >
          <input
              v-model="searchQuery"
              type="text"
              placeholder="חיפוש..."
              class="w-full px-4 py-2 text-sm rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none transition-all duration-200 bg-white shadow-sm"
          />
          <span class="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400">
            <RIcon small>mdi-magnify</RIcon>
          </span>
        </div>
        <div class="d-flex u-gap-1 align-center">
          <RButton
              md
              class="sm:flex add-finding-button--desktop"
              @click="openReportDefectModal('add', null)"
          >
            הוספת ממצא
          </RButton>
          <RActions icon="mdi-cog">
            <RAction
                v-if="!isUserCanReorder"
                icon="mdi-reorder-horizontal"
                text="גרירת ממצאים ידנית"
                @click="isUserCanReorder = true"
            />
            <RAction
                v-if="isUserCanReorder"
                icon="mdi-reorder-horizontal"
                text="כיבוי גרירת ממצאים ידנית"
                @click="isUserCanReorder = false"
            />
            <RAction
                v-if="defectsGroupedBy !== 'category'"
                text="סידור ממצאים לפי קטגוריה"
                icon="mdi-shape"
                @click="setDefectsGroupBy('category')"
            />
            <RAction
                v-if="defectsGroupedBy !== 'location'"
                text="סידור ממצאים לפי מיקום"
                icon="mdi-map-marker"
                @click="setDefectsGroupBy('location')"
            />
            <RAction
                text="סגירת כל הממצאים"
                icon="mdi-unfold-less-horizontal"
                @click="closeAllDefects"
            />
            <RAction
                v-if="!showDeletedDefects"
                text="הצג ממצאים שנמחקו"
                icon="mdi-delete"
                @click="setShowDeletedDefects(true)"
            />
            <RAction
                v-if="showDeletedDefects"
                text="הסתר ממצאים שנמחקו"
                icon="mdi-delete-off"
                @click="setShowDeletedDefects(false)"
            />
          </RActions>
        </div>
      </div>
    </div>

    <div
        class="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200 shadow-lg sm:hidden z-50"
    >
      <RButton
          class="add-finding-button--mobile"
          block
          lg
          @click="openReportDefectModal('add', null)"
      >
        הוספת ממצא
      </RButton>
    </div>

    <div class="pb-20 sm:pb-0">
      <draggable
          handle=".reportCategory-handle"
          :list="sortedDefectsList"
          item-key="id"
          v-bind="dragOptions"
          class="rp-defects__categories"
          :class="{
          'search-on': searchQuery,
          'user-cant-reorder': !isUserCanReorder || searchQuery,
          'show-deleted-defects': showDeletedDefects,
        }"
          @end="onEndDraggingDefectsGroups"
      >
        <template #item="{ element: group }">
          <div
              class="rp-defects__category"
              :class="{
              'is-open': isOpen.hasOwnProperty(group.id) && isOpen[group.id],
              'is-empty': getTotalCategoryValidDefects(group.items) === 0,
            }"
          >
            <div
                class="rp-defects__category__header"
                @click="toggleOpen(group.id)"
            >
              <div class="rp-defects__category__avatar reportCategory-handle">
                <RIcon small class="mr-1 ml-1">mdi-drag</RIcon>
              </div>

              <div class="rp-defects__category__title">
                <span class="rp-defects__category__title__name">{{
                    group.name
                  }}</span>
                <small>
                  ({{ getTotalCategoryValidDefects(group.items) }})
                </small>
              </div>

              <div class="rp-defects__category__arrow">
                <RIcon small>mdi-chevron-down</RIcon>
              </div>
            </div>

            <transition name="slide">
              <div
                  v-show="isOpen.hasOwnProperty(group.id) && isOpen[group.id]"
                  class="rp-defects__category__body"
              >
                <draggable
                    :list="group.items"
                    handle=".reportCategoryNode-handle"
                    item-key="id"
                    v-bind="dragOptions"
                    class="rp-defects__items"
                    :empty-insert-threshold="50"
                    @change="onEndDraggingDefects"
                >
                  <template #item="{ element: defect }">
                    <div
                        class="rp-defects__item"
                        :class="{
                        'is-highlighted': defectIdToHighlight === defect.id,
                        'is-deleted': defect.deletedAt,
                        'is-disabled': defect.isDisabled,
                      }"
                        :data-defect-id="defect.id"
                        @click="openReportDefectModal('edit', defect)"
                    >
                      <div
                          class="rp-defects__item__handle reportCategoryNode-handle"
                      >
                        <RIcon small>mdi-drag</RIcon>
                      </div>

                      <div class="rp-defects__item__avatar">
                        <img
                            v-if="getDefectFirstImageUrl(defect)"
                            :src="getDefectFirstImageUrl(defect)"
                            loading="lazy"
                        />
                        <span v-else>0</span>
                      </div>

                      <div class="rp-defects__item__content">
                        <div class="rp-defects__item__content1 mb-1">
                          <span
                              v-if="defectsGroupedBy === 'category'"
                              class="rp-p2"
                              style="font-weight: 600"
                          >{{ defect.location }}</span
                          >
                          <b
                              v-if="defectsGroupedBy === 'location'"
                              class="rp-p2"
                              style="font-weight: 600"
                          >{{ defect.category }}</b
                          >

                          <RChip
                              v-if="
                              getDefectInvalidFields(defect) &&
                              getDefectInvalidFields(defect).length
                            "
                            sm
                              color="red"
                              class="mr-1"
                          >חסרים פרטים
                          </RChip
                          >

                          <RChip
                              v-if="defect.deletedAt"
                              color="black"
                              class="mr-1"
                              sm
                          >נמחק
                          </RChip
                          >

                          <RChip
                              v-if="
                              report.enableDefectStatus && defect.defectStatus
                            "
                              :color="convertStatusToColor(defect.defectStatus)"
                              class="mr-1"
                              sm
                          >{{ defect.defectStatus }}
                          </RChip
                          >

                          <RChip
                              v-if="
                              report.enableDefectRecheckStatus &&
                              defect.defectRecheckStatus
                            "
                              :color="
                              convertStatusToColor(defect.defectRecheckStatus)
                            "
                              class="mr-1"
                              sm
                          >{{ defect.defectRecheckStatus }}
                          </RChip
                          >
                        </div>
                        <div class="rp-defects__item__content2">
                          {{ defect.description }}
                        </div>
                      </div>
                      <RActions>
                        <RAction
                            v-if="!defect.deletedAt"
                            icon="mdi-delete"
                            text="מחיקה"
                            @click="removeDefectClicked(defect)"
                        />
                        <RAction
                            v-if="defect.deletedAt"
                            icon="mdi-restore"
                            text="שחזור"
                            @click="restoreDefectClicked(defect)"
                        />
                        <RAction
                            v-if="defect.isDisabled"
                            icon="mdi-eye"
                            color="green"
                            text="הדלקה"
                            @click="enableDefect(defect)"
                        />
                        <RAction
                            v-else
                            icon="mdi-eye-off"
                            color="grey"
                            text="כיבוי"
                            @click="disableDefect(defect)"
                        />
                      </RActions>
                    </div>
                  </template>
                </draggable>
              </div>
            </transition>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import ReportDefectModal from "./ReportDefectModal";
import {DefectsUtils, Utils} from "@/common/Utils";
import {mapActions, mapGetters} from "vuex";
import {dragOptions} from "@/common/settings";
import draggable from "vuedraggable";
// import Vue from "vue";

export default {
  components: {ReportDefectModal, draggable},
  data() {
    return {
      isUserCanReorder: false,
      showDeletedDefects: false,
      sortedDefectsList: [],
      defectsOrder: [],
      dragOptions,
      defectIdToHighlight: null,
      isOpen: {},
      searchQuery: null,
      defectsGroupedBy: "location",
      reportDefectModal: {
        isOpen: false,
        mode: "add",
        editing_defect_id: false,
        defect: {},
      },
    };
  },
  computed: {
    ...mapGetters("report", ["report"]),
    defectsList() {
      return this.report.data.defects;
    },
    validations() {
      let totalRequiredFields = 0;
      let validations = null;
      for (const item of Object.values(this.report.data.items)) {
        if (item.type === "defectsTable" && item.validation) {
          validations = item.validation;
          for (const [key, field] of Object.entries(item.validation)) {
            if (key === "defectStatus" && !this.report.enableDefectStatus) {
              continue;
            }

            if (
                key === "defectRecheckStatus" &&
                !this.report.enableDefectRecheckStatus
            ) {
              continue;
            }
            if (field.required) {
              totalRequiredFields++;
            }
          }
        }
      }

      if (validations && !this.report.enableDefectStatus) {
        delete validations.defectStatus;
      }
      if (validations && !this.report.enableDefectRecheckStatus) {
        delete validations.defectRecheckStatus;
      }

      if (totalRequiredFields) {
        return validations;
      }
      return false;
    },
    totalSortedDefectsList() {
      let total = 0;
      this.sortedDefectsList.forEach((group) => {
        total = total + this.getTotalCategoryValidDefects(group.items);
      });
      return total;
    },
    defectsEmptyText() {
      if (this.defectsGroupedBy === "category") {
        return "ללא פרק";
      } else {
        return "ללא מיקום";
      }
    },
  },
  watch: {
    reportDefectModal: {
      handler(val) {
        if (!val.isOpen) {
          document.documentElement.style.overflow = "auto"; // prevent body scrolling
        }
      },
      deep: true,
    },
    defectsList: {
      handler() {
        this.updateSortedDefectsList();
      },
      deep: true,
    },
    searchQuery() {
      this.updateSortedDefectsList();
    },
  },
  created() {
    if (this.report.defectsGroupedBy) {
      this.defectsGroupedBy = this.report.defectsGroupedBy;
    }
    this.updateSortedDefectsList();
  },
  beforeUnmount() {
    document.documentElement.style.overflow = "auto";
  },
  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),
    setShowDeletedDefects(value) {
      this.showDeletedDefects = value;
    },
    updateSortedDefectsList() {
      if (!this.defectsList) {
        this.sortedDefectsList = [];
        return;
      }

      let sortedDefects = [];

      let defectsArray = Object.values(this.defectsList).sort(
          (a, b) => a.order - b.order
      );

      for (const defect of defectsArray) {
        // Exclude if is not in search
        if (!DefectsUtils.isNodeInSearch(defect, this.searchQuery)) {
          continue;
        }

        let addToSortedBy = defect[this.defectsGroupedBy]
            ? defect[this.defectsGroupedBy]
            : this.defectsEmptyText;

        // find index of category
        let foundGroup = false;
        sortedDefects.forEach((defectsGroup) => {
          if (defectsGroup.id === addToSortedBy) {
            defectsGroup.items.push(defect);
            foundGroup = true;
          }
        });

        if (!foundGroup) {
          sortedDefects.push({
            name: addToSortedBy,
            id: addToSortedBy,
            items: [defect],
          });
        }
      }

      this.sortedDefectsList = sortedDefects;
    },
    closeAllDefects() {
      this.isOpen = {};
    },
    getTotalCategoryValidDefects(defects) {
      let totalValidDefects = 0;
      defects.forEach((defect) => {
        if (!(defect.deletedAt && !this.showDeletedDefects)) {
          totalValidDefects++;
        }
      });
      return totalValidDefects;
    },
    getDefectInvalidFields(defect) {
      if (!this.validations) {
        return [];
      }
      let invalidFields = [];

      for (const [key, validation] of Object.entries(this.validations)) {
        if (validation.required && !defect[key]) {
          invalidFields.push(key);
        }
      }

      if (!invalidFields.length) {
        return false;
      }

      return invalidFields;
    },
    reorderDefectsByDOM() {
      let currentDefectsOrder = JSON.parse(JSON.stringify(this.defectsOrder));
      this.updateDefectsOrderFromDOM();
      setTimeout(() => {
        if (
            JSON.stringify(currentDefectsOrder) !==
            JSON.stringify(this.defectsOrder)
        ) {
          let data = {};
          this.defectsOrder.forEach((defectId, index) => {
            let defect = this.defectsList[defectId];
            data[`defects.${defect.id}.order`] = index + 1;
          });

          this.doUpdateMany({data});
        }
      }, 100);
    },
    async onEndDraggingDefects() {
      this.reorderDefectsByDOM();
    },
    async onEndDraggingDefectsGroups() {
      this.reorderDefectsByDOM();
    },
    async setDefectsGroupBy(value) {
      this.defectsGroupedBy = value;

      if (this.defectsList) {
        let defects = Object.values(this.defectsList).sort(
            (a, b) => a.order - b.order
        );

        let newDefectsOrder = DefectsUtils.orderDefectsByGroup(
            this.defectsGroupedBy,
            defects
        );

        let data = {
          defectsGroupedBy: this.defectsGroupedBy,
        };
        for (const defect of newDefectsOrder) {
          data[`defects.${defect.id}.order`] = defect.order;
        }

        await this.doUpdateMany({data});

        this.reorderDefectsByDOM();
      }
    },
    getDefectFirstImageUrl(defect) {
      if (!defect.uploads) return false;

      let uploads = Object.values(defect.uploads).sort(
          (a, b) => a.order - b.order
      );

      if (!uploads || uploads.length === 0) {
        return false;
      }
      uploads = uploads.filter((upload) => !upload.deletedAt);

      if (!uploads || uploads.length === 0) {
        return false;
      }

      return this.getImageSrc(uploads[0].path);
    },
    toggleOpen(id) {
      this.isOpen[id] = !this.isOpen[id];
      this.$forceUpdate();
    },
    openReportDefectModal(mode, defect = null) {
      document.documentElement.style.overflow = "hidden"; // prevent body scrolling
      let maxOrder = 0;
      if (this.defectsList) {
        maxOrder = Utils.findMaxOrderInObject(this.defectsList);
      }
      maxOrder++;
      let initialDefect = {
        order: maxOrder,
        id: Utils.guid(),
        note: "",
        uploads: [],
        category: "",
        location: "",
        standard: "",
        unitType: "קומפ׳",
        unitPrice: null,
        attachments: [],
        description: "",
        defectStatus: null,
        unitQuantity: null,
        recommendation: "",
        defectRecheckStatus: null,
        deletedAt: false,
      };
      if (defect) {
        initialDefect = {...defect};
      }

      this.reportDefectModal.defect = initialDefect;
      this.reportDefectModal.mode = mode;
      this.reportDefectModal.isOpen = true;
    },
    closeReportDefectModal() {
      document.documentElement.style.overflow = "auto"; // activate back body scrolling
      this.reportDefectModal.isOpen = false;
    },
    async submitDefect(defect) {
      let flattenDefect = Utils.flattenObject(defect);
      let data = {};
      for (const [key, value] of Object.entries(flattenDefect)) {
        data[`defects.${defect.id}.${key}`] = value;
      }

      // if we are not on 'add' mode, remove key 'order'
      if (this.reportDefectModal.mode !== "add") {
        delete data[`defects.${defect.id}.order`];
      }

      await this.doUpdateMany({data});

      this.isOpen[defect.category] = true;
      // Vue.set(this.isOpen, defect[this.defectsGroupedBy], true);
      this.defectIdToHighlight = defect.id;
      setTimeout(() => {
        if (this.defectIdToHighlight === defect.id) {
          this.defectIdToHighlight = null;
        }
      }, 10000);

      // if (this.reportDefectModal.mode === "add") {
      //   this.$toast.success("הממצא נוסף בהצלחה");
      // } else {
      //   this.$toast.success("הממצא עודכן בהצלחה");
      // }

      this.closeReportDefectModal();
      if (this.reportDefectModal.mode === "add") {
        setTimeout(() => {
          this.reorderDefectsByDOM();
        }, 100);
      }
    },
    updateDefectsOrderFromDOM() {
      let defects = document.querySelectorAll(".rp-defects__item");
      let defectsOrder = [];
      for (let i = 0; i < defects.length; i++) {
        let defect = defects[i];
        let defectId = defect.getAttribute("data-defect-id");
        defectsOrder.push(defectId);
      }

      this.defectsOrder = defectsOrder;
    },
    async disableDefect(defect) {
      await this.doUpdate({
        key: `defects.${defect.id}.isDisabled`,
        value: true,
      });
    },
    async enableDefect(defect) {
      await this.doUpdate({
        key: `defects.${defect.id}.isDisabled`,
        value: false,
      });
    },
    async removeDefectClicked(defect) {
      if (confirm("האם אתה בטוח שברצונך למחוק את הממצא?")) {
        await this.removeDefect(defect);
      }
    },
    async restoreDefectClicked(defect) {
      await this.doUpdate({
        key: `defects.${defect.id}.deletedAt`,
        value: null,
      });
      this.$toast.success("הממצא שוחזר בהצלחה");
    },
    async removeDefect(defect) {
      await this.doUpdate({
        key: `defects.${defect.id}.deletedAt`,
        value: Utils.currentTimestamp(),
      });

      this.$toast.success("הממצא נמחק בהצלחה");
      this.closeReportDefectModal();
    },
    getImageSrc(imagePath) {
      if (imagePath && imagePath.length > 50) {
        return imagePath;
      }
      return this.$Reporto.globals.MEDIA_URL + imagePath;
    },
    convertStatusToColor(val) {
      if (val === "לא תקין") {
        return "red";
      } else if (val === "לא תוקן") {
        return "red";
      } else if (val === "אזהרה") {
        return "orange";
      } else if (val === "תוקן חלקית") {
        return "orange";
      } else if (val === "תקין") {
        return "green";
      } else if (val === "תוקן") {
        return "green";
      } else if (val === "ממצא קל / אסתטי") {
        return "blue";
      } else if (val === "עבודה שטרם הושלמה") {
        return "blue";
      } else if (val === "ממצא חדש") {
        return "green";
      } else if (val === "חדש מבדיקה חוזרת") {
        return "blue";
      }
      return val;
    },
  },
};
</script>

<style scoped lang="scss">
.search-input {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.search-input :deep(fieldset) {
  border: 0 !important;
}

.add-finding-button--desktop {
  @media (max-width: 640px) {
    display: none !important;
  }
}
</style>
