<template>
  <div class="p-4">
    <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4">
      <!-- Header -->
      <div class="p-4 border-b">
        <h2 class="text-lg font-medium">{{ areaType?.name || 'סוג אזור' }}</h2>
      </div>

      <!-- Tab Navigation -->
      <div class="border-b">
        <nav class="flex">
          <button
            @click="activeTab = 'general'"
            :class="[
              'px-4 py-2 text-sm font-medium',
              activeTab === 'general'
                ? 'border-b-2 border-purple-500 text-purple-600'
                : 'text-gray-500 hover:text-gray-700'
            ]"
          >
            פרטים כלליים
          </button>
          <button
            @click="activeTab = 'checklist'"
            :class="[
              'px-4 py-2 text-sm font-medium',
              activeTab === 'checklist'
                ? 'border-b-2 border-purple-500 text-purple-600'
                : 'text-gray-500 hover:text-gray-700'
            ]"
          >
            צ'קליסט
          </button>
        </nav>
      </div>

      <div class="p-4 space-y-6">
        <!-- General Details Tab -->
        <div v-if="activeTab === 'general'" class="space-y-6">
          <!-- Name Edit -->
          <div>
            <RFieldText
              v-model="areaType.name"
              label="שם"
              required
            />
          </div>

          <!-- Locations List -->
          <div class="space-y-2">
            <h3 class="text-sm font-medium text-gray-700">מיקומים</h3>
            <draggable 
              v-model="areaType.locations"
              item-key="id"
              class="space-y-2"
            >
              <template #item="{ element, index }">
                <div class="flex items-center gap-2">
                  <button class="cursor-move">⋮⋮</button>
                  <input
                    v-model="element.name"
                    type="text"
                    class="rounded-md border border-gray-300 px-3 py-1 shadow-sm focus:border-purple-500 focus:ring-purple-500"
                  >
                  <button
                    @click="removeLocation(index)"
                    class="text-red-500 hover:text-red-700"
                  >
                    ✕
                  </button>
                </div>
              </template>
            </draggable>
            <button
              @click="addLocation"
              class="text-purple-500 hover:text-purple-700 text-sm"
            >
              + הוסף מיקום
            </button>
          </div>

          <!-- Save Button -->
          <div class="flex justify-end">
            <RButton
              @click="updateAreaType"
              lg
              :loading="isUpdating"
            >
              שמור שינויים
            </RButton>
          </div>
        </div>

        <!-- Checklist Tab -->
        <div v-if="activeTab === 'checklist'" class="space-y-4">
          <h3 class="text-sm font-medium text-gray-700">צ׳קליסט</h3>
          
          <!-- List header -->
          <div class="grid grid-cols-[1fr,2fr,auto] gap-2 px-4 py-2 bg-gray-100 rounded-t-lg font-medium text-sm">
            <div>קטגוריה</div>
            <div>תיאור</div>
            <div></div>
          </div>

          <div class="space-y-2">
            <div 
              v-for="finding in areaType.findings" 
              :key="finding.id"
              class="grid grid-cols-[1fr,2fr,auto] gap-2 items-center px-4 py-2 bg-white border rounded-lg"
            >
              <div>{{ finding.category }}</div>
              <div>{{ finding.description }}</div>
              <div class="flex gap-2">
                <RButtonIcon
                  @click="showEditFindingModal(finding)"
                  icon="mdi-pencil"
                  title="ערוך פריט"
                />
                <RButtonIcon
                  @click="showDeleteFindingModal(finding)"
                  icon="mdi-delete"
                  color="red"
                  title="מחק פריט"
                />
              </div>
            </div>
          </div>

          <RButton
            @click="showAddFindingModal"
            icon-before="mdi-plus"
          >
            הוסף סעיף לצ׳קליסט
          </RButton>

          <!-- Edit Finding Modal -->
          <RModal
            v-model="isEditFindingModalOpen"
            title="ערוך סעיף"
          >
            <div class="space-y-4">
              <RFieldSelect
                v-model="editingFinding.category"
                :items="siteCategories.map(category => ({ text: category, value: category }))"
                label="קטגוריה"
                required
              />

              <RFieldTextarea
                v-model="editingFinding.description"
                label="תיאור"
                required
              />

              <RFieldCheckbox
                v-model="editingFinding.applyToAllExisting"
                label="החל על כל האזורים הקיימים מסוג זה"
              />
            </div>

            <RButton 
              :disabled="!editingFinding.category || !editingFinding.description" 
              class="mt-4" 
              @click="saveEditFinding" 
              :loading="isEditingFinding" 
              block 
              lg
            >
              שמור שינויים
            </RButton>
          </RModal>

          <!-- Add Finding Modal -->
          <RModal
            v-model="isAddFindingModalOpen"
            title="הוסף סעיף חדש"
          >
            <div class="space-y-4">
              <RFieldSelect
                v-model="newFinding.category"
                :items="siteCategories.map(category => ({ text: category, value: category }))"
                label="קטגוריה"
                required
              />

              <RFieldTextarea
                v-model="newFinding.description"
                label="תיאור"
                required
              />

              <RFieldCheckbox
                v-model="newFinding.applyToAllExisting"
                label="הוסף לכל האזורים הקיימים מסוג זה"
              />
            </div>

            <RButton :disabled="!newFinding.category || !newFinding.description" class="mt-4" @click="addFinding" :loading="isAddingFinding" block lg>
                  הוסף סעיף חדש
            </RButton>
          </RModal>

          <!-- Add Delete Finding Modal after the other modals -->
          <RModal
            v-model="isDeleteFindingModalOpen"
            title="מחק סעיף"
          >
            <div class="space-y-4">
              <p class="font-medium text-gray-800">האם אתה בטוח שברצונך למחוק סעיף זה?</p>
              
              <div class="bg-gray-50 p-3 rounded-lg">
                <div class="font-medium">{{ deletingFinding.category }}</div>
                <div class="text-gray-600">{{ deletingFinding.description }}</div>
              </div>

              <div class="text-md text-gray-800">האם אתה רוצה להסיר סעיף זה מכל האזורים הקיימים מסוג זה?</div>
              <RFieldRadio
                v-model="deletingFinding.applyToAllExisting"
                :items="[{ text: 'הסר מכל האזורים הקיימים מסוג זה', value: true }, { text: 'אל תסיר מהאזורים שכבר יצרתי', value: false }]"
              />
            </div>

            <div class="flex gap-3 mt-4">
                <RButton 
                @click="isDeleteFindingModalOpen = false" 
                color="black"
                block 
                lg 
              >
                ביטול
              </RButton>
              <RButton 
                @click="confirmDeleteFinding" 
                :loading="isDeletingFinding" 
                color="red"
                block 
                lg
              >
                מחק סעיף
              </RButton>
            </div>
          </RModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import SiteAreaTypesApi from '@/api/SiteAreaTypesApi'
import draggable from 'vuedraggable'
import { useSite } from '@/composables/useSite'
import { generateUUID } from '@/common/helpers/uuid'
import RFieldTextarea from '@/ui/RFieldTextarea.vue'
import RFieldRadio from '@/ui/RFieldRadio.vue'
import { useToast } from 'vue-toastification'

const toast = useToast()
const { siteId, siteCategories } = useSite()
const route = useRoute()
const areaTypeId = route.params.areaTypeId
const areaType = ref({
  name: '',
  locations: [],
  findings: []
})

const activeTab = ref('general')
const isUpdating = ref(false)
const isAddFindingModalOpen = ref(false)
const isAddingFinding = ref(false)
const isEditFindingModalOpen = ref(false)
const isEditingFinding = ref(false)
const newFinding = ref({
  category: '',
  description: '',
  applyToAllExisting: false
})
const editingFinding = ref({
  id: null,
  category: '',
  description: '',
  applyToAllExisting: false
})
const isDeleteFindingModalOpen = ref(false)
const isDeletingFinding = ref(false)
const deletingFinding = ref({
  id: null,
  category: '',
  description: '',
  applyToAllExisting: false
})

const loadAreaType = async () => {
  try {
    const response = await SiteAreaTypesApi.getAreaType(siteId.value, areaTypeId)
    areaType.value = {
      ...response.data,
      locations: response.data.locations || [],
      findings: response.data.findings || []
    }
  } catch (error) {
    console.error('Error loading area type:', error)
  }
}

const updateAreaType = async () => {
  try {
    isUpdating.value = true
    const updateData = {
      ...areaType.value,
      locations: areaType.value.locations,
      findings: areaType.value.findings
    }
    await SiteAreaTypesApi.updateAreaType(siteId.value, areaTypeId, updateData)
    await loadAreaType()
    toast.success('הפרטים נשמרו בהצלחה')
  } catch (error) {
    toast.error('שגיאה בעת שמירת הפרטים')
    console.error('Error updating area type:', error)
  } finally {
    isUpdating.value = false
  }
}

const addLocation = () => {
  areaType.value.locations.push({
    id: generateUUID(),
    name: ''
  })
}

const removeLocation = (index) => {
  areaType.value.locations.splice(index, 1)
}

const showAddFindingModal = () => {
  newFinding.value = {
    category: '',
    description: '',
    applyToAllExisting: false
  }
  isAddFindingModalOpen.value = true
}

const addFinding = async () => {
  try {
    isAddingFinding.value = true
    const findingData = {
      category: newFinding.value.category,
      description: newFinding.value.description,
      applyToAllExisting: newFinding.value.applyToAllExisting
    }
    await SiteAreaTypesApi.addAreaTypeChecklistItem(siteId.value, areaTypeId, findingData)
    await loadAreaType()
    isAddFindingModalOpen.value = false
  } catch (error) {
    console.error('Error adding checklist item:', error)
  } finally {
    isAddingFinding.value = false
  }
}

const showEditFindingModal = (finding) => {
  editingFinding.value = { ...finding }
  isEditFindingModalOpen.value = true
}

const saveEditFinding = async () => {
  try {
    isEditingFinding.value = true
    await SiteAreaTypesApi.updateAreaTypeChecklistItem(
      siteId.value, 
      areaTypeId, 
      editingFinding.value.id, 
      editingFinding.value
    )
    await loadAreaType()
    toast.success('הפרטים נשמרו בהצלחה')
    isEditFindingModalOpen.value = false
  } catch (error) {
    toast.error('שגיאה בעת שמירת הפרטים')
    console.error('Error updating checklist item:', error)
  } finally {
    isEditingFinding.value = false
  }
}

const showDeleteFindingModal = (finding) => {
  deletingFinding.value = { 
    ...finding,
    applyToAllExisting: false
  }
  isDeleteFindingModalOpen.value = true
}

const confirmDeleteFinding = async () => {
  try {
    isDeletingFinding.value = true
    await SiteAreaTypesApi.deleteAreaTypeChecklistItem(
      siteId.value, 
      areaTypeId, 
      deletingFinding.value.id,
      { applyToAllExisting: deletingFinding.value.applyToAllExisting }
    )
    await loadAreaType()
    isDeleteFindingModalOpen.value = false
  } catch (error) {
    console.error('Error deleting checklist item:', error)
  } finally {
    isDeletingFinding.value = false
  }
}

onMounted(() => {
  if (siteId.value && areaTypeId) {
    loadAreaType()
  }
})
</script>
