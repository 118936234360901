<template>
  <div v-if="area">
    <div class="p-4 border-b bg-white">
      <div class="flex flex-wrap items-center gap-2">
        <div class="flex flex-wrap items-center gap-1">
          <router-link 
            :to="`/sites/${site.id}`"
            class="flex items-center gap-2 hover:text-primary-600 transition-colors"
          >
            <v-icon class="text-gray-500">mdi-office-building-marker</v-icon>
            <span class="text-gray-600 hidden sm:inline">כל הבניינים</span>
          </router-link>
          <span class="text-gray-400 mx-1">›</span>
          <router-link 
            :to="`/sites/${site.id}/buildings/${building.id}`"
            class="flex items-center gap-2 hover:text-primary-600 transition-colors"
          >
            <v-icon class="text-gray-500">mdi-office-building-outline</v-icon>
            <span class="text-gray-600 hidden sm:inline">{{ building.name }}</span>
          </router-link>
          <span class="text-gray-400 mx-1">›</span>
          <router-link 
            :to="`/sites/${site.id}/levels/${level.id}`"
            class="flex items-center gap-2 hover:text-primary-600 transition-colors"
          >
            <v-icon class="text-gray-500">mdi-floor-plan</v-icon>
            <span class="text-gray-600 hidden sm:inline">{{ level.name }}</span>
          </router-link>
          <span class="text-gray-400 mx-1">›</span>
          <router-link 
            :to="`/sites/${site.id}/areas/${area.id}`"
            class="flex items-center gap-1 hover:text-primary-600 transition-colors"
          >
            <v-icon class="text-gray-500">mdi-home</v-icon>
            <div class="flex items-center gap-1">
              <h1 class="text-md sm:text-xl font-medium">{{ area.name }}</h1>
              <span class="text-gray-500 text-xs">({{ getAreaTypeName(area.site_area_type_id) }})</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <div class="p-4">
      <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4">
        <!-- Add Finding Button -->
        <div class="p-4 border-b flex justify-space-between items-center">
          <div class="flex items-center gap-4">
            <h2 class="text-lg font-medium">
              ממצאים
              <span class="text-gray-500 text-sm">({{ filteredFindings.length }})</span>
            </h2>
          </div>
          <RButton 
            @click="openAddFinding"
            icon-before="mdi-plus"
            color="purple"
          >
            הוסף ממצא
          </RButton>
        </div>

        <!-- Filters and Sorting -->
        <div class="px-4 py-3 border-b bg-gray-50">
          <div class="flex items-center gap-4 justify-between">
            <div class="flex items-center gap-2">
              <v-menu :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <button
                    v-bind="props"
                    class="flex items-center gap-2 text-sm bg-white border rounded-md px-3 py-1.5 hover:border-gray-400 focus:border-primary-500 focus:ring-1 focus:ring-primary-500 transition-colors"
                  >
                    <v-icon size="20" class="text-gray-500">mdi-filter-variant</v-icon>
                    <span>{{ selectedStatuses.length ? `${selectedStatuses.length} סטטוסים` : 'כל הסטטוסים' }}</span>
                  </button>
                </template>
                <div class="bg-white rounded-md shadow-lg py-1 min-w-[160px]">
                  <div 
                    v-for="status in site.statuses" 
                    :key="status.text"
                    @click="toggleStatus(status.text)"
                    class="px-3 py-1 hover:bg-gray-50 cursor-pointer flex items-center gap-2 text-sm"
                  >
                    <v-checkbox
                      v-model="selectedStatuses"
                      :value="status.text"
                      hide-details
                      density="compact"
                      class="my-0"
                    ></v-checkbox>
                    <div class="flex items-center gap-1.5">
                      <div 
                        class="w-2.5 h-2.5 rounded-full border border-gray-300"
                        :class="{
                          'bg-gray-400': status.text === 'לא נבדק',
                          'bg-green-500': status.text === 'תקין',
                          'bg-red-500': status.text === 'לא תקין',
                          'bg-yellow-500': status.text === 'תקין חלקית'
                        }"
                      ></div>
                      <span>{{ status.text }}</span>
                    </div>
                  </div>
                </div>
              </v-menu>
              
              <!-- Added: Display selected statuses -->
              <div class="hidden sm:flex flex-wrap gap-2">
                <span
                  v-for="status in selectedStatuses"
                  :key="status"
                  class="inline-flex items-center gap-1.5 text-sm px-2 py-1 rounded-full cursor-pointer"
                  :class="{
                    'bg-gray-100 text-gray-600': status === 'לא נבדק',
                    'bg-green-100 text-green-600': status === 'תקין',
                    'bg-red-100 text-red-600': status === 'לא תקין',
                    'bg-yellow-100 text-yellow-600': status === 'תקין חלקית'
                  }"
                  @click="toggleStatus(status)"
                >
                  {{ status }}
                  <v-icon size="16">mdi-close</v-icon>
                </span>
              </div>
            </div>
            <div class="flex items-center gap-2">
                <RButtonIcon
                @click="sortDirection = sortDirection === 'asc' ? 'desc' : 'asc'"
                color="gray"
                :icon="sortDirection === 'asc' ? 'mdi-sort-ascending' : 'mdi-sort-descending'"
                />
              <select 
                v-model="sortOrder" 
                class="text-sm bg-white border rounded-md px-3 py-1.5 hover:border-gray-400 focus:border-primary-500 focus:ring-1 focus:ring-primary-500 transition-colors"
              >
                <option value="category">מיון לפי קטגוריה</option>
                <option value="status">מיון לפי סטטוס</option>
              </select>
            </div>
          </div>
        </div>

        <!-- Findings List -->
        <div class="divide-y divide-gray-200">
          <div v-if="!filteredFindings.length" class="p-4 text-center text-gray-500">
            אין ממצאים באזור זה
          </div>
          
          <div 
            v-for="finding in filteredFindings" 
            :key="finding.id"
            class="p-4 flex items-center gap-4 hover:bg-gray-50 transition-colors cursor-pointer"
            @click="openEditFinding(finding)"
          >

            
            <!-- Finding Content -->
            <div class="flex-grow">
              <div class="flex items-center mb-0.5">
                <h3 class="font-medium">{{ finding.category }}</h3>
              </div>
              <p class="text-sm text-gray-600 line-clamp-2">{{ finding.description }}</p>
              
              <div v-if="finding.responsibility || finding.location" class="flex flex-col gap-1 mt-2">
                <div class="flex gap-2 text-sm text-gray-600">
                  <div v-if="finding.responsibility" class="flex items-center gap-0">
                    <v-icon size="16" class="text-gray-500">mdi-account-hard-hat</v-icon>
                    {{ finding.responsibility }}
                  </div>
                  <div v-if="finding.responsibility && finding.location" class="text-gray-300">•</div>
                  <div v-if="finding.location" class="flex items-center gap-0">
                    <v-icon size="16" class="text-gray-500">mdi-map-marker</v-icon>
                    {{ finding.location }}
                  </div>
                </div>
              </div>

              <!-- Modified Images Grid -->
              <div v-if="finding.images?.length" class="mt-2 flex gap-2">
                <div 
                  v-for="(image, index) in finding.images.slice(0, 3)" 
                  :key="image.id" 
                  class="w-12 h-12 rounded-md overflow-hidden bg-gray-100 relative"
                >
                  <img 
                    :src="image.url" 
                    :alt="finding.category"
                    class="w-full h-full object-cover"
                  />
                  <div 
                    v-if="index === 2 && finding.images.length > 3" 
                    class="absolute inset-0 bg-black/50 flex items-center justify-center text-white font-medium"
                  >
                    +{{ finding.images.length - 3 }}
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Status Badge -->
            <div class="flex-shrink-0">
              <span v-if="finding.status" class="text-sm px-2 py-1 rounded-full" :class="{
                'bg-gray-100 text-gray-600': finding.status === 'לא נבדק',
                'bg-green-100 text-green-600': finding.status === 'תקין',
                'bg-red-100 text-red-600': finding.status === 'לא תקין',
                'bg-yellow-100 text-yellow-600': finding.status === 'תקין חלקית'
              }">
                {{ finding.status }}
              </span>
              <span v-else class="text-sm px-2 py-1 rounded-full bg-gray-100 text-gray-600">לא נבדק</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Finding Modal -->
    <FindingModal
      v-model="showFindingModal"
      :finding="selectedFinding"
      :action="modalAction"
      @submit="handleFindingSubmit"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useSite } from '@/composables/useSite'
import SiteAreasApi from '@/api/SiteAreasApi'
import FindingModal from './components/FindingModal.vue'
import SiteFindingsApi from '@/api/SiteFindingsApi'
import { useToast } from 'vue-toastification'
import { toastMessageFromResponse } from '@/common/helpers/response-helpers'

const route = useRoute()
const router = useRouter()
const { site, getAreaTypeName } = useSite()
const toast = useToast()

const building = computed(() => {
  return site.value?.buildings.find(b => 
    b.levels?.some(l => l.areas?.some(a => a.id === route.params.areaId))
  )
})

const level = computed(() => {
  return building.value?.levels?.find(l => 
    l.areas?.some(a => a.id === route.params.areaId)
  )
})

const area = ref(null)
const loading = ref(true)
const error = ref(null)

// Modal state
const showFindingModal = ref(false)
const selectedFinding = ref(null)
const modalAction = ref('add')

// Replace statusFilter ref with these new refs
const selectedStatuses = ref([])

const sortOrder = ref('category')
const sortDirection = ref('asc')

const toggleStatus = (status) => {
  const index = selectedStatuses.value.indexOf(status)
  if (index === -1) {
    selectedStatuses.value.push(status)
  } else {
    selectedStatuses.value.splice(index, 1)
  }
}

// Add computed property for filtered and sorted findings
const filteredFindings = computed(() => {
  let findings = area.value?.findings || []
  
  // Apply status filter
  if (selectedStatuses.value.length > 0) {
    findings = findings.filter(f => selectedStatuses.value.includes(f.status))
  }
  
  // Apply sorting
  return [...findings].sort((a, b) => {
    let comparison = 0
    if (sortOrder.value === 'status') {
      // Handle null/undefined status values
      const statusA = a.status || ''
      const statusB = b.status || ''
      comparison = statusA.localeCompare(statusB)
    } else {
      // Handle null/undefined category values
      const categoryA = a.category || ''
      const categoryB = b.category || ''
      comparison = categoryA.localeCompare(categoryB)
    }
    return sortDirection.value === 'desc' ? -comparison : comparison
  })
})

// Modal methods
const openAddFinding = () => {
  selectedFinding.value = {
    site_area_id: area.value.id,
    site_area_type_id: area.value.site_area_type_id,
    site_level_id: area.value.site_level_id,
    status: 'לא נבדק'
  }
  modalAction.value = 'add'
  showFindingModal.value = true
}

const openEditFinding = (finding) => {
  selectedFinding.value = { ...finding }
  modalAction.value = 'edit'
  showFindingModal.value = true
}

const handleFindingSubmit = async (findingData) => {
  try {
    if (modalAction.value === 'add') {
      await SiteFindingsApi.createFinding(site.value.id, area.value.id, findingData)
    } else {
      await SiteFindingsApi.updateFinding(site.value.id, findingData.id, findingData)
    }
    // Refresh area data
    const response = await SiteAreasApi.getArea(site.value.id, area.value.id)
    area.value = response.data
    showFindingModal.value = false
  } catch (e) {
    error.value = e.message || 'שגיאה בשמירת הממצא'
    toast.error(toastMessageFromResponse(e), 'שגיאה בשמירת הממצא');
  }
}

onMounted(async () => {
  try {
    const areaId = route.params.areaId
    const siteId = site.value.id
    const response = await SiteAreasApi.getArea(siteId, areaId)
    area.value = response.data
  } catch (e) {
    error.value = e.message || 'האזור לא נמצא'
  } finally {
    loading.value = false
  }
})
</script> 