import { computed } from 'vue'
import { useStore } from 'vuex'

export function useUserRole() {
    const store = useStore()
    
    const user = computed(() => {
        const storeUser = store.state.auth?.user
        return storeUser
    })
    
    const getCurrentRoleName = () => {
        if (user.value) {
            let currentUserRoleId = user.value.role_id;
            let currentUserRoleName = null;
            
            switch (currentUserRoleId) {
                  case 2:
                    currentUserRoleName = "admin"
                    break
                  case 3:
                    currentUserRoleName = "user"
                    break
                  case 4:
                    currentUserRoleName = "owner"
                    break
            }
            
            return currentUserRoleName;
        }
        return null;
    }

    return {
        getCurrentRoleName
    }
}