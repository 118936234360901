<template>
  <div
    v-bind="$attrs"
    :class="[
      'inline-flex items-center rounded-md px-2 py-1 text-sm font-medium',
      sm ? 'px-2 py-0.5 text-xs' : '',
      colorClasses[color] || colorClasses.purple
    ]"
  >
    <slot></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  color: {
    type: String,
    default: 'purple',
    validator: (value) => {
      return ['purple', 'blue', 'green', 'red', 'yellow', 'gray', 'black'].includes(value)
    }
  },
  sm: {
    type: Boolean,
    default: false
  }
})

const colorClasses = {
  purple: 'bg-purple-100 text-purple-700',
  blue: 'bg-blue-100 text-blue-700',
  green: 'bg-green-100 text-green-700',
  red: 'bg-red-100 text-red-700',
  yellow: 'bg-yellow-100 text-yellow-700',
  gray: 'bg-gray-100 text-gray-700'
}
</script>
