async function getCompanyReports(params) {
  const response = await window.axios.get(
    "/api/v1/company/reports/search",
    params
  );
  return response.data;
}

async function getCompanySettings() {
  const response = await window.axios.get("/api/v1/company/settings");
  return response.data;
}

async function updateCompanySettings(companySettings) {
  const response = await window.axios.put(
    "/api/v1/company/settings",
    companySettings
  );
  return response.data;
}

async function getCompanyFeatures() {
  const response = await window.axios.get("/api/v1/company/features");
  return response.data;
}

async function enableCompanyFeature(featureId) {
  const response = await window.axios.post(
    `/api/v1/company/features/enable`,
    {
      featureId,
    }
  );
  return response.data;
}

async function disableCompanyFeature(featureId) {
  const response = await window.axios.post(
    `/api/v1/company/features/disable`,
    {
      featureId,
    }
  );
  return response.data;
}

export default {
  getCompanyReports,
  getCompanySettings,
  updateCompanySettings,
  getCompanyFeatures,
  enableCompanyFeature,
  disableCompanyFeature,
};
