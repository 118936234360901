<template>
  <div v-if="level">
    <!-- Add header section similar to SiteBuilding -->
    <div class="p-4 border-b bg-white">
      <div class="flex flex-wrap items-center gap-2">
        <div class="flex flex-wrap items-center gap-1">
          <router-link 
            :to="`/sites/${site.id}`"
            class="flex items-center gap-2 hover:text-primary-600 transition-colors"
          >
            <v-icon class="text-gray-500">mdi-office-building-marker</v-icon>
            <span class="text-gray-600 hidden sm:inline">כל הבניינים</span>
          </router-link>
          <span class="text-gray-400 mx-1">›</span>
          <router-link 
            :to="`/sites/${site.id}/buildings/${building.id}`"
            class="flex items-center gap-2 hover:text-primary-600 transition-colors"
          >
            <v-icon class="text-gray-500">mdi-office-building-outline</v-icon>
            <span class="text-gray-600 hidden sm:inline">{{ building.name }}</span>
          </router-link>
          <span class="text-gray-400 mx-1">›</span>
          <router-link 
            :to="`/sites/${site.id}/levels/${level.id}`"
            class="flex items-center gap-1 hover:text-primary-600 transition-colors"
          >
            <v-icon class="text-gray-500">mdi-floor-plan</v-icon>
            <h1 class="text-md sm:text-xl font-medium">{{ level.name }}</h1>
          </router-link>
        </div>
      </div>
    </div>

    <!-- Update main content section -->
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
      <div v-if="!level.areas.length" class="text-center text-gray-500">
        אין דירות בקומה זו
      </div>
      <router-link 
        v-else
        v-for="area in sortedAreas"
        :key="area.id"
        :to="`/sites/${site.id}/areas/${area.id}`"
        class="bg-white rounded-lg shadow hover:shadow-md transition-shadow p-4 border border-gray-200"
      >
        <div class="flex flex-col items-center gap-2 text-center">
          <div class="relative">
            <v-icon class="text-gray-500" size="32">mdi-home</v-icon>
            <div v-if="area.findings?.length" 
                 class="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
              {{ area.findings.length }}
            </div>
          </div>
          <div class="space-y-1">
            <h3 class="text-lg font-medium">{{ area.name }}</h3>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { useSite } from '@/composables/useSite'

const route = useRoute()
const { site } = useSite()

const level = computed(() => {
  console.log('Site value:', site.value)
  console.log('Level ID:', route.params.levelId)
  // Search through all buildings to find the level
  const foundLevel = site.value?.buildings?.reduce((found, building) => {
    if (found) return found
    return building.levels?.find(l => l.id === route.params.levelId)
  }, null)
  console.log('Found level:', foundLevel)
  return foundLevel
})

const building = computed(() => {
  return site.value?.buildings.find(b => 
    b.levels?.some(l => l.id === route.params.levelId)
  )
})

const sortedAreas = computed(() => {
  if (!level.value?.areas) return []
  return [...level.value.areas].sort((a, b) => a.order - b.order)
})
</script> 