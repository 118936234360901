<template>
  <div class="mx-auto h-full px-0 py-5 max-w-[1460px]" v-if="isLoaded">
    <div class="mb-8">
      <div class="flex flex-col mb-6 px-5 mt-2">
        <h1 class="text-xl font-bold text-gray-800">היי, {{ $store.state.auth.user.name }}</h1>
        <p v-if="totalReportsForEachStatus.length" class="text-sm text-gray-500 mt-1">
          מבט מהיר על מצב הדוחות שלך לפי סטטוס
          <v-icon
              icon="mdi-information"
              size="small"
              class="ml-1"
              v-tooltip="'הנתונים כאן מתעדכנים אחת ל-10 דקות'"
          />
        </p>
      </div>

      <div v-if="totalReportsForEachStatus.length" class="px-5 overflow-x-auto pb-2">
        <div class="grid grid-cols-2 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-4 gap-3 md:gap-4">
          <div v-for="stat in totalReportsForEachStatus"
              :key="stat.status"
              class="rounded-lg md:rounded-xl p-4 md:p-4 bg-white border-[1px]"
              :class="[
                  colorToBorderClassMap[stat.color],
                  stat.status === 'ללא סטטוס' ? 'hover:bg-gray-50' : '',
                  'cursor-pointer hover:bg-gray-50'
                ]"
              @click="handleStatusClick(stat)"
          >
            <div class="flex flex-col text-right">
              <div class="flex items-center justify-start gap-1 mb-0">
                <h3 class="text-xl md:text-2xl font-bold" :class="colorToTextClassMap[stat.color]">
                  <span v-if="statsLoading">...</span>
                  <span v-else>{{ stat.total }}</span>
                </h3>
                <span class="text-sm md:text-sm font-medium" :class="colorToTextClassMap[stat.color]">
                  ({{ Math.round(stat.percentage) }}%)
                </span>
              </div>
              <p class="text-sm md:text-base font-medium" :class="colorToTextClassMap[stat.color]">
                {{ stat.status }}
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <HomeTrialBanner/>
    <HomeScheduleDemo/>


    <div v-if="company.isTrial" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-10 gap-5 px-5 mb-5">
      <div v-if="isSelfSignup" class="lg:col-span-7">
        <FirstReportTour/>
      </div>
      <div class="lg:col-span-3">
        <HomePrimaryVideoCard/>
      </div>
    </div>

    <div class="mb-5 px-5">
      <!-- Modern tabs design -->
      <div class="mb-6">
        <div class="border-b border-gray-200">
          <div class="max-w-2xl">
            <nav class="flex -mb-px space-x-4 rtl:space-x-reverse">
              <button
                  @click="activeTab = 'reports'"
                  :class="[
                  'relative py-4 px-2 transition-all duration-200 flex items-center gap-2',
                  activeTab === 'reports' ? 'text-purple-600' : 'text-gray-500 hover:text-gray-700'
                ]"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"/>
                </svg>
                <span class="font-medium text-base">דוחות</span>
                <!-- Animated bottom line -->
                <span
                    :class="[
                    'absolute bottom-0 left-0 w-full h-0.5 transition-transform duration-200',
                    activeTab === 'reports' ? 'bg-purple-600 scale-x-100' : 'bg-transparent scale-x-0'
                  ]"
                    aria-hidden="true"
                />
              </button>

              <button
                  @click="activeTab = 'projects'"
                  :class="[
                  'relative py-4 px-2 transition-all duration-200 flex items-center gap-2',
                  activeTab === 'projects' ? 'text-purple-600' : 'text-gray-500 hover:text-gray-700'
                ]"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"/>
                </svg>
                <span class="font-medium text-base">פרויקטים</span>
                <!-- Animated bottom line -->
                <span
                    :class="[
                    'absolute bottom-0 left-0 w-full h-0.5 transition-transform duration-200',
                    activeTab === 'projects' ? 'bg-purple-600 scale-x-100' : 'bg-transparent scale-x-0'
                  ]"
                    aria-hidden="true"
                />
              </button>
            </nav>
          </div>
        </div>
      </div>

      <!-- Tab panels with enhanced transition -->
      <div class="mt-4 -mx-5 md:mx-0">
        <transition
            name="fade"
            mode="out-in"
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1"
        >
          <ReportsList
              v-if="activeTab === 'reports'"
          />
          <ProjectsList
              :title="'📓 פרויקטים'"
              v-else-if="activeTab === 'projects'"
          />
        </transition>
      </div>
    </div>
  </div>
  <PageLoader v-else/>
</template>

<script setup>
import {ref, onMounted, computed, watch} from 'vue'
import {useStore} from 'vuex'
import HomeTrialBanner from "./components/HomeTrialBanner.vue"
import HomeScheduleDemo from "./components/HomeScheduleDemo.vue"
import ProjectsList from "@/components/ProjectsList.vue"
import HomePrimaryVideoCard from "./components/HomePrimaryVideoCard.vue"
import ReportsList from "@/components/ReportsList.vue"
import StatsApi from '@/api/StatsApi'
import {colorToTextClassMap, colorToBorderClassMap} from '@/common/constants'
import PageLoader from "@/components/PageLoader.vue"
import {useToast} from 'vue-toastification'
import posthog from 'posthog-js'
import {useRouter} from 'vue-router'
import FirstReportTour from "@/views/home/components/FirstReportTour.vue"

// State
const store = useStore()
const isLoaded = ref(false)
const reports = ref([])
const activeTab = ref('reports')
const statsLoading = ref(true)
const totalReportsForEachStatus = ref([])
const toast = useToast()

// Add router
const router = useRouter()


// Computed
const company = computed(() => store.getters['auth/company'])
const isSelfSignup = computed(() => store.getters['auth/isSelfSignup'])

const reportStatusesOptions = computed(() => {
  return store.getters['auth/reportStatuses'].map(status => ({
    value: status.id,
    text: status.status
  }))
})

// Fetch stats
const fetchStats = async () => {
  statsLoading.value = true
  try {
    const {data} = await StatsApi.getStats()
    totalReportsForEachStatus.value = data.totalReportsForEachStatus
  } catch (error) {
    console.error('Error fetching stats:', error)
  } finally {
    statsLoading.value = false
    isLoaded.value = true
  }
}

// Add handleStatusClick method
const handleStatusClick = (stat) => {
  if (stat.status === 'ללא סטטוס') {
    return false;
  } else {
    posthog.capture("log:home_stats_status_clicked");
    router.push({
      path: '/reports',
      query: {
        status_id: stat.status_id
      }
    })
  }
}

// Lifecycle
onMounted(() => {
  fetchStats()
  store.dispatch("reportTemplates/fetchReportTemplates")
})
</script>

<style scoped>
input, select {
  border-bottom-width: 2px !important;
  border-bottom-style: solid !important;
}
</style>
