import { Utils } from "@/common/Utils";
import ReportsApi from "@/api/ReportsApi";
import { SET_REPORT, UPDATE_REPORT_STATE } from "./mutations";
import { useBackgroundSync } from "@/composables/useBackgroundSync";
import posthog from 'posthog-js';

const backgroundSync = useBackgroundSync();
const debounceDoUpdateV2 = Utils.debounceV2(doUpdateV2);
const debounceDoUpdateManyV2 = Utils.debounceV2(doUpdateManyV2);

export const doUpdate = (context, ...args) => {
    return debounceDoUpdateV2(context, ...args);
};

export const doUpdateMany = (context, ...args) => {
    return debounceDoUpdateManyV2(context, ...args);
};

export const getReport = async ({ commit }, payload) => {
    try {
        const data = await ReportsApi.getReport(payload.reportId);
        if (!data.defectsGroupedBy) {
            data.defectsGroupedBy = 'category';
        }
        commit(SET_REPORT, data);
    } catch (err) {
        console.error('Had issues fetching report', err);
        throw err;
    }
};

async function doUpdateV2({ commit, state, rootState }, payload) {
    if (Utils.isObjectEmpty(payload.value)) {
        payload.value = null;
    }
    const data = { [payload.key]: payload.value };

    const message = _createMessage({
        report: state.report,
        user: rootState.auth.user,
        data
    });

    try {
        await backgroundSync.addMessage(message);

        commit(UPDATE_REPORT_STATE, { data: message.data });
    } catch (error) {
        const logMsg = JSON.parse(JSON.stringify(message));
        delete logMsg.data;
        posthog.capture('error:failed_to_queue_message', { err: error, data: logMsg, function: 'doUpdateV2' });
    }
}

async function doUpdateManyV2({ commit, state, rootState }, payload) {
    for (const [key, value] of Object.entries(payload.data)) {
        if (Utils.isObjectEmpty(value)) {
            payload.data[key] = null;
        }
    }

    const message = _createMessage({ 
        report: state.report, 
        user: rootState.auth.user, 
        data: payload.data 
    });
    
    commit(UPDATE_REPORT_STATE, { data: message.data });

    try {
        await backgroundSync.addMessage(message);
    } catch (error) {
        const logMsg = JSON.parse(JSON.stringify(message));
        delete logMsg.data;
        posthog.capture('error:failed_to_queue_message', { err: error, data: logMsg, function: 'doUpdateManyV2' });
    }
}

function _createMessage({ report, user, data }) {
    return {
        company_id: report.company_id,
        user_id: user.id,
        dataset: "reports",
        dataset_id: report.id,
        data,
        action: 'do-update',
        timestamp: Utils.currentTimestamp(),
        report_address: report.address,
        report_number: report.number,
    };
}
