<template>
  <div class="flex flex-col gap-1 position-relative">
    <div class="flex justify-end items-center position-absolute top-[-20px] left-[5px]">
      <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
      >
        <template #activator="{ props }">
          <span
              v-bind="props"
              :class="{ 'opacity-50': disabled }"
              class="text-decoration-underlin2e text-xs d-flex align-center cursor-pointer"
              :style="{ pointerEvents: disabled ? 'none' : 'auto' }"
          >
            <i class="mdi mdi-calendar me-1"></i>
            לוח שנה
          </span>
        </template>
        <v-date-picker
            v-model="selectedDate"
            show-adjacent-months
            no-title
            scrollable
            color="primary"
            locale="he-IL"
            :hide-header=true

            @update:model-value="handleDatePickerChange"
        >
        </v-date-picker>
      </v-menu>
    </div>

    <RFieldText
        type="text"
        :modelValue="modelValue"
        @update:modelValue="handleInputChange"
        class="w-full"
        :disabled="disabled"
    />

    <div class="flex gap-2 mt-2 flex-wrap">
      <RButton
          sm
          :outlined="modelValue !== 'מיידי'"
          color="black"
          @click="setText('מיידי')"
          :disabled="disabled"
      >
        מיידי
      </RButton>
      <RButton
          sm
          :outlined="modelValue !== 'לא נדרש'"
          color="black"
          @click="setText('לא נדרש')"
          :disabled="disabled"
      >
        לא נדרש
      </RButton>
      <RButton
          sm
          :outlined="!isNextWeek"
          color="black"
          @click="setNextWeek"
          :disabled="disabled"
      >
        עוד שבוע
      </RButton>
      <RButton
          sm
          :outlined="!isNextMonth"
          color="black"
          @click="setNextMonth"
          :disabled="disabled"
      >
        עוד חודש
      </RButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RFieldDatePicker2',
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
      selectedDate: null,
    }
  },
  computed: {
    isNextWeek() {
      if (!this.isDateFormat(this.modelValue)) return false;
      const nextWeek = new Date();
      nextWeek.setDate(nextWeek.getDate() + 7);
      return this.formatDate(nextWeek) === this.modelValue;
    },
    isNextMonth() {
      if (!this.isDateFormat(this.modelValue)) return false;
      const nextMonth = new Date();
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      return this.formatDate(nextMonth) === this.modelValue;
    }
  },
  methods: {
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    handleInputChange(value) {
      this.$emit('update:modelValue', value);
    },
    handleDatePickerChange(value) {
      if (!value) return;

      try {
        // If value is a Date object
        if (value instanceof Date) {
          const formattedDate = this.formatDate(value);
          this.$emit('update:modelValue', formattedDate);
          this.menu = false;
          this.selectedDate = null;
          return;
        }

        // If value is a string in YYYY-MM-DD format
        if (typeof value === 'string' && value.includes('-')) {
          const [year, month, day] = value.split('-');
          const formattedDate = `${day}/${month}/${year}`;
          this.$emit('update:modelValue', formattedDate);
          this.menu = false;
          this.selectedDate = null;
          return;
        }

        throw new Error(`Unexpected value format: ${value}`);
      } catch (e) {
        console.error('Date conversion error:', e);
        console.error('Value that caused error:', value);
      }
    },
    setText(value) {
      this.$emit('update:modelValue', value);
    },
    isDateFormat(value) {
      return /^\d{2}\/\d{2}\/\d{4}$/.test(value);
    },
    setNextWeek() {
      const nextWeek = new Date();
      nextWeek.setDate(nextWeek.getDate() + 7);
      this.$emit('update:modelValue', this.formatDate(nextWeek));
    },
    setNextMonth() {
      const nextMonth = new Date();
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      this.$emit('update:modelValue', this.formatDate(nextMonth));
    },
  },
}
</script>

<style scoped>
.field-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.field-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.datepicker-wrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}

.datepicker-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
}

.datepicker-input:focus {
  outline: none;
  border-color: #2563eb;
  ring: 2px;
  ring-color: #93c5fd;
}

.quick-action-btn {
  padding: 0.5rem 1rem;
  background-color: #f3f4f6;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #374151;
  cursor: pointer;
  transition: all 0.2s;
}

.quick-action-btn:hover:not(:disabled) {
  background-color: #e5e7eb;
}

.quick-action-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

dialog {
  border: none;
  background: white;
}

dialog::backdrop {
  background: rgba(0, 0, 0, 0.3);
}
</style>
