export const toastMessageFromResponse = (e, defaultMessage = "שגיאה") => {
    let toastMessage = "";

    if (e.response && e.response.data && e.response.data.errors) {
        const errorMessages = [];

        const errors = e.response.data.errors;
        for (const key in errors) {
            if (Object.prototype.hasOwnProperty.call(errors, key)) {
                const errorValue = errors[key];
                errorMessages.push(`${errorValue}`);
            }
        }

        if (errorMessages.length > 0) {
            toastMessage += errorMessages.join("\n");
        }
    }

    if (
        e.response &&
        e.response.data &&
        e.response.data.success === false &&
        e.response.data.message
    ) {
        if (!toastMessage) {
            toastMessage = e.response.data.message;
        }
    }

    if (!toastMessage) {
        toastMessage = defaultMessage;
    }

    return toastMessage;
}