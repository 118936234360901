<template>
  <div>
    <div class="rp-table__header">
      <div class="rp-table__header-label">
        {{ label }}
        <small>({{ $ItemsUtils.getTotalValidItems(rows) }})</small>
      </div>

      <div class="rp-table__header-actions">
        <RSearchCompact v-model="searchQuery"/>
        <RActions>
          <RAction v-if="!showDisabledRows" icon="mdi-eye" @click="showDisabledRows = true" text="הצג שורות כבויות"/>
          <RAction v-if="showDisabledRows" icon="mdi-eye-off" @click="showDisabledRows = false" text="הסתר שורות כבויות"/>
          <RAction v-if="!userCanDragRows" icon="mdi-reorder-horizontal" @click="userCanDragRows = true; sortBy = null;" text="גרירת שורות"/>
          <RAction v-if="userCanDragRows" icon="mdi-checkbox-marked-outline" @click="userCanDragRows = false;" text="בחירה מרובה"/>
        </RActions>
        <!--
        <div class="d-flex u-gap-2">
          <RButton
            v-if="!userCanDragRows"
            xs
            color="grey"
            outlined
            icon-before="mdi-reorder-horizontal"
            @click="
              userCanDragRows = true;
              sortBy = null;
            "
          >
            גרירת שורות
          </RButton>
          <RButton
            v-if="userCanDragRows"
            xs
            color="grey"
            outlined
            icon-before="mdi-checkbox-marked-outline"
            @click="userCanDragRows = false"
          >
            בחירה מרובה
          </RButton>
        </div>
        -->
      </div>
    </div>

    <div class="rp-table__bigWrapper">
      <div class="rp-table__wrapper">
        <table class="rp-table" cellpadding="0" cellspacing="0"
            :class="{ 'hide-disabled-rows': !showDisabledRows }"
        >
          <thead>
          <tr>
            <th
                class="td-checkbox"
                :class="{
                  'u-pointer-events-none': isDraggingEnabled,
                }"
                style="width: 45px"
                @click="allRowsCheckboxClicked"
            >
              <template v-if="!userCanDragRows">
                <RIcon v-if="areAllRowsSelected">mdi-checkbox-marked</RIcon>
                <RIcon v-else>mdi-checkbox-blank-outline</RIcon>
              </template>
            </th>
            <th
                v-for="column in columns"
                :key="column.id"
                class="text-right"
                :class="{
                  'is-sort-active': sortBy === column.id,
                }"
                :style="{
                  width: column.settings.columnWidth + '%',
                }"
                @click="setSortByColumn(column.id)"
            >
              {{ column.settings.label }}

              <RIcon v-if="!sortBy || sortBy !== column.id" class="mr-1" small
              >mdi-sort
              </RIcon
              >

              <RIcon
                  v-if="
                    sortBy && sortBy === column.id && sortDirection === 'ASC'
                  "
                  class="mr-1"
                  small
              >mdi-sort-ascending
              </RIcon
              >
              <RIcon
                  v-if="
                    sortBy && sortBy === column.id && sortDirection === 'DESC'
                  "
                  class="mr-1"
                  small
              >mdi-sort-descending
              </RIcon
              >
            </th>
          </tr>
          </thead>

          <draggable
              v-bind="dragOptions"
              handle=".td-handle"
              :list="rowsSorted"
              tag="tbody"
              item-key="id"
              @change="onDragChange"
          >
            <template #item="{ element: row }">
              <tr
                  :key="row.id"
                  :class="{
                  deleted: row.deletedAt,
                  disabled: !row.isActive,
                  'not-in-search': !isRowInSearch(row),
                  selected: isRowSelected(row),
                }"
              >
                <td
                    :class="{
                    'td-handle': isDraggingEnabled,
                    'td-checkbox': !isDraggingEnabled,
                  }"
                    @click="rowCheckboxClicked(row)"
                >
                  <span v-if="userCanDragRows" class="mycounter"></span>
                  <template v-else>
                    <RIcon v-if="isRowSelected(row)">mdi-checkbox-marked</RIcon>
                    <RIcon v-else>mdi-checkbox-blank-outline</RIcon>
                  </template>
                </td>
                <td
                    v-for="column in columns"
                    :key="column.id"
                    :style="getColumnCss(column, row)"
                    @click="editRow(row)"
                >
                  <template v-if="column.type === 'text'">
                    {{ printText(row.columns[column.id], column.id) }}
                  </template>
                  <template v-if="column.type === 'datePicker'">
                    {{ printText(row.columns[column.id], column.id) }}
                  </template>
                  <template v-if="column.type === 'textChoices'">
                    {{ printText(row.columns[column.id], column.id) }}
                  </template>
                  <template v-if="column.type === 'wysiwyg'">
                    {{ printText(row.columns[column.id], column.id) }}
                  </template>
                  <template
                      v-if="column.type === 'files' || column.type === 'images'"
                  >
                    <template
                        v-if="
                        row.columns[column.id] &&
                        $ItemsUtils.getTotalValidItems(row.columns[column.id])
                      "
                    >
                      <RIcon size="20">mdi-check</RIcon>
                      <span style="font-size: 12px">
                        ({{
                          $ItemsUtils.getTotalValidItems(
                              row.columns[column.id]
                          )
                        }})
                      </span>
                    </template>
                  </template>
                  <template v-if="column.type === 'image'">
                    <RIcon v-if="row.columns[column.id]" size="20"
                    >mdi-check
                    </RIcon
                    >
                  </template>
                  <template v-if="column.type === 'signature'">
                    <RIcon v-if="row.columns[column.id]" size="20"
                    >mdi-check
                    </RIcon
                    >
                  </template>
                </td>
              </tr>
            </template>
          </draggable>

          <tfoot>
          <tr>
            <td class="u-pointer-events-none td-checkbox">
              <RIcon v-show="!isDraggingEnabled" style="opacity: 0.2"
              >mdi-checkbox-blank-outline
              </RIcon
              >
            </td>
            <td :colspan="columns.length" @click="addRow()">
              <span>+ הוספת שורה</span>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>

      <div v-if="totalSelectedRows > 0" class="rp-table__floatingSelected">
        <div
            class="d-flex align-center u-gap-2 justify-space-between rp-table__floatingSelected__inner"
        >
          <div class="rp-table__floatingSelected__title">
            {{ totalSelectedRows }}
            שורות נבחרו
          </div>
          <div class="d-flex justify-end u-gap-2">
            <RButton
                icon-before="mdi-delete"
                sm
                color="red"
                outlined
                @click="removeSelectedRows"
            >
              מחיקה
            </RButton>
            <RButton
                icon-before="mdi-pause"
                sm
                color="grey"
                outlined
                @click="setSelectedRowsActive(false)"
            >
              כיבוי
            </RButton>
            <RButton
                icon-before="mdi-eye"
                sm
                color="green"
                outlined
                @click="setSelectedRowsActive(true)"
            >
              הדלקה
            </RButton>
            <RButton
                icon-before="mdi-content-copy"
                sm
                color="blue"
                outlined
                @click="duplicateSelectedRows"
            >
              שכפול
            </RButton>
            <RButtonIcon
                class="rp-table__floatingSelected__close"
                icon="mdi-close"
                @click="clearSelectedRows"
            />
          </div>
        </div>
      </div>
    </div>

    <RModal
        v-model="rowModal.isOpen"
        :title="
        rowModal.mode === 'edit' ? 'עריכת שורה בטבלה' : 'הוספת שורה לטבלה'
      "
    >
      <div>
        <div class="rp-report__items">
          <div
              v-for="column in columns"
              :key="column.id"
              class="rp-report__item rp-report__item--smallGap"
          >
            <template v-if="column.type === 'text'">
              <report-label :label="column.settings.label"/>
              <RFieldText
                  class="mb-3"
                  v-model="rowModal.row.columns[column.id]"
              />
            </template>

            <template v-if="column.type === 'textChoices'">
              <report-label :label="column.settings.label"/>

              <RFieldTextChoices
                  v-model="rowModal.row.columns[column.id]"
                  :multiple="column.settings.multiple"
                  :choices="column.settings.options"
              />
            </template>

            <template v-if="column.type === 'datePicker'">
              <report-label :label="column.settings.label"/>

              <RFieldDatePicker2
                  v-model="rowModal.row.columns[column.id]"
                  label="תאריך"
                  placeholder="בחרו תאריך"
              />
            </template>

            <template v-if="column.type === 'wysiwyg'">
              <RFieldWysiwyg
                  v-model="rowModal.row.columns[column.id]"
                  :label="column.settings.label"
              />
            </template>

            <template v-if="column.type === 'image'">
              <report-image
                  :label="column.settings.label"
                  :load-image-url="rowModal.row.columns[column.id]"
                  :update-key="`${column.id}`"
                  @do-update="doUpdateImage"
              />
            </template>

            <template v-if="column.type === 'signature'">
              <report-signature
                  :label="column.settings.label"
                  :load-signature-url="rowModal.row.columns[column.id]"
                  :update-key="`${column.id}`"
                  @do-update="doUpdateSignature"
              />
            </template>

            <template v-if="column.type === 'images'">
              <ReportImages
                  :label="column.settings.label"
                  :load-list="rowModal.row.columns[column.id]"
                  :edit-image-mode="column.settings.editImage"
                  :update-key="`${column.id}`"
                  @do-update="doUpdateImages"
                  @do-update-many="doUpdateManyImages"
                  :max-images="20"
              />
            </template>

            <template v-if="column.type === 'files'">
              <report-files
                  :label="column.settings.label"
                  :load-list="rowModal.row.columns[column.id]"
                  :update-key="`${column.id}`"
                  @do-update="doUpdateFiles"
                  @do-update-many="doUpdateManyFiles"
              />
            </template>
          </div>
        </div>

        <div class="d-flex flex-wrap mt-5" style="gap: 5px">
          <RButton
              v-if="rowModal.row.isActive && rowModal.mode === 'edit'"
              color="grey"
              icon-before="mdi-pause"
              outlined
              sm
              @click="saveAndDisableRow"
          >
            כיבוי שורה
          </RButton>

          <RButton
              v-if="!rowModal.row.isActive"
              color="green"
              sm
              icon-before="mdi-toggle-switch"
              outlined
              @click="saveRowModal"
          >
            הפעלת שורה
          </RButton>

          <br/>

          <RButton
              sm
              color="red"
              icon-before="mdi-delete"
              outlined
              @click="removeRow()"
          >
            מחיקת שורה
          </RButton>
        </div>
        <RButton class="mt-5" lg block @click="saveRowModal"> שמירה</RButton>
      </div>
    </RModal>
  </div>
</template>

<script>
import {onUnmounted} from "vue";
import {generateUUID} from "@/common/helpers/uuid";
import {dragOptions} from "@/common/settings";
import draggable from "vuedraggable";
import {Utils} from "@/common/Utils";
import {mapActions} from "vuex";
import ReportImages from "@/views/reports/components/ReportImages";
import ReportImage from "@/views/reports/components/ReportImage";
import ReportSignature from "@/views/reports/components/ReportSignature";
import ReportLabel from "@/views/reports/components/ReportLabel";
import ReportFiles from "@/views/reports/components/ReportFiles.vue";
import RFieldWysiwyg from "@/ui/RFieldWysiwyg.vue";
import RFieldDatePicker2 from "@/ui/RFieldDatePicker2.vue";

export default {
  components: {
    RFieldWysiwyg,
    RFieldDatePicker2,
    ReportFiles,
    draggable,
    ReportLabel,
    ReportImages,
    ReportImage,
    ReportSignature,
  },
  props: {
    loadRows: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      },
    },
    label: {
      type: [Number, String],
      default: () => {
        return "";
      },
    },
    updateKey: {
      type: String,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      sortDirection: "ASC",
      sortBy: null,
      id: this._uid,
      userCanDragRows: false,
      showDisabledRows: true,
      searchQuery: null,
      modalId: Utils.guid(),
      rows: [],
      dragOptions,
      selectedRowsIds: [],
      rowModal: {
        // modalReady: false,
        isOpen: false,
        mode: "add",
        row: {
          columns: {},
        },
      },
    };
  },
  computed: {
    rowsSorted() {
      if (!this.sortBy) {
        return this.rows;
      }

      let rowsSorted = [...this.rows].sort((a, b) => {
        if (
            a.columns[this.sortBy] === null ||
            a.columns[this.sortBy] === undefined
        ) {
          return this.sortDirection === "ASC" ? 1 : -1;
        }
        if (
            b.columns[this.sortBy] === null ||
            b.columns[this.sortBy] === undefined
        ) {
          return this.sortDirection === "ASC" ? -1 : 1;
        }

        if (this.sortDirection === "ASC") {
          if (a.columns[this.sortBy] < b.columns[this.sortBy]) {
            return -1;
          }
          if (a.columns[this.sortBy] > b.columns[this.sortBy]) {
            return 1;
          }
          return 0;
        } else if (this.sortDirection === "DESC") {
          if (a.columns[this.sortBy] > b.columns[this.sortBy]) {
            return -1;
          }
          if (a.columns[this.sortBy] < b.columns[this.sortBy]) {
            return 1;
          }
          return 0;
        }
      });

      return rowsSorted;
    },

    totalSelectedRows() {
      return this.selectedRowsIds.length;
    },
    areAllRowsSelected() {
      let areAllRowsSelected = true;
      this.rows.forEach((row) => {
        if (!row.deletedAt && !this.selectedRowsIds.includes(row.id)) {
          areAllRowsSelected = false;
        }
      });
      return areAllRowsSelected;
    },
    isDraggingEnabled() {
      return !this.searchQuery && this.userCanDragRows && !this.sortBy;
    },
    hasCustomDoUpdate() {
      return this.$attrs && this.$attrs["onDoUpdate"];
    },
    hasCustomDoUpdateMany() {
      return this.$attrs && this.$attrs["onDoUpdateMany"];
    },
  },
  watch: {
    userCanDragRows(val) {
      if (val) {
        this.clearSelectedRows();
      }
    },
    // rowModal: {
    //   handler(val) {
    //     if (!val.isOpen) {
    //       this.rowModal.modalReady = false;
    //     }
    //   },
    //   deep: true,
    // },
  },
  created() {
    onUnmounted(() => {
      // this.rowModal.modalReady = false;
    });

    this.initRows(this.loadRows);
  },
  methods: {
    ...mapActions("report", ["doUpdate", "doUpdateMany"]),
    async duplicateSelectedRows() {
      let data = {};

      if (this.selectedRowsIds.length === 1) {
        const [rowIdToDuplicate] = this.selectedRowsIds;
        const rowIndex = this.rows.findIndex(
            (row) => row.id === rowIdToDuplicate
        );

        if (rowIndex !== -1) {
          const duplicatedRow = {...this.rows[rowIndex]};
          duplicatedRow.id = generateUUID();
          duplicatedRow.order = this.rows[rowIndex].order + 1;

          this.rows.splice(rowIndex + 1, 0, duplicatedRow);

          // Update the order of the rows after the inserted row
          for (let i = rowIndex + 2; i < this.rows.length; i++) {
            this.rows[i].order++;
          }

          // Add row to data to server
          data[`${this.updateKey}.${duplicatedRow.id}.id`] = duplicatedRow.id;
          data[`${this.updateKey}.${duplicatedRow.id}.isActive`] =
              duplicatedRow.isActive;
          data[`${this.updateKey}.${duplicatedRow.id}.order`] =
              duplicatedRow.order;
          data[`${this.updateKey}.${duplicatedRow.id}.deletedAt`] =
              duplicatedRow.deletedAt;
          for (const [rowKey, rowValue] of Object.entries(
              duplicatedRow.columns
          )) {
            data[`${this.updateKey}.${duplicatedRow.id}.columns.${rowKey}`] =
                rowValue;
          }
        }
      } else {
        const duplicatedRows = [];
        let maxOrder = Math.max(...this.rows.map((row) => row.order));

        for (const rowIdToDuplicate of this.selectedRowsIds) {
          const rowIndex = this.rows.findIndex(
              (row) => row.id === rowIdToDuplicate
          );

          if (rowIndex !== -1) {
            const duplicatedRow = {...this.rows[rowIndex]};
            duplicatedRow.id = generateUUID();
            duplicatedRow.order = ++maxOrder;
            duplicatedRows.push(duplicatedRow);

            // Add row to data to server
            data[`${this.updateKey}.${duplicatedRow.id}.id`] = duplicatedRow.id;
            data[`${this.updateKey}.${duplicatedRow.id}.isActive`] =
                duplicatedRow.isActive;
            data[`${this.updateKey}.${duplicatedRow.id}.order`] =
                duplicatedRow.order;
            data[`${this.updateKey}.${duplicatedRow.id}.deletedAt`] =
                duplicatedRow.deletedAt;
            for (const [rowKey, rowValue] of Object.entries(
                duplicatedRow.columns
            )) {
              data[`${this.updateKey}.${duplicatedRow.id}.columns.${rowKey}`] =
                  rowValue;
            }
          }
        }

        this.rows.push(...duplicatedRows);
      }

      // Add rows order to server update
      for (const row of this.rows) {
        data[`${this.updateKey}.${row.id}.order`] = row.order;
      }

      data = Utils.flattenObject(data);
      await this.localDoUpdateMany({data});

      if (this.selectedRowsIds.length === 1) {
        this.$toast.success("השורה שבחרת שוכפלה בהצלחה");
      } else {
        this.$toast.success("השורות שבחרת שוכפלו בתחתית הטבלה");
      }

      this.clearSelectedRows();
    },
    setSortByColumn(columnId) {
      if (this.sortBy && this.sortBy !== columnId) {
        this.sortBy = columnId;
        this.sortDirection = "ASC";
        return;
      }
      if (this.sortBy === columnId && this.sortDirection === "DESC") {
        this.sortBy = null;
        this.sortDirection = "ASC";
        return;
      }

      if (!this.sortBy) {
        this.sortBy = columnId;
        this.sortDirection = "ASC";
        return;
      }

      if (this.sortBy === columnId && this.sortDirection === "ASC") {
        this.sortBy = columnId;
        this.sortDirection = "DESC";
        return;
      }
    },
    clearSelectedRows() {
      this.selectedRowsIds = [];
    },
    async removeSelectedRows() {
      let data = {};

      if (!confirm("בטוח למחוק את כל השורות המסומנות?")) {
        return;
      }

      // update locally
      let deletedAt = Utils.currentTimestamp();
      this.rows.forEach((row, rowIndex) => {
        if (!this.selectedRowsIds.includes(row.id)) {
          return;
        }
        this.rows[rowIndex].deletedAt = deletedAt;
        data[`${this.updateKey}.${row.id}.deletedAt`] = deletedAt;
      });

      await this.localDoUpdateMany({data});

      this.$toast.success("השורות שבחרת נמחקו בהצלחה");

      this.clearSelectedRows();
    },
    async setSelectedRowsActive(isActive) {
      let data = {};

      // update locally
      this.rows.forEach((row, rowIndex) => {
        if (!this.selectedRowsIds.includes(row.id)) {
          return;
        }
        this.rows[rowIndex].isActive = isActive;
        data[`${this.updateKey}.${row.id}.isActive`] = isActive;
      });

      await this.localDoUpdateMany({data});

      this.clearSelectedRows();
    },
    allRowsCheckboxClicked() {
      if (this.areAllRowsSelected) {
        this.clearSelectedRows();
      } else {
        this.clearSelectedRows();
        this.rows.forEach((row) => {
          if (!row.deletedAt) {
            this.selectedRowsIds.push(row.id);
          }
        });
      }
    },
    isRowSelected(row) {
      return this.selectedRowsIds.includes(row.id);
    },
    rowCheckboxClicked(row) {
      if (this.userCanDragRows) {
        return;
      }
      if (this.selectedRowsIds.includes(row.id)) {
        this.selectedRowsIds = this.selectedRowsIds.filter(
            (id) => id !== row.id
        );
      } else {
        this.selectedRowsIds.push(row.id);
      }
    },
    isRowInSearch(row) {
      if (!this.searchQuery) {
        return true;
      }

      for (const [, value] of Object.entries(row.columns)) {
        if (value && value.toString().trim().includes(this.searchQuery)) {
          return true;
        }
      }
    },
    doUpdateSignature({key, value}) {
      this.rowModal.row.columns = Utils.flatAndMergeObjects(
          this.rowModal.row.columns,
          {
            [key]: value,
          }
      );
    },
    doUpdateImage({key, value}) {
      this.rowModal.row.columns = Utils.flatAndMergeObjects(
          this.rowModal.row.columns,
          {
            [key]: value,
          }
      );
    },
    doUpdateImages({key, value}) {
      this.rowModal.row.columns = Utils.flatAndMergeObjects(
          this.rowModal.row.columns,
          {
            [key]: value,
          }
      );
    },
    doUpdateManyImages({data}) {
      this.rowModal.row.columns = Utils.flatAndMergeObjects(
          this.rowModal.row.columns,
          data
      );
    },
    doUpdateFiles({key, value}) {
      this.rowModal.row.columns = Utils.flatAndMergeObjects(
          this.rowModal.row.columns,
          {
            [key]: value,
          }
      );
    },
    doUpdateManyFiles({data}) {
      this.rowModal.row.columns = Utils.flatAndMergeObjects(
          this.rowModal.row.columns,
          data
      );
    },
    initRows(rows) {
      if (!rows) {
        return;
      }
      this.rows = Object.values(rows);

      let maxOrder = Utils.findMaxOrderInObject(this.rows);
      maxOrder++;

      // 1) add 'order' to missing rows.
      this.rows.forEach((row) => {
        if (!row.order) {
          row.order = maxOrder;
          maxOrder++;
        }
      });

      // 2) add 'columns' to missing rows.
      this.rows.forEach((row) => {
        if (!row.columns) {
          row.columns = {};
        }
      });

      // 3) sort the array
      this.rows = this.rows.sort((a, b) => a.order - b.order);
    },
    printText(string) {
      if (!string) {
        return null;
      }

      string = this.$Utils.stripHtmlTags(string);

      if (string.length > 120) {
        return string.substring(0, 120) + "...";
      }
      return string;
    },
    editRow(row) {
      this.openRowModal(row, "edit");
    },
    addRow() {
      let maxOrder = Utils.findMaxOrderInObject(this.rows);
      let newRow = {
        id: Utils.guid(),
        columns: {},
        isActive: true,
        order: maxOrder + 1,
      };
      this.openRowModal(newRow, "add");
    },
    async saveAndDisableRow() {
      this.rowModal.row.isActive = false;
      await this.submitRowModal();
      this.$toast.success("השורה כובה בהצלחה");
    },
    closeRowModal() {
      this.rowModal.isOpen = false;
      // this.rowModal.modalReady = false;
    },
    isRowEmpty(row) {
      let isRowEmpty = true;

      for (const [key, value] of Object.entries(row.columns)) {
        if (key && value) {
          isRowEmpty = false;
        }
      }
      return isRowEmpty;
    },
    async submitRowModal() {
      if (this.isRowEmpty(this.rowModal.row)) {
        this.$toast.error("לא ניתן לשמור שורה ריקה");
        return false;
      }

      let data = {};

      data[`${this.updateKey}.${this.rowModal.row.id}.id`] =
          this.rowModal.row.id;
      data[`${this.updateKey}.${this.rowModal.row.id}.isActive`] =
          this.rowModal.row.isActive;
      data[`${this.updateKey}.${this.rowModal.row.id}.order`] =
          this.rowModal.row.order;

      data[`${this.updateKey}.${this.rowModal.row.id}.deletedAt`] =
          this.rowModal.row.deletedAt;

      for (const [rowKey, rowValue] of Object.entries(
          this.rowModal.row.columns
      )) {
        data[`${this.updateKey}.${this.rowModal.row.id}.columns.${rowKey}`] =
            rowValue;
      }

      data = Utils.flattenObject(data);

      // Update locally
      if (this.rowModal.mode === "edit") {
        this.rows.forEach((row, rowIndex) => {
          if (row.id === this.rowModal.row.id) {
            this.rows[rowIndex] = Object.assign({}, row, this.rowModal.row);
          }
        });
      }
      if (this.rowModal.mode === "add") {
        this.rows.push(JSON.parse(JSON.stringify(this.rowModal.row)));
      }

      await this.localDoUpdateMany({data});

      this.closeRowModal();
    },
    async removeRow() {
      if (confirm("בטוח למחוק את השורה?")) {
        this.rowModal.row.deletedAt = Utils.currentTimestamp();
        await this.submitRowModal();
        this.$toast.success("השורה נמחקה בהצלחה");
      }
    },
    async saveRowModal() {
      this.rowModal.row.isActive = true;
      await this.submitRowModal();
    },
    openRowModal(row, mode) {
      row = JSON.parse(JSON.stringify(row));
      this.rowModal.row.id = row.id;
      this.rowModal.row.columns = {};
      this.columns.forEach((column) => {
        this.rowModal.row.columns[column.id] = row.columns[column.id];
      });
      this.rowModal.mode = mode;
      this.rowModal.row.isActive = row.isActive;
      this.rowModal.row.deletedAt = row.deletedAt;
      this.rowModal.row.order = row.order;
      this.rowModal.isOpen = true;

      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     // this.rowModal.modalReady = true;
      //   }, 200);
      // });
    },
    getColumnCss(column, row) {
      let css = {
        backgroundColor: "#ffffff",
        color: "#000000",
      };

      let columnBackgroundColor = false;
      let columnColor = false;

      if (column.type === "textChoices" && column.settings.options) {
        column.settings.options.forEach((option) => {
          if (option.label === row.columns[column.id]) {
            if (option.color === "#ffffff" || option.color === "#fff") {
              return;
            }
            columnBackgroundColor = Utils.hexToRgbA(option.color, 0.04);
            columnColor = option.color;
          }
        });
      }

      if (columnBackgroundColor) {
        css.backgroundColor = columnBackgroundColor;
      }
      if (columnColor) {
        css.color = columnColor;
      }

      return css;
    },
    async onDragChange() {
      this.rows = [...this.rows].map((row, index) => {
        const newSort = index + 1;
        let hasChanged = row.order !== newSort;
        if (hasChanged) {
          row.order = newSort;
        }
        return row;
      });
      let data = {};
      for (const row of this.rows) {
        data[`${this.updateKey}.${row.id}.order`] = row.order;
      }
      await this.localDoUpdateMany({data});
    },
    async localDoUpdate({key, value}) {
      if (this.hasCustomDoUpdate) {
        this.$emit("doUpdate", {
          key: key,
          value: value,
        });
      } else {
        await this.doUpdate({
          key: key,
          value: value,
        });
      }
    },
    async localDoUpdateMany({data}) {
      if (this.hasCustomDoUpdateMany) {
        this.$emit("doUpdateMany", {data});
      } else {
        await this.doUpdateMany({data});
      }
    },
  },
};
</script>
