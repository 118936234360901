import * as types from "./mutation-types";
import {setCookie, deleteCookie} from "@/utils/cookies";

export default {
    [types.SET_TOKEN](state, token) {
        state.token = token;
        if (!state.isImpersonate) {
            setCookie("token", token, 365);
            window.localStorage.setItem("token", token);
        }
    },

    [types.SET_SHOW_ONBOARDING](state, data) {
        state.showOnboarding = data;
    },

    [types.SET_IS_SELF_SIGNUP](state, data) {
        state.isSelfSignup = data;
    },

    [types.SET_FEATURES](state, data) {
        state.features = data;
    },

    [types.REMOVE_TOKEN](state) {
        state.token = null;
        deleteCookie("token");
        window.localStorage.removeItem("token");
    },

    [types.SET_IS_LOGGED_IN](state, data) {
        state.isLoggedIn = data;
    },

    [types.SET_USER](state, data) {
        state.user = data;
    },

    [types.SET_COMPANIES](state, data) {
        state.companies = data;
    },

    [types.SET_COMPANY](state, data) {
        state.company = data;
    },

    [types.SET_LOGOUT](state) {
        state.isLoggedIn = false;
        state.token = null;
        deleteCookie("token");
        window.localStorage.removeItem("token");
    },
};
