<template>
  <div class="r-auth-wrapper">
    <div class="r-auth-inner">
      <div class="r-auth-logo">
        <img src="@/assets/images/reporto-logo.svg" alt="logo" />
      </div>
      <div class="r-auth-title">היי, טוב לראות אותך!</div>
      <div
        class="r-auth-text"
        style="max-width: 300px; margin-right: auto; margin-left: auto"
      >
        כדי להיכנס לשירות יישלח לך
        <b>קוד אימות חד-פעמי ב-SMS.</b>
      </div>
      <div class="r-auth-form">
        <form
          :class="{
            loading: loading,
          }"
          @submit.prevent="sendOtp"
        >
          <RFieldText
            v-model="phone"
            type="text"
            label="מספר טלפון"
            autocomplete="off"
            autofocus
            placeholder="05X - XXX - XXXX"
          />

          <RButton
            lg
            color="primary"
            type="submit"
            :loading="loading"
            block
            class="mt-6"
            :disabled="!phone"
          >
            שלחו לי קוד כניסה
          </RButton>
        </form>
      </div>

      <RButton
        lg
        block
        color="primary"
        outlined
        class="mt-4 mb-10"
        icon-after="mdi-email-outline"
        :to="{ name: 'login' }"
      >
        התחברות עם מייל
      </RButton>

      <div class="r-auth-disclaimer">
        בהתחברות אתם מסכימים ל<a
          style="color: currentColor"
          href="https://reporto.co.il/reporto_terms_and_privacy.pdf"
          target="_blank"
          >תנאי השימוש ומדיניות הפרטיות</a
        >
        שלנו.
      </div>
    </div>
  </div>
</template>

<script>
import AuthApi from "@/api/AuthApi";
import { Utils } from "@/common/Utils";

export default {
  data() {
    return {
      loading: false,
      phone: null,
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const phoneParam = urlParams.get("phone");
    if (phoneParam) {
      this.phone = phoneParam;
      this.sendOtp();
    }
  },
  methods: {
    async sendOtp() {
      if (!Utils.isValidPhone(this.phone)) {
        this.$toast.error("חובה להזין טלפון תקין");
        return;
      }

      this.loading = true;
      try {
        let response = await AuthApi.sendOtp(this.phone);
        window.localStorage.setItem("otp_key", response.data.key);

        this.$posthog.capture("log:auth:login_with_phone");

        await this.$router.push({
          name: "login-phone-verify",
          query: { phone: this.phone },
        });
      } catch (e) {
        this.loading = false;
        if (e.response && e.response.data.message) {
          this.$toast.error(e.response.data.message);
        } else {
          this.$toast.error("שגיאה בהתחברות עם טלפון ");
        }
      }
    },
  },
};
</script>
