<template>
  <div v-if="building">
    <div class="p-4 border-b bg-white">
      <div class="flex flex-wrap items-center gap-2">
        <div class="flex flex-wrap items-center gap-1">
          <router-link 
            :to="`/sites/${site.id}`"
            class="flex items-center gap-2 hover:text-primary-600 transition-colors"
          >
            <v-icon class="text-gray-500">mdi-office-building-marker</v-icon>
            <span class="text-gray-600 hidden sm:inline">כל הבניינים</span>
          </router-link>
          <span class="text-gray-400 mx-1">›</span>
          <router-link 
            :to="`/sites/${site.id}/buildings/${building.id}`"
            class="flex items-center gap-1 hover:text-primary-600 transition-colors"
          >
            <v-icon class="text-gray-500">mdi-office-building-outline</v-icon>
            <h1 class="text-md sm:text-xl font-medium">{{ building.name }}</h1>
          </router-link>
        </div>
      </div>
    </div>
    
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
      <router-link 
        v-for="level in sortedLevels" 
        :key="level.id"
        :to="`/sites/${site.id}/levels/${level.id}`"
        class="bg-white rounded-lg shadow hover:shadow-md transition-shadow p-4 border border-gray-200"
      >
        <div class="flex flex-col items-center gap-2 text-center">
          <v-icon class="text-gray-500" size="32">mdi-floor-plan</v-icon>
          <div class="space-y-1">
            <h3 class="text-lg font-medium">{{ level.name }}</h3>
            <span class="text-gray-500 text-sm">{{ level.areas.length }} אזורים</span>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useSite } from '@/composables/useSite'

const route = useRoute()
const { site } = useSite()

const building = computed(() => {
  return site.value?.buildings.find(b => b.id === route.params.buildingId)
})

const sortedLevels = computed(() => {
  if (!building.value?.levels) return []
  return [...building.value.levels].sort((a, b) => a.order - b.order)
})
</script> 