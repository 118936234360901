<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
    <router-link 
      v-for="building in sortedBuildings" 
      :key="building.id"
      :to="`/sites/${site.id}/buildings/${building.id}`"
      class="bg-white rounded-lg shadow hover:shadow-md transition-shadow p-4 border border-gray-200"
    >
      <div class="flex flex-col items-center gap-2 text-center">
        <v-icon class="text-gray-500" size="32">mdi-office-building-outline</v-icon>
        <div class="space-y-1">
          <h2 class="text-lg font-medium">{{ building.name }}</h2>
          <span class="text-gray-500 text-sm">{{ building.levels?.length || 0 }} קומות</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useSite } from '@/composables/useSite'

const { site } = useSite()

const sortedBuildings = computed(() => {
  if (!site.value?.buildings) return []
  return [...site.value.buildings].sort((a, b) => a.order - b.order)
})
</script> 