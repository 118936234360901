<template>
  <div class="p-4">
    <!-- Area Types Management Card -->
    <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4">
      <div class="p-4 border-b flex justify-between items-center">
        <div class="flex items-center gap-4">
          <h2 class="text-lg font-medium">
            סוגי אזורים
            <span class="text-gray-500 text-sm">({{ site.area_types?.length || 0 }})</span>
          </h2>
        </div>
        <RButton
          @click="showCreateAreaTypeForm = true"
          icon-before="mdi-plus"
        >
          הוסף סוג אזור
        </RButton>
      </div>

      <!-- Updated Create Modal for Multiple Area Types -->
      <RModal
        v-model="showCreateAreaTypeForm"
        title="הוסף סוגי אזורים"
        @close="closeCreateAreaTypeModal"
      >
        <div class="space-y-4">
          <div v-for="(areaType, index) in newAreaTypes" :key="index" class="flex gap-2 items-center">
            <RFieldText
              v-model="areaType.name"
              type="text"
              required
              placeholder="למשל: דירת 5 חדרים"
              class="w-full"
            />
            <RButtonIcon
              @click="removeNewAreaType(index)"
              icon="mdi-close"
              color="red"
            />
          </div>
          
          <button
            @click="addNewAreaType"
            class="text-purple-500 hover:text-purple-700 text-sm mb-4"
          >
            + הוסף סוג אזור נוסף
          </button>

          <div class="flex gap-2">
            <RButton
              type="submit"
              lg 
              block
              :loading="isCreating"
              :disabled="!hasValidAreaTypes"
              @click="createAreaTypes"
            >
              צור סוגי אזורים
            </RButton>
          </div>
        </div>
      </RModal>

      <!-- Area Types List -->
      <div class="divide-y divide-gray-200">
        <div v-if="!site.area_types?.length" class="p-4 text-center text-gray-500">
          אין סוגי אזורים באתר זה
        </div>

        <RouterLink
          v-for="areaType in site.area_types"
          :key="areaType.id"
          class="p-4 flex items-start gap-4 hover:bg-gray-50 transition-colors cursor-pointer"
          :to="{ name: 'area-types.edit', params: { id: siteId, areaTypeId: areaType.id } }"
        >
          <!-- Area Type Icon -->
          <div class="flex-shrink-0">
            <v-icon size="24" class="text-gray-400">
              mdi-home-variant
            </v-icon>
          </div>

          <!-- Area Type Content -->
          <div class="flex-grow">
            <div class="flex items-center gap-2 mb-1">
              <h3 class="font-medium">{{ areaType.name }}</h3>
            </div>
            <!-- Optional: Show additional info like locations count -->
            <div class="text-sm text-gray-600 flex items-center gap-1">
              <v-icon size="16" class="text-gray-500">mdi-map-marker</v-icon>
              {{ areaType.locations?.length || 0 }} מיקומים
            </div>

            <div class="text-sm text-gray-600 flex items-center gap-1">
              <v-icon size="16" class="text-gray-500">mdi-clipboard-list</v-icon>
              {{ areaType.findings?.length || 0 }} סעיפים בצ׳קליסט
            </div>
          </div>

          <!-- Actions -->
          <RActions @click.stop>
            <RAction 
              text="עריכה"
              icon="mdi-pencil"
              :to="{ name: 'area-types.edit', params: { id: site.id, areaTypeId: areaType.id } }"
            />
            <RAction 
              text="מחיקה"
              icon="mdi-delete"
              @click="deleteAreaType(areaType.id)"
            />
          </RActions>
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useSite } from '@/composables/useSite'
import SitesApi from '@/api/SitesApi'
import SiteAreaTypesApi from '@/api/SiteAreaTypesApi'

const { site, siteId } = useSite()
const isCreating = ref(false)
const showCreateAreaTypeForm = ref(false)
const newAreaTypes = ref([{ name: '' }])
const isUpdating = ref(false)

const hasValidAreaTypes = computed(() => {
  return newAreaTypes.value.some(areaType => areaType.name.trim() !== '')
})

const loadSite = async () => {
  try {
    const response = await SitesApi.getSite(siteId)
    site.value = response.data
  } catch (error) {
    console.error('Error loading site:', error)
  }
}

// Area Type Management Functions
const createAreaTypes = async () => {
  isCreating.value = true
  try {
    const validAreaTypes = newAreaTypes.value.filter(areaType => areaType.name.trim() !== '')
    for (const areaType of validAreaTypes) {
      await SiteAreaTypesApi.createAreaType(siteId.value, areaType)
    }
    await loadSite()
    closeCreateAreaTypeModal()
  } catch (error) {
    console.error('Error creating area types:', error)
  } finally {
    isCreating.value = false
  }
}

const closeCreateAreaTypeModal = () => {
  showCreateAreaTypeForm.value = false
  newAreaTypes.value = [{ name: '' }]
}

const deleteAreaType = async (areaTypeId) => {
  if (!confirm('האם אתה בטוח שברצונך למחוק סוג אזור זה?')) return

  try {
    await SiteAreaTypesApi.removeAreaType(siteId, areaTypeId)
    await loadSite()
  } catch (error) {
    console.error('Error deleting area type:', error)
  }
}

const addNewAreaType = () => {
  newAreaTypes.value.push({ name: '' })
}

const removeNewAreaType = (index) => {
  if (newAreaTypes.value.length > 1) {
    newAreaTypes.value.splice(index, 1)
  }
}
</script>