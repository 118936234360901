<template>
  <div class="space-y-4">
    <!-- Buildings -->
    <draggable 
      :model-value="sortedBuildings"
      @update:model-value="updateBuildings"
      item-key="id"
      class="space-y-4"
      handle=".building-drag-handle"
      :group="{ name: 'buildings' }"
      :rtl="true"
      @start="drag=true" 
      @end="drag=false"
      @change="handleBuildingDragChange"
    >
      <template #item="{ element: building }">
        <div class="bg-white">
          <!-- Building Header -->
          <div 
            class="p-4 border-b border-gray-200 cursor-pointer"
            @click="toggleBuilding(building.id)"
          >
            <div class="flex items-center justify-between">
              <div class="flex items-center gap-2">
                <v-icon class="building-drag-handle cursor-move text-gray-400 opacity-100">mdi-drag</v-icon>
                <v-icon class="text-gray-500" size="18">mdi-office-building-outline</v-icon>
                <h2 class="text-lg font-medium">{{ building.name }}</h2>
                <span class="text-gray-500 text-sm">({{ building.levels.length }} קומות)</span>
              </div>
              <div class="flex items-center gap-2" @click.stop>
                <RActions>
                  <RAction 
                    text="הוסף קומה" 
                    icon="mdi-plus" 
                    @click="addLevel(building.id)"
                  />
                  <RAction 
                    text="הוספה מהירה של קומות ודירות" 
                    icon="mdi-flash" 
                    @click="openQuickSetup(building.id)"
                  />
                  <RAction 
                    text="ערוך" 
                    icon="mdi-pencil" 
                    @click="editBuilding(building)"
                  />
                  <RAction 
                    text="מחק" 
                    icon="mdi-delete" 
                    @click="deleteBuilding(building.id)"
                  />
                </RActions>
                <v-icon class="text-gray-400">
                  {{ isBuildingCollapsed(building.id) ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                </v-icon>
              </div>
            </div>
          </div>

          <!-- Building Content -->
          <div v-show="!isBuildingCollapsed(building.id)" class="space-y-6">
            <!-- Levels -->
            <div v-if="!building.levels.length" class="p-4 text-center text-gray-500">
              אין קומות בבניין זה
            </div>
            <draggable 
              v-else
              :model-value="sortedLevels(building.levels)"
              @update:model-value="(newValue) => updateLevels(building, newValue)"
              item-key="id"
              class="divide-y divide-gray-200"
              handle=".level-drag-handle"
              :group="{ name: 'levels' }"
              @change="(event) => handleLevelDragChange(building.id, event)"
            >
              <template #item="{ element: level }">
                <div class="pt-0">
                  <!-- Level Header -->
                  <div 
                    class="flex items-center justify-between cursor-pointer bg-gray-50/50 hover:bg-gray-50 py-4 pr-7 pl-4" 
                    @click="toggleLevel(level.id)"
                  >
                    <div class="flex items-center gap-2">
                      <v-icon class="level-drag-handle cursor-move text-gray-400 opacity-100">mdi-drag</v-icon>
                      <v-icon class="text-gray-500" size="18">mdi-floor-plan</v-icon>
                      <h3 class="font-medium text-gray-900">{{ level.name }}</h3>
                      <span class="text-gray-500 text-sm">({{ level.areas.length }} אזורים)</span>
                    </div>
                    <div class="flex items-center gap-2" @click.stop>
                      <RActions>
                        <RAction 
                          text="הוסף אזור" 
                          icon="mdi-plus" 
                          @click="addArea(building.id, level.id)"
                        />
                        <RAction 
                          text="ערוך" 
                          icon="mdi-pencil" 
                          @click="editLevel(building.id, level)"
                        />
                        <RAction 
                          text="מחק" 
                          icon="mdi-delete" 
                          @click="deleteLevel(building.id, level.id)"
                        />
                      </RActions>
                      <v-icon class="text-gray-400">
                        {{ isLevelCollapsed(level.id) ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
                      </v-icon>
                    </div>
                  </div>

                  <!-- Apartments Grid -->
                  <div v-show="!isLevelCollapsed(level.id)">
                    <div class="flex flex-col">
                      <div v-if="!level.areas.length" class="p-4 text-center text-gray-500">
                        אין אזורים בקומה זו
                      </div>
                      <draggable 
                        v-else
                        :model-value="sortedAreas(level.areas)"
                        @update:model-value="(newValue) => updateAreas(level, newValue)"
                        item-key="id"
                        class="flex flex-col divide-y divide-gray-200"
                        handle=".area-drag-handle"
                        :group="{ name: 'areas' }"
                        @change="(event) => handleAreaDragChange(level.id, event)"
                      >
                        <template #item="{ element: area }">
                          <div 
                            class="relative bg-white pr-12 pl-3 py-3 flex items-center justify-between"
                            style="direction: rtl;"
                          >
                            <div class="flex items-center gap-2">
                              <v-icon class="area-drag-handle cursor-move text-gray-400 opacity-100">mdi-drag</v-icon>
                              <v-icon class="text-gray-500" size="18">mdi-home-outline</v-icon>
                              <span class="font-medium text-gray-700">{{ area.name }}</span>
                              <span class="text-sm text-gray-500">({{ area.findings?.length || 0 }} ממצאים)</span>
                            </div>
                            <RActions>
                              <RAction 
                                text="ערוך" 
                                icon="mdi-pencil" 
                                @click="editArea(building.id, level.id, area)"
                              />
                              <RAction 
                                text="מחק" 
                                icon="mdi-delete" 
                                @click="deleteArea(building.id, level.id, area.id)"
                              />
                            </RActions>
                          </div>
                        </template>
                      </draggable>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </div>
      </template>
    </draggable>

    <!-- Add Building Button -->
    <div class="pa-4 text-center">
      <RButton 
      lg
      @click="addBuilding" 
      outlined
      color="black"
      icon-before="mdi-plus"
    >
      הוסף בניין
    </RButton>
    </div>

    <!-- Edit Building Modal -->
    <RModal v-model="editBuildingDialog" :title="'ערוך בניין'">
      <div class="space-y-4">
        <RFieldText
          v-model="editingItem.name"
          label="שם הבניין"
          required
        />
        <RButton @click="saveEdit" lg block>שמור</RButton>
      </div>
    </RModal>

    <!-- Edit Level Modal -->
    <RModal v-model="editLevelDialog" :title="'ערוך קומה'">
      <div class="space-y-4">
        <RFieldText
          v-model="editingItem.name"
          label="שם הקומה"
          required
        />
        <RButton @click="saveEdit" lg block>שמור</RButton>
      </div>
    </RModal>

    <!-- Edit Area Modal -->
    <RModal v-model="editAreaDialog" :title="'ערוך אזור'">
      <div class="space-y-4">
        <RFieldText
          v-model="editingItem.name"
          label="שם האזור"
          required
        />
        <RButton @click="saveEdit" lg block>שמור</RButton>
      </div>
    </RModal>

    <!-- Add Building Modal -->
    <RModal v-model="addBuildingDialog" title="הוסף בניין">
      <div class="space-y-4">
        <RFieldText
          v-model="newItem.name"
          label="שם הבניין"
          required
        />
        <RButton @click="saveNewItem" lg block>הוסף בניין</RButton>
      </div>
    </RModal>

    <!-- Add Level Modal -->
    <RModal v-model="addLevelDialog" title="הוסף קומה">
      <div class="space-y-4">
        <RFieldText
          v-model="newItem.name"
          label="שם הקומה"
          required
        />
        <RButton @click="saveNewItem" lg block>הוסף קומה</RButton>
      </div>
    </RModal>

    <!-- Add Area Modal -->
    <RModal v-model="addAreaDialog" title="הוסף אזור">
      <div class="space-y-4">
        <RFieldText
          v-model="newItem.name"
          label="שם האזור"
          required
        />
        <RFieldSelect
          v-model="newItem.site_area_type_id"
          label="סוג אזור"
          :items="site.area_types?.map(type => ({
            text: type.name,
            value: type.id
          })) || []"
          required
        />
        <RFieldCheckbox
          v-model="newItem.should_add_findings"
          label="הוספת צ׳קליסט"
        />
        <RButton 
          @click="saveNewItem" 
          lg 
          block
          :disabled="!newItem.site_area_type_id"
        >
          הוסף אזור
        </RButton>
      </div>
    </RModal>

    <!-- Quick Building Setup Modal -->
    <RModal 
      v-model="quickSetupDialog" 
      :title="`הוספה מהירה של קומות ודירות - ${getCurrentBuildingName}`"
    >
      <div class="space-y-6">
        <!-- Step indicator -->
        <div class="flex items-center justify-center mb-4">
          <div class="flex items-center">
            <div class="flex items-center">
              <div :class="[
                'rounded-full w-8 h-8 flex items-center justify-center',
                currentStep === 1 ? 'bg-purple-600 text-white' : 'bg-gray-200'
              ]">1</div>
              <span class="mr-2" :class="currentStep === 1 ? 'text-purple-600' : 'text-gray-500'">הגדרות</span>
            </div>
            <div class="w-16 h-1 mx-4" :class="currentStep === 2 ? 'bg-purple-600' : 'bg-gray-200'"></div>
            <div class="flex items-center">
              <div :class="[
                'rounded-full w-8 h-8 flex items-center justify-center',
                currentStep === 2 ? 'bg-purple-600 text-white' : 'bg-gray-200'
              ]">2</div>
              <span class="mr-2" :class="currentStep === 2 ? 'text-purple-600' : 'text-gray-500'">תצוגה מקדימה</span>
            </div>
          </div>
        </div>

        <h3 class="text-lg font-medium text-center">בניין: {{ getCurrentBuildingName }}</h3>

        <!-- Step 1: Configuration -->
        <div v-if="currentStep === 1">
          <!-- Ground Floor -->
          <RFieldCheckbox
            class="mt-3"
            v-model="quickSetup.hasGroundFloor"
            label="האם להוסיף גם קומת קרקע לבניין?"
          /> 

          <!-- Level Groups -->
          <div class="space-y-4 mt-3">
            <div class="flex justify-between items-center">
              <h3 class="text-lg font-medium">קבוצות קומות</h3>
              <RButton 
                small 
                @click="addLevelGroup" 
                icon-before="mdi-plus"
              >
                הוסף קבוצת קומות
              </RButton>
            </div>

            <!-- Level Group Cards -->
            <div v-for="(group, index) in quickSetup.levelGroups" :key="index" class="border rounded-lg p-4 space-y-4 bg-gray-50">
              <div class="flex justify-between items-center">
                <h4 class="font-medium">קבוצת קומות #{{ index + 1 }}</h4>
                <v-icon @click="removeLevelGroup(index)" class="text-gray-400 cursor-pointer">mdi-close</v-icon>
              </div>

              <!-- Level Range -->
              <div class="grid grid-cols-2 sm:grid-cols-4 gap-4">
                <RFieldText
                  v-model="group.startLevel"
                  label="מקומה"
                  type="number"
                  min="1"
                  class="col-span-1 sm:col-span-2"
                />
                <RFieldText
                  v-model="group.endLevel"
                  label="עד קומה"
                  type="number"
                  min="1"
                  class="col-span-1 sm:col-span-2"
                />
              </div>

              <!-- Areas Configuration -->
              <div class="space-y-2">
                <RFieldCheckbox
                  v-model="group.shouldAddAreas"
                  label="אני רוצה להוסיף גם דירות לכל קומה"
                />

                <div v-if="group.shouldAddAreas">
                  <div class="flex justify-between items-center">
                    <h5 class="text-sm font-medium">מה להוסיף לכל קומה וכמה?</h5>
                    <RButton 
                      small 
                      @click="addAreaType(group)" 
                      icon-before="mdi-plus"
                    >
                      הוסף סוג אזור
                    </RButton>
                  </div>

                  <div class="space-y-2 mt-4">
                    <div v-for="(areaType, areaIndex) in group.areaTypes" :key="areaIndex" 
                      class="space-y-2"
                    >
                      <div class="flex flex-col sm:flex-row items-start sm:items-center gap-2 pb-6">
                        <RFieldText
                          v-model="areaType.count"
                          label="מס׳ יחידות"
                          type="number"
                          min="1"
                          class="w-[80px] full-width-on-mobile"
                          :error="!areaType.count || areaType.count < 1"
                          error-messages="יש להגדיר מעל 0"
                        />
                        <RFieldSelect
                          v-model="areaType.typeId"
                          :items="site.area_types?.map(type => ({
                            text: type.name,
                            value: type.id
                          })) || []"
                          label="סוג אזור"
                          class="w-[140px] full-width-on-mobile"
                          :error="!areaType.typeId"
                          error-messages="יש לבחור סוג אזור"
                        />
                        <div class="relative flex-1 full-width-on-mobile">
                          <RFieldText
                            v-model="areaType.prefix"
                            label="שם יחידה"
                            :placeholder="getNumberingPlaceholder(areaType.numberingType)"
                            maxlength="20"
                            class="w-full"
                            :error="areaType.numberingType !== 'none' && (!areaType.prefix || !areaType.prefix.trim())"
                            error-messages="יש להגדיר שם"
                          />
                          <div class="absolute left-2 top-7">
                            <select
                              v-model="areaType.numberingType"
                              class="w-17 h-7 px-2 text-[12px] border border-gray-300 rounded-md bg-gray-100"
                            >
                              <option value="by_area">מס׳ אזור</option>
                              <option value="by_level">מס׳ קומה</option>
                              <option value="none">ללא מס׳</option>
                            </select>
                          </div>
                          <div class="text-xs text-gray-500 mt-1 absolute bottom-n1 right-0">
                            דוגמה: {{ getNumberingExample(areaType) }}
                          </div>
                        </div>

                        <RButtonIcon 
                          @click="removeAreaType(group, areaIndex)" 
                          icon="mdi-close"
                          color="red"
                          class="mt-6 sm:block hidden"
                        />

                        <button @click="removeAreaType(group, areaIndex)" class="mt-6 sm:hidden flex items-center gap-2 text-red-500 text-sm"> 
                          <v-icon size="16">mdi-close</v-icon>
                          הסר אזור
                        </button>
                      </div>
                      
                      <!-- Show validation errors if any -->
                      <div v-if="getValidationErrors(group, areaType).length > 0" class="text-red-500 text-sm mt-2">
                        <ul class="list-disc list-inside">
                          <li v-for="(error, index) in getValidationErrors(group, areaType)" :key="index">
                            {{ error }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-between gap-4 mt-8">
            <RButton 
              @click="goToPreview" 
              lg
              class="flex-1"
              :disabled="!isConfigurationValid"
              icon-after="mdi-arrow-left"
            >
              המשך לתצוגה מקדימה
            </RButton>
          </div>
        </div>

        <!-- Step 2: Preview -->
        <div v-else class="space-y-6">
          <div class="bg-gray-50 p-4 rounded-lg">
            <h3 class="font-medium mb-4">תצוגה מקדימה של מה שניצור בבניין:</h3>
            
            <!-- Ground Floor Preview -->
            <div v-if="quickSetup.hasGroundFloor" class="mb-4">
              <div class="flex items-center gap-2 mb-2">
                <v-icon size="18">mdi-floor-plan</v-icon>
                <span class="font-medium">קומת קרקע</span>
              </div>
            </div>

            <!-- Level Groups Preview -->
            <div v-for="(group, index) in quickSetup.levelGroups" :key="index" class="mb-4">
              <div v-for="level in getGroupLevels(group)" :key="level" class="mb-2">
                <div class="flex items-center gap-2 mb-1">
                  <v-icon size="18">mdi-floor-plan</v-icon>
                  <span class="font-medium">קומה {{ level }}</span>
                </div>
                
                <!-- Areas Preview -->
                <div v-if="group.shouldAddAreas" class="mr-6 space-y-1">
                  <div v-for="areaType in group.areaTypes" :key="areaType.typeId">
                    <div v-for="i in areaType.count" :key="i" class="flex items-center gap-2">
                      <v-icon size="16">mdi-home-outline</v-icon>
                      <span class="text-sm">
                        {{ getAreaName(areaType, i, level) }}
                        <span class="text-gray-500">
                          ({{ getAreaTypeName(areaType.typeId) }})
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-between gap-4">
            <RButton 
              @click="currentStep = 1" 
              lg 
              outlined
              class="flex-1"
              icon-before="mdi-arrow-right"
            >
              חזרה
            </RButton>
            <RButton 
              @click="generateStructure" 
              lg
              class="flex-1"
              :loading="isGenerating"
              :disabled="isGenerating"
            >
              צור קומות ודירות
            </RButton>
          </div>
        </div>
      </div>
    </RModal>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue'
import draggable from 'vuedraggable'
import { useSite } from '@/composables/useSite'
import { useToast } from 'vue-toastification'
import SiteBuildingsApi from '@/api/SiteBuildingsApi'
import SiteLevelsApi from '@/api/SiteLevelsApi'
import SiteAreasApi from '@/api/SiteAreasApi'
import { toastMessageFromResponse } from '@/common/helpers/response-helpers'

const { site, loadSite } = useSite()
const toast = useToast()
const drag = ref(false)
const collapsedBuildings = ref(new Set())
const collapsedLevels = ref(new Set())

// Dialog control
const editBuildingDialog = ref(false)
const editLevelDialog = ref(false)
const editAreaDialog = ref(false)
const editingItem = ref({})
const editingType = ref('') // 'building', 'level', or 'area'
const editingParentIds = ref({}) // Store parent IDs for nested items

// Add new refs for add dialogs
const addBuildingDialog = ref(false)
const addLevelDialog = ref(false)
const addAreaDialog = ref(false)
const newItem = ref({ 
  name: '', 
  site_area_type_id: '',
  should_add_findings: true
})
const addingBuildingId = ref(null) // For storing building ID when adding a level
const addingLevelId = ref(null) // For storing level ID when adding an area

const quickSetupDialog = ref(false)
const quickSetup = ref({
  hasGroundFloor: true,
  levelGroups: []
})

const currentBuildingId = ref(null)

const isGenerating = ref(false)

// Add these new refs
const currentStep = ref(1)

const globalAreaCounters = new Map() // Move counter to component scope

const resetGlobalCounters = () => {
  globalAreaCounters.clear()
}

const getNextAreaNumber = (prefix, level, numberingType) => {
  if (numberingType === 'by_area') {
    const key = prefix
    const current = globalAreaCounters.get(key) || 0
    globalAreaCounters.set(key, current + 1)
    return current + 1
  } else if (numberingType === 'by_level') {
    return level
  } else { // 'none'
    return null
  }
}

const formatAreaName = (prefix, number) => {
  if (number === null) {
    return prefix
  }
  return `${prefix} ${number}`
}

const sortedBuildings = computed(() => {
  if (!site.value?.buildings) return []
  return [...site.value.buildings].sort((a, b) => a.order - b.order)
})

const updateBuildings = (newValue) => {
  site.value.buildings = newValue
}

const toggleBuilding = (buildingId) => {
  if (collapsedBuildings.value.has(buildingId)) {
    collapsedBuildings.value.delete(buildingId)
  } else {
    collapsedBuildings.value.add(buildingId)
  }
}

const toggleLevel = (levelId) => {
  if (collapsedLevels.value.has(levelId)) {
    collapsedLevels.value.delete(levelId)
  } else {
    collapsedLevels.value.add(levelId)
  }
}

const isBuildingCollapsed = (buildingId) => collapsedBuildings.value.has(buildingId)
const isLevelCollapsed = (levelId) => collapsedLevels.value.has(levelId)

const closeEditDialog = () => {
  editBuildingDialog.value = false
  editLevelDialog.value = false
  editAreaDialog.value = false
  editingItem.value = {}
  editingType.value = ''
  editingParentIds.value = {}
}

const saveEdit = async () => {
  try {
    if (editingType.value === 'building') {
      await SiteBuildingsApi.updateBuilding(site.value.id, editingItem.value.id, {
        name: editingItem.value.name
      })
      const buildingIndex = site.value.buildings.findIndex(b => b.id === editingItem.value.id)
      if (buildingIndex !== -1) {
        site.value.buildings[buildingIndex].name = editingItem.value.name
      }
      toast.success('הבניין עודכן בהצלחה')
    } else if (editingType.value === 'level') {
      await SiteLevelsApi.updateLevel(
        site.value.id,
        editingItem.value.id,
        { name: editingItem.value.name }
      )
      const building = site.value.buildings.find(b => b.id === editingParentIds.value.buildingId)
      if (building) {
        const levelIndex = building.levels.findIndex(l => l.id === editingItem.value.id)
        if (levelIndex !== -1) {
          building.levels[levelIndex].name = editingItem.value.name
        }
      }
      toast.success('הקומה עודכנה בהצלחה')
    } else if (editingType.value === 'area') {
      await SiteAreasApi.updateArea(
        site.value.id,
        editingItem.value.id,
        { 
          name: editingItem.value.name,
          site_area_type_id: editingItem.value.site_area_type_id
        }
      )
      const building = site.value.buildings.find(b => b.id === editingParentIds.value.buildingId)
      if (building) {
        const level = building.levels.find(l => l.id === editingParentIds.value.levelId)
        if (level) {
          const areaIndex = level.areas.findIndex(a => a.id === editingItem.value.id)
          if (areaIndex !== -1) {
            level.areas[areaIndex] = {
              ...level.areas[areaIndex],
              name: editingItem.value.name,
              site_area_type_id: editingItem.value.site_area_type_id
            }
          }
        }
      }
      toast.success('האזור עודכנה בהצלחה')
    }
  } catch (error) {
    console.error('Error saving edit:', error)
    toast.error('שגיאה בעדכון הפריט')
  }
  closeEditDialog()
}

const editBuilding = async (building) => {
  editingItem.value = { ...building }
  editingType.value = 'building'
  editBuildingDialog.value = true
}

const editLevel = async (buildingId, level) => {
  editingItem.value = { ...level }
  editingType.value = 'level'
  editingParentIds.value = { buildingId }
  editLevelDialog.value = true
}

const editArea = async (buildingId, levelId, area) => {
  editingItem.value = { ...area }
  editingType.value = 'area'
  editingParentIds.value = { buildingId, levelId }
  editAreaDialog.value = true
}

// CRUD Operations
const addBuilding = async () => {
  addBuildingDialog.value = true
}

const deleteBuilding = async (buildingId) => {
  if (confirm('האם אתה בטוח שברצונך למחוק את הבניין?')) {
    try {
      await SiteBuildingsApi.removeBuilding(site.value.id, buildingId)
      site.value.buildings = site.value.buildings.filter(b => b.id !== buildingId)
      toast.success('הבניין נמחק בהצלחה')
    } catch (error) {
      console.error('Error deleting building:', error)
      toast.error(toastMessageFromResponse(error, 'שגיאה במחיקת הבניין'))
    }
  }
}

const addLevel = (buildingId) => {
  addingBuildingId.value = buildingId
  addLevelDialog.value = true
}

const deleteLevel = async (buildingId, levelId) => {
  if (confirm('האם אתה בטוח שברצונך למחוק את הקומה?')) {
    try {
      await SiteLevelsApi.removeLevel(site.value.id, levelId)
      const building = site.value.buildings.find(b => b.id === buildingId)
      if (building) {
        building.levels = building.levels.filter(l => l.id !== levelId)
        toast.success('הקומה נמחקה בהצלחה')
      }
    } catch (error) {
      console.error('Error deleting level:', error)
      toast.error(toastMessageFromResponse(error, 'שגיאה במחיקת הקומה'))
    }
  }
}

const addArea = (buildingId, levelId) => {
  addingBuildingId.value = buildingId
  addingLevelId.value = levelId
  addAreaDialog.value = true
}

const deleteArea = async (buildingId, levelId, areaId) => {
  if (confirm('האם אתה בטוח שברצונך למחוק את האזור?')) {
    try {
      await SiteAreasApi.removeArea(site.value.id, areaId)
      const building = site.value.buildings.find(b => b.id === buildingId)
      if (building) {
        const level = building.levels.find(l => l.id === levelId)
        if (level) {
          level.areas = level.areas.filter(a => a.id !== areaId)
          toast.success('האזור נמחק בהצלחה')
        }
      }
    } catch (error) {
      toast.error(toastMessageFromResponse(error, 'שגיאה במחיקת האזור'))
    }
  }
}

const closeAddDialog = () => {
  addBuildingDialog.value = false
  addLevelDialog.value = false
  addAreaDialog.value = false
  newItem.value = { 
    name: '', 
    site_area_type_id: '',
    should_add_findings: false 
  }
  addingBuildingId.value = null
  addingLevelId.value = null
}

const saveNewItem = async () => {
  if (!newItem.value.name.trim()) return

  try {
    if (addBuildingDialog.value) {
      const response = await SiteBuildingsApi.createBuilding(site.value.id, {
        name: newItem.value.name
      })
      if (response.data) {
        site.value.buildings.push(response.data)
        toast.success('הבניין נוסף בהצלחה')
      }
    } else if (addLevelDialog.value) {
      const response = await SiteLevelsApi.createLevel(
        site.value.id,
        addingBuildingId.value,
        { name: newItem.value.name }
      )
      if (response.data) {
        const building = site.value.buildings.find(b => b.id === addingBuildingId.value)
        if (building) {
          building.levels.push(response.data)
          toast.success('הקומה נוספה בהצלחה')
        }
      }
    } else if (addAreaDialog.value) {
      const response = await SiteAreasApi.createArea(site.value.id, {
        site_level_id: addingLevelId.value,
        name: newItem.value.name,
        site_area_type_id: newItem.value.site_area_type_id,
        should_add_findings: newItem.value.should_add_findings
      })
      if (response.data) {
        const building = site.value.buildings.find(b => b.id === addingBuildingId.value)
        if (building) {
          const level = building.levels.find(l => l.id === addingLevelId.value)
          if (level) {
            level.areas.unshift(response.data)
            toast.success('האזור נוסף בהצלחה')
          }
        }
      }
    }
  } catch (error) {
    console.error('Error saving new item:', error)
    toast.error('שגיאה בהוספת פריט חדש')
  }

  closeAddDialog()
}

const handleBuildingDragChange = async (event) => {
  if (event.moved) {
    const { oldIndex, newIndex } = event.moved
    console.log('Building moved:', { oldIndex, newIndex })

    try {
      // Update all affected buildings' orders
      const updatedBuildings = site.value.buildings.map((building, index) => ({
        ...building,
        order: index + 1
      }))
      
      // Update the local state
      site.value.buildings = updatedBuildings

      // Update the moved building in the backend
      const movedBuilding = updatedBuildings[newIndex]
      await SiteBuildingsApi.updateBuilding(site.value.id, movedBuilding.id, {
        name: movedBuilding.name,
        order: newIndex + 1
      })
      toast.success('סדר המבנים עודכן בהצלחה')
    } catch (error) {
      console.error('Error updating building order:', error)
      toast.error('שגיאה בעדכון סדר המבנים')
      // Revert the drag if the API call fails
      const building = site.value.buildings.splice(newIndex, 1)[0]
      site.value.buildings.splice(oldIndex, 0, building)
    }
  }
}

// Sorting function for levels
const sortedLevels = (levels) => {
  if (!levels) return []
  return [...levels].sort((a, b) => a.order - b.order)
}

// Update levels when dragged
const updateLevels = (building, newValue) => {
  building.levels = newValue
}

// Handle level drag changes
const handleLevelDragChange = async (buildingId, event) => {
  if (event.moved) {
    const { oldIndex, newIndex } = event.moved
    console.log('Level moved:', { oldIndex, newIndex })

    try {
      const building = site.value.buildings.find(b => b.id === buildingId)
      if (!building) return

      // Update all affected levels' orders
      const updatedLevels = building.levels.map((level, index) => ({
        ...level,
        order: index + 1
      }))
      
      // Update the local state
      building.levels = updatedLevels

      // Update the moved level in the backend
      const movedLevel = updatedLevels[newIndex]
      await SiteLevelsApi.updateLevel(
        site.value.id,
        movedLevel.id,
        {
          name: movedLevel.name,
          order: newIndex + 1
        }
      )
      toast.success('סדר הקומות עודכן בהצלחה')
    } catch (error) {
      console.error('Error updating level order:', error)
      toast.error('שגיאה בעדכון סדר הקומות')
      // Revert the drag if the API call fails
      const building = site.value.buildings.find(b => b.id === buildingId)
      if (building) {
        const level = building.levels.splice(newIndex, 1)[0]
        building.levels.splice(oldIndex, 0, level)
      }
    }
  }
}

// Sorting function for areas
const sortedAreas = (areas) => {
  if (!areas) return []
  return [...areas].sort((a, b) => a.order - b.order)
}

// Update areas when dragged
const updateAreas = (level, newValue) => {
  level.areas = newValue
}

// Handle area drag changes
const handleAreaDragChange = async (levelId, event) => {
  if (event.moved) {
    const { oldIndex, newIndex } = event.moved
    console.log('Area moved:', { oldIndex, newIndex })

    try {
      const level = site.value.buildings
        .flatMap(b => b.levels)
        .find(l => l.id === levelId)
      
      if (!level) return

      // Update all affected areas' orders
      const updatedAreas = level.areas.map((area, index) => ({
        ...area,
        order: index + 1
      }))
      
      // Update the local state
      level.areas = updatedAreas

      // Update the moved area in the backend
      const movedArea = updatedAreas[newIndex]
      await SiteAreasApi.updateArea(
        site.value.id,
        movedArea.id,
        {
          name: movedArea.name,
          site_area_type_id: movedArea.site_area_type_id,
          order: newIndex + 1
        }
      )
      toast.success('סדר האזורים עודכן בהצלחה')
    } catch (error) {
      console.error('Error updating area order:', error)
      toast.error('שגיאה בעדכון סדר האזורים')
      // Revert the drag if the API call fails
      const level = site.value.buildings
        .flatMap(b => b.levels)
        .find(l => l.id === levelId)
      
      if (level) {
        const area = level.areas.splice(newIndex, 1)[0]
        level.areas.splice(oldIndex, 0, area)
      }
    }
  }
}

// Initialize collapsed levels when site data changes
watchEffect(() => {
  if (site.value?.buildings) {
    // Clear existing set
    collapsedLevels.value.clear()
    collapsedBuildings.value.clear()  // Also clear collapsed buildings
    
    // Add all building and level IDs to their respective collapsed sets
    site.value.buildings.forEach(building => {
      collapsedBuildings.value.add(building.id)  // Collapse buildings by default
      building.levels.forEach(level => {
        collapsedLevels.value.add(level.id)
      })
    })
  }
})

const addLevelGroup = () => {
  quickSetup.value.levelGroups.push({
    startLevel: 1,
    endLevel: 1,
    shouldAddAreas: true,
    areaTypes: [{ 
      count: 1,
      typeId: site.value?.area_types?.[0]?.id || '',
      prefix: 'דירה',
      numberingType: 'by_area'
    }]
  })
}

const removeLevelGroup = (index) => {
  quickSetup.value.levelGroups.splice(index, 1)
}

const addAreaType = (group) => {
  group.areaTypes.push({
    count: 1,
    typeId: '',
    prefix: 'דירה',
    numberingType: 'by_area'  // Default to by_area numbering
  })
}

const removeAreaType = (group, index) => {
  group.areaTypes.splice(index, 1)
}

const generateStructure = async () => {
  if (isGenerating.value) return
  
  isGenerating.value = true
  resetGlobalCounters() // Reset counters before starting

  try {
    // Create ground floor if specified
    if (quickSetup.value.hasGroundFloor) {
      await SiteLevelsApi.createLevel(site.value.id, currentBuildingId.value, {
        name: 'קומת קרקע'
      })
    }

    // Create level groups
    for (const group of quickSetup.value.levelGroups) {
      for (let level = group.startLevel; level <= group.endLevel; level++) {
        // Create level
        const levelResponse = await SiteLevelsApi.createLevel(
          site.value.id,
          currentBuildingId.value,
          { name: `קומה ${level}` }
        )

        // Create areas for this level
        if (group.shouldAddAreas) {
          for (const areaType of group.areaTypes) {
            const prefix = areaType.prefix?.trim() || 'דירה'
            for (let i = 1; i <= areaType.count; i++) {
              const areaNumber = getNextAreaNumber(prefix, level, areaType.numberingType)
              const areaName = formatAreaName(prefix, areaNumber)
              
              await SiteAreasApi.createArea(site.value.id, {
                site_level_id: levelResponse.data.id,
                name: areaName,
                site_area_type_id: areaType.typeId,
                should_add_findings: true
              })
            }
          }
        }
      }
    }

    toast.success('המבנה נוצר בהצלחה')
    quickSetupDialog.value = false
    
    // Refresh building data
    await loadSite()
  } catch (error) {
    console.error('Error generating structure:', error)
    toast.error('שגיאה ביצירת המבנה')
  } finally {
    isGenerating.value = false
  }
}

// Add this new counter for preview
const previewAreaCounters = new Map()

const resetPreviewCounters = () => {
  previewAreaCounters.clear()
}

const getPreviewAreaNumber = (prefix, level, numberingType) => {
  if (numberingType === 'by_area') {
    const key = prefix
    const current = previewAreaCounters.get(key) || 0
    previewAreaCounters.set(key, current + 1)
    return current + 1
  } else if (numberingType === 'by_level') {
    return level
  } else { // 'none'
    return null
  }
}

const getAreaName = (areaType, index, level) => {
  const prefix = areaType.prefix?.trim() || 'דירה'
  let number
  
  if (areaType.numberingType === 'by_area') {
    number = getPreviewAreaNumber(prefix, level, areaType.numberingType)
  } else if (areaType.numberingType === 'by_level') {
    number = level
  } else { // 'none'
    number = null
  }
  
  return formatAreaName(prefix, number)
}

const goToPreview = () => {
  if (isConfigurationValid.value) {
    resetPreviewCounters() // Reset counters before showing preview
    currentStep.value = 2
  }
}

const openQuickSetup = (buildingId) => {
  currentBuildingId.value = buildingId
  currentStep.value = 1 // Reset to first step
  quickSetup.value = {
    hasGroundFloor: true,
    levelGroups: []
  }
  resetGlobalCounters() // Reset counters when opening dialog
  resetPreviewCounters() // Reset preview counters as well
  quickSetupDialog.value = true
}

// Add these new computed properties and methods
const isConfigurationValid = computed(() => {
  // Check if there's at least ground floor or one level group
  const hasValidStructure = quickSetup.value.hasGroundFloor || quickSetup.value.levelGroups.length > 0
  
  // Validate each level group
  const hasValidGroups = quickSetup.value.levelGroups.every(group => {
    // Basic level validation
    const hasValidLevels = group.startLevel <= group.endLevel
    
    // If group has areas, validate area configurations
    if (group.shouldAddAreas) {
      return hasValidLevels && group.areaTypes.every(type => {
        return (
          // Area type must be selected
          !!type.typeId &&
          // Count must be positive
          type.count > 0 &&
          // Prefix must not be empty when using numbering
          (type.numberingType === 'none' || (type.prefix && type.prefix.trim().length > 0))
        )
      })
    }
    
    return hasValidLevels
  })

  return hasValidStructure && hasValidGroups
})

const getGroupLevels = (group) => {
  const levels = []
  for (let i = group.startLevel; i <= group.endLevel; i++) {
    levels.push(i)
  }
  return levels
}

const getAreaTypeName = (typeId) => {
  return site.value?.area_types?.find(type => type.id === typeId)?.name || ''
}

const getNumberingPlaceholder = (numberingType) => {
  switch (numberingType) {
    case 'by_area':
      return 'דירה'
    case 'by_level':
      return 'דירה'
    case 'none':
      return 'שם האזור'
    default:
      return 'דירה'
  }
}

const getNumberingExample = (areaType) => {
  const prefix = areaType.prefix?.trim() || 'דירה'
  switch (areaType.numberingType) {
    case 'by_area':
      return `${prefix} 1, ${prefix} 2, ${prefix} 3 ... ${prefix} 100`
    case 'by_level':
     return `${prefix} 1, ${prefix} 2, ${prefix} 3...`
    case 'none':
      return prefix
    default:
      return ''
  }
}

// Add this computed property
const getCurrentBuildingName = computed(() => {
  const building = site.value?.buildings?.find(b => b.id === currentBuildingId.value)
  return building?.name || ''
})

// Add validation messages
const getValidationErrors = (group, areaType) => {
  const errors = []
  
  if (!areaType.typeId) {
    errors.push('יש לבחור סוג אזור')
  }
  
  if (!areaType.count || areaType.count < 1) {
    errors.push('יש להגדיר כמות חיובית')
  }
  
  if (areaType.numberingType !== 'none' && (!areaType.prefix || !areaType.prefix.trim())) {
    errors.push('יש להגדיר שם')
  }
  
  return errors
}
</script>

<style scoped>
.building-drag-handle,
.level-drag-handle {
  @apply opacity-0 group-hover:opacity-100 hover:opacity-100;
}

@media (max-width: 640px) {
  .building-drag-handle,
  .level-drag-handle {
    @apply opacity-100;
  }
}

.area-drag-handle {
  @apply opacity-100;
}

@media (max-width: 640px) {
  .area-drag-handle {
    @apply opacity-100;
  }
}

.ltr {
  direction: ltr;
}
.rtl {
  direction: rtl;
}

.full-width-on-mobile {
  @media (max-width: 640px) {
    width:100%;
  }
}
</style> 
