<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class="r-page-table">
    <div class="r-page-table-header">
      <div class="r-page-table-header-content flex gap-4 flex-col sm:flex-row items-start sm:items-center">
        <h3 class="r-page-table-header-title">
          📋 {{ pageTitle }}
          <span v-if="totalReports">({{ totalReports }})</span>
        </h3>
        <div
            v-if="canFilterByUser"
            class="reports-toggle"
        >
          <button
              class="toggle-btn"
              :class="{ active: !showOnlyMyReports }"
              @click="showOnlyMyReports = false; onToggleReportsFilter(false)"
          >
            כל הדוחות
          </button>
          <button
              class="toggle-btn"
              :class="{ active: showOnlyMyReports }"
              @click="showOnlyMyReports = true; onToggleReportsFilter(true)"
          >
            הדוחות שלי
          </button>
        </div>
      </div>

      <div class="r-page-table-header-actions">
        <div class="d-flex align-center u-gap-2 flex-1">
          <RSearchCompact
              class="reports-search"
              v-model="searchQuery"
              placeholder="חיפוש שם לקוח, מספר דוח, כתובת"
              :initial-open="true"
          />
        </div>
        <div class="d-flex u-gap-2">
          <RButton
              color="grey-700"
              :icon-before="filtersIcon"
              outlined
              @click="isFiltersModalOpen = true"
          >
            {{ isMobile ? "" : "סינון" }}
          </RButton>
          <AddReportButton color="purple" id="create-report-button"/>
        </div>
      </div>
    </div>

    <div class="r-page-table-body">
      <div v-if="isMobile && isLoaded && reportsSections" class="rm-list">
        <div v-for="item in reportsSections" :key="item.id">
          <div class="rm-group">
            <p>
              {{ item.label }}
              <span>({{ item.reports.length }})</span>
            </p>
          </div>

          <div v-for="report in item.reports" :key="report.id" class="rm-item">
            <router-link
                :to="{
                name: 'reports.edit',
                params: { id: report.id },
                query: shouldStartAddFindingTour ? { tour: 'add-finding' } : {}
              }"
            >
              <div class="rm-item--header">
                <span class="date">
                  {{ $Utils.presentHebrewDate(report.created_at) }}
                </span>
                <span
                    class="number"
                    :class="{
                    draft: report.is_draft,
                  }"
                >
                  <template v-if="report.is_draft" class="mr-2"
                  >טיוטה /
                  </template>
                  {{ report.number }}
                </span>
              </div>
              <div class="rm-item--body">
                <div class="rm-item--body--right">
                  <p class="address">{{ report.address }}</p>
                  <p class="customer mt-3">{{ report.customerName }}</p>
                </div>

                <div class="rm-item--body--left">
                  <p>{{ report.user_name }}</p>
                  <RChip v-if="report.tag" sm class="mt-3">
                    {{ report.tag }}
                  </RChip>
                </div>
              </div>
            </router-link>
            <div class="rm-item--footer mt-3">
              <ReportStatusSelect
                  :report-id="report.id"
                  :status-id="report.status_id"
                  @update:status-id="(newStatusId) => report.status_id = newStatusId"
                  @click.prevent.stop
              />
            </div>
          </div>
        </div>
      </div>

      <v-table v-if="!isMobile && isLoaded && reportsSections">
        <thead>
        <tr>
          <th class="reports-col-number">מספר</th>
          <th class="reports-col-address">כתובת</th>
          <th class="reports-col-customer">לקוח</th>
          <th class="reports-col-user">משתמש</th>
          <th class="reports-col-template">תבנית</th>
          <th class="reports-col-status">סטטוס</th>
          <th class="reports-col-tag">תווית</th>
          <th class="reports-col-project">פרויקט</th>
          <th class="reports-col-date">תאריך יצירה</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="item in reportsSections" :key="item.id">
          <tr class="date-row">
            <td colspan="9">
              {{ item.label }}
              <span>({{ item.reports.length }})</span>
            </td>
          </tr>
          <tr v-for="report in item.reports" :key="report.id">
            <td>
              <router-link
                  :to="{
                    name: 'reports.edit',
                    params: { id: report.id },
                    query: shouldStartAddFindingTour ? { tour: 'add-finding' } : {}
                  }"
                  style="
                    color: var(--color-gray-700) !important;
                    text-decoration: underline;
                    text-underline-position: under;
                  "
              >{{ report.number }}
              </router-link
              >
              <span
                  v-if="report.is_draft"
                  class=" bg-gray-100 text-gray-600 text-xs px-1.5 py-0.25 rounded"
              >טיוטה</span>
            </td>
            <td>
              <router-link
                  :to="{
                    name: 'reports.edit',
                    params: { id: report.id },
                    query: shouldStartAddFindingTour ? { tour: 'add-finding' } : {}
                  }"
                  style="
                    color: var(--color-gray-700) !important;
                    text-decoration: underline;
                    text-underline-position: under;
                  "
              >{{ report.address }}
              </router-link
              >
            </td>
            <td>
              {{ report.customerName }}
            </td>
            <td>
              {{ report.user_name }}
            </td>
            <td>
              {{ report.name }}
            </td>
            <td style="font-size:13px;" @click.stop>
              <ReportStatusSelect
                  :report-id="report.id"
                  :status-id="report.status_id"
                  @update:status-id="(newStatusId) => report.status_id = newStatusId"
              />
            </td>
            <td>
              <RChip v-if="report.tag">{{ report.tag }}</RChip>
            </td>
            <td>
              <template v-if="report.project_name">{{
                  report.project_name
                }}
              </template>
            </td>
            <td>
              {{ $Utils.presentHebrewDate(report.created_at) }}
            </td>
          </tr>
        </template>
        </tbody>
      </v-table>

      <div v-if="isLoadingReports || !isLoaded" class="reports-loader">
        <RIconLoading size="20"/>
        <div class="subtitle-1">טוענים דוחות ...</div>
      </div>
    </div>

    <RModal v-model="isFiltersModalOpen" title="סינון דוחות">
      <RFields>
        <RFieldText v-model="filterReportTag" label="תווית"/>
        <RFieldSelect
            v-if="canFilterByUser"
            v-model="filterReportUserId"
            :clearable="true"
            :items="usersOptions"
            label="משתמש"
        />
        <RFieldSelect
            v-model="filterReportTemplateId"
            :clearable="true"
            :items="reportTemplatesOptions"
            label="תבנית"
        />
        <RFieldSelect
            v-model="filterReportStatusId"
            :clearable="true"
            :items="reportStatusesOptions"
            label="סטטוס"
        />


        <RButton
            color="primary"
            block
            lg
            type="submit"
            @click="isFiltersModalOpen = false"
        >
          סגירה
        </RButton>
      </RFields>
    </RModal>
  </div>


</template>

<script>
import {mapGetters} from "vuex";
import AddReportButton from "@/components/AddReportButton";
import {colorToBackgroundClassMap} from '@/common/constants'
import ReportStatusSelect from "@/components/ReportStatusSelect";
import {useUserRole} from '@/composables/useUserRole'
import {useShepherd} from 'vue-shepherd'
import TourService from '@/services/TourService'
import {onMounted, onBeforeUnmount} from 'vue'
import {useRouter, useRoute} from 'vue-router'

export default {
  setup() {
    const {getCurrentRoleName} = useUserRole()
    const router = useRouter()
    const route = useRoute()

    let shouldStartAddFindingTour = false;
    if (route.fullPath.includes('?tour=add-finding')) {
      shouldStartAddFindingTour = true;
    }

    // Add Finding Tour
    const addFindingTour = useShepherd({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shepherd-theme-default',
        scrollTo: true
      }
    });

    addFindingTour.addStep({
      id: 'step-1',
      text: '<b>לחצו על אחד הדוחות</b> <br> והוסיפו ממצא ראשון.',
      attachTo: {element: '.r-page-table-body', on: 'bottom'},
    })

    // Create Report Tour
    const createReportTour = useShepherd({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shepherd-theme-default',
        scrollTo: true
      }
    });

    createReportTour.addStep({
      id: 'step-1',
      text: '<b>לחצו על כפתור יצירת דוח חדש</b> <br> ומלאו את שם לקוח וכתובת.',
      attachTo: {element: '#create-report-button', on: 'bottom'},
      buttons: [
        {
          text: 'לחצתי',
          action: () => createReportTour.next()
        },
      ]
    })

    createReportTour.addStep({
      id: 'step-2',
      text: '<b>מלאו את שם הלקוח</b> <br> <small>(אפשר גם להכניס סתם משהו לדוגמא כרגע)</small>',
      attachTo: {element: '[name="customerName"]', on: 'bottom'},
      buttons: [
        {
          text: 'מילאתי, הבא',
          action: () => createReportTour.next()
        },
      ]
    })

    createReportTour.addStep({
      id: 'step-3',
      text: '<b>מלאו את כתובת הלקוח</b> <br> <small>(אפשר גם להכניס סתם משהו לדוגמא כרגע)</small>',
      attachTo: {element: '[name="address"]', on: 'top'},
      buttons: [
        {
          text: 'מילאתי, הבא',
          action: () => createReportTour.next()
        },
      ]
    })

    createReportTour.addStep({
      id: 'step-4',
      text: '<b>בחרו תבנית מהרשימה</b>',
      attachTo: {element: '#report-template-select', on: 'top'},
      buttons: [
        {
          text: 'בחרתי, הבא',
          action: () => createReportTour.next()
        },
      ]
    })

    createReportTour.addStep({
      id: 'step-5',
      text: '<b>לחצו על כפתור יצירת דוח</b> <br> ומידע תועברו לעמוד עריכת הדוח.',
      attachTo: {element: '#submit-report-button', on: 'top'},
    })

    onMounted(() => {

      // Start tour if URL contains ?tour
      if (route.fullPath.includes('?tour=create-report')) {
        setTimeout(() => {
          createReportTour.start()
        }, 1000)
      }

      if (shouldStartAddFindingTour) {
        setTimeout(() => {
          addFindingTour.start()
        }, 1000)
      }
    })

    onBeforeUnmount(() => {
      if (createReportTour.isActive()) {
        TourService.setStepComplete('createReport')
        createReportTour.complete()
      }
      if (addFindingTour.isActive()) {
        addFindingTour.hide();
      }
    })

    return {colorToBackgroundClassMap, getCurrentRoleName, shouldStartAddFindingTour}
  },
  components: {AddReportButton, ReportStatusSelect},
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isFiltersModalOpen: false,
      filterReportUserId: null,
      filterReportTemplateId: null,
      filterReportTag: "",
      filterReportStatusId: this.$route.query.status_id ? parseInt(this.$route.query.status_id) : null,
      isLoaded: false,
      isFiltersOpen: false,
      isLoadingReports: false,
      reports: [],
      totalReports: 0,
      page: 1,
      lastPage: 1,
      searchQuery: "",
      awaitingSearch: false,
      showOnlyMyReports: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["company"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("reportTemplates", ["reportTemplates"]),
    ...mapGetters("auth", ["reportStatuses"]),
    pageTitle() {
      if (this.getCurrentRoleName() === "user") {
        return "הדוחות שלי"
      }
      return "דוחות"
    },
    canFilterByUser() {
      if (this.getCurrentRoleName() === "user") {
        return false;
      }
      return true;
    },
    isMobile() {
      return window.innerWidth < 768;
    },
    filtersIcon() {
      let total = 0;
      if (this.filterReportUserId) {
        total++;
      }
      if (this.filterReportTemplateId) {
        total++;
      }
      if (this.filterReportTag) {
        total++;
      }
      if (this.filterReportStatusId) {
        total++;
      }

      if (total === 1) {
        return "mdi-numeric-1";
      }
      if (total === 2) {
        return "mdi-numeric-2";
      }
      if (total === 3) {
        return "mdi-numeric-3";
      }

      return "mdi-filter";
    },
    usersOptions() {
      return this.users.map((user) => {
        return {
          value: user.id,
          text: user.name,
        };
      });
    },
    reportStatusesOptions() {
      return this.reportStatuses.map((status) => {
        return {
          value: status.id,
          text: status.status,
        };
      });
    },
    reportTemplatesOptions() {
      return this.reportTemplates.map((reportTemplate) => {
        return {
          value: reportTemplate.id,
          text: reportTemplate.name,
        };
      });
    },
    reportsSections() {
      if (!this.reports) {
        return false;
      }

      let reportsToOrganize = [...this.reports];

      let reportsSections = {};

      if (!reportsToOrganize.length) {
        return reportsSections;
      }

      reportsSections = {};

      reportsToOrganize.forEach((report) => {
        let reportDate = this.getReportDate(report);

        let date_year_and_month = reportDate.year + "-" + reportDate.month;

        if (!reportsSections[date_year_and_month]) {
          reportsSections[date_year_and_month] = {
            label: this.convertMonthAndYearToString(date_year_and_month),
            reports: [],
          };
        }
        reportsSections[date_year_and_month].reports.push(report);
      });

      return reportsSections;
    },
  },
  watch: {
    searchQuery() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.resetFetchReports();
          this.awaitingSearch = false;
        }, 600);
      }
      this.awaitingSearch = true;
    },
    filterReportTag() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.resetFetchReports();
          this.awaitingSearch = false;
        }, 600);
      }
      this.awaitingSearch = true;
    },
    filterReportTemplateId() {
      this.resetFetchReports();
    },
    filterReportStatusId() {
      this.resetFetchReports();
    },
    filterReportUserId() {

      this.resetFetchReports();
    },
    '$route.query.status_id'(newStatusId) {
      this.filterReportStatusId = newStatusId ? parseInt(newStatusId) : null;
    }
  },
  async created() {
    await this.$store.dispatch("reportTemplates/fetchReportTemplates");
    if (this.$checkRole(["admin", "owner"])) {
      await this.$store.dispatch("users/fetchUsers");
    }
    await this.fetchReports();
    this.isLoaded = true;
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.onScroll);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    reportsServiceFetch(params) {
      return window.axios.get("/api/v1/reports/search", {params})
    },
    onScroll() {
      const endOfPage =
          window.innerHeight + window.pageYOffset + 40 >=
          document.body.offsetHeight;

      if (endOfPage) {
        this.fetchReports();
      }
    },
    resetFetchReports() {
      this.reports = [];
      this.lastPage = 1;
      this.page = 1;
      this.fetchReports();
    },
    // add leading zero
    pad(n, width, z) {
      z = z || "0";
      n = n + "";
      return n.length >= width
          ? n
          : new Array(width - n.length + 1).join(z) + n;
    },

    getReportDate(report) {
      let dateObject = new Date(report.created_at);
      return {
        day: this.pad(dateObject.getDate(), 2),
        month: this.pad(dateObject.getMonth() + 1, 2),
        year: dateObject.getFullYear(),
        dateObject: dateObject,
      };
    },
    getReportDayAndMonthString(report) {
      return (
          this.getReportDate(report).day + "." + this.getReportDate(report).month
      );
    },
    getReportDateDayName(report) {
      return this.convertDayNumberToName(
          this.getReportDate(report).dateObject.getDay()
      );
    },
    getReportDateMonthName(report) {
      return this.convertMonthNumberToName(this.getReportDate(report).month);
    },
    convertMonthAndYearToString(month_and_year) {
      var event = new Date(month_and_year);
      var options = {year: "numeric", month: "long"};
      return event.toLocaleDateString("he-IL", options);
    },
    convertMonthNumberToName(monthNumber) {
      var event = new Date(monthNumber);
      var options = {month: "long"};
      return event.toLocaleDateString("he-IL", options);
    },
    convertDayNumberToName(dayNumber) {
      let hebrewDays = [
        "ראשון",
        "שני",
        "שלישי",
        "רביעי",
        "חמישי",
        "שישי",
        "שבת",
      ];
      return hebrewDays[dayNumber];
    },
    isToday(someDate) {
      const today = new Date();
      return (
          someDate.getDate() === today.getDate() &&
          someDate.getMonth() === today.getMonth() &&
          someDate.getFullYear() === today.getFullYear()
      );
    },
    isYesterday(someDate) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return (
          someDate.getDate() === yesterday.getDate() &&
          someDate.getMonth() === yesterday.getMonth() &&
          someDate.getFullYear() === yesterday.getFullYear()
      );
    },
    isThisWeek(date) {
      const todayObj = new Date();
      const todayDate = todayObj.getDate();
      const todayDay = todayObj.getDay();

      // get first date of week
      const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));

      // get last date of week
      const lastDayOfWeek = new Date(firstDayOfWeek);
      lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

      // reset all date times to 0
      todayObj.setHours(0, 0, 0, 0);
      firstDayOfWeek.setHours(0, 0, 0, 0);
      lastDayOfWeek.setHours(0, 0, 0, 0);

      // if date is equal or within the first and last dates of the week
      return date >= firstDayOfWeek && date <= lastDayOfWeek;
    },
    async fetchReports() {
      if (this.isLoadingReports) {
        return;
      }
      if (this.page > this.lastPage) {
        return;
      }

      this.isLoadingReports = true;
      const {data} = await this.reportsServiceFetch({
        page: this.page,
        searchQuery: this.searchQuery,
        userId: this.filterReportUserId,
        reportTemplateId: this.filterReportTemplateId,
        reportTag: this.filterReportTag,
        reportStatusId: this.filterReportStatusId,
      });
      this.reports.push(...data.data);
      this.isLoadingReports = false;

      this.lastPage = data.meta.last_page;
      this.totalReports = data.meta.total;
      this.page++;
    },
    onToggleReportsFilter(value) {
      this.filterReportUserId = value ? this.$store.state.auth.user.id : null;
    },
  },
};
</script>

<style lang="scss">
.reports-loader {
  background: #fff;
  padding: 10px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  flex-direction: column;
  @media (max-width: 767px) {
    border-right: 0;
    border-left: 0;
  }

  &__text {
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
  }
}

.r-page-table .v-table tbody tr.date-row > td {
  font-size: 1rem;
  font-family: var(--font-family-noto);
  font-weight: 500;
  height: 52px !important;

  span {
    font-size: 0.875rem;
    font-family: var(--font-family-noto);
  }
}

.rm-list {
  padding-bottom: 8rem;
}

.rm-group {
  padding: 1rem;
  font-size: 18px;
  font-family: var(--font-family-noto);
  font-weight: 700;
  color: var(--color-gray-700) !important;

  p {
    margin-bottom: 0 !important;
  }

  span {
    font-size: 14px;
    font-family: var(--font-family-noto);
  }
}

.rm-item {
  margin: 0 1rem;
  background: white;
  border: 1px solid var(--color-gray-300);
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: 0.75rem;
  margin-bottom: 1rem;

  &--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    color: var(--color-gray-700) !important;
    margin-bottom: 0.75rem;

    .number {
      color: var(--color-gray-600) !important;
      background: var(--color-gray-100) !important;
      padding: 0.1rem 0.2rem;
      border-radius: 0.25rem;
      font-weight: 600;

      &.draft {
        background: var(--color-gray-100) !important;
        color: var(--color-gray-600) !important;
      }
    }
  }

  &--body {
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    font-size: 0.75rem;
    color: var(--color-gray-700) !important;

    p {
      margin-bottom: 0;
    }

    &--left {
      text-align: left;
      font-family: var(--font-family-noto);
      font-weight: 700;
      flex-basis: 40%;

      p {
        margin-top: 0.2rem;
      }
    }

    &--right {
      flex-basis: 60%;

      .address {
        font-family: var(--font-family-noto);
        font-weight: 700;
        font-size: 1rem !important;
      }

      .customer {
        font-size: 0.875rem !important;
        color: var(--color-gray-400) !important;
      }
    }
  }
}

// Report columns
.reports-col-number {
  width: 80px;
}

.reports-col-address {
  width: 220px;
  @media (max-width: 1440px) {
    width: 187px; // 220px - 15%
  }
  @media (max-width: 1250px) {
    width: 168px; // 187px - 10%
  }
}

.reports-col-customer {
  width: 200px;
  @media (max-width: 1440px) {
    width: 170px; // 200px - 15%
  }
  @media (max-width: 1250px) {
    width: 153px; // 170px - 10%
  }
}

.reports-col-user {
  width: 120px;
  @media (max-width: 1440px) {
    width: 102px; // 120px - 15%
  }
  @media (max-width: 1250px) {
    width: 92px; // 102px - 10%
  }
}

.reports-col-template {
  width: 200px;
  @media (max-width: 1440px) {
    width: 170px; // 200px - 15%
  }
  @media (max-width: 1250px) {
    width: 153px; // 170px - 10%
  }
}

.reports-col-status {
  width: 130px;
  @media (max-width: 1440px) {
    width: 110px; // 130px - 15%
  }
  @media (max-width: 1250px) {
    width: 99px; // 110px - 10%
  }
}

.reports-col-tag {
  width: 140px;
  @media (max-width: 1440px) {
    width: 119px; // 140px - 15%
  }
  @media (max-width: 1250px) {
    width: 107px; // 119px - 10%
  }
}

.reports-col-project {
  width: 140px;
  @media (max-width: 1440px) {
    width: 119px; // 140px - 15%
  }
  @media (max-width: 1250px) {
    width: 107px; // 119px - 10%
  }
}

.reports-search {
  @media (min-width: 1024px) {
    min-width: 300px;
  }
}

.reports-col-date {
  width: 150px;
}

.reports-toggle {
  display: inline-flex;
  border: 1px solid var(--color-gray-300);
  border-radius: 0.5rem;
  overflow: hidden;

  .toggle-btn {
    padding: 8px 12px;
    color: var(--color-gray-700) !important;
    box-shadow: 0px 1px 2px #1018280a;
    border: none;
    background: white;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
    color: var(--color-gray-600);
    border-radius: 0 !important;

    &:first-child {
      border-left: 1px solid var(--color-gray-300);
    }

    &:hover:not(.active) {
      background: var(--color-gray-50);
    }

    &.active {
      background: var(--color-purple-100);
      color: var(--color-purple-700) !important;
      font-weight: 500;
    }
  }
}
</style>
