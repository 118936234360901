import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
    timeout: 2000,
    position: "bottom-center",
    rtl: true,
    hideProgressBar: true,
    closeOnClick: false,
    draggablePercent: 0.9,
    draggable: false,
    maxToasts: 2,
  };

export default {
    install: (app) => {
        app.use(Toast, options);

        const toast = useToast();


        app.config.globalProperties.$toast = {
            success(message, args = {}) {
                toast.success(message, args);
            },
            error(message, args) {
                toast.error(message, args);
            },
            info(message, args) {
                toast.info(message, args);
            },
            warning(message, args) {
                toast.warning(message, args);
            }
        };
    }
};
