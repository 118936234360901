<template>
  <div class="p-4">
    <!-- Recipients Management Card -->
    <div class="bg-white sm:rounded-lg shadow sm:mx-0 -mx-4">
      <div class="p-4 border-b flex justify-between items-center">
        <div class="flex items-center gap-4">
          <h2 class="text-lg font-medium">
            נמענים
            <span v-if="recipients.length" class="text-gray-500 text-sm">({{ recipients.length }})</span>
          </h2>
        </div>
        <RButton
          icon-before="mdi-plus"
          @click="showRecipientForm = true"
        >
          הוספת נמען
        </RButton>
      </div>

      <!-- Replace table with list view -->
      <div class="divide-y divide-gray-200">
        <div v-if="!recipients.length" class="p-4 text-center text-gray-500">
          אין נמענים באתר זה
        </div>

        <div
          v-for="recipient in recipients"
          :key="recipient.id"
          class="p-4 flex items-start gap-4 hover:bg-gray-50 transition-colors cursor-pointer"
          @click="handleRowClick($event, recipient)"
        >
          <!-- Recipient Icon -->
          <div class="flex-shrink-0">
            <v-icon size="24" class="text-gray-400">
              mdi-account
            </v-icon>
          </div>

          <!-- Recipient Content -->
          <div class="flex-grow">
            <div class="flex items-center gap-2 mb-1">
              <h3 class="font-medium">{{ recipient.name }}</h3>
              <span class="text-sm text-gray-500">({{ recipient.role }})</span>
            </div>

            <!-- Contact Info -->
            <div class="text-sm text-gray-600 space-y-1">
              <div v-if="recipient.email" class="flex items-center gap-2">
                <v-icon size="16" class="text-gray-500">mdi-email</v-icon>
                {{ recipient.email }}
              </div>
              <div v-if="recipient.phone" class="flex items-center gap-2">
                <v-icon size="16" class="text-gray-500">mdi-phone</v-icon>
                {{ recipient.phone }}
              </div>
            </div>
          </div>

          <!-- Actions -->
           <RActions @click.stop>
            <RAction 
              text="עריכה"
              icon="mdi-pencil"
              @click="startEditingRecipient(recipient)"
            />
            <RAction 
              text="מחיקה"
              icon="mdi-delete"
              @click="removeRecipient(recipient)"
            />
           </RActions>
        </div>
      </div>
    </div>

    <!-- Update modal title dynamically based on editing state -->
    <RModal
      v-model="showRecipientForm"
      :title="editingRecipient ? 'ערוך נמען' : 'הוסף נמען'"
    >
      <div class="space-y-4">
        <RFieldText
          v-model="formRecipient.name"
          label="שם"
          required
        />
        <RFieldText
          v-model="formRecipient.role"
          label="תפקיד"
        />
        <RFieldText
          v-model="formRecipient.email"
          label="אימייל"
          type="email"
          required
        />
        <RFieldText
          v-model="formRecipient.phone"
          label="טלפון"
          type="tel"
        />
        <RButton 
          lg 
          block 
          :loading="isSubmitting"
          :disabled="!formRecipient.name || !formRecipient.email || isSubmitting"
          @click="editingRecipient ? updateRecipient() : createRecipient()"
        >
          {{ editingRecipient ? 'עדכן נמען' : 'הוסף נמען' }}
        </RButton>
      </div>
    </RModal>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import SitesApi from '@/api/SitesApi'
import { toastMessageFromResponse } from '@/common/helpers/response-helpers'
import { useToast } from 'vue-toastification'

const toast = useToast()
const route = useRoute()
const siteId = route.params.id
const recipients = ref([])
const showRecipientForm = ref(false)
const editingRecipient = ref(null)
const isSubmitting = ref(false)
const formRecipient = ref({
  name: '',
  role: '',
  email: '',
  phone: ''
})

// Load recipients when component mounts
const loadRecipients = async () => {
  try {
    const response = await SitesApi.getSiteRecipients(siteId)
    recipients.value = response.data
  } catch (error) {
    console.error('Error loading recipients:', error)
    // TODO: Add error handling/notification
  }
}

const closeForm = () => {
  showRecipientForm.value = false
  editingRecipient.value = null
  formRecipient.value = {
    name: '',
    role: '',
    email: '',
    phone: ''
  }
}

const createRecipient = async () => {
  try {
    isSubmitting.value = true
    const response = await SitesApi.createSiteRecipient(siteId, formRecipient.value)
    if (!Array.isArray(recipients.value)) {
      recipients.value = []
    }
    recipients.value = [...recipients.value, response.data]
    closeForm()
  } catch (error) {
    console.error('Error creating recipient:', error)
    toast.error(toastMessageFromResponse(error, 'שגיאה ביצירת נמען'))
  } finally {
    isSubmitting.value = false
  }
}

const startEditingRecipient = (recipient) => {
  editingRecipient.value = recipient
  formRecipient.value = { ...recipient }
  showRecipientForm.value = true
}

const updateRecipient = async () => {
  try {
    isSubmitting.value = true
    const response = await SitesApi.updateSiteRecipient(
      siteId,
      editingRecipient.value.id,
      formRecipient.value
    )
    
    const index = recipients.value.findIndex(r => r.id === editingRecipient.value.id)
    if (index !== -1) {
      recipients.value[index] = response.data
    }
    
    closeForm()
  } catch (error) {
    console.error('Error updating recipient:', error)
    toast.error(toastMessageFromResponse(error, 'שגיאה בעדכון נמען'))
  } finally {
    isSubmitting.value = false
  }
}

const removeRecipient = async (recipient) => {
  if (!confirm('האם אתה בטוח שברצונך למחוק נמען זה?')) return
  
  try {
    await SitesApi.removeSiteRecipient(siteId, recipient.id)
    recipients.value = recipients.value.filter(r => r.id !== recipient.id)
  } catch (error) {
    console.error('Error removing recipient:', error)
    // TODO: Add error handling/notification
  }
}

const handleRowClick = (event, recipient) => {
  // Prevent click if user is selecting text
  if (window.getSelection().toString()) return
  
  startEditingRecipient(recipient)
}

onMounted(() => {
  loadRecipients()
})
</script> 