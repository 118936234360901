import { ref, readonly, computed, watch } from 'vue';
import ReportsApi from '@/api/ReportsApi';
import { Utils } from '@/common/Utils';
import store from '@/store';
import { generateUUID } from '@/common/helpers/uuid';
import posthog from 'posthog-js';
import db from '@/common/db';
import { useToast } from 'vue-toastification';

const toast = useToast();

// Constants
const RETRY_DELAY = 10000;
const MAX_LOGS = 500;
const LOG_BATCH_INTERVAL = 60000; // 1 minute in milliseconds

// State
const state = {
    isSyncing: ref(false),
    currentMessage: ref(null),
    hasInternet: ref(true),
    logs: ref([]),
    retryTimeout: null,
    totalMessagesToSync: ref(0),
    totalMessagesSynced: ref(0),
    initialLoadingTotalMessagesToSync: ref(0),
    isInitialized: false,
    logBuffer: ref([]),
    logBatchTimer: null
}

// Computed
const currentUserId = computed(() => store.state.auth.user?.id);
const currentCompanyId = computed(() => store.state.auth.company?.company_id)

// Initialize log batch sending
function initializeLogBatching() {
    if (state.logBatchTimer) {
        clearInterval(state.logBatchTimer);
    }
    
    state.logBatchTimer = setInterval(() => {
        if (state.logBuffer.value.length > 0 && localStorage.getItem('settings/debugMode') === 'true') {
            posthog.capture('log:background_sync_logs_batch', {
                logs: state.logBuffer.value
            });
            state.logBuffer.value = [];
        }
    }, LOG_BATCH_INTERVAL);
}

// Logging functions
function addLog(message, type = 'info', details = null) {
    const time = new Date().toLocaleTimeString('he-IL', { 
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });
    
    const logEntry = { message, type, time, details: details ? JSON.stringify(details) : null };
    console.log(`[SYNC] [${type.toUpperCase()}] ${message}`, details || '');
    
    // Add to buffer if debug mode is enabled
    if (localStorage.getItem('settings/debugMode') === 'true') {
        state.logBuffer.value.push({
            message,
            type,
            details,
            timestamp: Date.now()
        });
    }
    
    state.logs.value.push(logEntry);
    if (state.logs.value.length > MAX_LOGS) state.logs.value.shift();
}

// Initialize messages count
async function initializeMessageCount() {
    if (state.isInitialized) return;
    
    try {
        const count = await db.messages
            .where('[user_id+company_id]')
            .equals([currentUserId.value, currentCompanyId.value])
            .count();
        
        state.totalMessagesToSync.value = count;
        state.totalMessagesSynced.value = 0;
        state.initialLoadingTotalMessagesToSync.value = count;
        console.log('initialLoadingTotalMessagesToSync', state.initialLoadingTotalMessagesToSync.value);
        addLog('נטען מספר הודעות ממתינות', 'info', { count });
        state.isInitialized = true;
    } catch (err) {
        addLog('שגיאה בטעינת מספר הודעות', 'error', { error: err.message });
        toast.error('שגיאה בטעינת מספר הודעות. נא לפנות לתמיכה.');
        state.totalMessagesToSync.value = 0;
        state.totalMessagesSynced.value = 0;
    }
}

// Watch for sync completion and refresh page
watch(state.totalMessagesSynced, (newVal) => {
  if (state.initialLoadingTotalMessagesToSync.value > 0 && newVal === state.initialLoadingTotalMessagesToSync.value) {
    window.location.reload();
  }
});

// Internet connectivity
async function checkInternetConnection() {
    try {
        const isOnline = await ReportsApi.checkInternet();
        const wasOffline = !state.hasInternet.value;
        state.hasInternet.value = isOnline;
        
        if (wasOffline && isOnline) {
            addLog('חיבור אינטרנט זוהה, מתחיל סנכרון', 'success');
            sync();
        }
        
        return isOnline;
    } catch (err) {
        state.hasInternet.value = false;
        addLog('אין חיבור אינטרנט', 'error');
        return false;
    }
}

// Message handling
async function addMessage(message) {
    try {
        const enrichedMessage = {
            ...message,
            created_at: Date.now(),
            user_id: currentUserId.value,
            company_id: currentCompanyId.value,
            message_id: generateUUID() // uuid
        };

        addLog('שומר הודעה חדשה', 'info', { messageId: enrichedMessage.message_id });
        await db.messages.add(enrichedMessage);
        state.totalMessagesToSync.value++;
        
        if (!state.isSyncing.value) {
            sync();
        }

        return enrichedMessage;
    } catch (err) {
        addLog('שגיאה בשמירת הודעה', 'error', { error: err.message });
        toast.error('שגיאה בשמירת הודעה. נא לפנות לתמיכה.');
        posthog.capture('error:failed_to_add_message', { error: err.message });
        throw err;
    }
}

// Queue management

async function getNextMessageToSync() {
    try {
        const message = await db.messages
            .where('[user_id+company_id]').equals([currentUserId.value, currentCompanyId.value])
            .first();
        
        if (!message) {
            return null;
        }
        
        return message;
    } catch (err) {
        addLog('שגיאה בקבלת ההודעה הבאה', 'error', { error: err.message });
        return null;
    }
}

// Image processing
async function processMessageImages(message) {
    if (!message.data) return message;

    const imagesToUpload = Object.entries(message.data)
        .filter(([_, value]) => value && Utils.isBase64Image(value))
        .map(([key, value]) => ({
            key,
            value,
            sizeInMB: (value.length / 1024 / 1024).toFixed(2)
        }));

    if (!imagesToUpload.length) return message;

    const processedMessage = { ...message };
    addLog(`מתחיל העלאת ${imagesToUpload.length} תמונות`, 'info');

    for (const [index, image] of imagesToUpload.entries()) {
        try {
            addLog(`מעלה תמונה ${index + 1}/${imagesToUpload.length}`, 'info', {
                sizeInMB: image.sizeInMB
            });

            const { data } = await Utils.uploadImageBase64(image.value);
            processedMessage.data[image.key] = data.path;
        } catch (err) { 
            addLog(`שגיאה בהעלאת תמונה ${index + 1}`, 'error', {
                messageId: message.id,
                error: err.message
            });
            throw err;
        }
    }

    return processedMessage;
}

// Retry mechanism
function scheduleRetry() {
    if (state.retryTimeout) {
        clearTimeout(state.retryTimeout);
    }

    addLog('מתזמן ניסיון סנכרון חדש בעוד 10 שניות');
    
    state.retryTimeout = setTimeout(async () => {
        const isOnline = await checkInternetConnection();
        if (isOnline) {
            state.isSyncing.value = false; // Reset sync state before retrying
            sync();
        } else {
            addLog('עדיין אין חיבור אינטרנט, מנסה שוב בעוד 10 שניות', 'warning');
            scheduleRetry();
        }
    }, RETRY_DELAY);
}

// Main sync function
async function sync() {
    if (state.isSyncing.value) {
        addLog('סנכרון כבר פעיל', 'info');
        return;
    }

    if (!state.hasInternet.value) {
        addLog('אין חיבור אינטרנט, ממתין לחיבור', 'warning');
        scheduleRetry();
        return;
    }

    state.isSyncing.value = true;
    state.currentMessage.value = null;

    try {
        const message = await getNextMessageToSync();
        if (!message) {
            addLog('אין הודעות לסנכרון', 'success');
            state.isSyncing.value = false;
            state.currentMessage.value = null;
            return;
        }

        state.currentMessage.value = message;
        addLog('מתחיל סנכרון הודעה', 'info', { messageId: message.id });

        const processedMessage = await processMessageImages(message);
        const response = await ReportsApi.doUpdate(processedMessage.dataset_id, processedMessage);
        
        if (!response?.success) {
            throw new Error('Server returned unsuccessful response');
        }

        let messageIdFromServer = response.data.id;

        if (messageIdFromServer !== message.id) {
            toast.error('שגיאה במערכת הסנכרון. נא לפנות לתמיכה.');
            throw new Error('messageIdFromServer and message.id arent equal');

        }

        await db.messages.where('id').equals(message.id).delete();
        addLog('הודעה סונכרנה בהצלחה', 'success', { messageId: message.id });
        state.totalMessagesToSync.value = Math.max(0, state.totalMessagesToSync.value - 1);
        state.totalMessagesSynced.value++;
        
        // Reset states
        state.isSyncing.value = false;
        state.currentMessage.value = null;

        // If we have more messages, continue syncing immediately
        if (state.totalMessagesToSync.value > 0) {
            sync();
        }

    } catch (error) {
        addLog('שגיאה בסנכרון', 'error', { 
            messageId: state.currentMessage.value?.id,
            error: error.message 
        });

        const isOnline = await checkInternetConnection();
        if (!isOnline) {
            scheduleRetry();
        } else {
            // If we're online but got an error, retry after delay
            setTimeout(() => {
                state.isSyncing.value = false;
                state.currentMessage.value = null;
                sync();
            }, RETRY_DELAY);
        }
    }
}

// Cleanup function
function cleanup() {
    if (state.retryTimeout) {
        clearTimeout(state.retryTimeout);
        state.retryTimeout = null;
    }
    if (state.logBatchTimer) {
        clearInterval(state.logBatchTimer);
        state.logBatchTimer = null;
    }
}

// Create a singleton instance with initialization
let instance = null;

export function useBackgroundSync() {
    if (instance) return instance;

    // Initialize the counter and log batching only once
    initializeLogBatching();

    instance = {
        // State
        isSyncing: readonly(state.isSyncing),
        currentMessage: readonly(state.currentMessage),
        hasInternet: readonly(state.hasInternet),
        logs: readonly(state.logs),
        totalMessagesToSync: readonly(state.totalMessagesToSync),
        totalMessagesSynced: readonly(state.totalMessagesSynced),

        // Methods
        addMessage,
        sync,
        addLog,
        initializeMessageCount,
        cleanup, // Make sure cleanup is exposed
    };

    return instance;
}

// Export both the function and the singleton instance
export default useBackgroundSync();
