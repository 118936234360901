import { Utils } from "@/common/Utils";

async function getCompanyReports(data) {
  const response = await window.axios.get(`/company/reports/search`, data);

  return response.data;
}

async function getReport(reportId) {
  const response = await window.axios.get(`/api/v1/reports/${reportId}/`);

  if (!response.data.defectsGroupedBy) {
    response.data.defectsGroupedBy = "category";
  }

  return response.data;
}

async function getReports() {
  const response = await window.axios.get(`/api/v1/reports/search`);
  return response.data;
}

async function getReportPreview(reportId) {
  const response = await window.axios.get(
    `/api/v1/reports/${reportId}/preview`
  );

  return response.data;
}

async function getDownloadReportShortLink(reportId) {
  const response = await window.axios.get(
    `/api/v1/reports/${reportId}/download-short-link`
  );
  return response.data.data.url;
}

async function downloadReport(reportId) {
  await window.axios
    .get(`/api/v1/reports/${reportId}/download`)
    .then((response) => {
      if (response.data.data.url) {
        let fileUrl = response.data.data.url;

        var filename = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);

        // Create a new link
        const anchor = document.createElement("a");
        anchor.href = fileUrl;
        anchor.download = filename;
        if (!Utils.isIOSDevice() && window.innerWidth > 1100) {
          anchor.target = "_blank"; // TODO: it's not working on the iOS App so we disabled it for iOS for now.
        }

        // Append to the DOM
        document.body.appendChild(anchor);

        // Trigger `click` event
        anchor.click();

        // Remove element from DOM
        document.body.removeChild(anchor);
      } else {
        console.error("Error download report");
      }
    })
    .catch((error) => {
      console.error("Fail to download file", error);
    });
}


async function exportReportToExcel(reportId) {
  const response = await window.axios.get(
    `/api/v1/reports/${reportId}/excel`
  );
  return response.data;
}


async function saveAsTemplate(reportId, templateName) {
  const response = await window.axios.post(
    `/api/v1/reports/${reportId}/save-as-template`,
    {
      templateName,
    }
  );
  return response.data;
}

async function doUpdate(reportId, fields) {
  const response = await window.axios.put(
    `/api/v1/reports/${reportId}/do-update`,
    fields
  );
  return response.data;
}

async function sendReport(reportId, options = []) {
  const response = await window.axios.put(
    `/api/v1/reports/${reportId}/send`,
    options
  );
  return response.data;
}

async function doUpdateItemSettings(reportId, itemId, settings) {
  const response = await window.axios.put(
    `/api/v1/reports/${reportId}/do-update-item-settings`,
    {
      item_id: itemId,
      settings: settings,
    }
  );
  return response.data;
}

async function doUpdateItemsOrder(reportId, newItemsOrder) {
  const response = await window.axios.put(
    `/api/v1/reports/${reportId}/do-update-items-order`,
    {
      new_items_order: newItemsOrder,
    }
  );
  return response.data;
}

async function doRemoveItem(reportId, itemId) {
  const response = await window.axios.put(
    `/api/v1/reports/${reportId}/do-remove-item`,
    {
      item_id: itemId,
      deleted_at: Utils.currentTimestamp(),
    }
  );
  return response.data;
}

async function doAddItem(reportId, params) {
  const response = await window.axios.post(
    `/api/v1/reports/${reportId}/do-add-item`,
    {
      new_item: params.newItem,
      new_items_order: params.newItemsOrder,
    }
  );
  return response.data;
}

async function updateSettings(reportId, settings) {
  const response = await window.axios.put(
    `/api/v1/reports/${reportId}/settings`,
    settings
  );
  return response.data;
}

async function convertDraftToPublished(reportId) {
  const response = await window.axios.put(
    `/api/v1/reports/${reportId}/convert-draft-to-published`
  );
  return response.data;
}

async function removeReport(reportId) {
  const response = await window.axios.delete(`/api/v1/reports/${reportId}`);
  return response.data;
}

async function cloneReport(reportId, params) {
  const response = await window.axios.put(
    `/api/v1/reports/${reportId}/clone`,
    params
  );
  return response.data;
}

async function updateReport(reportId, params) {
  const response = await window.axios.put(
    `/api/v1/reports/${reportId}`,
    params
  );
  return response.data;
}

async function createReport(params) {
  const response = await window.axios.post(`/api/v1/reports`, params);
  return response.data;
}

async function convertDraftToPublishedReport(reportId) {
  const response = await window.axios.put(
    `/api/v1/reports/${reportId}/convert-draft-to-published`
  );
  return response.data;
}

async function checkInternet() {
  try {
    const response = await window.axios.get('/api/v1/check-internet');
    return response.status === 200;
  } catch (err) {
    return false;
  }
}

async function updateStatus(reportId, statusId) {
  const response = await window.axios.put(`/api/v1/reports/${reportId}`, {
    statusId: statusId,
  });
  return response.data;
}

export default {
  getCompanyReports,
  getReport,
  getReports,
  getReportPreview,
  doUpdate,
  downloadReport,
  getDownloadReportShortLink,
  saveAsTemplate,
  sendReport,
  doUpdateItemSettings,
  doRemoveItem,
  doUpdateItemsOrder,
  doAddItem,
  updateSettings,
  convertDraftToPublished,
  removeReport,
  cloneReport,
  updateReport,
  createReport,
  convertDraftToPublishedReport,
  checkInternet,
  updateStatus,
  exportReportToExcel
};
