<template>
  <div v-if="shouldShow" class="log-card" :class="{ 'minimized': isLogMinimized }">
    <div class="card-header">
      <div class="header-left">
        <!-- Sync Status Icons -->
        <div class="status-icons">
          <div v-if="isSyncing" class="status-icon">
            <div class="sync-spinner"></div>
          </div>
          <div v-if="!hasInternet" class="status-icon offline">
            <i class="mdi mdi-wifi-off"></i>
          </div>
          <div v-if="totalMessagesToSync > 0" class="messages-count">
            {{ totalMessagesToSync }} הודעות לסנכרון
          </div>
        </div>
        <h3
          v-if="!isSyncing && totalMessagesToSync < 1 && hasInternet"
         class="title">לוג סנכרון</h3>
      </div>
      <div class="header-actions">
        <RButtonIcon
          color="slate-700"
          class="mr-2"
          @click="toggleLogMinimize"
          :title="isLogMinimized ? 'הרחב' : 'כווץ'"
          :icon="isLogMinimized ? 'mdi-arrow-expand' : 'mdi-arrow-collapse'"
        />
      </div>
    </div>

    <div v-if="!isLogMinimized" class="log-window" ref="logWindow">
      <div v-for="(log, index) in [...logs].reverse()" :key="index" 
           :class="['log-entry', log.type]">
        <span class="log-time">{{ log.time }}</span>
        <span class="log-message">{{ log.message }}</span>
        <div v-if="log.details" class="log-details">
          <template v-if="typeof log.details === 'string'">
            <div v-for="(value, key) in JSON.parse(log.details)" :key="key" 
                 class="detail-item">
              <template v-if="key === 'messageId'">
                <span class="detail-label">מזהה הודעה:</span>
                <span class="detail-value">{{ value }}</span>
              </template>
              <template v-else-if="key === 'sizeInMB'">
                <span class="detail-label">גודל קובץ:</span>
                <span class="detail-value">{{ value }} MB</span>
              </template>
              <template v-else-if="key === 'count'">
                <span class="detail-label">כמות:</span>
                <span class="detail-value">{{ value }}</span>
              </template>
              <template v-else-if="key === 'error'">
                <span class="detail-label">שגיאה:</span>
                <span class="detail-value error-text">{{ value }}</span>
              </template>
              <template v-else>
                <span class="detail-label">{{ key }}:</span>
                <span class="detail-value">{{ value }}</span>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import backgroundSync from '@/composables/useBackgroundSync';

const {
  isSyncing,
  hasInternet,
  sync,
  logs,
  totalMessagesToSync,
  initializeMessageCount,
} = backgroundSync;

const logWindow = ref(null);
const isLogMinimized = ref(true);

const shouldShow = computed(() => {
  const debugMode = localStorage.getItem('settings/debugMode') === 'true'
  return debugMode || totalMessagesToSync.value > 0
})

const toggleLogMinimize = () => {
  isLogMinimized.value = !isLogMinimized.value;
};

onMounted(() => {
  initializeMessageCount();
  sync();
});
</script>

<style scoped>
.log-card {
  position: fixed;
  bottom: 24px;
  left: 24px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  width: 480px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  direction: rtl;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 300;
}

.log-card.minimized {
  width: auto;
  max-width: 300px;
  transform-origin: bottom left;
}

.card-header {
  padding: 10px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: #f8fafc;
}

.header-left {
  display: flex;
  align-items: center;
  gap: 6px;
}

.status-icons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.status-icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sync-spinner {
  width: 100%;
  height: 100%;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #3b82f6;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.status-icon.offline {
  color: #dc2626;
}

.messages-count {
  font-size: 14px;
  color: #3b82f6;
  font-weight: 500;
}

.title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #1e293b;
}

.header-actions {
  display: flex;
  align-items: center;
}

.log-window {
  background: #f8fafc;
  padding: 12px;
  height: 600px;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  font-size: 13px;
}

.log-entry {
  padding: 8px 12px;
  border-radius: 6px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.log-time {
  color: #64748b;
  font-size: 12px;
}

.log-message {
  color: #1e293b;
  line-height: 1.4;
}

.log-details {
  color: #475569;
  font-size: 12px;
  padding: 8px;
  margin-top: 4px;
  background: #f1f5f9;
  border-radius: 4px;
}

.detail-item {
  display: flex;
  gap: 8px;
  padding: 2px 0;
}

.detail-label {
  color: #64748b;
  min-width: 100px;
}

.detail-value {
  color: #1e293b;
}

.error-text {
  color: #dc2626;
}

.log-entry.error {
  background: #fef2f2;
  border: 1px solid rgba(239, 68, 68, 0.2);
  border-right: 3px solid #dc2626;
}

.log-entry.warning {
  background: #fffbeb;
  border: 1px solid rgba(245, 158, 11, 0.2);
  border-right: 3px solid #f59e0b;
}

.log-entry.success {
  background: #f0fdf4;
  border: 1px solid rgba(34, 197, 94, 0.2);
  border-right: 3px solid #22c55e;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.minimized .card-header {
  padding: 6px 10px;
}

.minimized .title {
  font-size: 14px;
}

.minimized .messages-count {
  font-size: 12px;
}

.minimized .status-icon {
  width: 16px;
  height: 16px;
}

@media (max-width: 768px) {
  .log-card {
    width: calc(100% - 48px);
    max-width: none;
    top: 24px;
    bottom: auto;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  
  .log-card.minimized {
    width: auto;
    max-width: 360px;
    transform: translateX(-50%);
  }

  .log-window {
    max-height: calc(80vh - 180px);
  }
}
</style>
