<template>
    <div class="space-y-2">
      <!-- Header with Toggle -->
      <div 
        class="text-md font-medium text-gray-700 flex items-center justify-between p-3 rounded-lg bg-gray-100 hover:bg-gray-200 transition-colors cursor-pointer"
        @click="isExpanded = !isExpanded"
      >
        <div class="flex items-center gap-1">
          תמונות
          <span v-if="images.length > 0" class="text-sm text-gray-500 bg-white rounded-full px-1.5">
            {{ images.length }}
          </span>
        </div>
        <v-icon
          :icon="isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-left'"
          size="small"
          class="text-gray-600"
        />
      </div>

      <!-- Collapsible Content -->
      <div v-show="isExpanded">
        <!-- Upload Area -->
        <div 
          class="border-2 border-dashed border-purple-300 rounded-lg p-6 text-center hover:border-purple-500 transition-colors cursor-pointer"
          :class="{ 'opacity-50': isUploading }"
          @click="triggerFileInput"
          @dragover.prevent="isDragging = true"
          @dragleave.prevent="isDragging = false"
          @drop.prevent="handleDrop"
        >
          <input
            type="file"
            ref="fileInput"
            class="hidden"
            accept="image/*"
            multiple
            @change="handleFileSelect"
          >
          <div class="space-y-2">
            <div class="text-purple-600">
              <i class="mdi mdi-cloud-upload text-3xl"></i>
            </div>
            <template v-if="!isUploading">
              <div class="text-gray-600">
                גרור תמונות לכאן או לחץ להעלאה
              </div>
              <div class="text-sm text-gray-400">
                ניתן להעלות מספר תמונות
              </div>
            </template>
            <template v-else>
              <div class="text-purple-600 flex items-center justify-center gap-2">
                <v-icon
                  icon="mdi-loading"
                  class="animate-spin"
                  size="small"
                />
                מעלה תמונות...
              </div>
            </template>
          </div>
        </div>
    
        <!-- Images List -->
        <div v-if="images.length > 0" class="space-y-3 mt-4">
          <div v-for="(image, index) in images" :key="image.id" 
            class="relative flex items-center border-2 border-gray-200 rounded-lg p-2"
          >
            <img 
              :src="image.url" 
              class="h-14 w-14 sm:h-16 sm:w-16 object-cover rounded-lg"
              @click="handleImageClick(image)"
              :class="{ 'opacity-50 cursor-not-allowed': syncingImageId !== null }"
              :style="syncingImageId === null || syncingImageId === image.id ? 'cursor: pointer' : ''"
            >
            <div class="mr-2 sm:mr-3 flex-grow">
              <div class="text-sm sm:text-base font-medium flex items-center gap-2">
                תמונה #{{ index + 1 }}
                <v-icon
                  v-if="syncingImageId === image.id"
                  icon="mdi-loading"
                  class="animate-spin text-purple-600"
                  size="small"
                />
              </div>
              <div class="text-xs sm:text-sm text-gray-500">
                נוספה ב-{{ new Date(image.createdAt).toLocaleString('he-IL', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                }).replace(',', ' · ') }}
              </div>
            </div>
            <RButtonIcon
              @click="deleteImage(image)"
              icon="mdi-delete"
              class="text-gray-500 hover:text-gray-600"
            />
          </div>
        </div>


        <v-dialog
                v-if="editImage.isEditImageModalOpen"
                v-model="editImage.isEditImageModalOpen"
                :max-width="700"
                :persistent="true"
                class="edit-image-dialog"
                :class="{ 'edit-image-dialog--v3': hasFeature('edit-image-modal-v3') }"
            >
              <component
                  :is="editImageModalComponent"
                  :is-edit-image-modal-open="editImage.isEditImageModalOpen"
                  :upload="editImage.upload"
                  :upload-initial="editImage.uploadInitial"
                  @close-edit-image="closeEditImage"
                  @update-edit-image="updateEditImage"
                  :close-edit-image="closeEditImage"
                  :update-edit-image="updateEditImage"
                  :media-url="$Reporto.globals.MEDIA_URL"
              />
              <!-- Remove :close, :update after remoeving old editImageModal -->
            </v-dialog>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed } from 'vue'
  import { Utils } from '@/common/Utils'
  import { useToast } from 'vue-toastification'
  import EditImageModalV3 from "@/components/EditImageModal-v3/EditImageModal.vue";
  import EditImageModalOld from "@/components/editImageModal.prev/EditImageModal.vue";
  import {useCompanyFeatures} from "@/composables/useCompanyFeatures";

  const {hasFeature} = useCompanyFeatures()

  
  const props = defineProps({
    modelValue: {
      type: Array,
      default: () => []
    }
  })
  
  const emit = defineEmits(['update:modelValue'])
  
  const toast = useToast()
  const fileInput = ref(null)
  const isUploading = ref(false)
  const isDragging = ref(false)
  
  const images = ref(props.modelValue || [])
  
  const editImage = ref({
    isEditImageModalOpen: false,
    upload: {},
    uploadInitial: {}
  })
  
  const syncingImageId = ref(null)
  
  const isExpanded = ref(false)
  
  const triggerFileInput = () => {
    fileInput.value.click()
  }
  
  const handleFileSelect = async (event) => {
    const files = Array.from(event.target.files)
    await uploadFiles(files)
    event.target.value = null // Reset input
  }
  
  const handleDrop = async (event) => {
    isDragging.value = false
    const files = Array.from(event.dataTransfer.files).filter(file => file.type.startsWith('image/'))
    await uploadFiles(files)
  }
  
  const uploadFiles = async (files) => {
    isUploading.value = true
    
    try {
      // Create an array of promises for all file uploads
      const uploadPromises = files.map(file => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          
          reader.onload = async (e) => {
            try {
              const response = await Utils.uploadImageBase64(e.target.result)
              const newImage = {
                id: response.data.id,
                url: Utils.getMediaUrl(response.data.path),
                path: response.data.path,
                initialPath: response.data.path,
                createdAt: response.data.createdAt,
              }
              images.value.push(newImage)
              emit('update:modelValue', images.value)
              resolve()
            } catch (error) {
              reject(error)
            }
          }
          
          reader.onerror = () => reject(reader.error)
          reader.readAsDataURL(file)
        })
      })

      // Wait for all uploads to complete
      await Promise.all(uploadPromises)
    } catch (error) {
      toast.error('שגיאה בהעלאת התמונות')
      console.error('Upload error:', error)
    } finally {
      isUploading.value = false
    }
  }
  
  const deleteImage = async (image) => {
    try {
      images.value = images.value.filter(img => img.id !== image.id)
      emit('update:modelValue', images.value)
    } catch (error) {
      toast.error('שגיאה במחיקת התמונה')
      console.error('Delete error:', error)
    }
  }

  const editImageModalComponent = computed(() => {
    if (hasFeature("edit-image-modal-v3")) {
      return EditImageModalV3;
    }
    return EditImageModalOld;
  })

  const openEditImage = (image) => {
    editImage.value.upload = image
    editImage.value.uploadInitial = { ...image }
    editImage.value.isEditImageModalOpen = true
  }

  const closeEditImage = () => {
    editImage.value.isEditImageModalOpen = false
  }

  const updateEditImage = async (newImage) => {
    try {
      // Set the syncing state for this image
      syncingImageId.value = editImage.value.upload.id

      // Upload the new image
      const response = await Utils.uploadImageBase64(newImage)

      // Find and update the image in the array
      const imageIndex = images.value.findIndex(img => img.id === editImage.value.upload.id)
      if (imageIndex !== -1) {
        images.value[imageIndex].url = Utils.getMediaUrl(response.data.path)
        images.value[imageIndex].path = response.data.path

        emit('update:modelValue', images.value)
      }
    } catch (error) {
      toast.error('שגיאה בעדכון התמונה')
      console.error('Update image error:', error)
    } finally {
      // Clear the syncing state
      syncingImageId.value = null
    }
  }

  const handleImageClick = (image) => {
    // Only allow clicking if no image is syncing or if clicking the currently syncing image
    if (syncingImageId.value === null) {
      openEditImage(image)
    }
  }
  </script>