const colorToBackgroundClassMap = {
    red: 'bg-red-600',
    orange: 'bg-orange-500',
    yellow: 'bg-yellow-400',
    green: 'bg-emerald-500',
    blue: 'bg-blue-600',
    purple: 'bg-purple-600',
    pink: 'bg-pink-500',
    brown: 'bg-amber-800',
    black: 'bg-black',
    gray: 'bg-gray-500',
    cyan: 'bg-cyan-500'
}

const colorToBorderClassMap = {
    red: 'border-red-500',
    orange: 'border-orange-300',
    yellow: 'border-yellow-500',
    green: 'border-emerald-400',
    blue: 'border-blue-400',
    purple: 'border-purple-500',
    pink: 'border-pink-400',
    brown: 'border-amber-700',
    black: 'border-gray-900',
    gray: 'border-gray-400',
    cyan: 'border-cyan-400'
}

const colorToTextClassMap = {
    red: 'text-red-600',
    orange: 'text-orange-500',
    yellow: 'text-yellow-500',
    green: 'text-emerald-500',
    blue: 'text-blue-600',
    purple: 'text-purple-600',
    pink: 'text-pink-500',
    brown: 'text-amber-800',
    black: 'text-black',
    gray: 'text-gray-500',
    cyan: 'text-cyan-500'
}

const allowedColors = [
    "red",
    "orange",
    "yellow",
    "green",
    "blue",
    "purple",
    "pink",
    "brown",
    "black",
    "gray",
    "cyan"
]

const siteCategories = [
    'חשמל',
    'אינסטלציה',
    'דלת כניסה',
    'אלומיניום',
]

const siteResponsaility = [
    'יזם',
    'מנהל עבודה',
    'קבלן'
]

const siteStatuses = [
    {
        id: 1,
        name: 'לא נבדק',
        color: 'gray'
    },
    {
        id: 2,
        name: 'פתוח',
        color: 'green'
    },
    {
        id: 3,
        name: 'סגור',
        color: 'red'
    }
]

export {
    colorToBackgroundClassMap,
    colorToBorderClassMap,
    colorToTextClassMap,
    allowedColors,
    siteCategories,
    siteResponsaility,
    siteStatuses
}
