async function getLevels(siteId, buildingId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/buildings/${buildingId}/levels`);
  return response.data;
}

async function createLevel(siteId, buildingId, data) {
  const response = await window.axios.post(`/api/v1/sites/${siteId}/levels`, {
    site_building_id: buildingId,
    ...data
  });
  return response.data;
}

async function getLevel(siteId, levelId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/levels/${levelId}`);
  return response.data;
}

async function updateLevel(siteId, levelId, data) {
  const response = await window.axios.put(`/api/v1/sites/${siteId}/levels/${levelId}`, data);
  return response.data;
}

async function removeLevel(siteId, levelId) {
  const response = await window.axios.delete(`/api/v1/sites/${siteId}/levels/${levelId}`);
  return response.data;
}

export default {
  getLevels,
  createLevel,
  getLevel,
  updateLevel,
  removeLevel,
}; 