async function getAreas(siteId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/areas`);
  return response.data;
}

async function createArea(siteId, data) {
  const response = await window.axios.post(`/api/v1/sites/${siteId}/areas`, data);
  return response.data;
}

async function getArea(siteId, areaId) {
  const response = await window.axios.get(`/api/v1/sites/${siteId}/areas/${areaId}`);
  return response.data;
}

async function updateArea(siteId, areaId, data) {
  const response = await window.axios.put(`/api/v1/sites/${siteId}/areas/${areaId}`, data);
  return response.data;
}

async function removeArea(siteId, areaId) {
  const response = await window.axios.delete(`/api/v1/sites/${siteId}/areas/${areaId}`);
  return response.data;
}

export default {
  getAreas,
  createArea,
  getArea,
  updateArea,
  removeArea,
}; 